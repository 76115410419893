import React, { useState,  useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SidebarCollapse from "../Components/SideBarCollapseComponents/SidebarCollapse";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const drawerWidth = 250;
const secondDrawerWidth = 300;

const MainContent = styled("main")({
  flexGrow: 1,
  padding: "16px",
  transition: "margin-right 0.3s ease",
  // marginRight: ({ open }) => (open ? drawerWidth : 0),
});

const Sidebar = styled("aside")(({ theme, collapse }) => ({
  width: collapse ? 0 : drawerWidth,
  flexShrink: 0,
  height: "calc(100vh - 54px)",
  // height: "100vh",
  overflow: "auto",
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: "width 0.3s ease",
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: "border-box",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const RightSidebar = styled("aside")(({ theme, collapse }) => ({
  width: collapse ? 0 : secondDrawerWidth,
  flexShrink: 0,
  height: "calc(100vh - 54px)",
  // height: "100vh",
  overflow: "auto",
  borderRight: collapse ? null : `1px solid ${theme.palette.divider}`,
  transition: "width 0.3s ease",
  [`& .MuiDrawer-paper`]: {
    width: secondDrawerWidth,
    boxSizing: "border-box",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const RightDrawer = styled(Drawer)(({ theme }) => ({
  width: secondDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [`& .MuiDrawer-paper`]: {
    width: secondDrawerWidth,
    boxSizing: "border-box",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const ExpandableView = ({
  children,
  leftSidebarTree,
  selectedItem,
  setSelectedItem,
  handleSaveAs,
  leftCollapse,
  setLeftCollapse,
  showLeftIcon,
  setShowLeftIcon,
}) => {
  const [mainContent, rightContent] = children;

  const [rightCollapse, setRightCollapse] = useState(true);
  const [treeData, setTreeData] = useState(leftSidebarTree);
  useEffect(() => {
    setTreeData(leftSidebarTree);
  }, [leftSidebarTree]);


  const handleListItemClick = (node, event) => {
    if (event.target.closest('.right-sidebar')) {
      return; 
    }
    if (node.children && node.children.length > 0) {
      const updatedTreeData = toggleNodeExpansion(treeData, node);
      setTreeData(updatedTreeData);
    }
    if (window.innerWidth < 600) {
      if (node.children && node.children.length === 0) {
        setLeftCollapse(true);
        setShowLeftIcon(false);
      }
    }
  
    if (node.children && node.children.length === 0) {
      setSelectedItem(node);
      setRightCollapse(false);
    } else {
      setRightCollapse(true);
    }
  };

  const toggleNodeExpansion = (data, nodeToToggle) => {
    return data.map((node) => {
      if (node === nodeToToggle) {
        return { ...node, open: !node.open };
      } else if (node.children) {
        return {
          ...node,
          children: toggleNodeExpansion(node.children, nodeToToggle),
        };
      } else {
        return node;
      }
    });
  };

  const handleMainContentClick = () => {
    setSelectedItem(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreClick = (event, node) => {
    event.stopPropagation();
    setSelectedItem(node);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderTree = (treeData, level = 1) => {
    return (
      <div
        style={{
          width: leftCollapse ? 0 : drawerWidth,
          transition: "width 0.3s ease",
          overflow: "hidden",
        }}
      >
        <List>
          {treeData?.map((node) => (
            <React.Fragment key={node.id}>
              <ListItem
                button
                onClick={(event) => handleListItemClick(node, event)}
                style={{
                  paddingLeft: `${level * 20}px`,
                    backgroundColor:
                      node === selectedItem ? "rgba(0, 0, 0, 0.1)" : "white",
                  height: "35px",
                }}
              >
                {node.children &&
                  (node.children.length > 0 ? (
                    node.open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null)}
                <ListItemText primary={node.name} />
                {node.children.length > 0 && (
                  <div
                    className="Editdeletebitton"
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <MoreHorizIcon
                      onClick={(event) => handleMoreClick(event, node)}
                    />
                  </div>
                )}
              </ListItem>
              {node.children && node.open && (
                <Collapse in={true} timeout="auto" unmountOnExit>
                  {renderTree(node.children, level + 1)}
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    );
  };

  const handleSave = () => {
    handleClose();
    handleSaveAs();
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Sidebar collapse={leftCollapse}>
          <>
            {renderTree(treeData)}
            <Menu
              PaperProps={{
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              disablePortal
              className="custom-menu"
            >
              <MenuItem className="custom-menu-item" onClick={handleSave}>
                <SaveAsIcon className="list-nav-icon" />
                Save as
              </MenuItem>
            </Menu>
          </>
        </Sidebar>
        <SidebarCollapse
          showmenudsk={leftCollapse}
          setShowdskmenu={setLeftCollapse}
          direction={0}
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} sm={3}>
          <RightSidebar collapse={rightCollapse}>
      
            {rightContent}
           
            </RightSidebar>
        </Grid>
        <Grid item xs={12} sm={9}>
          <MainContent onClick={handleMainContentClick}>
            {mainContent}
          </MainContent>
        </Grid>
      </div>
    </div>
  );
};

export default ExpandableView;
