import React from "react";
import themeProvider from "../../ThemeProvider";
import { Button, ThemeProvider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Action } from "./ActionList";
const CircleComponent = ({
  src,
  alt,
  size,
  position,
  title,
  text,
  textSize,
  itemID,
  agilineLink,
  fetched,
  admin,
  sectionTitle,
  pageName,
  setFetched,
  handleDialogToggle,
}) => {
  //URL for admin to change the marketing info
  const imageSize = position === "right" ? `${size / 4}px` : `${size / 2}px`;
  const circleHeight =
    position === "right" ? `${size / 2.7}px` : `${size / 1.4}px`;
  const circleWidth =
    position === "right" ? `${size / 2.7}px` : `${size / 1.4}px`;

  //animation variables
  const [showAnimation, setShowAnimation] = React.useState(false);
  React.useEffect(() => {
    async function FillData() {
      setShowAnimation(true);
    }
    if (fetched) {
      FillData();
    }
  }, [fetched]);

  const isAdmin = () => {
    return admin;
  };

  // const isAdmin = () => {
  //   let result =
  //     auth.user !== null &&
  //     auth.user !== undefined &&
  //     auth.user.isAdmin === true;
  //   return result;
  // };
  //for editing pop up
  const [open, setOpen] = React.useState(false);

  const handleLinkClick = () => {
    window.open(agilineLink, "_blank");
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <div
        style={{
          display: "flex",
          flexDirection: position === "right" ? "row" : "column",
          alignItems: "center",
          padding: position === "right" ? "2rem" : "60px",
          position: "relative",
          transition: "transform 3s ease",
          transform: showAnimation ? "translateX(0)" : "translateX(-350%)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: circleWidth,
            height: circleHeight,
            borderRadius: "50%",
            backgroundColor: themeProvider.palette.background.default,
            overflow: "hidden",
            marginRight: position === "right" ? "1rem" : "0",
            marginBottom: position === "bottom" ? "1rem" : "0",
          }}
        >
          {/* <Link to={agilineLink} target="_blank"> */}
          <img
            src={src}
            alt={alt}
            style={{
              width: imageSize,
              height: imageSize,
              borderRadius: "50%",
              objectFit: "cover",
            }}
            onClick={handleLinkClick}
          />
          {/* </Link> */}
        </div>
        <div
          style={{
            width: position === "right" ? "50%" : "auto",
          }}
        >
          {/* <div> */}
          {title && (
            <div
              style={{
                marginTop: "1rem",
                fontSize: `${textSize}px`,
                fontWeight: "bold",
                color: themeProvider.palette.primary.main,
                textAlign: position === "right" ? "left" : "center",
                maxWidth: "300px", // set the maximum width of the container
                wordWrap: "break-word", // allow the text to wrap to a new line when it reaches the maximum width
              }}
            >
              {title}
            </div>
          )}
          {text && (
            <div
              style={{
                fontSize: `${textSize * 0.75}px`,
                color: "#212325",
                justifyContent: "center",
                textAlign: position === "right" ? "left" : "center",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                maxHeight: "260px",
                maxWidth: "300px", // set the maximum width of the container
                wordWrap: "break-word", // allow the text to wrap to a new line when it reaches the maximum width
              }}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          )}
        </div>

        {isAdmin() && (
          <Button
            style={{
              position: "sticky",
              top: 165,
              right: 225,
            }}
            onClick={(event) =>
              handleDialogToggle(event, itemID, title, Action.EDIT_ITEM)
            }
          >
            <EditIcon
              style={{
                backgroundColor: "#f2f2f2",
                borderColor: "#e8e8e8",
                borderWidth: "2px",
                borderRadius: "25px",
                height: "2rem",
                width: "2rem",
                padding: "0.5rem",
                marginLeft: "5%",
                color: "#212325",
              }}
            />
          </Button>
        )}

        {/* {isAdmin() && (
          <Link
            to={EDIT_URL + itemID}
            target="_blank"
            style={{
              position: "sticky",
              top: 165,
              right: 225,
            }}
          >
            <EditIcon
              style={{
                backgroundColor: "#f2f2f2",
                borderColor: "#e8e8e8",
                borderWidth: "2px",
                borderRadius: "25px",
                height: "2rem",
                width: "2rem",
                padding: "0.5rem",
                marginLeft: "5%",
                color: theme.palette.black.light,
              }}
            />
          </Link>
        )} */}
      </div>
    </ThemeProvider>
  );
};

export default CircleComponent;
