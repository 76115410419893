// edgeFunctions.js

import { useCallback } from "react";

export const useFlipArrow = (setEdges) => {
  return useCallback(
    (edgeId) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                source: edge.target,
                sourceHandle: edge.targetHandle,
                target: edge.source,
                targetHandle: edge.sourceHandle,
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeColor = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                style: { ...edge.style, stroke: newColor },
                markerEnd: {
                  ...edge.markerEnd,
                  color: newColor,
                },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};

// export const useChangeMarkerEndColor = (setEdges) => {
//   return useCallback(
//     (edgeId, newColor) => {
//       setEdges((prevEdges) =>
//         prevEdges.map((edge) =>
//           edge.id === edgeId
//             ? {
//                 ...edge,
//                 markerEnd: {
//                   ...edge.markerEnd,
//                   color: newColor,
//                 },
//               }
//             : edge
//         )
//       );
//     },
//     [setEdges]
//   );
// };

export const useChangeMarkerEndColor = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          if (edge.id === edgeId) {
            if (typeof edge.markerEnd === "object") {
              // If markerEnd is an object, update its color property
              return {
                ...edge,
                markerEnd: {
                  ...edge.markerEnd,
                  color: newColor,
                },
              };
            } else if (typeof edge.markerEnd === "string") {
              // If markerEnd is a string, keep it as it is
              return edge;
            }
          }
          return edge; // For other edges, return them as they are
        })
      );
    },
    [setEdges]
  );
};

export const useChangeMarkerEnd = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerEnd) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerEnd: {
                  ...edge.markerEnd,
                  type: newMarkerEnd,
                  width: 30,
                  height: 30,
                },
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeMarkerEndSVG = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerEnd) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerEnd: newMarkerEnd,
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeMarkerStart = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerStart) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerStart: {
                  ...edge.markerStart,
                  type: newMarkerStart,
                  width: 30,
                  height: 30,
                },
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeMarkerStartSVG = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerStart) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerStart: newMarkerStart,
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeLineStyle = (setEdges) => {
  return useCallback(
    (edgeId, lineStyle) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                animated: lineStyle === "dashed" ? true : false,
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};

export const useChangeThickness = (setEdges) => {
  return useCallback(
    (edgeId, newStrokeWidth) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                style: { ...edge.style, strokeWidth: newStrokeWidth },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabel = (setEdges) => {
  return useCallback(
    (edgeId, text) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                label: text,
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabelFontStyle = (setEdges) => {
  return useCallback(
    (edgeId, newFontSize) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          console.log("edge.labelStyle ", edge.labelStyle);
          return edge.id === edgeId
            ? {
                ...edge,
                labelStyle: {
                  ...edge.labelStyle,
                  fontSize: newFontSize,
                },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabelColorStyle = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          console.log("edge.labelStyle ", edge.labelStyle);
          return edge.id === edgeId
            ? {
                ...edge,
                labelStyle: {
                  ...edge.labelStyle,
                  fill: newColor,
                },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabelBgStyle = (setEdges) => {
  return useCallback(
    (edgeId, newLabelBgStyle) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                labelBgStyle: { ...newLabelBgStyle },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeType = (setEdges) => {
  return useCallback(
    (edgeId, type) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                type: type,
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};

export const useDeleteEdge = (setEdges) => {
  return useCallback(
    (edgeId) => {
      setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== edgeId));
    },
    [setEdges]
  );
};

// export const useEdgeClick = (FlipArrow) => {
//   return useCallback(
//     (event, clickedEdge) => {
//       FlipArrow(clickedEdge.id);
//     },
//     [FlipArrow]
//   );
// };
export const useEdgeClick = (setClickedEdge, setDrawerOpen) => {
  return useCallback(
    (event, clickedEdge) => {
      setClickedEdge(clickedEdge);
      setDrawerOpen(true);
    },
    [setClickedEdge]
  );
};
