import React, { useState } from "react";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import {
  Typography,
  InputLabel,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
function DiagHeader({ open, handlePopup, item, handleNext, handleBack, data }) {
  const [formData, setFormData] = useState({
    diagTempID:
      item.diagTempID !== null && item.diagTempID !== undefined
        ? item.diagTempID
        : 0,
    diagID: item.diagID !== null && item.diagID !== undefined ? item.diagID : 0,
    title: item.title ? item.title : "",
    description: item.description ? item.description : "",
    notes: item.notes ? item.notes : "",
  });
  const options = data
    ?.filter((item) => item.diagID === 0)
    .map((item) => ({
      value: item.name, // Use name as the visible value
      diagTempID: item.diagTempID, // Store diagTempID for selection
    }));
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Check the type of input element
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  return (
    <CustomPopUpForm
      open={open}
      onClose={handlePopup}
      title="Project Information"
    >
      <div className="card-info">
        <div className="card-info-inside">
          <Typography style={{ fontWeight: "bold" }}>Title:</Typography>
          <TextField
            type="text"
            className="edit-popup"
            name="title"
            onChange={handleInputChange}
            value={formData.title}
          />
        </div>
        <div className="card-info-inside">
          <Typography style={{ fontWeight: "bold" }}>Description:</Typography>
          <TextField
            type="text"
            className="edit-popup"
            name="description"
            onChange={handleInputChange}
            value={formData.description}
          />
        </div>
        <div className="card-info-inside">
          <Typography style={{ fontWeight: "bold" }}>Notes:</Typography>
          <TextField
            type="text"
            className="edit-popup"
            name="notes"
            onChange={handleInputChange}
            value={formData.notes}
          />
        </div>
      </div>
      <div style={{ width: "90%", display: "flex" }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleBack}
          className="back-addtmplt"
        >
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={() => handleNext(formData)}
        >
          Next
        </Button>
      </div>
    </CustomPopUpForm>
  );
}

export default DiagHeader;
