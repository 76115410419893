import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/icofontcharts/icofont.min.css";
import CustomPopUpForm from "../layout/CustomPopUpForm";

const DiscoverEdit = ({
  source,
  dataSourceArID,
  description,
  arrayName,
  category,
  arID,
  setIsOpen,
  isOpen,
  isOpenDiscoverEdit,
  setIsOpenDiscoverEdit,
}) => {
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState({});
  const [loading, setLoading] = useState(false);

  const extractNames = (obj) => {
    let names = [];
    if (obj === null || typeof obj !== "object") {
      return names;
    }

    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        names = names.concat(extractNames(item));
      });
    } else {
      if (
        "type" in obj &&
        (obj.type === "TextBox" ||
          obj.type === "TextArea" ||
          obj.type === "Date" ||
          obj.type === "Image" ||
          obj.type === "Switch" ||
          obj.type === "Checkbox" ||
          obj.type === "Radio") &&
        "name" in obj
      ) {
        names.push({ name: obj.name, type: obj.type });
      }
      Object.values(obj).forEach((value) => {
        names = names.concat(extractNames(value));
      });
    }
    return names;
  };

  const names = source ? extractNames(JSON.parse(source)) : [];

  const extractNameData = (obj) => {
    let nameDataPairs = [];

    if (obj === null || typeof obj !== "object") {
      return nameDataPairs;
    }

    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        nameDataPairs = nameDataPairs.concat(extractNameData(item));
      });
    } else if (typeof obj === "object" && obj !== null) {
      if (
        "type" in obj &&
        (obj.type === "TextBox" ||
          obj.type === "TextArea" ||
          obj.type === "Date" ||
          obj.type === "Image" ||
          obj.type === "Switch" ||
          obj.type === "Checkbox" ||
          obj.type === "Radio") &&
        "name" in obj &&
        "data" in obj
      ) {
        let dataWithoutBrackets = "";
        if (obj.type === "Image") {
          dataWithoutBrackets = obj.imageText
            ? obj.imageText.replace(/[{}]/g, "").trim().toLowerCase()
            : "";
        } else if (obj.type === "Date") {
          dataWithoutBrackets = obj.inputText
            ? obj.inputText.replace(/[{}]/g, "").trim().toLowerCase()
            : "";
        } else {
          dataWithoutBrackets = obj.data
            ? obj.data.replace(/[{}]/g, "").trim().toLowerCase()
            : "";
        }
        nameDataPairs.push({ name: obj.name, data: dataWithoutBrackets });
      }
      Object.values(obj).forEach((value) => {
        nameDataPairs = nameDataPairs.concat(extractNameData(value));
      });
    }
    return nameDataPairs;
  };

  const nameDataPairs = extractNameData(JSON.parse(source));

  const tableControlNames = [];

  const extractTableControlDatas = (obj) => {
    let tableControlDatas = [];

    if (!obj) return tableControlDatas;

    const traverse = (obj) => {
      if (obj === null || typeof obj !== "object") {
        return;
      }
      if (Array.isArray(obj)) {
        obj.forEach((item) => traverse(item));
      } else if (typeof obj === "object") {
        if (
          (obj.type === "Table" && obj.control && obj.htmlTag === "td") ||
          (obj.controltype === "Row" && obj.control && obj.htmlTag === "td") ||
          (obj.type === "Table" && obj.data && obj.htmlTag === "td")
        ) {
          const dataWithoutBrackets = obj.data.replace(/[{}]/g, "").trim();
          tableControlDatas.push({
            option: obj.option || obj.option,
            data: dataWithoutBrackets,
          });
          obj.control &&
            obj.control.forEach((controlItem) => traverse(controlItem));
        } else {
          Object.values(obj).forEach((value) => traverse(value));
        }
      }
    };

    traverse(obj);

    return tableControlDatas;
  };

  const tableControlDatas = extractTableControlDatas(JSON.parse(source));

  let APIProvider = window.APIProvider;



  const [fieldData, setFieldData] = useState([]);
  const [fieldNames, setFieldNames] = useState([]);

  let GET_HEADER = {
    method: "GET",
    headers: {},
    mode: "cors",
  };

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          APIProvider.baseURL +
            APIProvider.recordPath.ReadArray +
            "?arID=" +
            dataSourceArID,
          GET_HEADER
        );
        const data = await response.json();

 

        const fetchedSourceData = JSON.parse(data.source);

        setFieldData(fetchedSourceData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dataSourceArID]);

  useEffect(() => {
    const fieldNames = fieldData
      ? fieldData
          .filter((item) => item.elementtype === "field")
          .map((item) => item.fieldname.toLowerCase())
      : [];
    setFieldNames(fieldNames);
  }, [fieldData]);

  const findClosestMatch = (inputName) => {
    const lowercaseName = inputName.toLowerCase();
    const closestMatch = fieldNames.find((fieldName) =>
      fieldName.includes(lowercaseName)
    );
    return closestMatch ? closestMatch : "";
  };

  const modifiedSource = JSON.parse(source);

  console.log(modifiedSource);

  const keys = Object.keys(selectedValues);

  const values = Object.values(selectedValues);

  function findObjectByKey(obj, key, value) {
    if (obj instanceof Array) {
      for (let i = 0; i < obj.length; i++) {
        const result = findObjectByKey(obj[i], key, value);
        if (result) {
          return result;
        }
      }
    } else {
      for (const prop in obj) {
        if (prop === key && obj[prop] === value) {
          return obj;
        }
        if (typeof obj[prop] === "object") {
          const result = findObjectByKey(obj[prop], key, value);
          if (result) {
            return result;
          }
        }
      }
    }
    return null;
  }

  const foundTableHeaders = [];
  keys.forEach((key) => {
    const foundObject = findObjectByKey(modifiedSource, "name", key);
    if (foundObject && foundObject.hasOwnProperty("tableHeader")) {
      foundTableHeaders.push(foundObject["tableHeader"]);
      const index = foundTableHeaders.indexOf(foundObject.tableHeader);
      if (index !== -1 && values[index]) {
        foundObject.data = `{${values[index]}}`;
      }
    }
  });

  function findMatchingObjects(data) {
    data.forEach((obj) => {
      if (
        obj.hasOwnProperty("option") &&
        foundTableHeaders.includes(obj.option)
      ) {
        const index = foundTableHeaders.indexOf(obj.option);
        if (index !== -1 && values[index]) {
          obj.data = `{${values[index]}}`;
        }
      }
      if (obj.hasOwnProperty("control")) {
        findMatchingObjects(obj.control, values);
      }
    });
  }

  findMatchingObjects(modifiedSource, values);

  const updateDescriptionNames = (obj, inputName, selectedValues) => {
    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        updateDescriptionNames(item, inputName, selectedValues);
      });
    } else if (typeof obj === "object") {
      if (obj.type === "Table" && obj.control) {
        obj.control.forEach((controlItem) => {
          updateDescriptionNames(controlItem, inputName, selectedValues);
        });
      } else if (obj.controltype === "Row" && obj.control) {
        obj.control.forEach((controlItem) => {
          updateDescriptionNames(controlItem, inputName, selectedValues);
        });
      } else if (obj.type === "Table" && obj.name) {
        const closestMatch = selectedValues[obj.name];
        if (closestMatch !== undefined && closestMatch !== "") {
          obj.data = closestMatch;
        }
      } else if (
        (obj.type === "TextBox" ||
          obj.type === "TextArea" ||
          obj.type === "Date" ||
          obj.type === "Image" ||
          obj.type === "Switch" ||
          obj.type === "Checkbox" ||
          obj.type === "Radio") &&
        obj.name
      ) {
        const closestMatch =
          selectedValues[obj.name] || findClosestMatch(obj.name);

        if (closestMatch !== undefined && closestMatch !== "") {
          // obj.data = `{${closestMatch}}`;
          if (obj.type === "Date") {
            obj.inputText = `{${closestMatch}}`;
            obj.data = `{${closestMatch}}`;
          } else {
            obj.data = `{${closestMatch}}`;
          }

          if (obj.type === "Date") {
            obj.inputText = `{${closestMatch}}`;
            obj.data = `{${closestMatch}}`;
          } else if (obj.type === "Image" || obj.type === "Switch") {
            obj.imageText = `{${closestMatch}}`;
          } else if (
            obj.type === "Checkbox" ||
            obj.type === "Radio" ||
            obj.type === "TextBox" ||
            obj.type === "TextArea"
          ) {
            obj.data = `{${closestMatch}}`;
          } else {
            obj.inputText = `{${closestMatch}}`;
          }
        }
      } else {
        Object.values(obj).forEach((value) => {
          updateDescriptionNames(value, inputName, selectedValues);
        });
      }
    }
  };

  names.forEach((inputName) => {
    updateDescriptionNames(modifiedSource, inputName, selectedValues);
  });

  const updatedSource = JSON.stringify(modifiedSource);

  const handleCreate = async () => {
    setLoading(true);
    try {
      if (updatedSource) {
        const requestBody = {
          arID: arID,
          arrayName: arrayName,
          description: description,
          category: category,
          source: updatedSource,
          IsTemplate: "0",
          htmlOutput: "new",
          dataSourceArID: dataSourceArID,
        };
        const response = await fetch(
          APIProvider.baseURL + APIProvider.recordPath.WriteArray,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {

          const stateObject = {
            arID: arID,
            arrayName: arrayName,
            description: description,
            category: category,
            source: updatedSource,
            IsTemplate: "0",
            htmlOutput: "new",
            dataSourceArID: dataSourceArID,
          };
          setLoading(false);
          navigate(`/Form/${arID}`, { state: stateObject });
        } else {
          console.error("Error:", response.statusText);
        }
      } else {
        console.error("Error: Source data is missing.");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleSelectChange = (inputName, newValue) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [inputName]: newValue,
    }));
  };

  const tableFieldNames = fieldData
    ? fieldData
        .filter((item) => item.elementtype === "array" && item.type === "table")
        .flatMap((item) => item.elements.map((element) => element.fieldname))
    : [];

  const handleCancel = () => {
    setIsOpenDiscoverEdit(false);
    if (isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <CustomPopUpForm
      open={isOpenDiscoverEdit}
      onClose={handleCancel}
      title="Discover"
      height="60%"
    >
      <>
        <div className="card-info-ovr-flow">
          <TableContainer>
            <Table className="custom-table" style={{ height: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Control</TableCell>
                  <TableCell>Data Source</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {names.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: "30%", color: "#0056b3" }}>
                      {item.name}
                    </TableCell>
                    <TableCell style={{ width: "100%" }}>
                      <Select
                        className="data-fild-size"
                        value={
                          selectedValues[item.name] ||
                          // findClosestMatch(item.name) ||
                          nameDataPairs.find(
                            (pair) =>
                              pair.name === item.name &&
                              fieldNames.includes(
                                pair.data.replace(/[{}]/g, "").trim() ||
                                  pair.imageText
                              )
                          )?.data ||
                          ""
                        }
                        onChange={(event) =>
                          handleSelectChange(item.name, event.target.value)
                        }
                      >
                        {fieldNames
                          .filter((fieldName) => {
                            if (
                              item.type === "TextBox" ||
                              item.type === "TextArea"
                            ) {
                              return fieldData.find(
                                (field) =>
                                  field.fieldname &&
                                  field.fieldname.toLowerCase() ===
                                    fieldName.toLowerCase() &&
                                  field.elementtype === "field" &&
                                  field.fieldtype === "text"
                              );
                            } else if (item.type === "Date") {
                              const foundField = fieldData.find(
                                (field) =>
                                  field.fieldname &&
                                  field.fieldname.toLowerCase() ===
                                    fieldName.toLowerCase() &&
                                  field.elementtype === "field" &&
                                  (field.fieldtype === "datetime" ||
                                    field.fieldtype === "time" ||
                                    field.fieldtype === "date")
                              );
                              return foundField;
                            } else if (item.type === "Image") {
                              const foundField = fieldData.find(
                                (field) =>
                                  field.fieldname &&
                                  field.fieldname.toLowerCase() ===
                                    fieldName.toLowerCase() &&
                                  field.elementtype === "field" &&
                                  field.fieldtype === "Image"
                              );
                              return foundField;
                            } else if (
                              item.type === "Switch" ||
                              item.type === "Checkbox" ||
                              item.type === "Radio"
                            ) {
                              const foundField = fieldData.find(
                                (field) =>
                                  (field.fieldname &&
                                    field.fieldname.toLowerCase() ===
                                      fieldName.toLowerCase() &&
                                    field.elementtype === "field" &&
                                    field.fieldtype === "boolean") ||
                                  (field.fieldname &&
                                    field.fieldname.toLowerCase() ===
                                      fieldName.toLowerCase() &&
                                    field.elementtype === "field" &&
                                    field.fieldtype === "number" && (field.controltype === "Checkbox" || field.controltype === "Switch" || field.controltype === "Radio" ))
                              );

                              return foundField;
                            }
                          })
                          .map((fieldName, index) => (
                            <MenuItem key={index} value={fieldName}>
                              {fieldName}
                            </MenuItem>
                          ))}
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}

                {tableControlNames.map((controlName, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: "30%", color: "#0056b3" }}>
                      {controlName}
                    </TableCell>
                    <TableCell style={{ width: "100%" }}>
                      <Select
                        className="data-fild-size"
                        value={
                          selectedValues[controlName] ||
                          tableControlDatas.find(
                            (pair) =>
                              pair.option === controlName &&
                              tableFieldNames.includes(
                                pair.data.replace(/[{}]/g, "").trim()
                              )
                          )?.data ||
                          ""
                        }
                        onChange={(e) =>
                          handleSelectChange(controlName, e.target.value)
                        }
                      >
                        {tableControlDatas.map((pair, pairIndex) => {
                          const fieldNameMatch = tableFieldNames.includes(
                            pair.data.replace(/[{}]/g, "").trim()
                          );
                          if (pair.option === controlName && fieldNameMatch) {
                            return (
                              <MenuItem key={pairIndex} value={pair.data}>
                                {pair.data}
                              </MenuItem>
                            );
                          }
                          return null;
                        })}

                        {tableFieldNames.map((fieldName, fieldIndex) => {
                          const fieldNameWithoutBraces = fieldName
                            .replace(/[{}]/g, "")
                            .trim();
                          const dataExists = tableControlDatas.some(
                            (pair) =>
                              pair.option === controlName &&
                              pair.data.includes(fieldNameWithoutBraces)
                          );
                          if (!dataExists) {
                            return (
                              <MenuItem key={fieldIndex} value={fieldName}>
                                {fieldName}
                              </MenuItem>
                            );
                          }
                          return null;
                        })}
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
      <div style={{ width: "93%", display: "flex" }}>
        <Button sx={{ fontSize: "1rem" }} fullWidth onClick={handleCancel}>
          Back
        </Button>
        <Button
          sx={{ fontSize: "1rem" }}
          variant="contained"
          fullWidth
          onClick={handleCreate}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
        </Button>
      </div>
    </CustomPopUpForm>
  );
};

export default DiscoverEdit;
