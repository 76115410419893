import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AppBar, Button } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LeftNavBar from "./LeftNavBar";
import themeProvider from "../../ThemeProvider";
import { useNavigate } from "react-router-dom";
import { Theme } from "../IncludeFile/Theme";

const drawerWidth = "200px";

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: themeProvider.palette.landingBg.navBar,
  height: "55px",
  alignContent: "center",
  alignItems: "center",
  borderBottom: "1px solid #ccc",
}));

function LandingNavBar({ title }) {
  const [open, setOpen] = React.useState(false);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const navigate = useNavigate()

  const handleBack = ()=>{
    navigate("/")
  }




  return (
    <Box sx={{ display: "flex" }}>
      <>
      <AppBarStyled position="fixed" open={open} elevation={0}>
   
        <IconButton
          // color="black"
          style={{color:"black"}}
          aria-label="open drawer"
          onClick={handleToggleDrawer}
          edge="start"
          sx={{ mr: 1, ml: 1 }}
        >
          <MenuIcon />
        </IconButton>

        {title && <Typography className="custom-typogrpy">{title}</Typography>}
        {/* <Button variant="contained" color="success"  onClick={handleBack} style={{position : "absolute" , right : "10px"}}>
          {Theme.GoBack}
          Back
          </Button> */}
      </AppBarStyled>
      {open && <LeftNavBar open={open} drawerWidth={drawerWidth} />}
      </>
     
    </Box>
  );
}

export default LandingNavBar;
