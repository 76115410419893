import React, { useState } from "react";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import Minutes from "./Minutes";
import Hourly from "./Hourly";
import Daily from "./Daily";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Yearly from "./Yearly";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const ScheduleSelector = () => {
  const [scheduleData, setScheduleData] = useState({
    minutes: 1,
    hourlyOption: "",
    hours: 1,
    hourlyTime: null,
    dailyOption: "",
    days: 1,
    dailyTime: null,
    daysOfTheWeek: [],
    weekTime: null,
    monthlyOption: "",
    monthlyDay: 1,
    monthlyMonth: 1,
    monthlyTime: null,
    monthlySequence: "",
    monthlyDayOfTheWeek: "",
    yearlyOption: "",
    yearlyMonth: "",
    yearlyDay: 1,
    yearlyTime: null,
    yearlySequence: "",
    yearlyDayOfTheWeek: "",
  });
  const [frequency, setFrequency] = useState("Minutes");

  const handleNumberChange = (event, min, max) => {
    let { name, value } = event.target;
    if (value < min) value = min;
    if (value > max) value = max;
    setScheduleData({
      ...scheduleData,
      [name]: value,
    });
  };
  const handleTimeChange = (event, name) => {
    setScheduleData({
      ...scheduleData,
      [name]: event,
    });
  };
  const handleDaysOfTheWeekOptionsChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === "all") {
      setScheduleData(
        ...scheduleData,
        scheduleData.daysOfTheWeek.length === daysOfWeek.length
          ? []
          : daysOfWeek
      );
      return;
    }
    setScheduleData({
      ...scheduleData,
      daysOfTheWeek: value,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setScheduleData({
      ...scheduleData,
      [name]: value,
    });
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  const renderSubOptions = () => {
    switch (frequency) {
      case "Minutes":
        return (
          <Minutes
            scheduleData={scheduleData}
            handleNumberChange={handleNumberChange}
          />
        );
      case "Hourly":
        return (
          <Hourly
            scheduleData={scheduleData}
            handleNumberChange={handleNumberChange}
            handleTimeChange={handleTimeChange}
            handleChange={handleChange}
          />
        );
      case "Daily":
        return (
          <Daily
            scheduleData={scheduleData}
            handleNumberChange={handleNumberChange}
            handleTimeChange={handleTimeChange}
            handleChange={handleChange}
          />
        );
      case "Weekly":
        return (
          <Weekly
            scheduleData={scheduleData}
            setScheduleData={setScheduleData}
            handleTimeChange={handleTimeChange}
            handleDaysOfTheWeekOptionsChange={handleDaysOfTheWeekOptionsChange}
          />
        );
      case "Monthly":
        return (
          <Monthly
            scheduleData={scheduleData}
            handleNumberChange={handleNumberChange}
            handleTimeChange={handleTimeChange}
            handleChange={handleChange}
          />
        );
      case "Yearly":
        return (
          <Yearly
            scheduleData={scheduleData}
            handleNumberChange={handleNumberChange}
            handleTimeChange={handleTimeChange}
            handleChange={handleChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        margin: "0.5rem",
      }}
    >
      <FormControl fullWidth size="small">
        <InputLabel>Frequency</InputLabel>
        <Select
          size="small"
          label="Frequency"
          value={frequency}
          onChange={handleFrequencyChange}
        >
          {["Minutes", "Hourly", "Daily", "Weekly", "Monthly", "Yearly"].map(
            (freq) => (
              <MenuItem key={freq} value={freq}>
                {freq}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      {renderSubOptions()}
    </div>
  );
};

export default ScheduleSelector;
