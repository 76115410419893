import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
} from "@mui/material";
import BoxTemplate from "../BoxTemplate";
import CallSplitIcon from "@mui/icons-material/CallSplit";

const checkResourceMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];
const resourceListOfFieldsMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "Field1" },
  { value: 1, label: "Field2" },
];
function ConditionalSplitBox({ formData, handleChange }) {
  return (
    <BoxTemplate
      icon={<CallSplitIcon style={{ marginRight: "0.5rem" }} />}
      title={"Split"}
      button={"Preview"}
      buttonOnClick={() => console.log("ButtonClicked")}
      subTitle={"Split according to condition"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            label="Name"
            size="small"
            variant="outlined"
            fullWidth
            name="name"
            value={formData.name}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Resource Fields</InputLabel>
            <Select
              label="Resource Fields"
              size="small"
              name="resourceListOfFields"
              value={formData.resourceListOfFields}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              {resourceListOfFieldsMenuItems
                .filter((option) => option.value !== -1)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Check Resource</InputLabel>
            <Select
              label="Check Resource"
              size="small"
              name="checkResource"
              value={formData.checkResource}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              {checkResourceMenuItems
                .filter((option) => option.value !== -1)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </BoxTemplate>
  );
}

export default ConditionalSplitBox;
