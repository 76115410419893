import React from "react";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import BoxTemplate from "../BoxTemplate";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const onStartMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "Action1" },
  { value: 1, label: "Action2" },
];
function EmailAction({ formData, handleChange }) {
  return (
    <BoxTemplate
      icon={<ReceiptLongOutlinedIcon style={{ marginRight: "0.5rem" }} />}
      title={"Action List"}
      button={"Preview"}
      buttonOnClick={() => console.log("ButtonClicked")}
      //   subTitle={"Profiles enter the flow when this happens."}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <InputLabel>On Start</InputLabel>
            <Select
              label="On Start"
              size="small"
              name="onStart"
              value={formData.onStart}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              {onStartMenuItems
                .filter((option) => option.value !== -1)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="loop"
            label="Loop"
            size="small"
            value={formData.loop}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="onExecutionAction"
            label="On Execution Action"
            size="small"
            value={formData.onExecutionAction}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <InputLabel>On End</InputLabel>
            <Select
              label="On End"
              size="small"
              name="onEnd"
              value={formData.onEnd}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              {onStartMenuItems
                .filter((option) => option.value !== -1)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </BoxTemplate>
  );
}

export default EmailAction;
