import React, { useState } from "react";
import IconBox from "../IconBox";
import { TextField, Select, MenuItem, Autocomplete } from "@mui/material";
import {
  PiAlignRightSimpleBold,
  PiAlignLeftSimpleDuotone,
} from "react-icons/pi";
import { BsAlignCenter } from "react-icons/bs";
import { GetGlyphicon } from "../../../apiCalls/DiagramsAPI";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  autocomplete: {
    width: "auto",
    height: "30px",
    padding: 0,
    margin: 0,
    minWidth: "75%",
    fontSize: "14px !important",
    marginLeft: "11px",
    boxShadow: "none !important",
  },
});

function CaptionIconDropDown({
  selectedItemID,
  editedProperties,
  handlePropertyChange,
}) {

  const classes = useStyles();
  const [captionIcon, setCaptionIcon] = useState({
    iconPosition: "",
    iconSize: "",
    iconType: "",
    iconLink: "",
  });
  const [glyphIcons, setGlyphIcons] = useState();
  React.useEffect(() => {
    async function fetchData() {
      try {
        const icons = await GetGlyphicon();
        setGlyphIcons(icons);
      } catch (error) {
        console.error("Fetch Error ", error);
        toast.error("Error fetching icons: " + error.message);
      }
    }
    fetchData();
  }, []);
  React.useEffect(() => {
    const propertyGroup = editedProperties.find(
      (group) => group[0]?.diagToolID === selectedItemID
    );

    if (propertyGroup) {
      const iconType = propertyGroup.find(
        (property) => property.propertyName === `Caption_IconType`
      );
      const iconLink = propertyGroup.find(
        (property) => property.propertyName === `Caption_IconLink`
      );
      const iconPosition = propertyGroup.find(
        (property) => property.propertyName === `Caption_IconPosition`
      );
      const iconSize = propertyGroup.find(
        (property) => property.propertyName === `Caption_IconSize`
      );
      setCaptionIcon((prevCaptionIcon) => ({
        ...prevCaptionIcon,
        iconPosition: iconPosition ? iconPosition.diagPropValue.toString() : "",
        iconSize: iconSize ? iconSize.diagPropValue.toString() : "",
        iconType: iconType ? iconType.diagPropValue.toString() : "",
        iconLink: iconLink ? iconLink.diagPropValue.toString() : "",
      }));
    }
  }, [selectedItemID, editedProperties]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCaptionIcon((prevState) => {
      const updatedCaptionIcon = {
        ...prevState,
        [name]: value,
      };
      if (name === "iconSize") {
        handlePropertyChange("Caption_IconSize", updatedCaptionIcon.iconSize);
      }
      if (name === "iconLink") {
        handlePropertyChange("Caption_IconLink", updatedCaptionIcon.iconLink);
      }
      if (name === "iconType") {
        handlePropertyChange("Caption_IconType", updatedCaptionIcon.iconType);
      }
      return updatedCaptionIcon;
    });
  };
  const handleIconLinkChange = (e, option) => {
    console.log("option ", option);
    setCaptionIcon((prevState) => {
      const updatedCaptionIcon = {
        ...prevState,
        iconLink: option ? option : "",
      };
      handlePropertyChange("Caption_IconLink", updatedCaptionIcon.iconLink);
      return updatedCaptionIcon;
    });
  };

  const handleIconPositionChange = (name, value) => {
    setCaptionIcon({
      ...captionIcon,
      iconPosition: name,
    });
    handlePropertyChange("Caption_IconPosition", name);
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="rht-info fontlft-size">Icon Position</div>
      <IconBox
        handleChange={handleIconPositionChange}
        buttonList={[
          {
            icon: <PiAlignLeftSimpleDuotone />,
            name: "Left",
            value: captionIcon.iconPosition === "Left" ? 1 : 0,
          },
          {
            icon: <BsAlignCenter />,
            name: "Center",
            value: captionIcon.iconPosition === "Center" ? 1 : 0,
          },
          {
            icon: <PiAlignRightSimpleBold />,
            name: "Right",
            value: captionIcon.iconPosition === "Right" ? 1 : 0,
          },
        ]}
      />
      <div className="rht-info fontlft-size">Icon Size</div>
      <Select
        name="iconSize"
        className="rht-fld-select"
        value={captionIcon.iconSize}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {["small", "medium", "large"].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <div className="rht-info fontlft-size">Icon Type</div>
      <Select
        name="iconType"
        className="rht-fld-select"
        value={captionIcon.iconType}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {["Bootstrap", "Url"].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {captionIcon.iconType === "Url" ? (
        <>
          <div className="rht-info fontlft-size">Icon Link</div>
          <input
            name="iconLink"
            label="Icon Link"
            className="rht-fld-select"
            value={captionIcon.iconLink}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </>
      ) : (
        <>
          <div className="rht-info fontlft-size">Icon Link</div>
          {/* <Select
            name="iconLink"
            className="rht-fld-select"
            value={captionIcon.iconLink}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {glyphIcons?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select> */}
          <Autocomplete
            name="iconLink"
            options={glyphIcons}
            onChange={handleIconLinkChange}
            size="small"
            renderInput={(params) => (
              <TextField
                className={classes.autocomplete}
                name="iconLink"
                {...params}
              />
            )}
          />
        </>
      )}
    </>
  );
}

export default CaptionIconDropDown;
