import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import TabsComponent from "../diagramsComponents/TabsComponent";
import { Theme, Image } from "../IncludeFile/Theme";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import "./RecursiveTreeView.css";
import Controls from "../IncludeFile/Controls";

const GeneralSection = ({
  tabValue,
  setTabValue,
  rightsidetabs,
  selectedItem,
  isRootItem,
  treeData,
  editedData,
  handleEditChange,
  tabSelect,
  handleEnterForNumber,
  handleAgree,
  handlePopupClick,
  isPopupVisible,
  handleClose,
  selectedOption,
  dataSourceArID,
  fieldNames,
  fieldNamestable,
  handleChange,
  inputValueRadio,
  handleInputChangeRadio,
  handleAddControlRadio,
  inputValue,
  handleInputChange,
  handleAddControlCheckbox,
  selectedOptioncheck,
  handleCheckboxChange,
  selectedPositioncheck,
  handleCheckboxPositonChange,
  selectedOptionSwitchcheck,
  handleSwitchChange,
  inputRef,
  handleImageChange,
  handleImageClick,
  handleDateChange,
  isOpen,
  handleEditChangeName1,
  handleRenameConfirm,
  controlsVisible,
  addNewColumn,
  selectedControlType,
  handleControlTypeChange,
  editedName,
  handleEditChangeforTable,
  fieldData,
  setFieldNames,
  handleRadioChange,
  selectedControl,
}) => {
  const [controlChanged, setcontrolChanged] = useState(null);
  const [DateField, SetDateField] = useState([]);
  const [ImageField, setImageField] = useState([]);
  const [IframeField, setIframeField] = useState([]);
  const [switchField, setSwitchField] = useState([]);
  const [checkboxField, setCheckboxField] = useState([]);
  const [RadioField, setRadioField] = useState([]);

  useEffect(() => {
    const fieldNames = fieldData
      ? fieldData
          .filter(
            (item) => item.elementtype === "field" && item.fieldtype === "text"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setFieldNames(fieldNames);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesImage = fieldData
      ? fieldData
          .filter(
            (item) => item.elementtype === "field" && item.fieldtype === "Image"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setImageField(fieldNamesImage);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesIframe = fieldData
      ? fieldData
          .filter(
            (item) =>
              item.elementtype === "field" && item.fieldtype === "Iframe"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setIframeField(fieldNamesIframe);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesSwitch = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "boolean") ||
              item.fieldtype === "number"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setSwitchField(fieldNamesSwitch);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesCheckbox = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "boolean") ||
              item.fieldtype === "number"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setCheckboxField(fieldNamesCheckbox);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesRadio = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "boolean") ||
              item.fieldtype === "number"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setRadioField(fieldNamesRadio);
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesDate = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "datetime") ||
              (item.elementtype === "field" && item.fieldtype === "date") ||
              (item.elementtype === "field" && item.fieldtype === "time")
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    SetDateField(fieldNamesDate);
  }, [fieldData]);

  const findControlcontrolChange = (controls, selectedControlType) => {
    for (const control of controls) {
      if (control.controlChange === selectedControlType) {
        return control.controlChange;
      }
      if (control.control && control.control.length > 0) {
        const nestedControlcontrolChange = findControlcontrolChange(
          control.control,
          selectedControlType
        );
        if (nestedControlcontrolChange) return nestedControlcontrolChange;
      }
    }
    return null;
  };

  useEffect(() => {
    if (treeData.length > 0 && treeData[0].Source && treeData[0].Source[0]) {
     
      const changed = findControlcontrolChange(
        treeData[0].Source[0].control,
        selectedControlType
      );
      setcontrolChanged(changed);
    }
  }, [selectedControlType]);

  const menuItems = Controls.filter(
    (category) => category.title === "Display" || category.title === "Input"
  ).flatMap((category) =>
    category.items
      .filter(
        (item) =>
          ![
            "List",
            "Table",
            "TableLabel",
            "Radio",
            "Dropdown",
            "Date",
            "Divider",
            "Switch",
            "Checkbox",
            "Iframe",
            "Row"
          ].includes(item.controltype)
      )
      .map((item) => ({
        key: item.controltype,
        value: item.controltype,
      }))
  );

  menuItems.sort((a, b) => a.value.localeCompare(b.value));

  
 

  return (
    <>
      <Box>
        <TabsComponent
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabs={rightsidetabs}
        >
          <div
            key="1"
            className="gnr-tile-name"
            style={{ marginLeft: "10px", paddingTop: "0px !important" }}
          >
            <div className="titleSection-gnr">
              {selectedItem && controlsVisible && (
                <div className="inputfildtet">
                  {selectedItem && selectedItem.styleType === "Icon" && (
                    <div>
                      <h5 className="sltitmnem1">Icon</h5>
                    </div>
                  )}
                  {selectedItem &&
                    selectedItem.styleType !== "Icon" &&
                    selectedItem.htmlTag !== "td" &&
                    selectedItem.htmlTag !== "th" &&
                    selectedItem.htmlTag !== "thead" &&
                    selectedItem.styleType !== "RadioContainer" &&
                    selectedItem.styleType !== "CheckboxContainer" && (
                      <div>
                        <h5 className="sltitmnem1">
                        {selectedControl &&
                    selectedControl.type}
                        </h5>
                      </div>
                    )}
                  {selectedItem && selectedItem.htmlTag === "td" && (
                    <div>
                      <h5 className="sltitmnem1">Label</h5>
                    </div>
                  )}
                  {selectedItem && selectedItem.htmlTag === "th" && (
                    <div>
                      <h5 className="sltitmnem1">Header</h5>
                    </div>
                  )}
                  {selectedItem && selectedItem.htmlTag === "thead" && (
                    <div>
                      <h5 className="sltitmnem1">Row</h5>
                    </div>
                  )}
                  {selectedItem &&
                    selectedItem.styleType === "RadioContainer" && (
                      <div>
                        <h5 className="sltitmnem1">Container</h5>
                      </div>
                    )}
                  {selectedItem &&
                    selectedItem.styleType === "CheckboxContainer" && (
                      <div>
                        <h5 className="sltitmnem1">Container</h5>
                      </div>
                    )}
                  <div>Name</div>
                  <div>
                    <input
                      className="tab-txtbox"
                      value={editedName}
                      onKeyUp={handleRenameConfirm}
                      onChange={handleEditChangeName1}
                    />
                  </div>
                </div>
              )}
              {selectedItem &&
                controlsVisible &&
                selectedItem.type !== "Root" &&
                selectedItem.type !== "Container" &&
                selectedItem.styleType !== "Icon" &&
                selectedItem.type !== "FooterContainer" &&
                selectedItem.type !== "HeaderContainer" &&
                selectedItem.type !== "RadioContainer" &&
                selectedItem.type !== "Radio" &&
                selectedItem.type !== "Checkbox" &&
                selectedItem.type !== "Date" &&
                selectedItem.type !== "Table" &&
                selectedItem.type !== "List" &&
                selectedItem.type !== "TableLabel" &&
                // selectedItem.controltype !== "Row" &&
                selectedItem.type !== "Divider" &&
                selectedItem.type !== "Iframe" &&
                selectedItem.type !== "Switch" &&
                selectedItem.inputType !== "Row" &&
                selectedItem.tableProp !== "tableProp" &&
                !isRootItem(selectedItem, treeData) && (
                  <div className="dt-src-name">
                    <div className="tab-titles dt-type">
                      Change Control Type :
                    </div>
                    <div>
                      {selectedItem &&
                        controlsVisible &&
                        selectedItem.type !== "Root" &&
                        selectedItem.type !== "Container" &&
                        selectedItem.type !== "FooterContainer" &&
                        selectedItem.type !== "HeaderContainer" &&
                        selectedItem.type !== "Table" &&
                        selectedItem.type !== "List" &&
                        selectedItem.type !== "TableLabel" &&
                        !isRootItem(selectedItem, treeData) && (
                          <Select
                            value={selectedControlType}
                            onChange={(e) =>
                              handleControlTypeChange(e, selectedItem.id)
                            }
                            className="hnd_slt_text-name"
                            MenuProps={{
                              PaperProps: {
                                className: "dt-dropdn-menu",
                              },
                              MenuListProps: {
                                className: "custom-menu-list",
                              },
                            }}
                          >
                            {menuItems.map((menuItem) => (
                              <MenuItem
                                key={menuItem.key}
                                value={menuItem.value}
                              >
                                {menuItem.value}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                    </div>
                    {selectedItem &&
                      Array.isArray(treeData) &&
                      treeData.forEach((item) => {
                        const findAndLogMatchedObjects = (controlItems) => {
                          controlItems.forEach((controlItem) => {
                            if (
                              controlItem.controlName ===
                              selectedItem.controlName
                            ) {
                            }

                            if (
                              controlItem.control &&
                              controlItem.control.length > 0
                            ) {
                              findAndLogMatchedObjects(controlItem.control);
                            }
                          });
                        };

                        item.Source.forEach((sourceItem) => {
                          findAndLogMatchedObjects(sourceItem.control);
                        });
                      })}
                  </div>
                )}
            </div>
            {selectedItem &&
              controlsVisible &&
              selectedItem.type !== "Container" &&
              selectedItem.type !== "HeaderContainer" &&
              selectedItem.type !== "ContainerLayout" &&
              selectedItem.type !== "FooterContainer" &&
              selectedItem.type !== "Image" &&
              selectedItem.type !== "Divider" &&
              selectedItem.type !== "Date" &&
              selectedItem.type !== "Radio-Container" &&
              selectedItem.type !== "Switch" &&
              selectedItem.htmlTag !== "tr" &&
              selectedItem.type !== "Slider" &&
              selectedItem.styleType !== "Icon" &&
              selectedItem.inputType !== "Row" &&
              selectedItem.inputType !== "Header" &&
              controlChanged !== "Divider" &&
              controlChanged !== "Image" &&
              controlChanged !== "Slider" &&
              controlChanged !== "Switch" &&
              selectedItem.htmlTag !== "table" &&
              selectedItem.htmlTag !== "thead" &&
              selectedItem.htmlTag !== "tr" &&
              !isRootItem(selectedItem, treeData) && (
                <div className="title-set-wrap">
                  {selectedItem.type === "Iframe" ? (
                    <div className="tab-titles dt-type">Enter Iframe URL</div>
                  ) : (
                    <div className="tab-titles dt-type">Text</div>
                  )}{" "}
                  <div className="titleSection">
                    <input
                      className="tab-txtbox"
                      value={editedData}
                      onChange={handleEditChange}
                      onKeyUp={
                        selectedItem.type === "TextBox" &&
                        (tabSelect === "Phone Number" || tabSelect === "Email")
                          ? handleEnterForNumber
                          : handleAgree
                      }
                    />
                    <span
                      className="iconBtn popIcn"
                      onClick={() => handlePopupClick(selectedItem.data)}
                      title="Edit title in popup"
                    >
                      {Theme.Open}
                    </span>
                  </div>
                  <div>
                    {isPopupVisible && (
                      <Dialog
                        open={isOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="lg"
                      >
                        <div className="expre-edit-place">
                          <DialogTitle
                            className="exedit"
                            style={{ width: "400px" }}
                          >
                            Expression Editor
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                            >
                              <CloseIcon />
                            </IconButton>
                          </DialogTitle>

                          {selectedItem && selectedItem.type === "List" && (
                            <div
                              style={{
                                marginTop: "10px",
                                textAlign: "center",
                              }}
                            >
                              <h5>List is separated by comma or enter key</h5>
                            </div>
                          )}
                        </div>

                        <textarea
                          className="gnrtextarea"
                          value={editedData || selectedOption}
                          onChange={handleEditChange}
                        />
                        <div className="slct-itm-nam-sty">
                          <DialogActions className="custombtn">
                            <Button onClick={handleClose} className="cancelbtn">
                              <span>Cancel</span>
                            </Button>
                            <Button
                              onClick={handleAgree}
                              autoFocus
                              className="savebtn"
                            >
                              <span>Save</span>
                            </Button>
                          </DialogActions>
                        </div>
                      </Dialog>
                    )}
                  </div>
                </div>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type !== "Container" &&
              selectedItem.htmlTag !== "tr" &&
              selectedItem.type !== "HeaderContainer" &&
              selectedItem.type !== "ContainerLayout" &&
              selectedItem.type !== "FooterContainer" &&
              selectedItem.type !== "Divider" &&
              selectedItem.type !== "Radio-Container" &&
              selectedItem.type !== "Radio" &&
              selectedItem.styleType !== "Icon" &&
              selectedItem.type !== "List" &&
              selectedItem.type !== "Iamge" &&
              selectedItem.type !== "Table" &&
              selectedItem.type !== "Date" &&
              selectedItem.type !== "Checkbox" &&
              selectedItem.type !== "Image" &&
              selectedItem.type !== "Iframe" &&
              selectedItem.type !== "Switch" &&
              tabSelect !== "Phone Number" &&
              tabSelect !== "Email" &&
              selectedItem.type !== "Slider" &&
              selectedItem.htmlTag !== "tr" &&
              selectedItem.sourceData !== "" &&
              selectedItem.tableHeader !== "tableHead" &&
              controlChanged !== "Divider" &&
              // controlChanged !== "Image" &&
              // controlChanged !== "Switch" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (editedData && editedData.toLowerCase()) ||
                            (selectedOption && selectedOption.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          {fieldNames.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Date" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (selectedOption && selectedOption.toLowerCase()) ||
                            (editedData && editedData.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                       
                          {DateField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Image" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (selectedOption && selectedOption.toLowerCase()) ||
                            (editedData && editedData.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                         
                          {ImageField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Iframe" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (selectedOption && selectedOption.toLowerCase()) ||
                            (editedData && editedData.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                         
                          {IframeField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <Select
                          labelId="select-field-label"
                          value={
                            (selectedItem.imageText &&
                              selectedItem.imageText.toLowerCase()) ||
                            (selectedOption && selectedOption.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          <MenuItem value="" className="mui_root_name">
                          Select FieldName
                          </MenuItem>
                          {IframeField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select> */}
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Switch" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (selectedOption && selectedOption.toLowerCase()) ||
                            (editedData && editedData.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                         
                          {switchField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Checkbox" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (editedData && editedData.toLowerCase()) ||
                            (selectedOption && selectedOption.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                         
                          {checkboxField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Radio" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={
                            (editedData && editedData.toLowerCase()) ||
                            (selectedOption && selectedOption.toLowerCase())
                          }
                          onChange={handleEditChange}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          {RadioField.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={`{${fieldName.toLowerCase()}}`}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem && controlsVisible && (
              <div className="dt-type">
                {selectedItem &&
                  Array.isArray(treeData) &&
                  treeData.forEach((item) => {
                    const findAndLogMatchedObjects = (controlItems) => {
                      controlItems.forEach((controlItem) => {
                        if (
                          controlItem.controlName === selectedItem.controlName
                        ) {
                        }

                        if (
                          controlItem.control &&
                          controlItem.control.length > 0
                        ) {
                          findAndLogMatchedObjects(controlItem.control);
                        }
                      });
                    };

                    item.Source.forEach((sourceItem) => {
                      findAndLogMatchedObjects(sourceItem.control);
                    });
                  })}
              </div>
            )}

            {selectedItem &&
              selectedItem.type === "Table" &&
              selectedItem.tableHeader !== "tableHead" &&
              selectedItem.htmlTag !== "thead" &&
              selectedItem.htmlTag !== "table" &&
              selectedItem.htmlTag !== "tr" &&
              controlsVisible && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="dt-src-name">
                      <FormControl>
                        <div className="tab-titles dt-type">Data Source</div>
                        <Select
                          labelId="select-field-label"
                          value={selectedOption && selectedOption.replace(/[{}]/g, '')}
                          onChange={handleEditChangeforTable}
                          className="hnd_slt_text-name"
                          MenuProps={{
                            PaperProps: {
                              className: "dt-dropdn-menu",
                            },
                            MenuListProps: {
                              className: "custom-menu-list",
                            },
                          }}
                        >
                          {fieldNamestable.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={fieldName}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              selectedItem.type === "TextBox" &&
              controlChanged === "TextBox" &&
              controlsVisible && (
                <div className="gnr-tile-name dt-type">
                  <FormControl className="custom-edit-text-bx">
                    <div className="tab-titles">Data Type</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tabSelect}
                      onChange={(event) => handleChange(event, selectedItem.id)}
                      className="custom-ipt-select cst-menu-item"
                      MenuProps={{
                        PaperProps: {
                          className: "cstm-dropdown-menu-text",
                        },
                        MenuListProps: {
                          className: "cstm-menu-list-text",
                        },
                      }}
                    >
                      <MenuItem value={"Text"} className="cst-menu-item">
                        {Theme.texttype} Text{" "}
                      </MenuItem>
                      <MenuItem
                        value={"Phone Number"}
                        className="cst-menu-item"
                      >
                        {Theme.Phone} Phone Number
                      </MenuItem>
                      <MenuItem value={"Email"} className="cst-menu-item">
                        {Theme.Email} Email
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}

            {(selectedItem && selectedItem.type === "TextBox") ||
              (controlChanged === "TextBox" && controlsVisible && (
                <div className="gnr-tile-name dt-type">
                  <FormControl className="custom-edit-text-bx">
                    <div className="tab-titles">Data Type</div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tabSelect}
                      onChange={(event) => handleChange(event, selectedItem.id)}
                      className="custom-ipt-select cst-menu-item"
                      MenuProps={{
                        PaperProps: {
                          className: "cstm-dropdown-menu-text",
                        },
                        MenuListProps: {
                          className: "cstm-menu-list-text",
                        },
                      }}
                    >
                      <MenuItem value={"Text"} className="cst-menu-item">
                        {Theme.texttype} Text{" "}
                      </MenuItem>
                      <MenuItem
                        value={"Phone Number"}
                        className="cst-menu-item"
                      >
                        {Theme.Phone} Phone Number
                      </MenuItem>
                      <MenuItem value={"Email"} className="cst-menu-item">
                        {Theme.Email} Email
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              ))}

            <div className="edit-value-wrap gnr-tile-name ">
              {selectedItem &&
                selectedItem.type === "Radio" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="dt-type">Create Multiple : </div>
                      <div className="titleSection">
                        <input
                          type="number"
                          className="tab-txtbox"
                          value={inputValueRadio}
                          onChange={handleInputChangeRadio}
                        />
                        <span
                          className="iconBtn"
                          title="Add"
                          onClick={() => handleAddControlRadio()}
                        >
                          {Theme.Add}
                        </span>
                      </div>

                      <div className="gnr-tile-name dt-type">
                      <div className="chck-stst-type">State</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedOptioncheck}
                          onChange={(e) =>
                            handleRadioChange(
                              e.target.value === "true",
                              selectedItem.id
                            )
                          }
                          className="custom-native-select"
                        >
                          <option value={false}>False</option>
                          <option value={true}>True</option>
                        </NativeSelect>
                      </FormControl>
                    </div>

                      <div className="gnr-tile-name dt-type">
                        <div className="check-bxtype">Position</div>
                        <FormControl className="custom-dropdown-bottom">
                          <NativeSelect
                            value={selectedPositioncheck}
                            onChange={(e) =>
                              handleCheckboxPositonChange(
                                e.target.value === "right",
                                selectedItem.id
                              )
                            }
                            style={{ position: "static" }}
                          >
                            <option value="left">Right</option>
                            <option value="right">Left</option>
                          </NativeSelect>
                        </FormControl>
                      </div>
                    </div>

                
                  </>
                )}

              {(selectedItem && selectedItem.type === "Checkbox") ||
                (controlChanged === "Checkbox" &&
                  controlsVisible &&
                  selectedItem.styleType !== "RadioContainer" && (
                    <div className="dt-type">
                      <div className="tab-titles">Create Multiple :</div>
                      <div className="titleSection">
                        <input
                          type="number"
                          className="tab-txtbox"
                          value={inputValue}
                          onChange={handleInputChange}
                        />
                        <span
                          className="iconBtn"
                          title="Add"
                          onClick={() => handleAddControlCheckbox()}
                        >
                          {Theme.Add}
                        </span>
                      </div>

                      <div className="gnr-tile-name dt-type">
                        <div className="chck-stst-type">State</div>
                        <FormControl className="custom-dropdown-bottom">
                          <NativeSelect
                            value={selectedOptioncheck}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e.target.value === "true",
                                selectedItem.id
                              )
                            }
                            className="custom-native-select"
                          >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                          </NativeSelect>
                        </FormControl>
                      </div>

                      <div className="gnr-tile-name dt-type">
                        <div className="check-bxtype">Position</div>
                        <FormControl className="custom-dropdown-bottom">
                          <NativeSelect
                            value={selectedPositioncheck}
                            onChange={(e) =>
                              handleCheckboxPositonChange(
                                e.target.value === "right",
                                selectedItem.id
                              )
                            }
                            style={{ position: "static" }}
                          >
                            <option value="left">Right </option>
                            <option value="right">Left</option>
                          </NativeSelect>
                        </FormControl>
                      </div>
                    </div>
                  ))}

              {selectedItem &&
                selectedItem.type === "Checkbox" &&
                controlChanged === "Checkbox" &&
                controlsVisible && (
                  <div className="dt-type">
                    <div className="tab-titles">Create Multiple :</div>
                    <div className="titleSection">
                      <input
                        type="number"
                        className="tab-txtbox"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      <span
                        className="iconBtn"
                        title="Add"
                        onClick={() => handleAddControlCheckbox()}
                      >
                        {Theme.Add}
                      </span>
                    </div>

                    <div className="gnr-tile-name dt-type">
                      <div className="chck-stst-type">State</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedOptioncheck}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e.target.value === "true",
                              selectedItem.id
                            )
                          }
                          className="custom-native-select"
                        >
                          <option value={false}>False</option>
                          <option value={true}>True</option>
                        </NativeSelect>
                      </FormControl>
                    </div>

                    <div className="gnr-tile-name dt-type">
                      <div className="check-bxtype">Position</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedPositioncheck}
                          onChange={(e) =>
                            handleCheckboxPositonChange(
                              e.target.value === "right",
                              selectedItem.id
                            )
                          }
                          style={{ position: "static" }}
                        >
                          <option value="left">Right</option>
                          <option value="right">Left</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.type === "Switch" &&
                controlChanged === "Switch" &&
                controlsVisible && (
                  <>
                    <div className="gnr-tile-name dt-type">
                      <div>State</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedOptionSwitchcheck}
                          onChange={(e) =>
                            handleSwitchChange(
                              e.target.value === "true",
                              selectedItem.id
                            )
                          }
                        >
                          <option value={false}>False</option>
                          <option value={true}>True</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  </>
                )}

              {(selectedItem && selectedItem.type === "Switch") ||
                (controlChanged === "Switch" && controlsVisible && (
                  <>
                    <div className="gnr-tile-name dt-type">
                      <div>State</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedOptionSwitchcheck}
                          onChange={(e) =>
                            handleSwitchChange(
                              e.target.value === "true",
                              selectedItem.id
                            )
                          }
                        >
                          <option value={false}>False</option>
                          <option value={true}>True</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  </>
                ))}

              {selectedItem &&
                selectedItem.type === "Image" &&
                controlChanged === "Image" &&
                controlsVisible && (
                  <div>
                    <div className="tab-titles dt-type">Select Image :</div>
                    <div className="titleSection">
                      <input
                        type="file"
                        id="imagePicker"
                        ref={inputRef}
                        accept="image/jpeg, image/png"
                        style={{ display: "none" }}
                        onChange={(e) => handleImageChange(e, selectedItem.id)}
                      />

                      <label onClick={handleImageClick}>
                        <AddPhotoAlternateIcon />
                      </label>
                    </div>
                  </div>
                )}

           


              {selectedItem &&
                selectedItem.styleType === "Date" &&
                controlsVisible && (
                  <div className="tab-titles dt-type">
                    <div>Select Date</div>
                    <div className="titleSection">
                      {selectedItem.inputType === "text" ? (
                        <input
                          className="tab-txtbox"
                          type="date"
                          value={selectedItem.data}
                          onChange={(e) => handleDateChange(e, selectedItem.id)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <input
                          className="tab-txtbox"
                          type="date"
                          value={selectedItem.data}
                          onChange={(e) => handleDateChange(e, selectedItem.id)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.htmlTag === "table" &&
                controlsVisible && (
                  <div className="tbl-row-col-btn">
                    <Button onClick={addNewColumn}>Add Columns</Button>
                  </div>
                )}
            </div>
          </div>
          {controlsVisible && <div key="2"> </div>}
        </TabsComponent>
      </Box>
    </>
  );
};

export default GeneralSection;