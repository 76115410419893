import React from "react";
import {
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
function Monthly({
  scheduleData,
  handleNumberChange,
  handleTimeChange,
  handleChange,
}) {
  return (
    <FormControl fullWidth size="small">
      <InputLabel>Option</InputLabel>
      <Select
        size="small"
        label="Option"
        name="monthlyOption"
        value={scheduleData.monthlyOption}
        onChange={handleChange}
        sx={{ marginBottom: "0.5rem" }}
      >
        <MenuItem value="byDayOfMonth">By day of every month</MenuItem>
        <MenuItem value="setDaysOfMonth">Set days of month</MenuItem>
      </Select>
      {scheduleData.monthlyOption === "byDayOfMonth" ? (
        <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
          <Grid item xs={5}>
            <TextField
              size="small"
              label="Day"
              type="number"
              name="monthlyDay"
              value={scheduleData.monthlyDay}
              onChange={(e) => handleNumberChange(e, 1, 31)}
            />
          </Grid>
          <Grid item xs={2}>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
            >
              of every
            </div>
          </Grid>
          <Grid item xs={5}>
            <TextField
              size="small"
              name="monthlyMonth"
              label={scheduleData.monthlyMonth > 1 ? "Months" : "Month"}
              type="number"
              value={scheduleData.monthlyMonth}
              onChange={(e) => handleNumberChange(e, 1, 12)}
            />
          </Grid>
        </Grid>
      ) : scheduleData.monthlyOption === "setDaysOfMonth" ? (
        <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>The</InputLabel>
              <Select
                size="small"
                label="The"
                name="monthlySequence"
                value={scheduleData.monthlySequence}
                onChange={handleChange}
              >
                {["First", "Second", "Third", "Fourth"].map((week) => (
                  <MenuItem key={week} value={week}>
                    {week}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Of</InputLabel>
              <Select
                size="small"
                label="Of"
                name="monthlyDayOfTheWeek"
                value={scheduleData.monthlyDayOfTheWeek}
                onChange={handleChange}
              >
                {[
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ].map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                marginLeft: "0.5rem",
              }}
            >
              of every
            </div>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              name="monthlyMonth"
              label={scheduleData.monthlyMonth > 1 ? "Months" : "Month"}
              type="number"
              value={scheduleData.monthlyMonth}
              onChange={(e) => handleNumberChange(e, 1, 12)}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              marginLeft: "0.5rem",
            }}
          >
            Start at:
          </div>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
            <TimePicker
              label="Time"
              value={scheduleData.monthlyTime}
              onChange={(event) => handleTimeChange(event, "monthlyTime")}
              renderInput={(params) => <TextField {...params} fullWidth />}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default Monthly;
