import React from "react";
import MiniCardComponent from "../../Components/landingPage/MiniCardComponent";
export default function CallMiniCardComponent({
  section,
  hideArray,
  admin,
  handleDialogToggle,
  isMobile,
}) {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        flexWrap: "wrap",
      }}
    >
      {section.defView === 10 &&
        !hideArray.includes(10) &&
        section.items &&
        section.items.map((item, itemIndex) => (
          <MiniCardComponent
            key={itemIndex}
            image={item.mediaURL}
            size={350}
            isMobile={isMobile}
            position={"left"}
            title={item.header}
            textSize={isMobile ? 15 : 17}
            itemID={item.feedID}
            admin={admin}
            handleDialogToggle={handleDialogToggle}
          />
        ))}
    </div>
  );
}
