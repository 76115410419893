import React from "react";
import Logo from "../../assets/bubblesLogo.png";
import email from "../../assets/email.svg";
import phone from "../../assets/phone.svg";
import { Typography } from "@mui/material";
import twitter from "../../assets/twitter.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import themeProvider from "../../ThemeProvider";

// let emailAddress = window.landingPageFooter.emailAddress;
// let phoneNumber = window.landingPageFooter.phoneNumber;

function LandingFooter() {
  const [open, setOpen] = React.useState(false);
  function onClose() {
    setOpen(false);
  }
  function handleFeedBack() {
    setOpen(true);
  }
  return (
    <div
      style={{
        backgroundColor: themeProvider.palette.landingBg.dark,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* <Link to="/"> */}
        <img
          src={Logo}
          style={{
            width: "15rem",
            height: "auto",
            marginTop: "20px",
            marginLeft: "20px",
          }}
          alt="Bubble icon"
        />
        {/* </Link> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "3rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {/* <img
              src={email}
              alt="email address"
              style={{ width: "35px", height: "35px", marginRight: "5px" }}
            /> */}
            {/* <p style={{ color: "white", marginLeft: "5px" }}>{emailAddress}</p> */}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            {/* <img
              src={phone}
              alt="phone number"
              style={{ width: "35px", height: "35px", marginRight: "5px" }}
            />
            <p style={{ color: "white", marginLeft: "5px" }}>{phoneNumber}</p> */}
          </div>
        </div>
      </div>
      <hr
        style={{
          borderColor: "#FFFFFF",
          borderWidth: "0.px",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: "4rem",
            alignItems: "center",
          }}
        >
          {/* <Link
            to="/PrivacyPolicy"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "20px" }}
          >
            Privacy Policy
          </Typography>
          {/* </Link> */}
          {/* <Link
            to="/TermOfUse"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "20px", marginTop: "1rem" }}
          >
            Terms Of Use
          </Typography>
          {/* </Link>
          <Link
            to="/CookiePolicy"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "20px", marginTop: "1rem" }}
          >
            Cookie Policy
          </Typography>
          {/* </Link> */}
          {/* <Link
            to="/AdvertisingPolicy"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "20px", marginTop: "1rem" }}
          >
            Advertising Policy
          </Typography>
          {/* </Link> */}
        </div>
        <div
          style={{
            borderLeft: "1px solid white",
            height: "300px",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        ></div>
        <div
          style={{
            marginTop: "4rem",
            alignItems: "center",
          }}
        >
          {/* <Link
            to="/About"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "20px" }}
          >
            About Us
          </Typography>
          {/* </Link> */}
          {/* <Link style={{ textDecoration: "none", color: "inherit" }}> */}
          <Typography
            onClick={handleFeedBack}
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "20px", marginTop: "1rem" }}
          >
            Contact Us
          </Typography>
          {/* </Link> */}
        </div>

        <div
          style={{
            borderLeft: "1px solid white",
            height: "300px",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            marginTop: "4rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={twitter}
            alt="phone number"
            style={{ width: "40px", height: "40px" }}
          />
          <img
            src={facebook}
            alt="phone number"
            style={{ width: "40px", height: "40px", marginTop: "1rem" }}
          />
          <img
            src={instagram}
            alt="phone number"
            style={{ width: "40px", height: "40px", marginTop: "1rem" }}
          />
        </div>
      </div>

      <span
        style={{
          fontSize: "0.8rem",
          backgroundColor: themeProvider.palette.landingBg.copyright,
          bottom: "0",
          width: "100%",
          color: "white",
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          marginTop: "2rem",
        }}
      >
        Copyright © 2023 | {window.landingPageFooter.companyName}™ | Powered by
        Agiline Software, LLC
      </span>
    </div>
  );
}
export default LandingFooter;
