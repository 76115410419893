import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CheckIcon from "@mui/icons-material/Check";
import EditOffIcon from "@mui/icons-material/EditOff";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import Controls from "../IncludeFile/Controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrag, useDrop } from "react-dnd";
import _, { cloneDeep } from "lodash";


const TreeItem = React.memo(
  ({
    item,
    index,
    addItem,
    selectedNode,
    setSelectedNode,
    treeData,
    setTreeData,
    parentID,
    handleSelectItem,
    selectedItems = [],
    handleDoubleClick,
    filterTreeData,
    searchQuery,
    // isExpanded,
    // setIsExpanded,
    setEditedName,
    setSelectedItem,
    setSelectedControl,
    setSelectedItemID,
  setSelectedOptioncheck,
  setSelectedOptionSwitchcheck,
   setSelectedPositioncheck,
   editedName,
   setEditedData,
  setSelectedOption,
   settabSelect,
setTableHeader,
   setSourceData,
    setSelectedControlType,
 setIsOptionSelected,
 isOptionSelected,
  settableProp,
  }) => {
    const [isRenaming, setIsRenaming] = useState(false);
    const [newName, setNewName] = useState(item.name);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isExpanded, setIsExpanded] = useState(true);

    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
      useState(false);

    const openDeleteConfirmation = () => {
      setIsDeleteConfirmationOpen(true);
    };

    const closeDeleteConfirmation = () => {
      setIsDeleteConfirmationOpen(false);
    };

    const isRootItem = (item, treeData) => {
      return treeData[0].Source[0].id === item.id;
    };

    const handleDelete = () => {
      closeMenu();
      if (item && item.id) {
        localStorage.setItem('deletedItemId', item.styleid);
        if (isRootItem(item, treeData)) {
          console.log("Cannot delete root level item.");
          return;
        }

        onDeleteItem(item.id);
      }

      closeDeleteConfirmation();
    };

    const onDeleteItem = (itemIdToDelete) => {
      
      let previousItem = null;
      
      const findPreviousItem = (node, parent = null) => {
        if (node.id === itemIdToDelete) {
          return previousItem;
        }
    
        previousItem = node;
    
        if (node.control) {
          for (let i = 0; i < node.control.length; i++) {
            const result = findPreviousItem(node.control[i], node);
            if (result) return result;
          }
        }
        return null;
      };
    
      treeData.forEach((rootItem) => {
        rootItem.Source.forEach((sourceItem) => {
          findPreviousItem(sourceItem);
        });
      });
    
      const deleteItemRecursively = (node) => {
        if (node.id === itemIdToDelete) {
          return null;
        }
    
        if (node.control) {
          const updatedControl = node.control
            .map((child) => deleteItemRecursively(child))
            .filter(Boolean);
    
          return {
            ...node,
            control: updatedControl,
          };
        }
    
        return node;
      };
    
      const updatedTreeData = treeData.map((rootItem) => ({
        ...rootItem,
        Source: rootItem.Source.map((sourceItem) => ({
          ...sourceItem,
          control: sourceItem.control
            .map((item) => deleteItemRecursively(item))
            .filter(Boolean),
        })),
      }));
    
      setTreeData(updatedTreeData);
    
      const element = document.getElementById(item.id);
      const focusedNode = document.querySelector(".focusingClass");
    
      if (element) {
        if (focusedNode) {
          focusedNode.classList.remove("focusingClass");
        }
        element.classList.add("focusingClass");
      }
      setTimeout(() => {
        const itemToSelect = previousItem || updatedTreeData[0]?.Source[0];
        if (itemToSelect) {
          setSelectedNode(itemToSelect);
          setSelectedItem(itemToSelect);
          setSelectedControl(itemToSelect);
          setEditedName(itemToSelect.name);
          setSelectedItemID(itemToSelect.id);
          setSelectedOptioncheck(itemToSelect.check ? "true" : "false");
          setSelectedOptionSwitchcheck(itemToSelect.switchcheck ? "true" : "false");
          setSelectedPositioncheck(itemToSelect.checkposition || (itemToSelect.media ? "left" : "right"));
          setEditedName(itemToSelect.name || editedName);
          setEditedData(itemToSelect.data || "");
          setSelectedOption(itemToSelect.imageText || itemToSelect.inputText || itemToSelect.data);
        
          settabSelect(itemToSelect.tab || "");
          setTableHeader(itemToSelect.tableHeader ? "TableHeader" : "");
          setSourceData(itemToSelect.sourceData ? "" : "");
          setSelectedControlType(itemToSelect.controlChange || itemToSelect.selectedControlType);
          setIsOptionSelected(!isOptionSelected);
          settableProp(itemToSelect.tableProp ? "tableProp" : "null");
        }
      }, 0);
      
    };
    

    // const onDeleteItem = (itemIdToDelete) => {
    //   const deleteItemRecursively = (node) => {
    //     if (node.id === itemIdToDelete) {
    //       return null;
    //     }
    
    //     if (node.control) {
    //       const updatedControl = node.control
    //         .map((child) => deleteItemRecursively(child))
    //         .filter(Boolean);
    
    //       return {
    //         ...node,
    //         control: updatedControl,
    //       };
    //     }
    
    //     return node;
    //   };
    
    //   const updatedTreeData = treeData.map((rootItem) => ({
    //     ...rootItem,
    //     Source: rootItem.Source.map((sourceItem) => ({
    //       ...sourceItem,
    //       control: sourceItem.control
    //         .map((item) => deleteItemRecursively(item))
    //         .filter(Boolean),
    //     })),
    //   }));
    
    //   setTreeData(updatedTreeData);

    //   setTimeout(() => {
    //     const firstItem = updatedTreeData[0]?.Source[0];
    //     if (firstItem) {
    //       setSelectedNode(firstItem);
    //       setSelectedItem(firstItem);
    //       setSelectedControl(firstItem);
    //       setEditedName(firstItem.name);
    //       setSelectedItemID(firstItem.id);
    //     }
    //   }, 0);
    // };
    

    useEffect(() => {
      setNewName(item.name);
    }, [item]);




    const findNodeById = (node, id) => {
      if (!node) {
        console.error("Node is undefined");
        return null;
      }
    
      if (node.id === id) {
        return node;
      }
    
      if (!node.control) {
        console.error("Node control is undefined");
        return null;
      }
    
      for (const childNode of node.control) {
        const foundNode = findNodeById(childNode, id);
        if (foundNode) {
          return foundNode;
        }
      }
    
      return null;
    };
    
  const isDescendant = (parentNode, childId) => {
      if (!parentNode.control) return false;
      for (const childNode of parentNode.control) {
        if (childNode.id === childId || isDescendant(childNode, childId)) {
          return true;
        }
      }
      return false;
    };

    const [{ isDragging }, drag] = useDrag({
      type: "TREE_ITEM",
      canDrag: () => {
        return (
          item.controltype !== "HeaderContainer" &&
          item.controltype !== "FooterContainer" &&
          item.htmlTag !== "td" &&
          item.htmlTag !== "tr" &&
          item.htmlTag !== "th" &&
          item.htmlTag !== "thead"
        );
      },
      item: { id: item.id, index, parentID, controltype: item.controltype },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
  
    const [, drop] = useDrop({
      accept: "TREE_ITEM",
      drop: (draggedItem, monitor) => {
        const draggedIndex = draggedItem.index;
        const hoverIndex = index;
  
   
  
        const updatedTreeData = cloneDeep(treeData);
        const sourceId = draggedItem.parentID;
        const destinationId = parentID;
  
        const sourceParent = findNodeById(updatedTreeData[0].Source[0], sourceId);
        const destinationParent = findNodeById(updatedTreeData[0].Source[0], destinationId);
  

  
        if (!sourceParent || !destinationParent) {
          console.error('Source or destination parent node not found');
          return;
        }
  
     
        const headerContainerIndex = destinationParent.control.findIndex(
          (node) => node.controltype === "HeaderContainer"
        );
        if (headerContainerIndex !== -1 && hoverIndex <= headerContainerIndex) {
          return;
        }
  
     
        if (isDescendant(draggedItem, destinationId) || draggedItem.id === destinationId) {
          console.error('Cannot drop into itself or its descendants');
          return;
        }
  

        const draggedNode = findNodeById(updatedTreeData[0].Source[0], draggedItem.id);
        if (isDescendant(draggedNode, destinationId)) {
          console.error('Cannot drop a node into one of its descendants');
          return;
        }
  
        const [draggedControl] = sourceParent.control.splice(draggedIndex, 1);
  
        const retainedControl = {
          ...draggedControl,
          controltype: draggedControl.controltype || draggedItem.controltype,
        };
  
        destinationParent.control.splice(hoverIndex, 0, retainedControl);
  
        updateIds(updatedTreeData);
  
        setTreeData(updatedTreeData);
      },
    });
  
  

    const updateIds = (treeData) => {
      treeData.forEach((root, index) => {
        root.Source[0].id = index.toString();
        updateChildIDs(root.Source[0].control, root.Source[0].id);
      });
    };

    const updateChildIDs = (children, parentID) => {
      children.forEach((child, index) => {
        if (child && child.id) {
          child.id = `${parentID}.${index}`;
          child.parentid = parentID;
          if (child.control && child.control.length > 0) {
            updateChildIDs(child.control, child.id);
          }
        }
      });
    };

    useEffect(() => {
      if (parentID === "0" && treeData[0].Source[0].id === item.id) {
        setIsExpanded(true);
      }
    }, [treeData, parentID, item.id]);

    if (!item || !item.id) {
      return null;
    }

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    const handleClick = () => {
      handleSelectItem(
        item.id,
        item.name,
        item.type,
        item.data,
        item.styles,
        item.styleid,
        item.htmlTag,
        item.media,
        item.styleType,
        item.check,
        item.option,
        item.position,
        item.switchcheck,
        item.switchoption,
        item.slidervalue,
        item.checkposition,
        item.value,
        item.tab,
        item.inputType,
        item.tableHeader,
        item.sourceData,
        item.controltype,
        item.controlName,
        item.controlChange,
        item.tableProp,
        item.inputText,
        item.imageText,
        item.url,
      );
    };

    const handleSelectNode = () => {
      if (item) {
        setSelectedNode(item);
      }
    };

    const isSelected = selectedNode && selectedNode.id === item.id;

    const openMenu = (event) => {
      setAnchorEl(event.currentTarget);
      setIsMenuOpen(true);
    };

    const closeMenu = () => {
      setAnchorEl(null);
      setIsMenuOpen(false);
    };

    const handleRename = () => {
      setIsRenaming(true);
      closeMenu();
    };

    const handleRenameConfirm = () => {
      let updatedName = newName.trim();

      updatedName = updatedName.replace(/"/g, "'");

      if (updatedName !== "") {
   

        const updatedTreeData = updateTreeData(treeData, item.id, (node) => {
          return { ...node, name: updatedName };
        });

        setEditedName(updatedName);
        setTreeData(updatedTreeData);
      } else {
        alert("Please enter a valid name.");
      }
      setIsRenaming(false);
    };



    const updateTreeData = (data, itemId, updateFn) => {
      return data.map((rootItem) => {
        return {
          ...rootItem,
          Source: rootItem.Source.map((sourceItem) => {
            return updateItem(sourceItem, itemId, updateFn);
          }),
        };
      });
    };

    const updateItem = (item, itemId, updateFn) => {
      if (item.id === itemId) {
        return updateFn(item);
      }

      if (item.control) {
        return {
          ...item,
          control: item.control.map((controlItem) => {
            return updateItem(controlItem, itemId, updateFn);
          }),
        };
      }

      return item;
    };

    const handleRenameCancel = () => {
      setNewName(item.name);
      setEditedName(item.name);
      setIsRenaming(false);
      closeMenu();
    };

 

    const findMaxNumber = (node, controlType) => {
      let maxNumber = 0;
      if (node.control) {
        for (const child of node.control) {
          if (child.type === controlType) {
            const number = parseInt(child.name.replace(controlType, ""));
            if (!isNaN(number) && number > maxNumber) {
              maxNumber = number;
            }
          }
          const childMaxNumber = findMaxNumber(child, controlType);
          if (childMaxNumber > maxNumber) {
            maxNumber = childMaxNumber;
          }
        }
      }
      return maxNumber;
    };

    const handleDuplicate = () => {
      if (isRootItem(item, treeData) && item.htmlTag !== "thead") {
        console.log("Cannot duplicate root level item.");
        return;
      }

      const newId = generateUniqueId(parentID);
      const parentItem = findItemById(treeData, parentID);
      const newStyleId = generateUniqueStyleId(parentItem);

      const baseName = item.name.replace(/\d+$/, "");

      const maxNumber = findMaxNumber(parentItem, baseName);
      const duplicatedName = `${baseName}${maxNumber + 1}`;

      const duplicatedItem = duplicateItem(
        item,
        newId,
        newStyleId,
        duplicatedName,
        parentID
      );

      if (parentItem) {
        const footerIndex = parentItem.control.findIndex(
          (child) => child.controltype === "FooterContainer"
        );

        parentItem.control.splice(
          footerIndex !== -1 ? footerIndex : parentItem.control.length,
          0,
          duplicatedItem
        );

        closeMenu();
        setTreeData([...treeData]);
      }
    };

    // const generateUniqueId = (parentId) => {
    //   const parentItem = findItemById(treeData, parentId);
    //   if (parentItem) {
    //     const childIndexes = parentItem.control.map((child) =>
    //       parseInt(child.id.split(".").pop())
    //     );
    //     const maxChildIndex = Math.max(0, ...childIndexes);

    //     const newIndex = maxChildIndex + 1;

    //     return `${parentId}.${newIndex}`;
    //   }
    //   return parentId;
    // };

    const generateUniqueId = (parentId) => {
      const parentItem = findItemById(treeData, parentId);
      if (parentItem) {
        const childIndexes = parentItem.control.map((child) => {
          const lastPart = child.id.split(".").pop();
          return isNaN(lastPart) ? 0 : parseInt(lastPart);
        });
        const maxChildIndex = Math.max(0, ...childIndexes);
    
        const newIndex = maxChildIndex + 1;
    
        return `${parentId}.${newIndex}`;
      }
      return `${parentId}.1`; 
    };

    const generateUniqueStyleId = (parentNode) => {
      let maxStyleId = 0;

      const findMaxStyleId = (node) => {
        const styleIdRegex = /([a-zA-Z]+)(\d*)/;
        if (typeof node.styleid === "string") {
          const matches = node.styleid.match(styleIdRegex);
          if (matches && matches[1] === "agrfb") {
            const currentId = parseInt(matches[2]);
            if (!isNaN(currentId) && currentId > maxStyleId) {
              maxStyleId = currentId;
            }
          }
        }

        if (node.control && node.control.length > 0) {
          for (const childNode of node.control) {
            findMaxStyleId(childNode);
          }
        }
      };

      if (parentNode && parentNode.control) {
        for (const childNode of parentNode.control) {
          findMaxStyleId(childNode);
        }
      }

      if (parentNode && parentNode.parentid) {
        const parentItem = findItemById(treeData, parentNode.parentid);
        if (parentItem) {
          for (const siblingNode of parentItem.control) {
            if (siblingNode.id !== parentNode.id) {
              findMaxStyleId(siblingNode);
              if (siblingNode.control && siblingNode.control.length > 0) {
                // Consider children of sibling nodes
                for (const childNode of siblingNode.control) {
                  findMaxStyleId(childNode);
                }
              }
            }
          }
        }
      }

      return `agrfb${maxStyleId + 1}`;
    };

    const duplicateItem = (
      sourceItem,
      newId,
      parentStyleId,
      duplicatedName,
      parentid
    ) => {
      const duplicated = JSON.parse(JSON.stringify(sourceItem));

      duplicated.id = newId;

      const lastDigit = parentStyleId.match(/\d+$/);
      const nextStyleId = `agrfb${parseInt(lastDigit[0]) + 1}`;

      duplicated.styleid = nextStyleId;

      if (duplicated.control) {
        duplicated.control = duplicated.control.map((child, index) => {
          const baseName = child.name.replace(/\d+$/, "");
          const childDuplicatedName = `${baseName}${index + 1}`;

          const childNextStyleId = `${nextStyleId}${index + 1}`;

          return duplicateItem(
            child,
            `${newId}.${index + 1}`,
            childNextStyleId,
            childDuplicatedName,
            newId
          );
        });
      }

      duplicated.name = duplicatedName;
      duplicated.parentid = parentid;

      return duplicated;
    };

    const findItemById = (data, id) => {
      for (const rootItem of data) {
        for (const sourceItem of rootItem.Source) {
          const foundItem = findItemRecursive(sourceItem, id);
          if (foundItem) {
            return foundItem;
          }
        }
      }
      return null;
    };

    const findItemRecursive = (item, id) => {
      if (item.id === id) {
        return item;
      }

      if (item.control) {
        for (const controlItem of item.control) {
          const foundItem = findItemRecursive(controlItem, id);
          if (foundItem) {
            return foundItem;
          }
        }
      }

      return null;
    };

    return (
      <>
        <div ref={(node) => drag(drop(node))}>
          <div
            onClick={() => {
              handleSelectNode();
              handleClick();
            }}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            className={`tree-item ${
              selectedItems.includes(item.id) ? "selected" : ""
            }`}
          >
            <div
              className="tree-item-in"
              style={{
                backgroundColor: isSelected ? "#e7e7e7" : "transparent",
                borderLeft: isSelected ? "1px solid #204568" : "none",
                display: "table",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "5px",
                    paddingLeft: 1 * item.id.split(".").length - 1 + "em",
                  }}
                >
                  <div className="expadeblemode" onClick={toggleExpand}>
                    {item.control && item.control.length > 0 ? (
                      isExpanded ? (
                        <ExpandMoreIcon
                          style={{ margin: "-9px 0px -6px 0px" }}
                        />
                      ) : (
                        <ChevronRightIcon
                          style={{ margin: "-9px 0px -6px 0px" }}
                        />
                      )
                    ) : null}
                  </div>
                  {isRenaming ? (
                    <>
                      <div className="inp-container-tre-itm">
                        {Controls.flatMap((category) =>
                          category.items.map((control) =>
                            control.controltype === item.controltype ? (
                              <FontAwesomeIcon
                                key={control.mediaURl}
                                icon={{
                                  prefix: "fa",
                                  iconName: [control.mediaURl],
                                }}
                                style={{ padding: "5px" }}
                              />
                            ) : null
                          )
                        )}
                        <div className="iptext">
                          <input
                            type="text"
                            className="inputfildtet"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            onBlur={handleRenameConfirm}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                handleRenameConfirm();
                              } else if (e.key === "Escape") {
                                handleRenameCancel();
                              }
                            }}
                            style={{ marginLeft: "4px" }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className="leftsideicon"
                      onClick={() => {
                        // handleSelectNode();
                        handleClick();
                      }}

                      //  onDoubleClick={handleRename}
                    >
                      <>
                        {Controls[2].items.map((iconItem, index) => {
                          if (iconItem && iconItem.styleType) {
                            const iconItemDescription = iconItem.styleType;
                            const itemControlType = item.controltype;

                            if (iconItemDescription === itemControlType) {
                              return (
                                <div
                                  id={"nav_" + item.id}
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "8px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={{
                                      prefix: "fa",
                                      iconName: [iconItem.mediaURl],
                                    }}
                                  />
                                </div>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return null;
                          }
                        })}
                        {Controls[0].items.map((iconItem, index) => {
                          if (iconItem && iconItem.styleType) {
                            const iconItemDescription = iconItem.styleType;
                            const itemControlType = item.controltype;

                            if (iconItemDescription === itemControlType) {
                              return (
                                <div
                                  id={"nav_" + item.id}
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "8px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={{
                                      prefix: "fa",
                                      iconName: [iconItem.mediaURl],
                                    }}
                                  />
                                </div>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return null;
                          }
                        })}

                        {Controls[3].items.map((iconItem, index) => {
                          if (iconItem && iconItem.styleType) {
                            const iconItemDescription = iconItem.controltype;
                            const itemControlType = item.controltype;

                            if (iconItemDescription === itemControlType) {
                              return (
                                <div
                                  id={"nav_" + item.id}
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "8px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={{
                                      prefix: "fa",
                                      iconName: [iconItem.mediaURl],
                                    }}
                                  />
                                </div>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return null;
                          }
                        })}

                        {Controls[1].items.map((iconItem, index) => {
                          if (iconItem && iconItem.styleType) {
                            const iconItemDescription = iconItem.styleType;
                            const itemControlType = item.controltype;

                            if (iconItemDescription === itemControlType) {
                              return (
                                <div
                                  id={"nav_" + item.id}
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "8px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={{
                                      prefix: "fa",
                                      iconName: [iconItem.mediaURl],
                                    }}
                                  />
                                </div>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return null;
                          }
                        })}
                      </>

                      <div>{item.name}</div>

                      {isRootItem(item, treeData) && (
                        <div
                          id={"nav_" + item.id}
                          style={{ display: "none" }}
                        />
                      )}
                    </div>
                  )}
                </div>

                <div
                  className="Editdeletebitton"
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  {isSelected && isSelected ? (
                    <IconButton onClick={openMenu}>
                      <MoreHorizIcon />
                    </IconButton>
                  ) : null}

                  <Menu
                    PaperProps={{
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={closeMenu}
                    disablePortal
                    className="custom-menu"
                  >
                    {/* <MenuItem className="custom-menu-item close-menu">
                      <CloseIcon onClick={handleClose} />
                    </MenuItem> */}

                    <MenuItem
                      onClick={handleRename}
                      className="custom-menu-item"
                    >
                      <DriveFileRenameOutlineIcon className="list-nav-icon" />
                      Rename
                    </MenuItem>
                    {!isRootItem(item, treeData) &&
                      (isDeleteConfirmationOpen ? (
                        [
                          <hr key="line" className="custom-menu-line-sprt" />,

                          <MenuItem key="label" className="lableTxt">
                            Confirm Delete?
                          </MenuItem>,
                          <Tooltip title="Cancel Delete" arrow>
                            <MenuItem
                              key="cancel"
                              onClick={closeDeleteConfirmation}
                              className="custom-menu-item cancel-btn"
                            >
                              <EditOffIcon className="list-nav-icon" />
                            </MenuItem>
                          </Tooltip>,
                          <Tooltip title="Confirm Delete" arrow>
                            <MenuItem
                              key="confirm"
                              onClick={handleDelete}
                              className="custom-menu-item confirm-btn"
                            >
                              <CheckIcon className="list-nav-icon" />
                            </MenuItem>
                          </Tooltip>,
                          <hr key="line2" className="custom-menu-line-sprt" />,
                        ]
                      ) : (
                        <MenuItem
                          onClick={openDeleteConfirmation}
                          className="custom-menu-item"
                        >
                          <DeleteIcon className="list-nav-icon" />
                          Delete
                        </MenuItem>
                      ))}

                    {!isRootItem(item, treeData) &&

                      (
                        <MenuItem
                          onClick={handleDuplicate}
                          className="custom-menu-item"
                        >
                          <ControlPointDuplicateIcon className="list-nav-icon" />
                          Duplicate
                        </MenuItem>
                      )}
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isExpanded && item.control && item.control.length > 0 && (
          <>
            {item.control.map((child, childIndex) => (
              <TreeItem
                key={`child-${child.id}`}
                item={child}
                addItem={addItem}
                selectedNode={selectedNode}
                setSelectedNode={setSelectedNode}
                index={childIndex}
                treeData={treeData}
                setTreeData={setTreeData}
                parentID={item.id}
                handleSelectItem={handleSelectItem}
                handleDoubleClick={handleDoubleClick}
                selectedItems={selectedItems}
                filterTreeData={filterTreeData}
                searchQuery={searchQuery}
                setEditedName={setEditedName}
                setSelectedItem={setSelectedItem}
                setSelectedControl ={setSelectedControl}
                setSelectedItemID ={setSelectedItemID}
                setSelectedOptioncheck={setSelectedOptioncheck}
                setSelectedOptionSwitchcheck={setSelectedOptionSwitchcheck}
                 setSelectedPositioncheck={setSelectedPositioncheck}
                 editedName={editedName}
                 setEditedData={setEditedData}
                setSelectedOption={setSelectedOption}
                 settabSelect={settabSelect}
              setTableHeader={setTableHeader}
                 setSourceData={setSourceData}
                  setSelectedControlType={setSelectedControlType}
               setIsOptionSelected={setIsOptionSelected}
               isOptionSelected={isOptionSelected}
                settableProp={settableProp}
              />
            ))}
          </>
        )}
      </>
    );
  }
);

export default TreeItem;