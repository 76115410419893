import React, { useEffect, useState } from "react";

function IconBox({ handleChange, buttonList }) {
  const [buttons, setButtons] = useState(buttonList);

  useEffect(() => {
    setButtons(buttonList);
  }, [buttonList]);

  const handleButtonClick = (name, value) => {
    handleChange(name, value);
    // Update the value in the buttons array
    const updatedButtons = buttons.map((button) => {
      if (button.name === name) {
        return { ...button, value };
      }
      return button;
    });
    setButtons(updatedButtons);
  };

  return (
    <div className="topicon">
      {buttons.map((button) => (
        <div className="iconset" key={button.name}>
          <div
            className={
              button.value === 0 ? "iconbtnflxtop" : "iconbtnflxtop-active"
            }
            onClick={() =>
              handleButtonClick(button.name, button.value === 0 ? 1 : 0)
            }
          >
            {button.icon}
            <span className="tooltiptext">{button.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default IconBox;
