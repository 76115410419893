import React from "react";
import { Button } from "@mui/material";

function EditCss({ value, onChange, handlePopup, controlName }) {
;
  // console.log("value:", value);
  // console.log("trimmedValue:", trimmedValue);

  return (
    <>
      <div className="editStylePopLayer"></div>
      <div className="textDialog">
        <div className="textmainarea">
          <div className="textareafildvaluename" style={{ display: "flex" }}>
            <div
              style={{
                color: "black",
                fontSize: "20px",
                alignSelf: "flex-start",
              }}
            >
              Edit Style : {controlName}
            </div>
          </div>

          <div className="textareafildvalue">
            <textarea
              className="textareafild"
              spellCheck="false"
              autoCorrect="off"
              autoComplete="off"
              autoCapitalize="off"
    
              value={value?.replace(/[{}]/g, " ")
              // Replace '": "' with ": " (assuming this is necessary)
              .replace(/: "/g, ": ")
              // Remove newline characters
              .replace(/\n/g, "")}
              onChange={onChange}
            ></textarea>
          </div>
          <div className="cnlaplybutto">
            <Button
              variant="contained"
              color="success"
              title="close"
              className="geBtn"
              onClick={handlePopup}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCss;
