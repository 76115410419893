import { createContext, useContext, useState } from "react";

const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    arrayName: null,
    description: null,
    category : null,
  });

  const setArrayData = (arrayName, description,category) => {
    setAppState({ arrayName, description, category});
  };

  return (
    <AppStateContext.Provider value={{ appState, setArrayData }}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  return useContext(AppStateContext);
};
