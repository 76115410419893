import { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import themeProvider from "../../ThemeProvider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  maxHeight: "480px",
  minWidth: "88%",
  width: "auto",
};
const mediaStyle = { height: "250px", width: "180px" };

const descriptionStyle = {
  fontSize: "15px",
  display: "flex-start",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "10px",
  maxHeight: "100px",
  maxWidth: "270px", // set the maximum width of the container
  wordWrap: "break-word", // allow the text to wrap to a new line when it reaches the maximum width
  overflow: "hidden",
};

const arrowButtonStyle = {
  position: "relative",
  display: "flex-end",
  left: "90%",
  bottom: "-25px", // move the button down slightly
};

const MobileProviderComponent = ({ items }) => {
  return (
    <ThemeProvider theme={themeProvider}>
      <div style={{ margin: "1rem" }}>
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile"]}
          deviceType={"mobile"}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {items.map((item, i) => (
            <Card key={i} style={cardStyle}>
              <CardMedia style={mediaStyle} image={item.mediaURL} />
              <CardContent>
                <Typography
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: themeProvider.palette.primary.main,
                    maxWidth: "270px",
                    wordWrap: "break-word",
                    fontSize: "20px",
                  }}
                >
                  {item.header}
                </Typography>
                <Typography
                  sx={descriptionStyle}
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></Typography>
                <Box>
                  <IconButton
                    sx={arrowButtonStyle}
                    style={{ color: themeProvider.palette.primary.main }}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
                {/* {isAdmin() ? (
                  <Link
                    to={EDIT_URL + item.feedID}
                    target="_blank"
                    style={{ position: "absolute", top: 10, right: 10 }}
                  >
                    <EditIcon />
                  </Link>
                ) : (
                  <></>
                )} */}
              </CardContent>
            </Card>
          ))}
        </Carousel>
      </div>
    </ThemeProvider>
  );
};

export default MobileProviderComponent;
