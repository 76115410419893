import React from "react";

const iconSizeMapping = {
  small: "24px", // Adjust this value according to your needs
  medium: "36px", // Adjust this value according to your needs
  large: "48px", // Adjust this value according to your needs
};
function PreviewNode({ tools, editedProperties, selectedItemID }) {
  const getFlexAlignItems = (alignment) => {
    switch (alignment?.toLowerCase()) {
      case "top":
        return "stretch";
      case "left":
        return "flex-start";
      case "vcenter":
        return "stretch";
      case "hcenter":
        return "center";
      case "center":
        return "center";
      case "bottom":
        return "stretch";
      case "vstretch":
        return "stretch";
      case "right":
        return "flex-end";
      default:
        return "initial";
    }
  };

  let selectedTool = tools.find(
    (tool) => tool?.diagToolID === selectedItemID?.toString()
  );
  let borderSize = "1";
  let borderColor = "#000000";
  let borderRadius = "0";
  let nodeBg = "";
  let nodeColor = "";
  let nodePadding = {};
  let nodeFontSize = "";
  let nodeFontName = "";
  let nodeFontWeight = {};
  let captionBackground = "";
  let captionColor = "#000000";
  let captionAlignmentHorizontal = "";
  let captionFontSize = "";
  let captionFontName = "";
  let captionFontWeight = {};
  let captionIconType = "";
  let captionIconLink = "";
  let captionIconPosition = "";
  let captionIconSize = "";
  let captionDividerSize = "";
  let captionDividerColor = "";
  let bodyTextOverflow = "";
  let bodyBg = "";
  let bodyColor = "#000000";
  let bodyAlignItemsValue = "";
  let bodyHorizontalAlignment = "";
  let bodyFontSize = "";
  let bodyFontName = "";
  let bodyFontWeight = {};
  let bodyType = "0";
  let body = "";
  let bodyURL = "";
  let bodyImageFit = "";
  let linkDividerSize = "";
  let linkDividerColor = "";
  let linkBg = "";
  let linkColor = "#000000";
  let linkFontSize = "";
  let linkFontName = "";
  let linkFontWeight = {};
  let property = [{}];
  // Step 1: Flatten the array of arrays
  const flattenedToolProps = editedProperties.flat();
  // Step 2: Filter the array based on the selectedItemID
  property = flattenedToolProps.filter(
    (toolProp) => toolProp.diagToolID === selectedItemID
  );
  const convertToCamelCase = (str) => {
    return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
  };
  const convertCSSStringToObject = (cssString) => {
    const cssProperties = cssString.split(";").filter(Boolean); // Split the string by semicolons and filter out empty strings
    const cssObject = {};
    cssProperties.forEach((property) => {
      const [key, value] = property.split(":").map((str) => str.trim()); // Split each property by colon and trim whitespaces
      const formattedKey = key.includes("-") ? convertToCamelCase(key) : key;
      cssObject[formattedKey] = value; // Convert the key to camelCase and set it in the object
    });
    return cssObject;
  };
  const getProperty = (propertyName) => {
    const ret = property.find((prop) => prop.propertyName === propertyName);
    if (ret) {
      if (
        (propertyName === "Node_Default_CSS" && property) ||
        (propertyName === "Body_Default_CSS" && property)
      ) {
        return convertCSSStringToObject(ret.diagPropValue);
      }
      return ret?.diagPropValue || "";
    }
  };
  borderSize = getProperty("Node_Border_size", property) || borderSize;
  borderColor = getProperty("Node_Border_color", property) || borderColor;
  borderRadius = getProperty("Node_Border_radius", property) || borderRadius;
  nodeBg = getProperty("Node_Background", property) || nodeBg;
  nodeColor = getProperty("Node_Color", property) || nodeColor;
  nodePadding = getProperty("Node_Padding", property);
  if (nodePadding) {
    nodePadding = JSON.parse(nodePadding);
  } else {
    nodePadding = {
      top: "0",
      topUnit: "px",
      right: "0",
      rightUnit: "px",
      bottom: "0",
      bottomUnit: "px",
      left: "0",
      leftUnit: "px",
    };
  }
  nodeFontSize = getProperty("Node_Font_Size", property) || nodeFontSize;
  nodeFontName = getProperty("Node_Font_Name", property) || nodeFontName;
  nodeFontWeight = getProperty("Node_Font_weight", property);
  if (nodeFontWeight) {
    nodeFontWeight = JSON.parse(nodeFontWeight);
  } else {
    nodeFontWeight = {
      bold: "0",
      italic: "0",
      underline: "0",
    };
  }
  captionBackground =
    getProperty("Caption_Background", property) || captionBackground;
  captionColor = getProperty("Caption_Color", property) || captionColor;
  captionAlignmentHorizontal =
    getProperty("Caption_Alignment_Horizontal", property) ||
    captionAlignmentHorizontal;
  captionFontSize =
    getProperty("Caption_Font_Size", property) || captionFontSize;
  captionFontName =
    getProperty("Caption_Font_Name", property) || captionFontName;
  captionFontWeight = getProperty("Caption_Font_weight", property);
  if (captionFontWeight) {
    captionFontWeight = JSON.parse(captionFontWeight);
  } else {
    captionFontWeight = {
      bold: "0",
      italic: "0",
      underline: "0",
    };
  }
  captionIconType =
    getProperty("Caption_IconType", property) || captionIconType;
  captionIconLink =
    getProperty("Caption_IconLink", property) || captionIconLink;
  captionIconPosition =
    getProperty("Caption_IconPosition", property) || captionIconPosition;
  captionIconSize =
    getProperty("Caption_IconSize", property) || captionIconSize;
  captionDividerSize =
    getProperty("Caption_Divider_size", property) || captionDividerSize;
  captionDividerColor =
    getProperty("Caption_Divider_color", property) || captionDividerColor;

  bodyTextOverflow =
    getProperty("Body_TextOverflow", property) || bodyTextOverflow;
  bodyBg = getProperty("Body_Background", property) || bodyBg;
  bodyColor = getProperty("Body_Color", property) || bodyColor;
  bodyAlignItemsValue = getFlexAlignItems(
    getProperty("Body_Alignment_Vertical", property)
  );
  bodyHorizontalAlignment = getFlexAlignItems(
    getProperty("Body_Alignment_Horizontal", property)
  );
  bodyFontSize = getProperty("Body_Font_Size", property) || bodyFontSize;
  bodyFontName = getProperty("Body_Font_Name", property) || bodyFontName;
  bodyFontWeight = getProperty("Body_Font_weight", property);
  if (bodyFontWeight) {
    bodyFontWeight = JSON.parse(bodyFontWeight);
  } else {
    bodyFontWeight = {
      bold: "0",
      italic: "0",
      underline: "0",
    };
  }
  bodyType = getProperty("Body_type", property) || bodyType;
  body = getProperty("Body_Text", property) || body;
  bodyURL = getProperty("Body_URL", property);
  bodyImageFit = getProperty("Body_Image_Fit", property);
  linkDividerSize = getProperty("Link_Divider_size", property);
  linkDividerSize = getProperty("Link_Divider_color", property);
  linkBg = getProperty("Link_Background", property) || linkBg;
  linkColor = getProperty("Link_Color", property) || linkColor;
  let linkAlignment = getFlexAlignItems(
    getProperty("Link_Alignment_Horizontal", property)
  );
  linkFontSize = getProperty("Link_Font_Size", property) || linkFontSize;
  linkFontName = getProperty("Link_Font_Name", property) || linkFontName;
  linkFontWeight = getProperty("Link_Font_weight", property);
  if (linkFontWeight) {
    linkFontWeight = JSON.parse(linkFontWeight);
  } else {
    linkFontWeight = {
      bold: "0",
      italic: "0",
      underline: "0",
    };
  }

  return (
    <>
      {/* NODE */}
      <div
        style={{
          width: "100%",
          height: "100%",
          border: `${borderSize}px solid ${borderColor}`,
          borderRadius: `${borderRadius}px`,
          background: `${nodeBg}`,
          color: `${nodeColor}`,
          padding: `${nodePadding.top}${nodePadding.topUnit} ${nodePadding.right}${nodePadding.rightUnit} ${nodePadding.bottom}${nodePadding.bottomUnit} ${nodePadding.left}${nodePadding.leftUnit}`,
          fontSize: `${nodeFontSize}`,
          fontFamily: `${nodeFontName}`,
          fontWeight: `${nodeFontWeight.bold === "1" ? "bold" : ""}`,
          fontStyle: `${nodeFontWeight.italic === "1" ? "italic" : ""}`,
          textDecoration: `${nodeFontWeight.underline === "1" ? "underline" : ""}`,
          ...getProperty("Node_Default_CSS", property),
        }}
      >
        {/* CAPTION */}
        <div
          style={{
            display: "flex",
            background: `${captionBackground}`,
            color: `${captionColor}`,
            justifyContent:
              captionAlignmentHorizontal?.toLowerCase() === "left"
                ? "flex-start"
                : captionAlignmentHorizontal?.toLowerCase() === "right"
                  ? "flex-end"
                  : "center",
            fontSize: `${captionFontSize}`,
            fontFamily: `${captionFontName}`,
            fontWeight: `${captionFontWeight.bold === "1" ? "bold" : ""}`,
            fontStyle: `${captionFontWeight.italic === "1" ? "italic" : ""}`,
            textDecoration: `${captionFontWeight.underline === "1" ? "underline" : ""}`,
          }}
        >
          {((captionIconPosition?.toLowerCase() === "left" &&
            captionIconType === "Url") ||
            (captionIconPosition?.toLowerCase() === "center" &&
              captionIconType === "Url" &&
              selectedTool?.caption)) &&
            captionIconLink && (
              <img
                src={captionIconLink}
                alt="Icon"
                style={{
                  width: iconSizeMapping[captionIconSize],
                  marginRight: "0.5rem",
                }}
              />
            )}
          {((captionIconPosition?.toLowerCase() === "left" &&
            captionIconType === "Bootstrap") ||
            (captionIconPosition?.toLowerCase() === "center" &&
              captionIconType === "Bootstrap" &&
              selectedTool?.caption)) && (
            <i
              class={`fa fa-${captionIconLink}`}
              aria-hidden="true"
              style={{ marginRight: "0.5rem" }}
            ></i>
          )}
          {!selectedTool?.caption &&
            captionIconPosition?.toLowerCase() === "left" &&
            captionIconType === "Url" &&
            captionIconLink && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
                  src={captionIconLink}
                  alt="Icon"
                  style={{
                    width: iconSizeMapping[captionIconSize],
                  }}
                />
              </div>
            )}
          {selectedTool?.caption}
          {captionIconPosition?.toLowerCase() === "right" &&
            captionIconType === "Url" && (
              <img
                src={captionIconLink}
                alt="Icon"
                style={{ width: iconSizeMapping[captionIconSize] }}
              />
            )}
        </div>
        {/* CAPTION DIVIDER */}
        {captionDividerSize && captionDividerColor && (
          <div
            style={{
              borderBottom: `${captionDividerSize} solid ${captionDividerColor}`,
              marginBottom: "0.5rem 0",
            }}
          ></div>
        )}
        {/* BODY */}
        <div
          style={{
            display: "flex",
            height:
              bodyTextOverflow === "0" || bodyTextOverflow === "1"
                ? "60%"
                : "auto",
            width:
              bodyTextOverflow === "0" || bodyTextOverflow === "1"
                ? "100%"
                : "auto",
            background: `${bodyBg}`,
            color: `${bodyColor}`,
            alignItems: bodyAlignItemsValue,
            justifyContent: bodyHorizontalAlignment,
            fontSize: `${bodyFontSize}`,
            fontFamily: `${bodyFontName}`,
            fontWeight: `${bodyFontWeight.bold === "1" ? "bold" : ""}`,
            fontStyle: `${bodyFontWeight.italic === "1" ? "italic" : ""}`,
            textDecoration: `${bodyFontWeight.underline === "1" ? "underline" : ""}`,
            overflow:
              bodyTextOverflow === "0" || bodyTextOverflow === "1"
                ? "hidden"
                : "",
            whiteSpace: bodyTextOverflow === "1" ? "nowrap" : "wrap",
            textOverflow: bodyTextOverflow === "1" ? "ellipsis" : "",
            ...getProperty("Body_Default_CSS", property),
          }}
        >
          {bodyType === "0" ? (
            body
          ) : bodyType === "1" ? (
            <div dangerouslySetInnerHTML={{ __html: body }} />
          ) : (
            <img
              src={bodyURL}
              alt="Body"
              style={{
                width: "100%",
                height: "100%",
                objectFit:
                  bodyImageFit === "0"
                    ? "none"
                    : bodyImageFit === "1"
                      ? "fill"
                      : "contain",
              }}
            />
          )}
        </div>
        {/* LINK DIVIDER */}
        {linkDividerSize && linkDividerColor && (
          <div
            style={{
              borderBottom: `${linkDividerSize} solid ${linkDividerColor}`,
              marginBottom: "0.5rem 0",
            }}
          ></div>
        )}
        {/* LINK */}
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            background: `${linkBg}`,
            color: `${linkColor}`,
            justifyContent: linkAlignment,
          }}
        >
          <a
            // href="https://hbq.agiline.com/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: `${linkColor}`,
              fontSize: `${linkFontSize}`,
              fontFamily: `${linkFontName}`,
              fontWeight: `${linkFontWeight.bold === "1" ? "bold" : ""}`,
              fontStyle: `${linkFontWeight.italic === "1" ? "italic" : ""}`,
              textDecoration: `${linkFontWeight.underline === "1" ? "underline" : ""}`,
            }}
          >
            {/* www.hbq.agiline.com */}
          </a>
        </div>
      </div>
    </>
  );
}

export default PreviewNode;
