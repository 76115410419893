import React, { useState } from "react";
import { IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import themeProvider from "../../ThemeProvider";
import { Action } from "./ActionList";

function SectionTitle({
  admin,
  section,
  hideArray,
  pageName,
  handleDialogToggle,
}) {
  const isMobile = window.innerWidth <= 750;
  const titleComponent = (
    <span>
      <span
        style={{
          display: "flex",
          justifyContent: "flex-end",
          color: themeProvider.palette.primary.main,
          fontSize: isMobile ? "20px" : "28px",
          fontWeight: "bold",
          padding: "1rem",
        }}
      >
        {admin && (
          <>
            <IconButton
              onClick={(event) =>
                handleDialogToggle(
                  event,
                  section.sectionID,
                  section.title,
                  Action.EDIT_SECTION
                )
              }
              color="primary"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(event) =>
                handleDialogToggle(
                  event,
                  section.sectionID,
                  pageName,
                  Action.ADD_ITEM_TO_SECTION
                )
              }
              color="primary"
              sx={{ marginRight: "10px" }}
            >
              <AddCircleIcon />
            </IconButton>
          </>
        )}
        {section.showTitle === 1 && section.title}
      </span>
      <hr
        style={{
          borderColor: "lightGray",
        }}
      />
    </span>
  );

  return (
    !hideArray.includes(section.defView) &&
    (section.showTitle === 1 || admin) &&
    titleComponent
  );
}

export default SectionTitle;
