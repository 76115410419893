import React from "react";
import {
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
function Weekly({
  scheduleData,
  setScheduleData,
  handleTimeChange,
  handleDaysOfTheWeekOptionsChange,
}) {
  const handleCheckAll = () => {
    setScheduleData({
      ...scheduleData,
      daysOfTheWeek:
        scheduleData.daysOfTheWeek.length === daysOfWeek.length
          ? []
          : daysOfWeek,
    });
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Days of the week</InputLabel>
      <Select
        multiple
        size="small"
        label="Days of the week"
        name="daysOfTheWeek"
        value={scheduleData.daysOfTheWeek}
        onChange={handleDaysOfTheWeekOptionsChange}
        renderValue={(selected) => selected.join(", ")}
        sx={{ marginBottom: "0.5rem" }}
      >
        <MenuItem
          value="all"
          onClick={handleCheckAll}
          //   onClick={() =>
          // setDayOptions(
          //   dayOptions.length === daysOfWeek.length ? [] : daysOfWeek
          // )
          //   }
        >
          <Checkbox
            checked={scheduleData.daysOfTheWeek.length === daysOfWeek.length}
            indeterminate={
              scheduleData.daysOfTheWeek.length > 0 &&
              scheduleData.daysOfTheWeek.length < daysOfWeek.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>
        {daysOfWeek.map((day) => (
          <MenuItem key={day} value={day}>
            <Checkbox checked={scheduleData.daysOfTheWeek.indexOf(day) > -1} />
            <ListItemText primary={day} />
          </MenuItem>
        ))}
      </Select>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              marginLeft: "0.5rem",
            }}
          >
            Start at:
          </div>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
            <TimePicker
              label="Time"
              value={scheduleData.weekTime}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default Weekly;
