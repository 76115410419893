import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Image, Theme } from "./IncludeFile/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FaUser } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "@mui/material";

const ContainerLoginPageBg = styled.div`
  background-image: url(${Image.Loginuser});
  background-size: cover;
  background-position: left top;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const LoginFormContainer = styled.div`
  /* Add your styles for LoginFormContainer here */
`;

const LoginPage = () => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [domain, setDomain] = useState("");
  const [isLoginDisabled, setIsLoginDisabled] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/Landing");
  };

  const [passwordError, setPasswordError] = useState("");

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    const passwordRegex = /^.{6,}$/;

    if (!passwordRegex.test(value)) {
      setPasswordError("Password must be at least 6 characters long.");
      setIsLoginDisabled(true);
    } else {
      setPasswordError("");
      setIsLoginDisabled(false);
    }
  };

  const handleUserName = (e) => {
    const value = e.target.value;

    setUsername(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isLoginDisabled && !passwordError) {
      handleNavigate();
    }
   
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (username !== "" && password !== "" && domain !== "") {
      setIsLoginDisabled(false);
    }
  }, []);

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  return (
    <ContainerLoginPageBg>
      <div className="containe-top-left"></div>
      <div className="containe-top-right">
        <LoginFormContainer className="cont-bg-login-form">
          <form className="login-form-name" onSubmit={handleSubmit}>
            <div className="user-icon-login-form">
              <FaUser />
            </div>
            <h2 className="input-login-frm-title">React Form Builder</h2>
            <div className="input-login-frm">
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="input-login-bg"
              >
                <FontAwesomeIcon icon={faUser} className="icon-user" />
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={handleUserName}
                  style={{
                    flex: "1",
                    padding: "8px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
            </div>
            <div className="input-login-frm">
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="input-login-bg"
              >
                <FontAwesomeIcon icon={faLock} className="icon-user" />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                  }}
                  inputmode="none"
                  className="custom-password-input"
                />
                <span className="them-eye-password">
                  {showPassword ? (
                    <span onClick={togglePasswordVisibility}>
                      {Theme.preview}
                    </span>
                  ) : (
                    <span onClick={togglePasswordVisibility}>
                      {Theme.eyeOff}
                    </span>
                  )}
                </span>
              </div>
              {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="input-login-frm input-login-bg"
            >
              <FontAwesomeIcon icon={faGlobe} className="icon-user" />
              <input
                type="text"
                id="domain"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                style={{
                  flex: "1",
                  padding: "8px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
            <ReCAPTCHA
              sitekey="6Lf0vB0qAAAAAGtG0Y9ji9-nEAwPHDE0tCLgf7h4"
            />
            <Button
              type="submit"
              disabled={isLoginDisabled}
              // className="login-button"
              variant="contained"
              color="info"
            >
              Login
            </Button>
          </form>
        </LoginFormContainer>
      </div>
    </ContainerLoginPageBg>
  );
};

export default LoginPage;