import React, { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { ToastContainer } from "react-toastify";
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogContentText,

  TextField,
  Typography,
} from "@mui/material";
import { Theme, Image } from "../IncludeFile/Theme";
import Tooltip from "@mui/material/Tooltip";
import { TiExport } from "react-icons/ti";
import { FaRegEye } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import "./RecursiveTreeView.css";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaAngleDown } from "react-icons/fa6";

const FormTopSection = ({
  searchQuery,
  handleSearch,
  handleControls,
  handleMenuButtonClick,
  handleTabshwButtonClick,
  onSaveClick,
  handleSaveToFile,
  handleGoBack,
  openConfirmation,
  handleCloseConfirmation,
  handleConfirmGoBackWithSave,
  setControlsVisible,
  controlsVisible,
  onSaveClick1,
  tempNum,
  templateSource,
  dataSourceArID,
  category,
  htmlFileContent,
  treeData,
  setSelectedNode,
  selectedNode,
  setSelectedItem,
  setSelectedItemID,
  setEditedName,
  setSelectedControl
}) => {
  const [openSavePopUp, setOpenSavesPopup] = useState(false);
  const [arrayNameError, setArrayNameError] = useState("");
  const [formNameExists, setFormNameExists] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const toggleControlsVisibility = () => {
      
    if (treeData.length > 0 && !selectedNode) {
      // const firstItem = treeData[0].Source[0];
      const firstItem = selectedNode.id;
      setSelectedNode(firstItem);
      setSelectedItem(firstItem);
      setSelectedItemID(firstItem.id);
    }
  
    setControlsVisible(!controlsVisible);
  };

  const nodeListfocused = document.querySelectorAll(".focusingClass")[0];

  const handlePreViewBack = () => {

    // const firstItem = treeData[0].Source[0];
    const firstItem = selectedNode.id;
    setSelectedNode(firstItem);
    setSelectedItem(firstItem);
    setSelectedItemID(firstItem.id);
    setSelectedControl(firstItem.type)
    const firstItem1 = treeData[0].Source[0];
    setSelectedNode(firstItem1);
    setSelectedItem(firstItem1);
    setSelectedControl(firstItem1)
    setEditedName(firstItem1.name);
    setSelectedItemID(firstItem1.id);
    setControlsVisible(true);
    if (nodeListfocused) {
      nodeListfocused.classList.remove("focusingClass");
    }
    };


  const inputRef = useRef(null);
  const [arrayName, setArrayName] = useState("");
  const [description, setDescription] = useState("");

  let APIProvider = window.APIProvider;

  const handleSaveClick = () => {
    if (!arrayName.trim()) {
      setArrayNameError("Form Name is required");
      return;
    } else {
      setArrayNameError("");
    }
    const data = {
      ArID: 0,
      ArrayName: arrayName,
      Description: description,
      source: JSON.stringify(templateSource),
      category: category,
      IsTemplate: "1",
      htmlOutput: htmlFileContent,
      dataSourceArID: dataSourceArID,
    };

    fetch(APIProvider.baseURL + APIProvider.recordPath.WriteArray, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        setLoading(true);
        setTimeout(() => {
          navigate("/Open");
        }, 2000);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("There was an error with the POST request:", error);
      });
  };

  const handleSaveClick1 = () => {
    if (!arrayName.trim()) {
      setArrayNameError("Form Name is required");
      return;
    } else {
      setArrayNameError("");
    }
    const data = {
      ArID: 0,
      ArrayName: arrayName,
      Description: description,
      source: JSON.stringify(templateSource),
      category: category,
      IsTemplate: "0",
      htmlOutput: htmlFileContent,
      dataSourceArID: dataSourceArID,
    };

    fetch(APIProvider.baseURL + APIProvider.recordPath.WriteArray, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        setLoading(true);
        setTimeout(() => {
          navigate("/Open");
        }, 2000);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("There was an error with the POST request:", error);
      });
  };

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    setOpenSavesPopup(value !== "");
  };

  useEffect(() => {
    const checkFormNameExists = async () => {
      try {
        const fetchDataResponseArray = await fetch(
          APIProvider.baseURL + APIProvider.recordPath.ReadArrayAll
        );
        if (fetchDataResponseArray.ok) {
          const data = await fetchDataResponseArray.json();
          const arrayNames = data.map((item) => item.arrayName);
   

          if (
            arrayNames.some(
              (name) => name.toLowerCase() === arrayName.toLowerCase()
            )
          ) {
            toast.error(
              "Form name already exists. Please choose a different name."
            );
            setFormNameExists(true);
            return;
          } else {
            setFormNameExists(false);
          }
        } else {
          console.error(
            "Error fetching data:",
            fetchDataResponseArray.statusText
          );
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    if (arrayName.trim()) {
      checkFormNameExists();
    }
  }, [arrayName]);

  const [toggleDisplay, setToggleDisplay] = useState(false);

  const toggleDropdown = () => {
    setToggleDisplay(!toggleDisplay); 
  };

  return (
    <div className="containerbtnsecction">
      <ToastContainer position="bottom-right" autoClose={3000} />
      {controlsVisible && (
        <div className="menu-srch-form-wrap">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="inputfield-wrap">
              <input
                className="inputfield"
                id="outlined-multiline-flexible"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
                ref={inputRef}
              />
              <div className="inputfield-srch-icon">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    inputRef.current.focus();
                  }}
                >
                  {Theme.Search}
                </span>
              </div>
            </div>
          </Box>
        </div>
      )}
      <div className="add-save-expt">
        <div className="addsavebtn">
          <div className="add-sv-lft-btns">
            {controlsVisible && (
              <Tooltip
                id="tooltipstyletop"
                arrow
                title={<span className="tooltipstyletop">Add Control</span>}
              >
                <Button
                  variant="contained"
                  onClick={handleControls}
                  className="addandsave"
                >
                  {Theme.Add}
                  <h6 className="addcontrollbutton">Add Control</h6>
                </Button>
              </Tooltip>
            )}
            {controlsVisible && (
              <Button
                className="shwmenubtn"
                variant="contained"
                onClick={handleMenuButtonClick}
              >
                <Tooltip
                  id="mblShowMenuBtn"
                  arrow
                  title={
                    <span className="mblShowMenuBtn">Show/Hide left panel</span>
                  }
                >
                  {Theme.MenuOpenIcon}
                </Tooltip>
              </Button>
            )}
            {controlsVisible && (
              <Button
                className="shwtabbtn"
                variant="contained"
                onClick={handleTabshwButtonClick}
              >
                <Tooltip
                  id="mblShowSetngBtn"
                  arrow
                  title={
                    <span className="mblShowSetngBtn">
                      Show/Hide right panel
                    </span>
                  }
                >
                  {Theme.TuneIcon}
                </Tooltip>
              </Button>
            )}
          </div>
          {controlsVisible && (
            <div className="save-export-btn">
              <Tooltip
                id="tooltipstyletop"
                arrow
                title={<span className="tooltipstyletop">Save</span>}
              >
                <div className="slect-sve-itm">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={tempNum === 1 ? onSaveClick1 : onSaveClick}
                    className="addandsavebutton save-btn-top"
                  >
                    {Theme.Save}
                    <h6 className="addcontrollbutton">Save </h6>
                  </Button>
                  <span className="border-left-edit"></span>
                  <div className="dropdown-top custom-select-container">
                    <div className="custom-select-container">
                      <div
                        className="icon-for-edit-cntrl"
                        onClick={toggleDropdown}
                      >
                        <FaAngleDown />
                      </div>
                      {toggleDisplay && (
                        <div
                          className="dropdown-options"
                          onChange={handleChange}
                        >
                          <div
                            className="seclt-option"
                            onClick={() => {
                              handleChange({ target: { value: "0" } });
                              setOpenSavesPopup(true);
                            }}
                          >
                            Save As
                          </div>
                          <div
                            className="seclt-option"
                            onClick={() => {
                              handleChange({ target: { value: "1" } });
                              setOpenSavesPopup(true);
                            }}
                          >
                            Save as a Template
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                id="tooltipstyletop"
                arrow
                title={<span className="tooltipstyletop">Export</span>}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  className="addandsavebutton"
                  onClick={() => {
                    handleSaveToFile();
                    onSaveClick(false);
                  }}
                >
                  <TiExport />
                  <h6 className="addcontrollbutton">Export </h6>
                </Button>
              </Tooltip>
              <Tooltip
                id="tooltipstyletop"
                arrow
                title={<span className="tooltipstyletop">Preview</span>}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  className="addandsavebutton"
                  onClick={(event) => {
                    toggleControlsVisibility(event);
                  }}
                >
                  <FaRegEye />
                  <h6 className="addcontrollbutton">Preview</h6>
                </Button>
              </Tooltip>
            </div>
          )}
        </div>

        <div className="backbtn-rht" style={{ marginright: "auto" }}>
          {controlsVisible && (
            <Tooltip
              id="tooltipstyletop"
              arrow
              title={<span className="tooltipstyletop">Back</span>}
            >
              <Button variant="contained" onClick={handleGoBack}>
                {Theme.GoBack}
                <h6 className="addcontrollbutton">Back</h6>
              </Button>
            </Tooltip>
          )}
          {/* {!controlsVisible && (
            <Button variant="contained" onClick={handlePreViewBack}>
              <Tooltip
                id="tooltipstyletop"
                arrow
                title={<span className="tooltipstyletop">Edit</span>}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {Theme.Open}
                  <h6 className="addcontrollbutton">Edit</h6>
                </div>
              </Tooltip>
            </Button>
          )} */}

          {!controlsVisible && (
            <Button variant="contained" onClick={handlePreViewBack}>
              <Tooltip
                id="tooltipstyletop"
                arrow
                title={<span className="tooltipstyletop">Back</span>}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {Theme.GoBack}
                  <h6 className="addcontrollbutton">Back</h6>
                </div>
              </Tooltip>
            </Button>
          )}

          <Dialog
            open={openConfirmation}
            onClose={handleCloseConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div style={{ padding: "25px" }}>
              <div className="cusm-usve-btn">
                <DialogTitle id="alert-dialog-title">
                  <div className="close-popup-frm">
                    <p className="con-frm-cls">Unsaved Changes</p>
                    <IconButton
                      onClick={handleCloseConfirmation}
                      color="inherit"
                      edge="end"
                      style={{ marginTop: "-9px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <p className="con-txt-fr">
                      Are you sure you want to Leave this page?
                      <br /> Changes you made will not be saved.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseConfirmation}
                    color="primary"
                    className="cancl-page-btn"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmGoBackWithSave}
                    color="primary"
                    autoFocus
                    className="leave-page-btn"
                  >
                    Leave Page
                  </Button>
                </DialogActions>
              </div>
            </div>
          </Dialog>
          <CustomPopUpForm
            open={openSavePopUp}
            onClose={() => setOpenSavesPopup(false)}
            title="Save as New Template"
            height="60%"
          >
            <div className="card-info card-info-view-crd">
              <div className="card-info-inside card-info-inside-view">
                <Typography style={{ fontWeight: "bold" }}>
                  Form Name*:
                </Typography>
                <TextField
                  required
                  type="text"
                  className="edit-popup"
                  name="Name"
                  // onChange={(e) => setArrayName(e.target.value)}
                  onChange={(e) => {
                    setArrayName(e.target.value);
                    setArrayNameError("");
                  }}
                  error={!!arrayNameError}
                  helperText={arrayNameError}
                />
              </div>
              <div className="card-info-inside">
                <Typography style={{ fontWeight: "bold" }}>
                  Description:
                </Typography>
                <TextField
                  type="text"
                  className="edit-popup"
                  name="Title"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <>
              <div style={{ width: "96%", display: "flex" }}>
                <Button fullWidth onClick={() => setOpenSavesPopup(false)}>Cancel</Button>
                {selectedValue === "0" && (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSaveClick1}
                    disabled={formNameExists}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                )}
                {selectedValue !== "0" && (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSaveClick}
                    disabled={formNameExists}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                )}
              </div>
            </>
          </CustomPopUpForm>
        </div>
      </div>
    </div>
  );
};

export default FormTopSection;