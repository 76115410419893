import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,

} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../assets/icofontcharts/icofont.min.css";
import { Theme } from "../Components/IncludeFile/Theme";
import { ReadArray } from "../Components/Api/ApiCalls";
import Typography from "@mui/material/Typography";
import {  toast } from "react-toastify";
import CustomPopUpForm from "../layout/CustomPopUpForm";
import { makeStyles } from "@mui/styles";
import Discover from "./Discover";

const useStyles = makeStyles({
  customSelectRoot: {
    "&:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent  !important",
    },
  },
});

const ViewCardInfo = () => {
  let APIProvider = window.APIProvider;
  const location = useLocation();
  const arrayName = location.state ? location.state.arrayName : null;
  const category = location.state ? location.state.category : null;
  const [editedcategory, seteditedcategory] = useState(category);
  const [editedArrayName, setEditedArrayName] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [arrayNameError, setArrayNameError] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const [isOpenSelectPage, setIsOpenSelectpage] = useState(true);
  const [arrayNames, setArrayNames] = useState([]);
  const [selectedArrayName, setSelectedArrayName] = useState("");
  const [selectedArrayId, setSelectedArrayId] = useState(null);
  const [showDiscover, setShowDiscover] = useState(false);
  const [isOpenDiscover, setIsOpenDiscover] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [formNameExists, setFormNameExists] = useState(false);
  
  const navigate = useNavigate();

  const handleCreate = async () => {

    setLoading(true);
    try {
      if (!editedArrayName.trim()) {
        setArrayNameError("Form Name is required");
        setLoading(false);
        return;
      }

      const fetchDataResponseArray = await fetch(
        APIProvider.baseURL + APIProvider.recordPath.ReadArrayAll
      );
      if (fetchDataResponseArray.ok) {
        const data = await fetchDataResponseArray.json();
        const arrayNames = data.map((item) => item.arrayName);

        if (
          arrayNames.some(
            (name) => name.toLowerCase() === editedArrayName.toLowerCase()
          )
        ) {
          toast.error(
            "Array name already exists. Please choose a different name."
          );
          return;
        }
      } else {
        console.error(
          "Error fetching data:",
          fetchDataResponseArray.statusText
        );
        return;
      }

      const fetchDataResponse = await fetch(
        APIProvider.baseURL +
          APIProvider.recordPath.ReadArrayByName +
          "?arrayName=" +
          arrayName
      );

      if (fetchDataResponse.ok) {
        const resultData = await fetchDataResponse.json();
        const arID = resultData.arID;

        const jsonData = await ReadArray(arID);
        
        if (jsonData) {
          const sourceData = jsonData[0].Source;

          const response = await fetch(
            APIProvider.baseURL + APIProvider.recordPath.WriteArray,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                arId: "0",
                arrayName: editedArrayName,
                description: editedDescription,
                category: category,
                source: JSON.stringify(sourceData),
                IsTemplate: "0",
                htmlOutput: "new",
                dataSourceArID: selectedArrayId || 0,
              }),
            }
          );

        

          if (response.ok) {
            const fetchDataResponse = await fetch(
              APIProvider.baseURL +
                APIProvider.recordPath.ReadArrayByName +
                "?arrayName=" +
                editedArrayName
            );
            if (fetchDataResponse.ok) {
              const resultData = await fetchDataResponse.json();
              const arID = resultData.arID;
              
              const newArrayName = resultData.arrayName;
              const newDescription = resultData.description;
              const newCategory = resultData.category;
              const newdataSourceArID = resultData.dataSourceArID;
             

              const newData = await ReadArray(arID);
            

              if (arID) {
                
              } else {
                const fetchDataResponseArID = await fetch(
                  APIProvider.baseURL + APIProvider.recordPath.ReadArrayAll
                );
                if (fetchDataResponseArID.ok) {
                  const data = await fetchDataResponseArID.json();
                  const arId = data.map((item) => item.arID);
                  
                }
              }

              if (newData) {
              

                if (category === "Blank Form") {
                  navigate(`/Form/${arID}`, {
                    state: {
                      arrayName: newArrayName,
                      arID: arID,
                      source: JSON.stringify(sourceData),
                      dataSourceArID: newdataSourceArID,
                      category: newCategory,
                      description: newDescription,
                    },
                  });
                } else if (newdataSourceArID > 0) {
                  navigate("/Open", {
                    state: {
                      arrayName: newArrayName,
                      arID: arID,
                      source: JSON.stringify(sourceData),
                      dataSourceArID: newdataSourceArID,
                      category: newCategory,
                      description: newDescription,
                    },
                  });
                } else {
                  navigate(`/Form/${arID}`, {
                    state: {
                      arrayName: newArrayName,
                      arID: arID,
                      source: JSON.stringify(sourceData),
                      dataSourceArID: newdataSourceArID,
                      category: newCategory,
                      description: newDescription,
                    },
                  });
                }
              }
            } else {
              console.error(
                "Error fetching data based on arrayName:",
                fetchDataResponse.statusText
              );
            }
          } else {
            console.error("Error creating array:", response.statusText);
          }
        }
      } else {
        console.error(
          "Error fetching data based on arrayName:",
          fetchDataResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoading(false);
    setShowDiscover(true);
    setIsOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.recordPath.ReadArrayAll}?isTemplate=2`
        );
        if (response.ok) {
          const data = await response.json();

          const filteredNames = data
            .filter((item) => item.isTemplate === 2)
            .map((item) => item.arrayName);
          setArrayNames(filteredNames);
        } else {
          console.error("Failed to fetch array names");
        }
      } catch (error) {
        console.error("Error fetching array names:", error);
      }
    };
    fetchData();
  }, []);

  const handleArrayNameChange1 = async (event) => {
    setSelectedArrayName(event.target.value);
    try {
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.recordPath.ReadArrayAll}?isTemplate=2`
      );
      if (response.ok) {
        const data = await response.json();
        const selectedArray = data.find(
          (item) => item.arrayName === event.target.value
        );
        if (selectedArray) {
          console.log("Selected ARID:", selectedArray.arID);
          setSelectedArrayId(selectedArray.arID);


    
        } else {
          console.error("Selected array not found");
        }
      } else {
        console.error("Failed to fetch array names");
      }
    } catch (error) {
      console.error("Error fetching array names:", error);
    }
  };

  const toggleEditState = () => {
    setIsEditable(!isEditable);
  };

  const handleSave = async () => {
    try {
      if (!editedArrayName.trim()) {
        setArrayNameError("Form Name is required");
        setLoading(false);
        return;
      } else {
        setArrayNameError("");
      }
      if (formNameExists) {
        toast.error(
          "Form name already exists. Please choose a different name."
        );
        return;
      }
      toggleEditState();
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    const checkFormNameExists = async () => {
      try {
        const fetchDataResponseArray = await fetch(
          APIProvider.baseURL + APIProvider.recordPath.ReadArrayAll
        );
        if (fetchDataResponseArray.ok) {
          const data = await fetchDataResponseArray.json();
          const arrayNames = data.map((item) => item.arrayName);

          if (
            arrayNames.some(
              (name) => name.toLowerCase() === editedArrayName.toLowerCase()
            )
          ) {
            toast.error(
              "Form name already exists. Please choose a different name."
            );
            setFormNameExists(true);
            return;
          } else {
            setFormNameExists(false);
          }
        } else {
          console.error(
            "Error fetching data:",
            fetchDataResponseArray.statusText
          );
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    if (editedArrayName.trim()) {
      checkFormNameExists();
    }
  }, [editedArrayName]);

  const iconButtonsSaveAndRestore = [
    {
      icon: Theme.Restore,
      color: "primary",
      handleClick: () => {
        handleCancelClick();
      },
      tooltip: "Refresh",
    },
    {
      icon: Theme.Save,
      color: "primary",
      handleClick: () => {
        handleSave();
      },
      tooltip: "Save",
    },
  ];

  const handleCancelClick = () => {
    setEditedArrayName("");
    setEditedDescription("");
    setSelectedArrayName("");
    setSelectedArrayId(null);
  };

  const iconButtonsEdit = [
    {
      icon: Theme.Edit,
      color: "primary",
      handleClick: () => {
        handleEdit();
      },
      tooltip: "Click To Edit",
    },
  ];

  const handleCancel = () => {
    setIsOpen(false);
    setIsOpenSelectpage(false);
  };

  const classes = useStyles();
  
  return (
    <CustomPopUpForm
      open={isOpenSelectPage}
      onClose={handleCancel}
      title="Create New Form"
      iconButtons={isEditable ? iconButtonsSaveAndRestore : iconButtonsEdit}
      height="60%"
    >
      <>
        {!isEditable ? (
          <>
            <div className="card-info">
              <div className="card-info-inside-view">
                <Typography className="card-info-inside-text">
                  Form Name*:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedArrayName}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Description:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedDescription}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Category:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedcategory}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Data Source:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {selectedArrayName}
                  </Typography>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card-info card-info-view-crd">
            <div className="card-info-inside card-info-inside-view">
              <Typography style={{ fontWeight: "bold" }}>
                Form Name*:
              </Typography>
              <TextField
                required
                type="text"
                className="edit-popup"
                name="Name"
                onChange={(e) => {
                  setEditedArrayName(e.target.value);
                  setArrayNameError("");
                }}
                value={editedArrayName}
                error={!!arrayNameError}
                helperText={arrayNameError}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Description:
              </Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Title"
                onChange={(e) => setEditedDescription(e.target.value)}
                value={editedDescription}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>Category:</Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Explanation"
                value={editedcategory}
                style={{ backgroundColor: "#bdbdbd" }}
                readOnly
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Data Source:
              </Typography>
              <Select
                value={selectedArrayName || "0"}
                onChange={handleArrayNameChange1}
                className={`${classes.customSelectRoot} data-select-crd`}
              >
                <MenuItem value="0">Select DataSource </MenuItem>
                {arrayNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}
      </>
      <>
        <div style={{ width: "96%", display: "flex" }}>
          <Button fullWidth onClick={handleCancel}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleCreate}
            disabled={formNameExists}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
          </Button>
        </div>
        {showDiscover && (
          <Discover
            arrayName={editedArrayName}
            description={editedDescription}
            category={editedcategory}
            setArrayName={setEditedArrayName}
            setDescription={setEditedDescription}
            setCategory={seteditedcategory}
            setIsOpen={setIsOpen}
            isOpenDiscover={isOpenDiscover}
            setIsOpenDiscover={setIsOpenDiscover}
            selectedArrayName={selectedArrayName}
            selectedArrayId={selectedArrayId}
            setSelectedArrayId={setSelectedArrayId}
            setSelectedArrayName={setSelectedArrayName}
            handleArrayNameChange1={handleArrayNameChange1}
            isOpen={isOpen}
            isOpenSelectPage={isOpenSelectPage}
            setIsOpenSelectpage={setIsOpenSelectpage}
          />
        )}
      </>
    </CustomPopUpForm>
  );
};

export default ViewCardInfo;
