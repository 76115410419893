import React, { useState, useEffect } from "react";
import { Divider, ThemeProvider } from "@mui/material";
import themeProvider from "../../../../ThemeProvider";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ConditionFactory from "./ConditionFactory";
import { v4 as uuidv4 } from "uuid";

function Conditions({ originalModule, originalDataSet, dataSetOptions }) {
  const [statement1, setStatement1] = useState(
    "Create a split in your flow based on a profile’s properties or behavior."
  );
  const [statement2, setStatement2] = useState(
    "Profiles that meet the following condition(s) will move to the YES side of this split:"
  );

  const [conditions, setConditions] = useState([
    {
      id: "1",
      mainCondition: "",
      condition1: "",
      condition2: "",
      condition2Boolean: "",
      condition2Date: "",
      condition2List: "",
      type: "text",
      value1: "",
      value2: "",
      // children: [
      //   {
      //     id: "1.1",
      //     mainCondition: "",
      //     condition1: "",
      //     condition2: "",
      //     condition2Boolean: "",
      //     condition2Date: "",
      //     condition2List: "",
      //     type: "text",
      //     value1: "",
      //     value2: "",
      //   },
      // ],
    },
  ]);

  const addCondition = async () => {
    // Assuming each main condition corresponds to a condition object
    setConditions([
      ...conditions,
      {
        id: uuidv4(),
      },
    ]);
  };

  const removeCondition = (id) => {
    setConditions((prevConditions) => {
      // Helper function to recursively find and remove the condition object by ID
      const removeById = (conditions, id) => {
        return conditions.reduce((acc, condition) => {
          if (condition.id === id) {
            // If the condition matches the ID, remove it
            return acc;
          } else if (condition.children) {
            // If the condition has children, recursively remove from children
            const updatedChildren = removeById(condition.children, id);
            // Update condition's children
            condition.children = updatedChildren;
            // If all children are removed and this condition has no other properties, skip adding it to the result
            if (
              updatedChildren.length === 0 &&
              Object.keys(condition).length === 1
            ) {
              return acc;
            }
          }
          // Add condition to the result
          acc.push(condition);
          return acc;
        }, []);
      };

      // Call the removeById function to remove the condition by ID
      return removeById(prevConditions, id);
    });
  };

  // Function to handle changes in condition objects
  const handleConditionChange = (id, fieldName, value) => {
    setConditions((prevConditions) => {
      const updatedConditions = [...prevConditions];

      // Helper function to recursively find the condition object by ID
      const findConditionById = (conditions, id) => {
        for (const condition of conditions) {
          if (condition.id === id) {
            return condition;
          }
          if (condition.children) {
            const foundCondition = findConditionById(condition.children, id);
            if (foundCondition) {
              return foundCondition;
            }
          }
        }
        return null;
      };

      // Find the condition object using the provided ID
      const conditionToUpdate = findConditionById(updatedConditions, id);

      // If condition with the provided ID is found, update its field
      if (conditionToUpdate) {
        conditionToUpdate[fieldName] = value;
      }

      return updatedConditions;
    });
  };

  return (
    <Grid
      style={{
        // border: "1px solid #A9A9A9",
        padding: "0.5rem",
        // borderRadius: "10px",
      }}
    >
      <ThemeProvider theme={themeProvider}>
        <div>
          {/* Header */}
          {/* <Typography
            variant="h6"
            gutterBottom
            style={{ display: "flex", alignItems: "center" }}
          >
            <SettingsIcon style={{ marginRight: "0.5rem" }} />
            Settings
          </Typography>
          <Divider sx={{ borderColor: "black", marginBottom: "0.5rem" }} /> */}

          {/* Main section */}
          {/* <Typography variant="body1" gutterBottom>
            {statement1}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {statement2}
          </Typography> */}
          {/* Conditions section */}
          {conditions.map((condition, index) => (
            <React.Fragment key={index}>
              {index !== 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "2rem",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid lightGray",
                      borderRadius: "5px",
                      padding: "0.2rem 0.5rem",
                      color: "gray",
                      marginBottom: "0.5rem",
                    }}
                  >
                    and
                  </div>
                </div>
              )}
              <ConditionFactory
                id={condition.id}
                removeCondition={removeCondition}
                condition={condition}
                setConditions={setConditions}
                handleConditionChange={handleConditionChange}
                originalModule={originalModule}
                originalDataSet={originalDataSet}
                dataSetOptions={dataSetOptions}
              />
            </React.Fragment>
          ))}

          <Button
            // size="small"
            style={{
              fontSize: "0.75rem",
              maxWidth: "50px",
              maxHeight: "30px",
              minWidth: "50px",
              minHeight: "30px",
            }}
            variant="outlined"
            onClick={addCondition}
            // style={{ margin: "1rem 0" }}
          >
            And
          </Button>
        </div>
      </ThemeProvider>
    </Grid>
  );
}

export default Conditions;
