import React, { useState } from "react";
import { Theme, fontFamilies } from "../../IncludeFile/Theme";
import { Select, MenuItem } from "@mui/material";
import IconBox from "../IconBox";

const predefinedFontSizes = [
  { value: "35px", label: "Headline1" },
  { value: "24px", label: "Headline2" },
  { value: "18px", label: "Headline3" },
  { value: "16px", label: "LargeText" },
  { value: "14px", label: "NormalText" },
  { value: "12px", label: "SmallText" },
];
function FontDropDown({
  selectedItemID,
  editedProperties,
  handlePropertyChange,
  propSection,
}) {
  const [fontStyles, setFontStyles] = useState({
    fontColor: "",
    fontSize: "",
    customFontSize: "",
    fontUnit: "",
    fontFamily: "",
    fontAlignment: "",
  });
  const [fontStylesWeight, setFontStylesWeight] = useState({
    bold: 0,
    italic: 0,
    underline: 0,
  });

  React.useEffect(() => {
    const propertyGroup = editedProperties.find(
      (group) => group[0]?.diagToolID === selectedItemID
    );

    if (propertyGroup) {
      const fontName = propertyGroup.find(
        (property) => property.propertyName === `${propSection}_Font_Name`
      );
      const fontSize = propertyGroup.find(
        (property) => property.propertyName === `${propSection}_Font_Size`
      );
      const fontWeight = propertyGroup.find(
        (property) => property.propertyName === `${propSection}_Font_weight`
      );
      const parsedWeight = fontWeight?.diagPropValue
        ? JSON.parse(fontWeight.diagPropValue)
        : null;
      setFontStyles((prevFontStyles) => ({
        ...prevFontStyles,
        fontFamily: fontName ? fontName.diagPropValue.toString() : "",
        fontSize: fontSize ? fontSize.diagPropValue.toString() : "",
        customFontSize: fontSize
          ? fontSize.diagPropValue
              .toString()
              .substring(0, fontSize.diagPropValue.toString().length - 2)
          : "",
        fontUnit: fontSize
          ? fontSize.diagPropValue
              .toString()
              .substring(
                fontSize.diagPropValue.toString().length - 2,
                fontSize.diagPropValue.toString().length
              )
          : "px"
      }));
      setFontStylesWeight({
        bold: parsedWeight ? parseInt(parsedWeight.bold) || 0 : 0,
        italic: parsedWeight ? parseInt(parsedWeight.italic) || 0 : 0,
        underline: parsedWeight ? parseInt(parsedWeight.underline) || 0 : 0,
      });
    } else {
      // Reset fontStyles if propertyGroup is not found
      setFontStyles({
        fontColor: "",
        fontSize: "",
        customFontSize: "",
        fontUnit: "",
        fontFamily: "",
        fontAlignment: "",
      });
      setFontStylesWeight({
        bold: 0,
        italic: 0,
        underline: 0,
      });
    }
  }, [selectedItemID, editedProperties, propSection]);

  function isPredefinedFontSize(fontSize, fontUnit) {
    const predefinedSizes = {
      px: ["35", "24", "18", "16", "14", "12"],
      pc: ["1", "0.75", "0.56", "0.5", "0.44", "0.38"],
      em: ["2", "1.5", "1.125", "1", "0.875", "0.75"],
      percent: ["200", "150", "112.5", "100", "87.5", "75"],
    };

    if (fontUnit === "") {
      return false;
    } else {
      return predefinedSizes[fontUnit].includes(fontSize);
    }
  }
  const handleFontFamilyChange = (value) => {
    setFontStyles({
      ...fontStyles,
      fontFamily: value,
    });
    handlePropertyChange(`${propSection}_Font_Name`, value);
  };
  const handleFontWeightChange = (name, value) => {
    setFontStylesWeight((prevFontStylesWeight) => ({
      ...prevFontStylesWeight,
      bold: name === "bold" ? value : prevFontStylesWeight.bold,
      italic: name === "italic" ? value : prevFontStylesWeight.italic,
      underline: name === "underline" ? value : prevFontStylesWeight.underline,
    }));

    // Since setFontStylesWeight is asynchronous, we need to use the updated state here
    // instead of fontStylesWeight directly
    handlePropertyChange(
      `${propSection}_Font_weight`,
      JSON.stringify({
        ...fontStylesWeight,
        [name]: value, // Update the specific property in the current state
      })
    );
  };
  const handleFontSizeChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFontStyles((prevFontStyles) => {
      const updatedFontStyles = {
        ...prevFontStyles,
        [name]: type === "checkbox" ? checked : value,
      };
      if (name === "fontSize") {
        updatedFontStyles.customFontSize = value.substring(0, value.length - 2); // px , em , pc all 2 chars
        updatedFontStyles.fontUnit = "px";
        handlePropertyChange(
          `${propSection}_Font_Size`,
          updatedFontStyles.fontSize
        );
      } else {
        handlePropertyChange(
          `${propSection}_Font_Size`,
          updatedFontStyles.customFontSize + updatedFontStyles.fontUnit
        );
      }
      return updatedFontStyles;
    });
  };

  return (
    <>
      <div className="stle-font">
        <div className="rht-fld-selectwrap for-fild-styl">
          <div className="rht-info fontlft-size">Font Size</div>
          <Select
            name="fontSize"
            className="rht-fld-select"
            value={fontStyles.fontSize}
            onChange={(e) => {
              handleFontSizeChange(e);
            }}
          >
            {[
              { value: "35px", label: "Headline1" },
              { value: "24px", label: "Headline2" },
              { value: "18px", label: "Headline3" },
              { value: "16px", label: "LargeText" },
              { value: "14px", label: "NormalText" },
              { value: "12px", label: "SmallText" },
            ].map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{ fontSize: option.value }}
              >
                {option.label}
              </MenuItem>
            ))}
            {fontStyles.customFontSize &&
              !isPredefinedFontSize(
                fontStyles.customFontSize,
                fontStyles.fontUnit
              ) && (
                <MenuItem
                  value={fontStyles.fontSize}
                  style={{ fontSize: "15px" }}
                >
                  CustomFontSize
                </MenuItem>
              )}
          </Select>
          <div className="rht-fnt">
            <div className="ipubrdr fontszefr-inp">
              <div className="rht-fld">
                <input
                  name="customFontSize"
                  type="number"
                  value={fontStyles.customFontSize}
                  onChange={(e) => {
                    handleFontSizeChange(e);
                  }}
                />
                <span className="unit-dropdown unit-font-drop">
                  <Select
                    name="fontUnit"
                    value={fontStyles.fontUnit}
                    onChange={(e) => {
                      handleFontSizeChange(e);
                    }}
                    style={{
                      width: "40px",
                      height: "25px",
                      backgroundColor: "#CFD8DD",
                      fontSize: "15px",
                    }}
                    displayEmpty
                    IconComponent={() => null}
                    MenuProps={{
                      style: { top: "5px" },
                    }}
                  >
                    <MenuItem value="px" className="custom-menuitem">
                      px
                    </MenuItem>
                    <MenuItem value="em" className="custom-menuitem">
                      em
                    </MenuItem>
                    <MenuItem value="pc" className="custom-menuitem">
                      %
                    </MenuItem>
                  </Select>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rht-fld-selectwrap font-style-displ">
        <div className="rht-info fontlft-size">Font Family</div>

        <Select
          className="rht-fld-select"
          name="fontFamily"
          value={fontStyles.fontFamily}
          onChange={(e) => handleFontFamilyChange(e.target.value)}
        >
          {fontFamilies.map((font, index) => (
            <MenuItem key={index} value={font} style={{ fontFamily: "font" }}>
              {font}
            </MenuItem>
          ))}
        </Select>
      </div>
      <IconBox
        handleChange={handleFontWeightChange}
        buttonList={[
          {
            icon: Theme.FormatBold,
            name: "bold",
            value: fontStylesWeight.bold,
          },
          {
            icon: Theme.FormatItalic,
            name: "italic",
            value: fontStylesWeight.italic,
          },
          {
            icon: Theme.FormatUnderline,
            name: "underline",
            value: fontStylesWeight.underline,
          },
        ]}
      />
    </>
  );
}

export default FontDropDown;
