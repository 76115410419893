import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { Theme } from "../Components/IncludeFile/Theme";
import { ReadArray } from "../Components/Api/ApiCalls";

import { toast } from "react-toastify";
import { useAppState } from "./AppStateProvider ";
import CustomPopUpForm from "../layout/CustomPopUpForm";
import { makeStyles } from "@mui/styles";
import DiscoverEdit from "./DiscoverEdit";
import Discover from "./Discover";

const useStyles = makeStyles({
  customSelectRoot: {
    "&:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent  !important",
    },
  },
});

const CreateInfo = () => {
  let APIProvider = window.APIProvider;

  const location = useLocation();
  const arrayName = location.state ? location.state.arrayName : null;
  const description = location.state ? location.state.description : null;
  const category = location.state ? location.state.category : null;
  const dataSourceArID = location.state ? location.state.dataSourceArID : null;

  const [isSaved, setIsSaved] = useState(false);
  const [editedArrayName, setEditedArrayName] = useState(arrayName);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedcategory, seteditedcategory] = useState(category);
  const [editeddataSourceArID, setediteddataSourceArID] =
    useState(dataSourceArID);
  const [isOpen, setIsOpen] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [arrayNameError, setArrayNameError] = useState("");
  const [dataSourceArrayName, setDataSourceArrayName] = useState("");
  const { appState, setArrayData } = useAppState();
  const [showDiscoverEdit, setShowDiscoverEdit] = useState(false);
  const [source, setSource] = useState(null);
  const [sourceData, setSourceData] = useState(null);
  const [arID, setArID] = useState(null);
  const [isOpenDiscoverEdit, setIsOpenDiscoverEdit] = useState(true);
  const [loading, setLoading] = useState(false);

  

  const handleOpenDiscoverEdit = async () => {
    setLoading(true);

    try {
      const fetchDataResponse = await fetch(
        APIProvider.baseURL +
          APIProvider.recordPath.ReadArrayByName +
          "?arrayName=" +
          arrayName
      );

      if (fetchDataResponse.ok) {
        const resultData = await fetchDataResponse.json();
        const arID = resultData.arID;

        if (arID) {
          const jsonData = await ReadArray(arID);

          if (jsonData) {
            const sourceData = jsonData[0].Source;
            await handleEditArray(
              arID,
              editedArrayName,
              editedDescription,
              editedcategory,
              editeddataSourceArID
            );
            setEditedArrayName(editedArrayName);
            setEditedDescription(editedDescription);
            seteditedcategory(editedcategory);
            setediteddataSourceArID(editeddataSourceArID);
          } else {
            console.error("Error: Unable to fetch jsonData");
            toast.error("Error: Unable to fetch jsonData");
          }
        } else {
          console.error("Error: Unable to fetch arID");
        }
      } else {
        console.error(
          "Error fetching data based on arrayName:",
          fetchDataResponse.statusText
        );
        toast.error("Error fetching data based on arrayName:");
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error:");
    }
    try {
      const fetchDataResponse = await fetch(
        APIProvider.baseURL +
          APIProvider.recordPath.ReadArrayByName +
          "?arrayName=" +
          editedArrayName
      );

      if (fetchDataResponse.ok) {
        const resultData = await fetchDataResponse.json();
        const arID = resultData.arID;
        const newArrayName = resultData.arrayName;
        const newDescription = resultData.description;
        const newCategory = resultData.category;
        const newdataSourceArID = resultData.dataSourceArID;

        const jsonData = await ReadArray(arID);

        if (jsonData) {
          const sourceData = jsonData[0].Source;

          if (
            JSON.stringify(sourceData) ===
            '[{"id":"0","name":"Root","type":"Root","control":[]}]'
          ) {
            navigate(`/Form/${arID}`, {
              state: {
                arID: arID,
                arrayName: newArrayName,
                description: newDescription,
                category: newCategory,
                source: JSON.stringify(sourceData),
                IsTemplate: "0",
                dataSourceArID: newdataSourceArID,
              },
            });
            setLoading(false);
            setShowDiscoverEdit(false);
            setIsOpen(false);
            setIsOpenDiscoverEdit(false);
          } else {
            const stateObject = {
              arrayName: newArrayName,
              description: newDescription,
              category: newCategory,
              source: JSON.stringify(sourceData),
              IsTemplate: "0",
              dataSourceArID: newdataSourceArID,
            };

            setArrayData(
              newArrayName,
              newDescription,
              newCategory,
              newdataSourceArID
            );
            setLoading(false);
            setShowDiscoverEdit(true);
            setIsOpen(true);
            setIsOpenDiscoverEdit(true);
            if (newdataSourceArID > 0) {
            } else {
              navigate(`/Form/${arID}`, {
                state: {
                  arID: arID,
                  arrayName: newArrayName,
                  description: newDescription,
                  category: newCategory,
                  source: JSON.stringify(sourceData),
                  IsTemplate: "0",
                  dataSourceArID: newdataSourceArID,
                },
              });
            }
          }
        } else {
          console.error("Error: Unable to fetch arID");
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error:");
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setIsSaved(false);
  };

  const handleCancelClick = () => {
    setEditedArrayName(arrayName);
    setEditedDescription(description);
    seteditedcategory(category);
    setediteddataSourceArID(dataSourceArID);
    setArrayNameError("");
    setLoading(false);
    setIsOpenDiscoverEdit(false);
  };

  const handleEditArray = async (
    arID,
    editedArrayName,
    editedDescription,
    editedCategory,
    editeddataSourceArID
  ) => {
    try {
      if (!editedArrayName.trim()) {
        setArrayNameError("Form Name is required");
        setLoading(false);
        return;
      } else {
        setArrayNameError("");
      }

      const requestBody = {
        arID: arID,
        arrayName: editedArrayName,
        description: editedDescription,
        category: editedCategory,
        IsTemplate: "0",
        dataSourceArID: editeddataSourceArID,
      };

      const response = await fetch(
        APIProvider.baseURL + APIProvider.recordPath.EditArray,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
      } else {
        console.error("Failed to edit item.");
        toast.error("Failed to edit item.");
        const responseBody = await response.text();
        console.error("Response Body:", responseBody);
        toast.error("Response Body:");
      }
    } catch (error) {
      console.error("Error editing item:", error);
      toast.error("Error editing item:");
    }
  };

  const navigate = useNavigate();


  const handleSave = async () => {
    setIsSaved(true);
    setIsEditMode(false);

    try {
      const fetchDataResponse1 = await fetch(
        APIProvider.baseURL +
          APIProvider.recordPath.ReadArrayByName +
          "?arrayName=" +
          arrayName
      );

      if (!fetchDataResponse1.ok) {
        throw new Error(
          "Error fetching data based on arrayName: " +
            fetchDataResponse1.statusText
        );
      }

      const resultData1 = await fetchDataResponse1.json();
      const arID1 = resultData1.arID;

      if (!arID1) {
        throw new Error("Error: Unable to fetch arID");
      }

      const jsonData = await ReadArray(arID1);

      if (!jsonData) {
        throw new Error("Error: Unable to fetch jsonData");
      }

      const sourceData = jsonData[0].Source;
      await handleEditArray(
        arID1,
        editedArrayName,
        editedDescription,
        editedcategory,
        editeddataSourceArID
      );

      setEditedArrayName(editedArrayName);
      setEditedDescription(editedDescription);
      seteditedcategory(editedcategory);
      setediteddataSourceArID(editeddataSourceArID);
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error: " + error.message);
      return;
    }
  };

  // useEffect(() => {
  //   setEditedArrayName(editedArrayName);
  //   setEditedDescription(editedDescription);
  //   seteditedcategory(editedcategory);
  //   setediteddataSourceArID(editeddataSourceArID);}, [editedArrayName, editedDescription, editedcategory, editeddataSourceArID]);

  const [fetched1 , setFetch] = useState(false)

  useEffect(() => {
  const fetchDataSourceArrayName = async (dataSourceArID) => {
    try {
      setFetch(true);
      const fetchDataResponse = await fetch(
        APIProvider.baseURL +
          APIProvider.recordPath.ReadArray +
          "?arID=" +
          dataSourceArID
      );

      if (fetchDataResponse.ok) {
        
        const resultData = await fetchDataResponse.json();
        const arrayName = resultData.arrayName;
        setDataSourceArrayName(arrayName);
       
      } else {
        console.error(
          "Error fetching data based on dataSourceArID:",
          fetchDataResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error:", error.message);

  } finally {
    setFetch(false); // Set loading to false after fetch completes
  }
  };

  fetchDataSourceArrayName(editeddataSourceArID);

}, [dataSourceArID]);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    fetchDataarrayname();
  }, []);

  const fetchDataarrayname = async () => {
    try {
      const response = await fetch(
        APIProvider.baseURL + APIProvider.recordPath.ReadArrayAll
      );
      const jsonData = await response.json();
      const filteredJsonData = jsonData.filter((item) => item.isTemplate === 2);
      setFilteredData(filteredJsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectChange = (event) => {
    const selectedArID = event.target.value;
    const selectedItem = filteredData.find(
      (item) => item.arID === parseInt(selectedArID)
    );
    setSelectedItem(selectedItem);
    setediteddataSourceArID(selectedArID);
  };

  const iconButtonsSaveAndRestore = [
    {
      icon: Theme.Restore,
      color: "primary",
      handleClick: () => {
        handleCancelClick();
      },
      tooltip: "Refersh",
    },
    {
      icon: Theme.Save,
      color: "primary",
      handleClick: () => {
        handleSave();
      },
      tooltip: "Save",
    },
  ];

  const iconButtonsEdit = [
    {
      icon: Theme.Edit,
      color: "primary",
      handleClick: () => {
        handleEdit();
      },
      tooltip: "Click To Edit",
    },
  ];

  const classes = useStyles();

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleArrayNameChange = (e) => {
    const trimmedValue = e.target.value;
    setEditedArrayName(trimmedValue);
    setArrayNameError("");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchDataResponse = await fetch(
          APIProvider.baseURL +
            APIProvider.recordPath.ReadArrayByName +
            "?arrayName=" +
            arrayName
        );

        if (fetchDataResponse.ok) {
          const resultData = await fetchDataResponse.json();
          const arID = resultData.arID;

          if (arID) {
            const jsonData = await ReadArray(arID);

            if (jsonData) {
              const sourceData = jsonData[0].Source;

              setSource(JSON.stringify(sourceData));
              setSourceData(sourceData);
              setArID(arID);
            } else {
              console.error("Error: Unable to fetch jsonData");
              toast.error("Error: Unable to fetch jsonData");
            }
          } else {
            console.error("Error: Unable to fetch arID");
          }
        } else {
          console.error(
            "Error fetching data based on arrayName:",
            fetchDataResponse.statusText
          );
          toast.error("Error fetching data based on arrayName:");
        }
      } catch (error) {
        console.error("Error:", error.message);
        toast.error("Error:");
      }
    };

    fetchData();
  }, []);

  return (
    <CustomPopUpForm
      open={isOpen}
      onClose={handleCancel}
      title="Overview"
      iconButtons={isEditMode ? iconButtonsSaveAndRestore : iconButtonsEdit}
      height="60%"
    >
      <>
        {!isEditMode ? (
          <>
            <div className="card-info card-info-ovr-flow">
              <div className="card-info-inside card-info-inside-view">
                <Typography className="card-info-inside-text">
                  Form Name*:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedArrayName}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Description:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedDescription}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Category:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedcategory}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
               DataSourceField:
                </Typography>
                <div>
    
          <Typography style={{ color: "#000" }}>
          {fetched1 ? "Loading..." : dataSourceArrayName}
          </Typography>
       
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card-info">
            <div className="card-info-inside card-info-inside-view">
              <Typography style={{ fontWeight: "bold" }}>
                Form Name*:
              </Typography>
              <TextField
                required
                type="text"
                className="edit-popup"
                name="Form Name"
                onChange={handleArrayNameChange}
                value={editedArrayName}
                error={!!arrayNameError}
                helperText={arrayNameError}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Description:
              </Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Description"
                onChange={(e) => setEditedDescription(e.target.value)}
                value={editedDescription}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>Category:</Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Category"
                value={editedcategory}
                style={{ backgroundColor: "#bdbdbd" }}
                readOnly
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
              DataSourceField:
              </Typography>
              <Select
                onChange={handleSelectChange}
                value={editeddataSourceArID || "0"}
                className={`${classes.customSelectRoot} data-select-crd`}
              >
                <MenuItem value="0">Select DataSource</MenuItem>
                {filteredData.map((item) => (
                  <MenuItem key={item.arID} value={item.arID}>
                    {item.arrayName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}
      </>
      <>
        <div style={{ width: "96%", display: "flex" }}>
          <Button fullWidth onClick={handleCancel}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleOpenDiscoverEdit}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
          </Button>
        </div>
        {showDiscoverEdit && (
          <DiscoverEdit
            showDiscoverEdit={showDiscoverEdit}
            setShowDiscoverEdit={setShowDiscoverEdit}
            arrayName={editedArrayName}
            description={editedDescription}
            category={editedcategory}
            dataSourceArID={editeddataSourceArID}
            source={source}
            sourceData={sourceData}
            arID={arID}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isOpenDiscoverEdit={isOpenDiscoverEdit}
            setIsOpenDiscoverEdit={setIsOpenDiscoverEdit}
          />
        )}
      </>
    </CustomPopUpForm>
  );
};

export default CreateInfo;
