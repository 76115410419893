import React from "react";
import BoxTemplate from "../BoxTemplate";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
function TriggerBox({ formData, handleChange, dataSetOptions }) {
  return (
    <BoxTemplate
      icon={<BoltIcon style={{ marginRight: "0.5rem" }} />}
      title={"Trigger"}
      // button={"Preview"}
      // buttonOnClick={() => console.log("ButtonClicked")}
      subTitle={"Profiles enter the flow when this happens."}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            label="Name"
            size="small"
            variant="outlined"
            fullWidth
            name="name"
            value={formData.name}
            onChange={(e) => handleChange(e)}
            // error={nameError}
            // helperText={nameError}
          />
        </Grid>
      {/* </Grid>
      <Grid container spacing={2}> */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Module"
            size="small"
            variant="outlined"
            fullWidth
            name="module"
            value={formData.module}
            onChange={(e) => handleChange(e)}
            // error={nameError}
            // helperText={nameError}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Data Set</InputLabel>
            <Select
              label="Data Set"
              fullWidth
              size="small"
              name="dataSet"
              value={formData.dataSet}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem value="Select" disabled>
                {formData.module === "" ? "Please enter a module" : "Select"}
              </MenuItem>
              {dataSetOptions
                .filter((option) => option.value !== -1)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </BoxTemplate>
  );
}

export default TriggerBox;
