import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CreateOrOpenDiag from "../../../PopUpForm/CreateOrOpenDiag";
import themeProvider from "../../../ThemeProvider";
import { ThemeProvider, Button } from "@mui/material";
import NavBar from "../../navbarComponents/NavBar";
import { Theme } from "../../IncludeFile/Theme";
import { useMenu } from "../../../reusableComponents/menuUtils";
import {
  UpdateTemplateHeader,
  UpdateDiagHeader,
  GetDiagCardsInfo,
  GetDiagTools,
} from "../../../apiCalls/DiagramsAPI";
import Overview from "../Overview";
import { ToastContainer, toast } from "react-toastify";
import CustomPopUpForm from "../../../layout/CustomPopUpForm";
import DiagHeader from "../DiagHeader";
import Diagrams from "../../../pages/diagramPages/Diagrams";

function DiagramsRenderer() {
  //react router Variables
  const navigate = useNavigate();
  //State Variables
  const [open, setOpen] = useState(false);
  const [openDiagHeader, setOpenDiagHeader] = useState(false);

  const { isMenuOpenleft, toggleMenu } = useMenu();
  const handlePopup = () => {
    setOpen(!open);
  };
  const handleDiagHeaderPopup = () => {
    setOpenDiagHeader(!openDiagHeader);
  };

  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched) {
      fetchData();
    }
  }, [fetched]);
  //cache
  // async function fetchData() {
  //   const scriptElement = document.createElement("script");
  //   // scriptElement.src = `FormJSON.js?v=${Date.now()}`;
  //   scriptElement.src = `TemplateJSON.js?v=${Date.now()}`;

  //   scriptElement.onload = () => {
  //     const fetchedData = window.MyVar || [];
  //     setData(fetchedData);
  //     setSelected(fetchedData);
  //     setFetched(true);
  //   };
  //   document.head.appendChild(scriptElement);
  // }
  //API
  async function fetchData() {
    try {
      const templates = await GetDiagCardsInfo();
      console.log("templates ", templates);
      setData(templates);
      setSelected(templates);
      setFetched(true);
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error getting templates: " + error.message);
    }
  }

  //Temp Get from Public folder
  const [tools, setTools] = useState([]);
  const [fetchedTools, setFetchedTools] = useState(false);
  //cache
  // async function fetchTools() {
  //   return new Promise((resolve) => {
  //     const scriptElement = document.createElement("script");
  //     scriptElement.src = `ToolsJSON.js?v=${Date.now()}`;

  //     scriptElement.onload = () => {
  //       const fetchedData = window.MyVar || [];
  //       setTools(fetchedData);
  //       setFetchedTools(true);
  //       resolve(fetchedData); // Resolve the promise with the fetched data
  //     };

  //     // Append the script tag to the document's head or body
  //     document.head.appendChild(scriptElement);
  //   });
  // }
  //API
  async function fetchTools(DiagTempID) {
    const response = await GetDiagTools(DiagTempID);
    return response.filter((item) => item.diagTempID === DiagTempID.toString());
  }

  //functions that decide how to call the second component (With variables or new)
  const [selectedItem, setSelectedItem] = useState(null);

  const handleDetails = (item) => {
    setSelectedItem(item);
    handlePopup();
  };
  // const handleDetails = (item) => {
  //   if (item !== -1) setSelectedItem(item);
  //   else {
  //     setSelectedItem(null);
  //   }
  //   handlePopup();
  // };

  // const handleEditDiagram = async (item) => {
  //   //Call Api tp get ToolsJSON
  //   const fetchedData = await fetchTools(item.diagTempID);
  //   console.log("fetchedData ", fetchedData);
  //   const foundObject = data.find((obj) => obj.diagID === item.diagID);
  //   if (fetchedData) {
  //     navigate("/Diagrams", {
  //       state: { data: fetchedData, diagramItem: foundObject },
  //     });
  //   }
  // };
  const handleEditDiagram = async (item) => {
    const foundObject = data.find((obj) => obj.diagID === item.diagID);
    navigate("/Diagrams", {
      state: { diagTempID: item.diagTempID, diagramItem: foundObject },
    });
  };
  //popup bottom buttons
  const handleBack = () => {
    console.log("Back");
    setSelectedItem(null);
    setOpenDiagHeader(false);
    handlePopup();
  };
  const handleDiagHeaderBack = () => {
    handleDiagHeaderPopup();
  };

  const handleNext = () => {
    if (selectedItem.diagID === 0) {
      setOpen(false);
      setOpenDiagHeader(true);
    } else {
      handleEditDiagram(selectedItem);
    }
  };
  const handleDiagHeaderNext = async (formData) => {
    try {
      const response = await UpdateDiagHeader([formData]);
      console.log("response ", response);
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error getting templates: " + error.message);
    }
    handleEditDiagram(formData);
  };

  //search
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  //navbar button
  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate("/Landing");
      },
    },
  ];
  const handleSave = async (data) => {
    try {
      // Extracting title and explanation
      const {
        DiagID,
        DiagTempID,
        Title,
        Explanation,
        nodes,
        edges,
        viewPort,
        ...rest
      } = data;
      // Object with everything except title and explanation
      const dataWithoutTitleAndExplanation = { ...rest, DiagTempID };
      // Object with only title and explanation
      const titleAndDescriptionOnly = {
        DiagID,
        Title,
        Description: Explanation,
        DiagTempID,
        Notes: data.Notes,
        nodes,
        edges,
        viewPort,
      };
      const response = await UpdateTemplateHeader([
        dataWithoutTitleAndExplanation,
      ]);
      const response1 = await UpdateDiagHeader([titleAndDescriptionOnly]);
      console.log("response template header", response);
      console.log("response diag header", response1);
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  };
  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavBar
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        isSearchBar={true}
        rightButtons={rightButtons}
        isMenuOpenleft={true}
        toggleMenu={toggleMenu}
        title="Choose Template"
      />
      <CreateOrOpenDiag
        handleDetails={handleDetails}
        handleEditDiagram={handleEditDiagram}
        searchQuery={searchQuery}
        caller={1}
        isMenuOpenleft={isMenuOpenleft}
        selected={selected}
        setSelected={setSelected}
        data={data}
        fetched={fetchData}
      />
      {open && (
        <Overview
          open={open}
          handlePopup={handlePopup}
          item={selectedItem}
          handleBack={handleBack}
          handleNext={handleNext}
          handleSave={handleSave}
          data={data}
        />
      )}
      {openDiagHeader && (
        <DiagHeader
          open={openDiagHeader}
          handlePopup={handleDiagHeaderPopup}
          item={selectedItem}
          handleBack={handleDiagHeaderBack}
          handleNext={handleDiagHeaderNext}
          data={data}
        />
      )}
    </ThemeProvider>
  );
}

export default DiagramsRenderer;
