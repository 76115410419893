import React, { useState } from "react";
import {
  ThemeProvider,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import themeProvider from "../../ThemeProvider";
//URLPicker 1 edit item 2 edit section 3 add item to section 4 add page 5 edit a page
function PopUpComponent({ open, onClose, title, ID, URLPicker, pageName }) {
  const EDIT_ITEM_URL =
    "https://aiworksdev.agiline.com//global/index?globalurlid=FFDAAC4F-9871-4849-8E96-E49B4E434090&h=420&w=780&param1=";

  const EDIT_SECTION_URL =
    "https://aiworksdev.agiline.com//global/index?globalurlid=9EF2E351-0F7D-4905-904B-EEA5F23490E8&h=420&w=780&param1=";

  const ADD_PAGE_URL =
    "https://aiworksdev.agiline.com//global/index?globalurlid=34458D23-562C-40C7-835C-6A39590B48CE&h=420&w=780&param1=99999&param2=";
  const EDIT_PAGE_URL =
    "https://aiworksdev.agiline.com//global/index?globalurlid=90106EB1-6D43-448E-924F-AD25EA69F5F5&h=420&w=780&param1=";

  return (
    <ThemeProvider theme={themeProvider}>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={(event) => onClose(event, pageName)}
      >
        <DialogTitle>
          <Typography
            style={{
              color: themeProvider.palette.primary.main,
              fontWeight: "bold",
              fontSize: "25px",
              textAlign: "center",
            }}
            className="text-center"
          >
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={(event) => onClose(event, pageName)}
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-1 gap-4 mt-4">
            <iframe
              width="780px"
              height="420px"
              src={
                URLPicker === 1
                  ? EDIT_ITEM_URL + ID
                  : URLPicker === 2
                  ? EDIT_SECTION_URL + ID
                  : URLPicker === 3
                  ? EDIT_ITEM_URL
                  : URLPicker === 4
                  ? ADD_PAGE_URL
                  : EDIT_PAGE_URL + ID
              }
              title="form"
            ></iframe>
          </div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default PopUpComponent;
