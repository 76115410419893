import React, { useState, useCallback } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IconButton, ThemeProvider, colors } from "@mui/material";
import themeProvider from "../../../ThemeProvider";
import SidebarCollapse from "../../SideBarCollapseComponents/SidebarCollapse";
const DesktopSidebar = ({ showmenudsk, setShowdskmenu, nodes, properties }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getNodeBorderColor = (nodeName) => {
    // Find the properties array where node.name = nodeName
    const nodeData = properties?.find((arr) =>
      arr?.some((obj) => obj.name === nodeName)
    );
    if (!nodeData) return null; // Handle case where node with given name is not found

    // Find the object in the nodeData array where propertyName === Node_Border_color
    const borderColorObj = nodeData?.find(
      (obj) => obj.propertyName === "Node_Border_color"
    );
    if (!borderColorObj) return null; // Handle case where Node_Border_color is not found

    return borderColorObj?.diagPropValue;
  };

  //shrink the first nodes sections
  const [customNodesCollapsed, setCustomNodesCollapsed] = useState(false);
  const toggleCustomNodesPanel = () => {
    console.log("collapse ", customNodesCollapsed);
    setCustomNodesCollapsed(!customNodesCollapsed);
  };
  const onDragStart = (event, node) => {
    console.log("node ", node);
    let nodeType = node.name;
    event.dataTransfer.setData("application/reactflow", nodeType);
    const filteredData = properties?.find(
      (item) =>
        item[0].name?.toLowerCase().replace(/\s/g, "") ===
        nodeType.toLowerCase().replace(/\s/g, "")
    );
    console.log("properties ", properties);
    console.log("filteredData ", filteredData);
    event.dataTransfer.setData("customData", JSON.stringify(filteredData));
    event.dataTransfer.setData("data", JSON.stringify(nodes));
    event.dataTransfer.effectAllowed = "move";
  };
  // Sort nodes based on SEQ
  const sortedNodes = nodes.sort((a, b) => a.seq - b.seq);
  sortedNodes.map((item, index) => {
    if (item.name === "InputNode") {
      item.icon =
        "https://icons.veryicon.com/png/o/education-technology/data-node-type-icon/type-merge-node.png";
    }
  });

  return (
    <ThemeProvider theme={themeProvider}>
      <aside
        style={{
          width: showmenudsk ? 0 : "100%",
          height: showmenudsk && isSmallScreen ? 0 : "auto",
          flexShrink: 0,
          transition: "all 0.3s ease",
          padding: showmenudsk ? 0 : "0.5rem",
          backgroundColor: themeProvider.palette.bg.lightWhite,
        }}
      >
        <div
          style={{
            width: showmenudsk ? 0 : "100%",
            height: showmenudsk ? 0 : "100%",
            // width: showmenudsk && !isSmallScreen ? 0 : "100%",
            // height: showmenudsk && isSmallScreen ? 0 : "100%",
            overflow: "hidden",
            padding: "0 1rem",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: "medium",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "0.5rem",
              }}
            >
              <div>Custom Nodes</div>
              <div>
                {customNodesCollapsed ? (
                  <IconButton onClick={toggleCustomNodesPanel}>
                    <ArrowDropDownIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={toggleCustomNodesPanel}>
                    <ArrowDropUpIcon />
                  </IconButton>
                )}
              </div>
            </div>
            {!customNodesCollapsed && (
              <div>
                {sortedNodes.map((node, index) => (
                  <div
                    key={index}
                    className={`dndnode`}
                    style={{
                      borderColor: getNodeBorderColor(node.name), // Set border color dynamically
                      borderRadius: "5px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      padding: "20px 10px",
                      marginBottom: "1rem",
                      transition: "box-shadow 0.3s ease",
                      // color: "blue",
                      // ":hover": {
                      //   boxShadow: "0px 4px 8px rgba(235, 5, 5, 0.2)",
                      //   color: "red",
                      // },
                    }}
                    onDragStart={(event) => onDragStart(event, node)}
                    draggable
                  >
                    {node?.icon && (
                      <img
                        src={node.icon}
                        alt="icon"
                        width={12.5}
                        height={12.5}
                        style={{ marginRight: "0.5rem" }}
                      />
                    )}
                    {node.caption}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </aside>
      <div
        style={{
          backgroundColor: themeProvider.palette.bg.lightWhite,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SidebarCollapse
          showmenudsk={showmenudsk}
          setShowdskmenu={setShowdskmenu}
          direction={isSmallScreen ? 2 : 0}
        />
      </div>
    </ThemeProvider>
  );
};

export default DesktopSidebar;
