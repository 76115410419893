import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import themeProvider from "../../ThemeProvider";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import { Action } from "./ActionList";
//ZoomIn Div
const ImageContainer = styled("div")({
  overflow: "hidden",
});

const ArticleCard = ({
  feedID,
  image,
  title,
  description,
  size,
  textSize,
  admin,
  handleDialogToggle,
}) => {
  const EDIT_URL =
    "https://aiworksqa.agiline.com//global/index?globalurlid=787048E1-D78C-4E6F-BEB2-D9ED05BD6266&param1=";
  //Zoom In Variables
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isAdmin = () => {
    return admin;
  };
  return (
    <ThemeProvider theme={themeProvider}>
      <Card
        id={feedID}
        sx={{
          display: "flex",
          margin: "20px",
          height: "auto",
          maxHeight: "800px",
          minWidth: "95%",
          borderRadius: "16px",
        }}
      >
        <Box sx={{ width: `${size}px`, margin: "2rem" }}>
          <ImageContainer
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <CardMedia
              component="img"
              image={image}
              alt="landing page image"
              style={{
                height: "auto",
                maxHeight: `${size}px`,
                width: "auto",
                maxWidth: `${size}px`,
                transform: isHovered ? "scale(1.2)" : "scale(1)",
                transition:
                  "transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
              }}
            />
          </ImageContainer>
          <Typography
            style={{
              color: themeProvider.palette.primary.main,
              fontWeight: "bold",
              fontSize: `${textSize}px`,
            }}
            className="text-center"
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            borderLeft: "1px solid gray",
            marginTop: "2rem",
            marginBottom: "2rem",
            flexGrow: 1,
            overflowY: "scroll",
            justifyContent: "space-between",
          }}
        >
          <CardContent>
            <Typography
              variant="body1"
              sx={{
                "& img": {
                  maxWidth: "100%",
                },
                wordWrap: "break-word",
                fontSize: `${textSize * 0.75}px`,
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></Typography>
          </CardContent>
          <Box>
            {isAdmin() && (
              <Button
                onClick={(event) =>
                  handleDialogToggle(event, feedID, title, Action.EDIT_ITEM)
                }
              >
                <EditIcon
                  style={{
                    // backgroundColor: "f2f2f2",
                    borderColor: "#e8e8e8",
                    borderWidth: "2px",
                    borderRadius: "25px",
                    height: "2rem",
                    width: "2rem",
                    padding: "0.5rem",
                    marginLeft: "5%",
                    color: "#212325",
                  }}
                />
              </Button>
            )}
          </Box>
        </Box>
      </Card>
      </ThemeProvider>
  );
};

export default ArticleCard;
