import { toast } from "react-toastify";
let APIProvider = window.APIProvider;

const DEFAULT_USERID = "-1";

let POST_HEADER = {
  method: "POST",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json, text/plain, */*",
  },
  mode: "cors",
};

let GET_HEADER = {
  method: "GET",
  headers: {},
  mode: "cors",
};

function GetRequestHeader() {
  return GET_HEADER;
}

function PostRequestHeader() {
  return POST_HEADER;
}

function GetDefaultUserID() {
  return DEFAULT_USERID;
}
function replaceNullsWithEmptyString(obj) {
  // Check if obj is null or undefined
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // If obj is an array, map over its elements
  if (Array.isArray(obj)) {
    return obj.map((item) => replaceNullsWithEmptyString(item));
  }

  // If obj is an object, recursively replace null values
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      value === null ? "" : replaceNullsWithEmptyString(value),
    ])
  );
}
async function GetDiagCardsInfo() {
  try {
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.GetDiagCardsInfo,
      GET_HEADER
    );
    const diagTemplates = await response.json();
    console.log("diagTemplates ", diagTemplates);
    return diagTemplates;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting Templates: " + error.message);
  }
}
async function GetDiagTools(DiagTempID) {
  console.log("param 1 ", DiagTempID);
  const response = await fetch(
    APIProvider.baseURL +
      APIProvider.recordPath.GetDiagTools +
      "?DiagTempID=" +
      DiagTempID,
    GET_HEADER
  );
  const diagTools = await response.json();
  const sanitizedDiagTools = replaceNullsWithEmptyString(diagTools);
  console.log("sanitizedDiagTools ", sanitizedDiagTools);
  return sanitizedDiagTools;
}
async function UpdateDiagTools(diagTools) {
  console.log("diagTools ", diagTools);
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify([diagTools]), // Wrap diagTools in an array
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.UpdateDiagTools,
      request
    );
    console.log("response ", response);
    if (response.ok) {
      toast.success("Tool List Updated!");
    }
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error saving data: " + error.message);
  }
}

async function GetToolProperties(diagToolID) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.GetToolProperties +
        "?diagToolID=" +
        diagToolID,
      GET_HEADER
    );
    const toolProperties = await response.json();
    console.log("toolProperties ", toolProperties);
    return toolProperties;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting properties: " + error.message);
  }
}
async function UpdateToolProperties(toolProperties) {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(toolProperties),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.UpdateToolProperties,
      request
    );
    console.log("response ", response);
    toast.success("Data Saved!");
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error saving data: " + error.message);
  }
}
async function GetToolPropertiesAll() {
  try {
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.GetToolPropertiesAll,
      GET_HEADER
    );
    const toolPropertiesAll = await response.json();
    console.log("toolPropertiesAll ", toolPropertiesAll);
    return toolPropertiesAll;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting properties: " + error.message);
  }
}
async function UpdateTemplateHeader(template) {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(template),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.UpdateTemplateHeader,
      request
    );
    console.log("response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
  }
}
async function UpdateTemplateDetails(template) {
  console.log("template ", template);
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(template),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.UpdateTemplateDetails,
      request
    );
    console.log("response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
  }
}
async function UnselectToolInTemplate(DetDiagTempID) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.UnselectToolInTemplate +
        "?DetDiagTempID=" +
        DetDiagTempID,
      GET_HEADER
    );
    console.log("response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting properties: " + error.message);
  }
}
async function UpdateDiagHeader(project) {
  console.log("project ", project);
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(project),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.UpdateDiagHeader,
      request
    );
    console.log("response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
  }
}
async function GetGlyphicon() {
  try {
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.GetGlyphicons,
      GET_HEADER
    );
    const icons = await response.json();
    // console.log("icons ", icons);
    return icons;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error getting icons: " + error.message);
  }
}
async function MakeToolInactive(DiagToolID) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.MakeToolInactive +
        "?DiagToolID=" +
        DiagToolID,
      GET_HEADER
    );
    const success = await response.json();
    toast.success("Tool is inactive");
    return success;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error making tool inactive: " + error.message);
  }
}
export {
  GetDiagTools,
  UpdateDiagTools,
  GetToolProperties,
  UpdateToolProperties,
  GetToolPropertiesAll,
  UpdateTemplateHeader,
  GetDiagCardsInfo,
  UpdateTemplateDetails,
  UnselectToolInTemplate,
  UpdateDiagHeader,
  GetGlyphicon,
  MakeToolInactive,
};
