import React from "react";
import Logo from "../../assets/bubblesLogo.png";
import { Theme } from "../IncludeFile/Theme";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding:"10px",
    height:"60%",
  },
  errorImage: {
    width: "20rem",
    height: "auto",
  },
  heading: {
    fontSize: "2rem",
    margin: "1rem 0",
  },
  text: {
    fontSize: "1.2rem",
    margin: "0.5rem ",
  },
  backButton: {
    icon: Theme.GoBack,
    text: "Back",
    // color: "#f1f1f1",
  },
};

function NotFound() {
  const Navigate = useNavigate();

  const handleBack = () => {
    Navigate("/Open");
  };

  return (
    <div style={styles.container}>
      <div className="bck-fristpage" style={{ display: "flex", alignSelf: "end", marginBottom: "auto" }}>
        <Button
          startIcon={styles.backButton.icon}
          variant="contained"
          color={styles.backButton.color}
          onClick={handleBack}
        >
          {styles.backButton.text}
        </Button>
      </div>
      <img src={Logo} alt="Error" style={styles.errorImage} />
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.text}>The page you are looking for does not exist.</p>
    </div>
  );
}

export default NotFound;
