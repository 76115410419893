import React from "react";
import { Select, MenuItem } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function BodyContentDropDown({
  selectedItemID,
  editedProperties,
  handlePropertyChange,
}) {
  const [bodyContent, setBodyContent] = React.useState({
    type: "",
    text: "",
    url: "",
    textOverFlow: "",
    imageFit: "",
  });

  React.useEffect(() => {
    const propertyGroup = editedProperties.find(
      (group) => group[0]?.diagToolID === selectedItemID
    );

    if (propertyGroup) {
      const type = propertyGroup.find(
        (property) => property.propertyName === "Body_type"
      );
      const textOverflow = propertyGroup.find(
        (property) => property.propertyName === "Body_TextOverflow"
      );
      const text = propertyGroup.find(
        (property) => property.propertyName === "Body_Text"
      );
      const url = propertyGroup.find(
        (property) => property.propertyName === "Body_URL"
      );
      const imageFit = propertyGroup.find(
        (property) => property.propertyName === "Body_Image_Fit"
      );
      setBodyContent((prevBodyContent) => ({
        ...prevBodyContent,
        type: type ? type.diagPropValue.toString() : "",
        textOverFlow: textOverflow ? textOverflow.diagPropValue.toString() : "",
        url: url ? url.diagPropValue.toString() : "",
        text: text ? text.diagPropValue.toString() : "",
        imageFit: imageFit ? imageFit.diagPropValue.toString() : "",
      }));
    } else {
      setBodyContent({
        type: "",
        text: "",
        url: "",
        textOverFlow: "",
        imageFit: "",
      });
    }
  }, [selectedItemID, editedProperties]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setBodyContent((prevBodyContent) => {
      const updatedBodyContent = {
        ...prevBodyContent,
        [name]: type === "checkbox" ? checked : value,
      };
      if (name === "type") {
        handlePropertyChange(`Body_type`, updatedBodyContent.type);
      } else if (name === "text") {
        handlePropertyChange("Body_Text", updatedBodyContent.text);
      } else if (name === "url") {
        handlePropertyChange("Body_URL", updatedBodyContent.url);
      } else if (name === "imageFit") {
        handlePropertyChange("Body_Image_Fit", updatedBodyContent.imageFit);
      } else if (name === "textOverFlow") {
        handlePropertyChange(
          "Body_TextOverflow",
          updatedBodyContent.textOverFlow
        );
      }
      return updatedBodyContent;
    });
  };
  const handleHTMLChange = (value) => {
    setBodyContent((prevBodyContent) => {
      const updatedBodyContent = {
        ...prevBodyContent,
        text: value,
      };
      handlePropertyChange("Body_Text", updatedBodyContent.text);
      return updatedBodyContent;
    });
  };

  return (
    <>
      <div className="rht-info fontlft-size">Type</div>
      <Select
        name="type"
        className="rht-fld-select"
        value={bodyContent.type}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {[
          { value: "0", label: "Text" },
          { value: "1", label: "HTML" },
          { value: "2", label: "Image" },
        ].map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {bodyContent.type === "0" && (
        <>
          <div className="rht-info fontlft-size">Text</div>
          <input
            name="text"
            className="rht-fld-select"
            value={bodyContent.text}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </>
      )}
      {bodyContent.type === "1" && (
        <>
          <div className="rht-info fontlft-size">HTML</div>
          <ReactQuill value={bodyContent.text} onChange={handleHTMLChange} />
          {/* <input
            name="text"
            className="rht-fld-select"
            value={bodyContent.text}
            onChange={(e) => {
              handleChange(e);
            }}
          /> */}
        </>
      )}
      {bodyContent.type === "2" && (
        <>
          <div className="rht-info fontlft-size">URL</div>
          <input
            name="url"
            className="rht-fld-select"
            value={bodyContent.url}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </>
      )}
      {bodyContent.type === "2" && (
        <>
          <div className="rht-info fontlft-size">Image Fit</div>
          <Select
            name="imageFit"
            className="rht-fld-select"
            value={bodyContent.imageFit}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {[
              { value: "0", label: "Maintain Aspect" },
              { value: "1", label: "Fill" },
              { value: "2", label: "Fit" },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {bodyContent.type === "0" && (
        <>
          <div className="rht-info fontlft-size">Text Overflow</div>
          <Select
            name="textOverFlow"
            className="rht-fld-select"
            value={bodyContent.textOverFlow}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {[
              { value: "0", label: "Hidden" },
              { value: "1", label: "Ellipsis" },
              { value: "2", label: "Wrap" },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </>
  );
}

export default BodyContentDropDown;
