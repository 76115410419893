import React from "react";
import { Box, Tab, ThemeProvider } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import themeProvider from "../../ThemeProvider";

function TabsComponent({ tabValue, setTabValue, tabs, children }) {
  const handleChangeTabs = (newValue) => {
    if (setTabValue) {
      setTabValue(newValue);
    }
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <TabContext value={tabValue}>
        <Box>
          <TabList className="rightbtn" style={{ minHeight: "initial" }}>
            {tabs.map((tab) => (
              <Tab
                id={`${tab.value}_tab`}
                key={tab.value}
                label={tab.label}
                onClick={() => handleChangeTabs(tab.value)}
                value={tab.value}
              />
            ))}
          </TabList>
          <Box>
            {tabs.map((tab) => (
              <TabPanel
                id={`${tab.value}_tab`}
                key={tab.value}
                value={tab.value}
                sx={{
                  "&.MuiTabPanel-root": {
                    margin: "1rem 0",
                    padding: 0,
                  },
                }}
              >
                {Array.isArray(children)
                  ? children.find((child) => child.key === tab.value)
                  : children}
              </TabPanel>
            ))}
          </Box>
        </Box>
      </TabContext>
    </ThemeProvider>
  );
}

export default TabsComponent;
