import React, { useState,  } from "react";
import { useNavigate } from "react-router-dom";
import { useAppState } from "./AppStateProvider ";
import { Theme } from "../Components/IncludeFile/Theme";
import Button from "@mui/material/Button";

import NavBar from "../Components/navbarComponents/NavBar";
import CreateCards from "./CreateCards";
import ViewCardInfo from "./ViewCardInfo";
import { useMenu } from "../reusableComponents/menuUtils";
import CreateInfo from "./CreateInfo";
import AddTemplate from "./AddTemplate";

const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const PopUpRenderer = () => {
  const [currentComponent, setCurrentComponent] = useState("1");
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [arrayName, setarrayName] = useState("");
  const [description, setdescription] = useState("");
  const [category, setcategory] = useState("");
  const [openDiscoverEdit, setOpenDiscoverEdit] = useState(false);

  const { appState, setArrayData } = useAppState();

  const { isMenuOpenleft, toggleMenu } = useMenu();


  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);

  const [openCreateInfo, setOpenCreateInfo] = useState(false);
  const [openViewCreateInfo, setOpenViewCreateInfo] = useState(false);
  const [openDiscoverPage, setOpenDiscoverPage] = useState(false);

  const handlePopup = () => {
    setOpenCreateInfo(!openCreateInfo);
    setOpenDiscoverEdit(!openDiscoverEdit);
  };

  const handlePopupView = () => {
    setOpenViewCreateInfo(!openViewCreateInfo);
  };

  async function fetchData() {
    const scriptElement = document.createElement("script");
    scriptElement.src = `ToolsJSON.js?v=${Date.now()}`;

    scriptElement.onload = () => {
      const fetchedData = window.MyVar || [];
      setData(fetchedData);
      setFetched(true);
    };
    document.head.appendChild(scriptElement);
  }

  const handleEditDiagram = async (DiagTempID) => {
    
    await fetchData();
    if (fetched) {
     
    }
  };

  const handleDetails = (item) => {
    if (item !== -1) setSelectedItem(item);
    else {
      setSelectedItem(null);
    }
    setCurrentComponent("2");
  };

  const renderComponent = (currentComponent) => {
    switch (currentComponent) {
      case "1":
        return (
          <CreateCards
            handleDetails={handleDetails}
            handleEditDiagram={handleEditDiagram}
            searchQuery={searchQuery}
            arrayName={arrayName}
            description={description}
            category={category}
            handleClick={handleClick}
            isMenuOpenleft={isMenuOpenleft}
            setOpenCreateInfo={setOpenCreateInfo}
            openCreateInfo={openCreateInfo}
            setOpenViewCreateInfo={setOpenViewCreateInfo}
            openViewCreateInfo={openViewCreateInfo}
            isDeleteConfirmation={isDeleteConfirmation}
            DeleteConfirmation={DeleteConfirmation}
            openDiscoverPage={openDiscoverPage}
            setOpenDiscoverPage={setOpenDiscoverPage}
          />
        );
      case "2":
        return (
          <ViewCardInfo
            setOpenViewCreateInfo={setOpenViewCreateInfo}
            openViewCreateInfo={openViewCreateInfo}
            openDiscoverPage={openDiscoverPage}
            setOpenDiscoverPage={setOpenDiscoverPage}
          />
        );
      default:
        return null;
    }
  };
  const handleDiscoverPopupView = () => {
    setOpenDiscoverPage(!openDiscoverPage);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    setArrayData(arrayName, description, category);
    setOpen(true);
    setPopupVisible(true);
  };

  const debouncedHandleSearch = debounce((query) => {
    setSearchQuery(query);
  }, 100);

  const handleSearch = (e) => {
    const newQuery = e.target.value;
    debouncedHandleSearch(newQuery);
  };

  const [IsAddTemplate, setAddTemplate] = useState(false);

  const handleAddTemplate = () => {
    setAddTemplate(true);
  };

  const rightButtons = [
    {
      icon: Theme.Add,
      text: "JSON Form",
      color: "background",
      handleClick: () => {
        handleAddTemplate();
      },
    },
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "background",
      handleClick: () => {
        handleBack();
      },
    },
  ];

  const handleBack = () => {
    navigate("/Landing");
  };

  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);


  const DeleteConfirmation = () => {
    setIsDeleteConfirmation(false);
  };

  return (
    <div className="popup-form">
      <>
        <NavBar
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          isSearchBar={true}
          rightButtons={rightButtons}
          title="Choose Template"
          // isMenuOpenleft={isMenuOpenleft}
          toggleMenu={toggleMenu}
          isMenuOpenleft={true}
        />

        <div id="pop-render-form">
          {renderComponent(currentComponent)}

          {currentComponent === "2" && (
            <div
              style={{
                padding: "1rem",
                flex: "0 0 auto",
                display: "flex",
              }}
            >
              <Button onClick={handleBack} sx={{ fontSize: "1rem" }} fullWidth>
                Back
              </Button>
              <Button sx={{ fontSize: "1rem" }} variant="contained" fullWidth>
                Next
              </Button>
            </div>
          )}

          {openCreateInfo && (
            <CreateInfo
              openCreateInfo={openCreateInfo}
              handlePopup={handlePopup}
              handleBack={handleBack}
              // handleNext={handleNext}
            />
          )}

          {openViewCreateInfo && (
            <ViewCardInfo
              openViewCreateInfo={openViewCreateInfo}
              openDiscoverPage={openDiscoverPage}
              handlePopupView={handlePopupView}
              handleDiscoverPopupView={handleDiscoverPopupView}
              handleBack={handleBack}
              // handleNext={handleNext}
            />
          )}
          {IsAddTemplate && (
            <AddTemplate
              IsAddTemplate={IsAddTemplate}
              setAddTemplate={setAddTemplate}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default PopUpRenderer;
