import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EditIcon from "@mui/icons-material/Edit";
import themeProvider from "../../ThemeProvider";
import { Button } from "@mui/material";
import { Action } from "./ActionList";
import { ThemeProvider } from "@mui/material";
function BannerComponent({
  section,
  size,
  textColor,
  textSize,
  admin,
  handleDialogToggle,
}) {
  const overlayStyle = {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    padding: "10px",
  };
  const overlayTopRightStyle = {
    position: "absolute",
    top: "20px",
    right: "20px",
    padding: "10px",
  };

  const headerTextStyle = {
    color: `${textColor}`,
    fontWeight: "bold",
    fontSize: `${textSize}px`,
    textShadow: "0 0 10px black",
    wordBreak: "break-word",
    maxWidth: "20rem",
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const isAdmin = () => {
    return admin;
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile"]}
        deviceType={"mobile"}
      >
        {section.items
          .sort((a, b) => a.dSortID - b.dSortID)
          .map((item, itemIndex) => (
            <div key={itemIndex}>
              <img
                src={item.mediaURL}
                style={{
                  minHeight: `${size}vh`,
                  maxHeight: `${size}vh`,
                  width: "100%",
                  objectFit: "fill",
                }}
                alt={item.header}
              />
              <div style={overlayStyle}>
                <p style={headerTextStyle}>{item.header}</p>
              </div>
              <div style={overlayTopRightStyle}>
                {isAdmin() && (
                  <Button
                    onClick={(event) =>
                      handleDialogToggle(
                        event,
                        item.feedID,
                        item.header,
                        Action.EDIT_ITEM
                      )
                    }
                  >
                    <EditIcon
                      style={{
                        backgroundColor: "f2f2f2",
                        borderColor: "#e8e8e8",
                        borderWidth: "2px",
                        borderRadius: "25px",
                        height: "2rem",
                        width: "2rem",
                        padding: "0.5rem",
                        marginLeft: "5%",
                        color: "#212325",
                      }}
                    />
                  </Button>
                )}
              </div>
            </div>
          ))}
      </Carousel>
    </ThemeProvider>
  );
}
export default BannerComponent;
