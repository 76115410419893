import { useState } from "react";

export const useMenu = () => {
  const [isMenuOpenleft, setMenuOpenleft] = useState(false);

  const [isVisible, setIsVisible] = useState(true);

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleMenu = () => {
    setMenuOpenleft((prevIsMenuOpenleft) => !prevIsMenuOpenleft);
  };

  return {
    isMenuOpenleft,
    toggleMenu,
    setMenuOpenleft,
    setIsVisible,
    isVisible,
    isExpanded,
    setIsExpanded
  };
};