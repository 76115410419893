import { useCallback } from "react";
import { useStore, getBezierPath, EdgeText } from "reactflow";

import { getEdgeParams } from "./utils.js";

function FloatingEdge({
  id,
  source,
  target,
  markerStart,
  markerEnd,
  style,
  label,
  labelStyle,
  labelBgStyle,
}) {
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={style}
        // markerStart: {
        //   type: MarkerType.Arrow, // or any other type you want
        //   color: '#FF0072', // customize color if needed
        // },
      />
      {label && (
        <EdgeText
          x={(sx + tx) / 2}
          y={(sy + ty) / 2}
          label={label}
          labelStyle={{ fill: "black", ...labelStyle }}
          labelBgStyle={{ ...labelBgStyle }}
          labelShowBg
          labelBgPadding={[2, 4]}
          labelBgBorderRadius={2}
        />
      )}
    </>
  );
}

export default FloatingEdge;
