import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Theme } from "../../IncludeFile/Theme";
import themeProvider from "../../../ThemeProvider";
import { ThemeProvider } from "@mui/styles";
import NavBar from "../../navbarComponents/NavBar";
import SaveAsConfirmation from "./SaveAsConfirmation";
import CustomPopUpForm from "../../../layout/CustomPopUpForm";
import { GetToolPropertiesAll } from "../../../apiCalls/DiagramsAPI";
import { ToastContainer, toast } from "react-toastify";
import {
  UpdateDiagTools,
  UpdateToolProperties,
} from "../../../apiCalls/DiagramsAPI";

import { Typography, Box, Button } from "@mui/material";
import ExpandableView from "../../../layout/ExpandableView";
import TabsComponent from "../TabsComponent";
import NodeDropDowns from "./NodeDropDowns";
import CaptionDropDowns from "./CaptionDropDowns";
import BodyDropDowns from "./BodyDropDowns";
import LinkDropDowns from "./LinkDropDowns";
import { TiExport } from "react-icons/ti";
import { GetToolProperties } from "../../../apiCalls/DiagramsAPI";
import PreviewNode from "./PreviewNode";
import DynamicNode from "../../../nodes/DynamicNode";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
function NodeProperties() {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedNodes = location.state?.prop;
  const tools = location.state?.tools;
  const [popup, setPopup] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const tabs = [{ label: "Style", value: "1" }];
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemID, setSelectedItemID] = useState();
  const [selectedItemPropertyGroup, setSelectedItemPropertyGroup] = useState();
  const [editedProperties, setEditedProperties] = useState();
  const [originalProperties, setOriginalProperties] = useState();
  const [leftSidebarTree, setLeftSidebarTree] = useState();

  const [leftCollapse, setLeftCollapse] = useState(false);

  // SidesList?.map((item) => ({
  //   id: item[0].diagToolID,
  //   name: item[0].name,
  //   children: [
  //     {
  //       id: `${item[0].diagToolID} Caption`,
  //       name: "Caption",
  //       children: [],
  //     },
  //     {
  //       id: `${item[0].diagToolID} Node`,
  //       name: "Node",
  //       children: [],
  //     },
  //     {
  //       id: `${item[0].diagToolID} Body`,
  //       name: "Body",
  //       children: [],
  //     },
  //     {
  //       id: `${item[0].diagToolID} Link`,
  //       name: "Link",
  //       children: [],
  //     },
  //   ],
  // }))

  let SidesList = null;
  async function getToolProperties(diagToolID) {
    try {
      let response = await GetToolProperties(diagToolID);
      console.log("response ", response);
      if (response[0].diagToolID !== 0) {
        return response;
      } else {
        let matchingTool = tools.find((tool) => tool.diagToolID === diagToolID);
        response[0].diagToolID = parseInt(diagToolID, 10);
        response[0].name = matchingTool.name;
        return response;
      }
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
      throw error;
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      // Map selectedNodes to getToolProperties calls and store the promises
      const propertyPromises = selectedNodes.map(async (toolID) => {
        return await getToolProperties(toolID); // Return the promise
      });

      try {
        SidesList = await Promise.all(propertyPromises);
        setEditedProperties(SidesList);
        setOriginalProperties(SidesList);
        const updatedTree = SidesList.map((item) => ({
          id: item[0].diagToolID,
          name: item[0].name,
          children: [
            {
              id: `${item[0].diagToolID} Caption`,
              name: "Caption",
              children: [],
            },
            {
              id: `${item[0].diagToolID} Node`,
              name: "Node",
              children: [],
            },
            {
              id: `${item[0].diagToolID} Body`,
              name: "Body",
              children: [],
            },
            {
              id: `${item[0].diagToolID} Link`,
              name: "Link",
              children: [],
            },
          ],
        }));
        setLeftSidebarTree(updatedTree);
      } catch (error) {
        // Handle errors if any
        console.error("Error fetching properties:", error);
      }
    };

    // Call the fetchData function
    fetchData();

    // Empty dependency array to run the effect only once
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const str = String(selectedItem.id);
      const [firstPart, secondPart] = str.split(/\s(.+)/);
      setSelectedItemID(parseInt(firstPart, 10));
      setSelectedItemPropertyGroup(secondPart);
    }
  }, [selectedItem, editedProperties]);
  //calling backend
  const [data, setData] = useState(null);
  async function fetchData() {
    try {
      const fetchedData = await GetToolPropertiesAll();
      setData(fetchedData);
    } catch (error) {
      console.error("Fetch Error ", error);
      // toast.error("Error fetching data: " + error.message);
    }
  }
  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [data]);
  //for getting the prop id
  const getPropertyDiagPropIDAndSection = (propertyName) => {
    const property = data?.find(
      (item) =>
        item.propertyName?.toString().toLowerCase() ===
        propertyName?.toLowerCase()
    );
    return {
      diagPropID: property.diagPropID,
      propSection: property.propSection,
    };
  };

  //for updating editedProperties
  const handlePropertyChange = async (propName, propValue) => {
    // Check if the property already exists in editedProperties
    const propertyGroup = editedProperties.find(
      (group) => group[0]?.diagToolID === selectedItemID
    );
    const existingPropertyIndex = propertyGroup.findIndex(
      (property) => property.propertyName === propName
    );
    if (existingPropertyIndex === -1) {
      if (data === null) {
        await fetchData();
      }
      if (data) {
        // Get the property's diagPropID and propSection
        const { diagPropID, propSection } =
          getPropertyDiagPropIDAndSection(propName);

        // If the property doesn't exist, add it
        if (diagPropID && propSection) {
          const newProperty = {
            toolPropID: 0,
            diagToolID: selectedItemID,
            diagPropID,
            name: propertyGroup[0].name,
            propSection,
            propertyName: propName,
            diagPropValue: propValue,
          };
          // Add the new property to the existing property group
          let updatedPropertyGroup;
          if (propertyGroup[0].diagPropValue === null) {
            updatedPropertyGroup = [newProperty];
          } else {
            updatedPropertyGroup = [...propertyGroup, newProperty];
          }
          // Update the state with the modified properties
          setEditedProperties((prevProperties) =>
            prevProperties.map((group) =>
              group[0]?.diagToolID === selectedItemID
                ? updatedPropertyGroup
                : group
            )
          );
        }
      }
    } else if (existingPropertyIndex !== -1) {
      // Find the property object with the matching diagToolID and propName
      const updatedProperties = editedProperties.map((propertyGroup) => {
        return propertyGroup.map((property) => {
          if (
            property.diagToolID === selectedItemID &&
            property.propertyName === propName
          ) {
            // Update the property's diagPropValue
            return { ...property, diagPropValue: propValue };
          }
          return property;
        });
      });

      // Update the state with the modified properties
      setEditedProperties(updatedProperties);
    }
  };

  //save as functionality
  async function saveasData(newTool) {
    try {
      const response = await UpdateDiagTools(newTool);
      console.log("response ", response);
      toast.success("Data Saved!");
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  }
  async function saveProperties(prop) {
    try {
      const filteredProperties = prop
        .flatMap((propertyGroup) => propertyGroup)
        .filter((property) => property.diagPropValue !== null);
      const response = await UpdateToolProperties(filteredProperties);
      console.log("response ", response);
    } catch (error) {
      console.error("Fetch Error ", error);
    }
  }

  const handleSaveAs = () => {
    setPopup(!popup);
  };

  const handleSave = async (toolInfo) => {
    // Find the tool in SidesList with the same ID as selectedItemID
    const originalTool = editedProperties.find(
      (tool) => tool[0]?.diagToolID === selectedItemID
    );

    if (originalTool) {
      // Create a deep copy of the original tool
      const duplicatedTool = JSON.parse(JSON.stringify(originalTool));
      // Generate a new unique ID by adding 1 to the maximum existing ID number
      let maxID = 0;
      editedProperties.forEach((side) => {
        side.forEach((obj) => {
          if (obj.diagToolID > maxID) {
            maxID = obj.diagToolID;
          }
        });
      });

      duplicatedTool.forEach((obj) => {
        // Change the `diagToolID` to `maxID + 1`
        obj.diagToolID = maxID + 1;

        // Change the `name` property to `toolInfo.toolName` if `toolInfo.toolName` exists, otherwise to an empty string
        obj.name = toolInfo.toolName ? toolInfo.toolName : "";
      });
      saveProperties(duplicatedTool);
      // Optionally, modify any other properties as needed
      // Add the duplicated tool to editedProperties
      setEditedProperties((prev) => [...prev, duplicatedTool]);

      // Update leftSidebarTree
      const updatedLeftSidebarTree = [
        ...leftSidebarTree,
        {
          id: duplicatedTool[0].diagToolID,
          name: duplicatedTool[0].name,
          children: [
            {
              id: `${duplicatedTool[0].diagToolID} Caption`,
              name: "Caption",
              children: [],
            },
            {
              id: `${duplicatedTool[0].diagToolID} Node`,
              name: "Node",
              children: [],
            },
            {
              id: `${duplicatedTool[0].diagToolID} Body`,
              name: "Body",
              children: [],
            },
            {
              id: `${duplicatedTool[0].diagToolID} Link`,
              name: "Link",
              children: [],
            },
          ],
        },
      ];

      setLeftSidebarTree(updatedLeftSidebarTree);
      await saveasData({
        diagToolID: (maxID + 1).toString(),
        name: toolInfo.toolName ? toolInfo.toolName : "",
        title: toolInfo.toolTitle ? toolInfo.toolTitle : "",
        explain: toolInfo.toolExplain ? toolInfo.toolExplain : "",
        notes: toolInfo.toolNotes ? toolInfo.toolNotes : "",
        caption: toolInfo.toolCaption ? toolInfo.toolCaption : "",
        description: toolInfo.toolDescription ? toolInfo.toolDescription : "",
      });
      handleSaveAs();
    } else {
      console.error("Original tool not found!");
    }
  };

  //Nav bar components
  const handleInfoPopup = () => {
    setInfoPopup(!infoPopup);
  };
  const leftButton = {
    icon: <InfoIcon />,
    text: "Info", // color: "primary", // "primary" or "secondary"
    handleClick: () => {
      // Handle click for the left button
      handleInfoPopup();
    },
  };
  const rightButtons = [
    {
      icon: Theme.Save,
      text: "Save",
      color: "secondary",
      handleClick: () => {
        saveProperties(editedProperties);
      },
    },
    {
      icon: Theme.Restore,
      text: "Restore",
      color: "secondary",
      handleClick: () => {
        setEditedProperties(originalProperties);
      },
    },
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];
  // const newNodeData = [tools[0], editedProperties[0], 1];

  const [showLeftIcon, setShowLeftIcon] = useState(true);

  const handleIconClick = () => {
    setShowLeftIcon(!showLeftIcon);
    setLeftCollapse(!leftCollapse);
  };
  // const handleListItemClick = () => {

  // }
  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavBar
        isSearchBar={true}
        rightButtons={rightButtons}
        title="Choose Tools properties"
        leftButton={leftButton}
      />
      {popup && (
        <SaveAsConfirmation
          open={popup}
          handleClose={handleSaveAs}
          handleSave={handleSave}
        />
      )}
      {infoPopup && (
        <CustomPopUpForm
          open={infoPopup}
          onClose={handleInfoPopup}
          title="Information"
        >
          <Typography>
            This Page allows you to to edit the properties of the tools on the
            left hand side of the workflow builder. You can also copy and tool
            and create a new tool using the Save As button.
          </Typography>
          <></>
        </CustomPopUpForm>
      )}
      <ExpandableView
        leftSidebarTree={leftSidebarTree}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        handleSaveAs={handleSaveAs}
        leftCollapse={leftCollapse}
        setLeftCollapse={setLeftCollapse}
        showLeftIcon={showLeftIcon}
        setShowLeftIcon={setShowLeftIcon}
      >
        {selectedItemID ? (
          <PreviewNode
            tools={tools}
            editedProperties={editedProperties}
            selectedItemID={selectedItemID}
          />
        ) : (
          // <DynamicNode data={newNodeData} selected={true} />
          <></>
        )}
        <Box padding="0.5rem">
          <div className="inputfildtet" style={{ marginBottom: "0.5rem" }}>
            <div className="tools-group">
              <div>
                Tool -{" "}
                <span className="sltitmnem">
                  {
                    editedProperties
                      ?.flatMap((propertyGroup) => propertyGroup)
                      .find(
                        (property) => property.diagToolID === selectedItemID
                      )?.name
                  }
                </span>
                <span className="sltitmnem">{selectedItemPropertyGroup}</span>
              </div>
            <div className="top-left-icon-panel" onClick={handleIconClick}>
      {showLeftIcon ? <FaAngleRight />  : <FaAngleLeft />}
    </div>
    </div>
          </div>
          <TabsComponent
            tabValue={tabValue}
            setTabValue={setTabValue}
            tabs={tabs}
          >
            <div key="1">
              {selectedItemPropertyGroup === "Caption" && (
                <CaptionDropDowns
                  selectedItemID={selectedItemID}
                  editedProperties={editedProperties}
                  handlePropertyChange={handlePropertyChange}
                />
              )}
              {selectedItemPropertyGroup === "Node" && (
                <NodeDropDowns
                  selectedItemID={selectedItemID}
                  editedProperties={editedProperties}
                  handlePropertyChange={handlePropertyChange}
                />
              )}
              {selectedItemPropertyGroup === "Body" && (
                <BodyDropDowns
                  selectedItemID={selectedItemID}
                  editedProperties={editedProperties}
                  handlePropertyChange={handlePropertyChange}
                />
              )}
              {selectedItemPropertyGroup === "Link" && (
                <LinkDropDowns
                  selectedItemID={selectedItemID}
                  editedProperties={editedProperties}
                  handlePropertyChange={handlePropertyChange}
                />
              )}
            </div>
          </TabsComponent>
        </Box>
      </ExpandableView>
    </ThemeProvider>
  );
}

export default NodeProperties;
