import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Tooltip,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import "../assets/icofontcharts/icofont.min.css";
import { Theme } from "../Components/IncludeFile/Theme";
import { ReadArray } from "../Components/Api/ApiCalls";

import CustomPopUpForm from "../layout/CustomPopUpForm";
import { useNavigate, useLocation } from "react-router-dom";

const AddTemplate = ({ AddTemplate, setAddTemplate }) => {
  const [isEditable, setIsEditable] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [arrayNames, setArrayNames] = useState([]);
  const [selectedArrayName, setSelectedArrayName] = useState("");
  const [editedArrayName, setEditedArrayName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedArrayId, setSelectedArrayId] = useState(0);
  const [generatedJSON, setGeneratedJSON] = useState([]);

  let APIProvider = window.APIProvider;
  const navigate = useNavigate();


  const iconButtonsEdit = [
    {
      icon: Theme.Edit,
      color: "primary",
      tooltip: "Click To Edit",
    },
  ];

  const handleCancel = () => {
    setIsOpen(false);
    setAddTemplate(false);
  };

  const [FormNameError, setFormNameError] = useState("");
  const [DataSourceError, setDataSourceError] = useState("");

  const handleNext = async () => {
    if (!editedArrayName.trim()) {
      setFormNameError("Form Name is required");
      return;
    } else {
      setFormNameError("");
    }
    if (!selectedArrayName.trim()) {
      setDataSourceError("Data Source is required");
      return;
    } else {
      setDataSourceError("");
    }
    const requestData = {
      arrayName: editedArrayName,
      description: description,
      category: "",
      source: JSON.stringify(generatedJSON[0].Source),
      IsTemplate: "0",
      htmlOutput: "new",
      dataSourceArID: selectedArrayId || 0,
    };

    try {
      const response = await fetch(
        "https://aicloud.agiline.com:7352/WriteArray",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        console.log("Data successfully posted");

        const readResponse = await fetch(
          `https://aicloud.agiline.com:7352/ReadArrayByName/?arrayName=${editedArrayName}`
        );
        if (readResponse.ok) {
          const readData = await readResponse.json();
          const arID = readData.arID;
          const newArrayName = readData.arrayName;
          const newDescription = readData.description;
          const newCategory = readData.category;
          const newSource = readData.source;
          const newdataSourceArID = readData.dataSourceArID;

        

          const stateObject = {
            arID: arID,
            arrayName: newArrayName,
            description: newDescription,
            category: newCategory,
            source: newSource,
            IsTemplate: "0",
            htmlOutput: "new",
            dataSourceArID: newdataSourceArID,
          };
         
          navigate(`/Form/${arID}`, { state: stateObject });
        } else {
          console.error("Failed to fetch arID");
        }
      } else {
        console.error("Failed to post data");
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleArrayNameChange = (e) => {
    const trimmedValue = e.target.value;
    setEditedArrayName(trimmedValue);
    setFormNameError("");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.recordPath.ReadArrayAll}?isTemplate=2`
        );
        if (response.ok) {
          const data = await response.json();
          const filteredNames = data
            .filter((item) => item.isTemplate === 2)
            .map((item) => item.arrayName);
          setArrayNames(filteredNames);
        } else {
          console.error("Failed to fetch array names");
        }
      } catch (error) {
        console.error("Error fetching array names:", error);
      }

      try {
        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.recordPath.ReadArray}?arID=9`
        );
        if (response.ok) {
          const data = await response.json();
          const fetchedSourceData = JSON.parse(data.source);
       
        } else {
          console.error("Failed to fetch source data");
        }
      } catch (error) {
        console.error("Error fetching source data:", error);
      }
    };

    fetchData();
  }, []);

  const handleArrayNameChange1 = async (event) => {
    setSelectedArrayName(event.target.value);
    try {
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.recordPath.ReadArrayAll}?isTemplate=2`
      );
      if (response.ok) {
        const data = await response.json();
        const selectedArray = data.find(
          (item) => item.arrayName === event.target.value
        );
        if (selectedArray) {
          const sourceDataResponse = await fetch(
            `${APIProvider.baseURL}${APIProvider.recordPath.ReadArray}?arID=${selectedArray.arID}`
          );
          if (sourceDataResponse.ok) {
            const { source } = await sourceDataResponse.json();
        
            setSelectedArrayId(selectedArray.arID);

            const parsedSource = JSON.parse(source);
            const textFields = parsedSource.filter(
              (item) =>
                item.elementtype === "field" && item.fieldtype === "text"
            );

            const generatedJSON = [
              {
                arid: 4144,
                arrayName: "TEST1234",
                description: "TEST123",
                dataSourceArID: 6,
                Source: [
                  {
                    id: "0",
                    name: "Root",
                    type: "Root",
                    control: textFields.map((field, index) => {
                      const containerID = `0.${index + 1}`;
                      const labelID = `${containerID}.1`;
                      const textBoxID = `${containerID}.2`;
                      const capitalizedFieldName = field.fieldname
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");

                      return {
                        id: containerID,
                        name: `Container${index + 1}`,
                        tableHeader: "",
                        data: "",
                        type: "Container",
                        parentid: "0",
                        htmlTag: "div",
                        controltype: "Container",
                        styles:
                          '{\n  "alignSelf": "initial",\n  "backgroundColor": "#ffffff",\n  "flexDirection": "row",\n  "borderWidth": "1px",\n  "borderColor": "#000000",\n  "borderRadius": "3px",\n  "marginTop": "2px",\n  "marginRight": "2px",\n  "marginBottom": "2px",\n  "marginLeft": "2px",\n  "paddingTop": "8px",\n  "paddingRight": "8px",\n  "paddingBottom": "8px",\n  "paddingLeft": "8px",\n  "width": "auto",\n  "height": "auto",\n  "minHeight": "50px",\n  "minWidth": "100px",\n  "maxHeight": "100%",\n  "maxWidth": "100%",\n  "display": "flex",\n  "position": "relative",\n  "overflow": "hidden",\n  "borderStyle": "solid",\n  "alignItems": "stretch",\n  "alignContent": "stretch",\n  "justifyItems": "flex-start",\n  "justifyContent": "flex-start",\n  "borderBottomStyle": "solid",\n  "borderBottomWidth": "1px",\n  "borderBottomColor": "#000000",\n  "borderTopWidth": "1px",\n  "borderTopColor": "#000000",\n  "borderTopStyle": "solid",\n  "borderLeftWidth": "1px",\n  "borderLeftColor": "#000000",\n  "borderLeftstyle": "solid",\n  "borderRightWidth": "1px",\n  "borderRightColor": "#000000",\n  "borderRightStyle": "solid"\n}',
                        styleid: `agrfb${containerID}`,
                        styleType: "Container",
                        mediaURl: "square-poll-horizontal",
                        check: false,
                        switchcheck: false,
                        title: "Containers",
                        value: "",
                        media: "",
                        option: "false",
                        switchoption: "true",
                        position: 1,
                        checkposition: "right",
                        tab: "",
                        control: [
                          {
                            id: labelID,
                            name: `${field.fieldname.charAt(0).toUpperCase() + field.fieldname.slice(1)}_label${index + 1}`,
                            tableHeader: "",
                            data: capitalizedFieldName,
                            type: "Label",
                            parentid: "0.1",
                            htmlTag: "label",
                            controltype: "Label",
                            styles:
                              '{\n  "alignSelf": "initial",\n  "fontSize": "14px",\n  "color": "#000000",\n  "fontFamily": "Arial",\n  "fontWeight": "normal",\n  "fontStyle": "normal",\n  "textDecoration": "none",\n  "textAlign": "left",\n  "marginTop": "4px",\n  "marginRight": "4px",\n  "marginBottom": "4px",\n  "marginLeft": "4px",\n  "paddingTop": "0px",\n  "paddingRight": "5px",\n  "paddingBottom": "0px",\n  "paddingLeft": "5px",\n  "width": "30%",\n  "height": "auto",\n  "minHeight": "30px",\n  "minWidth": "18px",\n  "maxHeight": "none",\n  "maxWidth": "none",\n  "display": "block"\n}',
                            styleid: `agrfb${labelID}`,
                            styleType: "Label",
                            mediaURl: "font",
                            check: false,
                            switchcheck: false,
                            title: "Display",
                            value: "",
                            media: "",
                            option: "false",
                            switchoption: "false",
                            position: 1,
                            checkposition: "right",
                            tab: "",
                            control: [],
                          },
                          {
                            id: textBoxID,
                            name: field.fieldname,
                            tableHeader: "",
                            data: `{${field.fieldname}}`,
                            type: "TextBox",
                            parentid: "0.1",
                            htmlTag: "input",
                            controltype: "TextBox",
                            styles:
                              '{\n  "display": "flex",\n  "alignSelf": "flex-start",\n  "justifySelf": "center",\n  "borderRadius": "3px",\n  "borderWidth": "1px",\n  "borderStyle": "solid",\n  "borderColor": "#767676",\n  "fontFamily": "Arial",\n  "fontSize": "12px",\n  "fontWeight": "normal",\n  "fontStyle": "normal",\n  "textDecoration": "none",\n  "backgroundColor": "#ffffff",\n  "color": "#000000",\n  "marginTop": "8px",\n  "marginRight": "8px",\n  "marginBottom": "8px",\n  "marginLeft": "8px",\n  "paddingTop": "2px",\n  "paddingRight": "5px",\n  "paddingBottom": "2px",\n  "paddingLeft": "5px",\n  "width": "auto",\n  "height": "auto",\n  "minHeight": "auto",\n  "minWidth": "auto",\n  "maxHeight": "none",\n  "maxWidth": "none",\n  "borderBottomStyle": "solid",\n  "borderBottomWidth": "1px",\n  "borderBottomColor": "#000000",\n  "borderTopWidth": "1px",\n  "borderTopColor": "#000000",\n  "borderTopStyle": "solid",\n  "borderLeftWidth": "1px",\n  "borderLeftColor": "#000000",\n  "borderLeftstyle": "solid",\n  "borderRightWidth": "1px",\n  "borderRightColor": "#000000",\n  "borderRightStyle": "solid"\n}',
                            styleid: `agrfb${textBoxID}`,
                            styleType: "TextBox",
                            mediaURl: "text-height",
                            check: false,
                            switchcheck: false,
                            title: "Input",
                            value: "",
                            media: "",
                            option: "false",
                            switchoption: "false",
                            position: 1,
                            checkposition: "right",
                            tab: "",
                            control: [],
                          },
                        ],
                      };
                    }),
                  },
                ],
              },
            ];

         
            setGeneratedJSON(generatedJSON);

            setDataSourceError("");
          } else {
            console.error("Failed to fetch source data");
          }
        } else {
          console.error("Selected array not found");
        }
      } else {
        console.error("Failed to fetch array names");
      }
    } catch (error) {
      console.error("Error fetching array names:", error);
    }
  };

  return (
    <CustomPopUpForm
      open={isOpen}
      onClose={handleCancel}
      title="Create New Form"
      height="60%"
    >
      <div className="card-info">
        <div className="card-info-inside">
          <Typography style={{ fontWeight: "bold" }}>Form Name*:</Typography>
          <TextField value={editedArrayName} onChange={handleArrayNameChange} />
          {FormNameError && (
            <Typography style={{ color: "red" }}>{FormNameError}</Typography>
          )}
        </div>
        <div className="card-info-inside">
          <Typography style={{ fontWeight: "bold" }}>Description:</Typography>
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="card-info-inside">
          <Typography style={{ fontWeight: "bold" }}>DataSource:</Typography>
          <Select
            className="data-select"
            value={selectedArrayName}
            onChange={handleArrayNameChange1}
          >
            {arrayNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {DataSourceError && (
            <Typography style={{ color: "red" }}>{DataSourceError}</Typography>
          )}
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Button variant="contained" fullWidth onClick={handleCancel}>
          Back
        </Button>
        <Button variant="contained" fullWidth onClick={handleNext}>
          Next
        </Button>
      </div>
    </CustomPopUpForm>
  );
};

export default AddTemplate;
