import React, { useEffect, useState, useRef } from "react";
import {
  Alert,
  Badge,
  Box,
  IconButton,
  Popper,
  Fade,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  ThemeProvider,
} from "@mui/material";
import themeProvider from "../../ThemeProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const popperStyles = {
  maxWidth: "100%",
  width: "min(40ch, 100ch)",
  paddingRight:"25px",
};


function NotificationCenter() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isTinyScreen, setIsTinyScreen] = useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600 || window.innerHeight <= 600);
      setIsTinyScreen(window.innerWidth <= 300 || window.innerHeight <= 300);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { notifications, clear, markAllAsRead, markAsRead, unreadCount } =
    useNotificationCenter();
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleNotificationCenter = (event) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);    
      if (typeof isOpen !== 'undefined') {
        // setIsOpen(!isOpen);
        setIsOpen((isOpen) => !isOpen);
        console.error("hello ")
      }
    }
  };
  
  const toggleFilter = (e) => {
    // setShowUnreadOnly(!showUnreadOnly);
    setShowUnreadOnly((showUnreadOnly) => !showUnreadOnly); 
  };
  const handleCloseButton = () => {
    setIsOpen(false);
  };
  return (
    <ThemeProvider theme={themeProvider}>
      <Box
        sx={{
          position: "fixed",
          bottom: "16px",
          right: "16px",
          zIndex: 1000, // adjust as needed to ensure it's above other elements
        }}
      >
        <IconButton size="large" onClick={toggleNotificationCenter}>
          <Badge badgeContent={unreadCount} color="primary">
            <NotificationsIcon color="action" />
          </Badge>
        </IconButton>

        <Popper
          open={isOpen}
          anchorEl={anchorEl}
          transition
          style={popperStyles}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box>
                <Box
                  sx={{
                    // display: "flex",
                    alignItems: "center",
                    gap: 2,
                    backgroundColor: "#4d75a2",
                    // justifyContent: "space-around",
                    borderRadius: "10px 10px 0 0",
                    // height: isSmallScreen ? "auto" : "2.5rem",
                  }}
                >
                  <div className="ntf-cnt">
                  <Typography
                    variant="body1"
                    color="#fff"
                    marginLeft="0.5rem"
                  >
                    Notification center
                  </Typography>
                  <IconButton onClick={handleCloseButton}>
                    <CloseIcon style={{color:"#fff"}}/>
                  </IconButton>
                  </div>
                  <div>
                  <FormGroup sx={{ color: "#fff" }}>
                    <FormControlLabel className="ntf-show-only"
                      control={
                        <Switch
                          // color="secondary"
                          color="primary"
                          classes={{
                            checked: 'custom-checked',
                          }}
                          sx={{
                            '& .Mui-checked + .MuiSwitch-track': {
                              backgroundColor: '#fff !important',
                              opacity:'3 !important',
                            }
                          }}
                          onChange={toggleFilter}
                          checked={showUnreadOnly}
                          // size="small"
                          size="large"
            
                        />
                      }
                      label={isTinyScreen ? "" : "Show unread only"}
                    />
                  </FormGroup>
                  </div>
                </Box>
                <Stack
                  sx={{
                    maxHeight: "calc(100vh - 100px)",
                    // height: "15rem",
                    maxWidth: "100%",
                    // width: "min(50ch, 100ch)",
                    background: "#f1f1f1",
                    overflowY: "auto",
                  }}
                  className="stock-notification"
                >
                  {(!notifications.length ||
                    (unreadCount === 0 && showUnreadOnly)) && (
                    <h4 style={{ display: "flex", justifyContent: "center" , fontSize: "15px", marginTop:"10px"}}>
                      {" "}
                      Your queue is empty! you are all set{" "}
                    </h4>
                  )}
                  {(showUnreadOnly
                    ? notifications.filter((v) => !v.read)
                    : notifications
                  ).map((notification) => {
                    return (
                      <Box style={{ padding: "0.5rem" }}>
                        <Alert
                          severity={notification.type || "info"}
                          action={
                            notification.read ? (
                              <CheckIcon />
                            ) : (
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={() => markAsRead(notification.id)}
                              >
                                <MarkAsUnreadIcon />
                              </IconButton>
                            )
                          }
                        >
                          {notification.content}
                        </Alert>
                      </Box>
                    );
                  })}
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    backgroundColor: "#ccc",
                    justifyContent: "center",
                    borderRadius: "0 0 10px 10px",
                    padding:"0.5rem",
                    borderTop:"2px solid #1565C0"
                  }}
                >
                  <div className="notification-btn">
                  <Button
                    variant="contained"
                    onClick={clear}
                    // style={{ borderRadius: "30px" }}
                    className="ntf-btn"
                    sx={{
                      height: isSmallScreen ? "1.5rem" : "auto",
                      fontSize: isSmallScreen ? "10px" : "14px",
                    }}
                  >
                    Clear All
                  </Button>

                  <Button
                    variant="contained"
                    onClick={markAllAsRead}
                    // style={{ borderRadius: "30px" }}
                    className="ntf-btn"
                    sx={{
                      height: isSmallScreen ? "1.5rem" : "auto",
                      fontSize: isSmallScreen ? "10px" : "14px",
                    }}
                  >
                    Mark all as read
                  </Button>
                  </div>
                </Box>
              </Box>
            </Fade>
          )}
        </Popper>
      </Box>
    </ThemeProvider>
  );
}

export default NotificationCenter;
