import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PopUpRenderer from "./PopUpForm/PopUpRenderer";
import RecursiveTreeView from "./Components/RecursiveTreeView/RecursiveTreeView";
import Landing from "./pages/landingPage/Landing";
import Diagrams from "./pages/diagramPages/Diagrams";
import DiagramsRenderer from "./Components/diagramsComponents/diagramsFormComponents/DiagramsRenderer";
import PanelComponent from "./reusableComponents/PanelComponent";
import NotFound from "./Components/RecursiveTreeView/NotFound";
import CreateInfo from "./PopUpForm/CreateInfo";
import ViewCardInfo from "./PopUpForm/ViewCardInfo";
import AddTemplatePage from "./pages/AddTemplatePage";
import NodeSelector from "./pages/diagramPages/NodeSelectorPage";
import NotificationCenter from "./Components/AlertComponents/NotificationCenter";
import themeProvider from "./ThemeProvider";
import NodeProperties from "./Components/diagramsComponents/toolProperties/NodeProperties";
import Discover from "./PopUpForm/Discover";
import DiscoverEdit from "./PopUpForm/DiscoverEdit";
import EditTemplatePage from "./pages/diagramPages/EditTemplatePage";
import EditControls from "./pages/diagramPages/EditControls";
import EditControlsStyles from "./pages/diagramPages/EditControlsStyles";
import LoginPage from "./Components/LoginPage";
import BillOfLading from "./DataGrid/BillOfLading";

function App() {
  document.documentElement.style.setProperty(
    "--primary-main",
    themeProvider.palette.primary.main
  );
  document.documentElement.style.setProperty(
    "--primaryContrastColor-main",
    themeProvider.palette.primaryContrastColor.main
  );
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <NotificationCenter />
      <Routes>
     
        <Route index element={<Landing />} />
        {/* <Route index element={<LoginPage />} /> */}
        <Route path="/Form/:arId" element={<RecursiveTreeView />} />
        <Route path="/Open" element={<PopUpRenderer/>} />
        <Route path="/Landing" element={<Landing />} />
        <Route path="/Diagrams" element={<Diagrams />} />
        <Route path="/Select" element={<DiagramsRenderer />} />
        <Route path="/PanelComponent" element={<PanelComponent />} />
        <Route path="/ViewCardInfo" element={<ViewCardInfo />} />
        <Route path="/CreateInfo" element={<CreateInfo />} />
        <Route path="/Discover" element={<Discover />} />
        <Route path="/DiscoverEdit" element={<DiscoverEdit />} />
        <Route path="/AddTemplate" element={<AddTemplatePage />} />
        <Route path="/EditTemplates" element={<EditTemplatePage />} />
        <Route path="/ToolSelector" element={<NodeSelector />} />
        <Route path="/Edit" element={<EditControls/>} />
        <Route path="/Styles" element={<EditControlsStyles/>} />
        <Route path="/ToolProperties" element={<NodeProperties />} />
        
    
        <Route path="*" element={<NotFound />} />
        {/* <Route path="BillOfLading" element={<BillOfLading />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;