import React, { useState } from "react";
import BoxTemplate from "../BoxTemplate";
import { TextField, MenuItem, Select, Grid, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

function WaitBox({
  formData,
  handleChange,
  handleDateChange,
  handleTimeChange,
}) {
  return (
    <BoxTemplate
      icon={<AccessTimeIcon style={{ marginRight: "0.5rem" }} />}
      title={"Wait"}
      button={"Preview"}
      buttonOnClick={() => console.log("ButtonClicked")}
      subTitle={"How much to wait"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date"
              value={formData.date}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Time"
              value={formData.time}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Frequency"
            size="small"
            variant="outlined"
            fullWidth
            type="number"
            name="frequency"
            value={formData.frequency}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            size="small"
            label="Unit"
            name="unit"
            value={formData.unit}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value="days">Day(s)</MenuItem>
            <MenuItem value="hours">Hour(s)</MenuItem>
            <MenuItem value="minutes">Minute(s)</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </BoxTemplate>
  );
}

export default WaitBox;
