import React, { useState } from "react";
import PropertiesAccordion from "../../../reusableComponents/PropertiesAccordion";
import IconBox from "../IconBox";
import FontDropDown from "./FontDropDown";
import { IconButton } from "@mui/material";

import {
  PiAlignRightSimpleBold,
  PiAlignLeftSimpleDuotone,
  PiAlignBottomSimpleBold,
  PiAlignCenterHorizontalSimpleThin,
} from "react-icons/pi";
import { BsAlignCenter } from "react-icons/bs";
import { RxStretchHorizontally, RxStretchVertically } from "react-icons/rx";
import { TbLayoutAlignTop } from "react-icons/tb";
import EditIcon from "@mui/icons-material/Edit";
import EditCss from "../../../reusableComponents/EditCss";
import BodyContentDropDown from "./BodyContentDropDown";

function BodyDropDowns({
  selectedItemID,
  editedProperties,
  handlePropertyChange,
}) {
  const [openPopup, setOpenPopup] = useState(false);
  const handlePopup = () => {
    setOpenPopup(!openPopup);
  };
  const [horizontalAlignment, setHorizontalAlignment] = useState({
    left: 0,
    horizontalCenter: 0,
    right: 0,
    horizontalStretch: 0,
  });

  const [verticalAlignment, setVerticalAlignment] = useState({
    top: 0,
    verticalCenter: 0,
    bottom: 0,
    verticalStretch: 0,
  });
  const [bodyColor, setBodyColor] = useState({
    backgroundColor: "",
    color: "",
  });
  React.useEffect(() => {
    const propertyGroup = editedProperties.find(
      (group) => group[0]?.diagToolID === selectedItemID
    );

    if (propertyGroup) {
      const verticalAlignment = propertyGroup.find(
        (property) => property.propertyName === "Body_Alignment_Vertical"
      );
      const horizontalAlignment = propertyGroup.find(
        (property) => property.propertyName === "Body_Alignment_Horizontal"
      );
      const bodyBackground = propertyGroup.find(
        (property) => property.propertyName === "Body_Background"
      );
      const color = propertyGroup.find(
        (property) => property.propertyName === "Body_Color"
      );
      if (verticalAlignment) {
        setVerticalAlignment({
          top:
            verticalAlignment?.diagPropValue.toString().toLowerCase() === "top"
              ? 1
              : 0,
          verticalCenter:
            verticalAlignment?.diagPropValue.toString().toLowerCase() ===
            "vcenter"
              ? 1
              : 0,
          bottom:
            verticalAlignment?.diagPropValue.toString().toLowerCase() ===
            "bottom"
              ? 1
              : 0,
          verticalStretch:
            verticalAlignment?.diagPropValue.toString().toLowerCase() ===
            "vstretch"
              ? 1
              : 0,
        });
      } else {
        setVerticalAlignment({
          top: 0,
          verticalCenter: 0,
          bottom: 0,
          verticalStretch: 0,
        });
      }
      if (horizontalAlignment) {
        setHorizontalAlignment({
          left:
            horizontalAlignment?.diagPropValue.toString().toLowerCase() ===
            "left"
              ? 1
              : 0,
          horizontalCenter:
            horizontalAlignment?.diagPropValue.toString().toLowerCase() ===
            "hcenter"
              ? 1
              : 0,
          right:
            horizontalAlignment?.diagPropValue.toString().toLowerCase() ===
            "right"
              ? 1
              : 0,
          horizontalStretch:
            horizontalAlignment?.diagPropValue.toString().toLowerCase() ===
            "hstretch"
              ? 1
              : 0,
        });
      } else {
        setHorizontalAlignment({
          left: 0,
          horizontalCenter: 0,
          right: 0,
          horizontalStretch: 0,
        });
      }
      if (bodyBackground) {
        setBodyColor((prevBodyColor) => ({
          ...prevBodyColor,
          backgroundColor: bodyBackground
            ? bodyBackground.diagPropValue.toString()
            : "",
        }));
      } else {
        setBodyColor((prevBodyColor) => ({
          ...prevBodyColor,
          backgroundColor: "",
        }));
      }
      if (color) {
        setBodyColor((prevBodyColor) => ({
          ...prevBodyColor,
          color: color ? color.diagPropValue.toString() : "",
        }));
      } else {
        setBodyColor((prevBodyColor) => ({
          ...prevBodyColor,
          color: "",
        }));
      }
    }
  }, [selectedItemID, editedProperties]);
  const handleAlignmentChange = (name, value) => {
    // Update the appropriate state variable based on the name passed
    if (
      name === "Left" ||
      name === "HCenter" ||
      name === "Right" ||
      name === "HStretch"
    ) {
      setHorizontalAlignment({
        left: name === "Left" ? value : 0,
        horizontalCenter: name === "HCenter" ? value : 0,
        right: name === "Right" ? value : 0,
        horizontalStretch: name === "HStretch" ? value : 0,
      });
      handlePropertyChange("Body_Alignment_Horizontal", name);
    } else if (
      name === "Top" ||
      name === "VCenter" ||
      name === "Bottom" ||
      name === "VStretch"
    ) {
      setVerticalAlignment({
        top: name === "Top" ? value : 0,
        verticalCenter: name === "VCenter" ? value : 0,
        bottom: name === "Bottom" ? value : 0,
        verticalStretch: name === "VStretch" ? value : 0,
      });
      handlePropertyChange("Body_Alignment_Vertical", name);
    }
  };
  const handleBodyColorChange = (name, value) => {
    setBodyColor({
      ...bodyColor,
      [name]: value,
    });
    if (name === "backgroundColor") {
      handlePropertyChange("Body_Background", value);
    } else {
      handlePropertyChange("Body_Color", value);
    }
  };
  const handleBgColorClear = () => {
    setBodyColor({
      ...bodyColor,
      backgroundColor: "",
    });
    handlePropertyChange("Body_Background", "transparent");
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "0.5rem 0",
            cursor: "pointer", // Adding cursor style to indicate it's clickable
          }}
          onClick={handlePopup}
        >
          Body Default Css
        </div>
        <IconButton onClick={handlePopup}>
          <EditIcon />
        </IconButton>
      </div>
      {openPopup && (
        <EditCss
          value={
            editedProperties
              .flatMap((propertyGroup) => propertyGroup)
              .find(
                (property) =>
                  property.diagToolID === selectedItemID &&
                  property.propertyName === "Body_Default_CSS"
              )?.diagPropValue
          }
          onChange={(newValue) =>
            handlePropertyChange("Body_Default_CSS", newValue.target.value)
          }
          handlePopup={handlePopup}
        />
      )}

      <PropertiesAccordion title={"Body Alignment"} onRightClick={handlePopup}>
        <IconBox
          handleChange={handleAlignmentChange}
          buttonList={[
            {
              icon: <PiAlignLeftSimpleDuotone />,
              name: "Left",
              value: horizontalAlignment.left,
            },
            {
              icon: <BsAlignCenter />,
              name: "HCenter",
              value: horizontalAlignment.horizontalCenter,
            },
            {
              icon: <PiAlignRightSimpleBold />,
              name: "Right",
              value: horizontalAlignment.right,
            },
            {
              icon: <RxStretchHorizontally />,
              name: "HStretch",
              value: horizontalAlignment.horizontalStretch,
            },
          ]}
        />
        <IconBox
          handleChange={handleAlignmentChange}
          buttonList={[
            {
              icon: <TbLayoutAlignTop />,
              name: "Top",
              value: verticalAlignment.top,
            },
            {
              icon: <PiAlignCenterHorizontalSimpleThin />,
              name: "VCenter",
              value: verticalAlignment.verticalCenter,
            },
            {
              icon: <PiAlignBottomSimpleBold />,
              name: "Bottom",
              value: verticalAlignment.bottom,
            },
            {
              icon: <RxStretchVertically />,
              name: "VStretch",
              value: verticalAlignment.verticalStretch,
            },
          ]}
        />
      </PropertiesAccordion>
      <PropertiesAccordion title={"Body Font"} onRightClick={handlePopup}>
        <FontDropDown
          selectedItemID={selectedItemID}
          editedProperties={editedProperties}
          handlePropertyChange={handlePropertyChange}
          propSection="Body"
        />
      </PropertiesAccordion>
      <PropertiesAccordion title={"Body Color"} onRightClick={handlePopup}>
        <div className="backgrounclr ">
          Background Color
          <div>
            <input
              type="color"
              name="backgroundColor"
              value={bodyColor.backgroundColor}
              onChange={(e) => {
                handleBodyColorChange(e.target.name, e.target.value);
              }}
            />
            <div className="iconset clearColorWrap">
              <div className="clearColor" onClick={handleBgColorClear}>
                <span className="crsLinelft"></span>
                <span className="crsLinerht"></span>
                <span className="tooltiptext">Transparent</span>
              </div>
            </div>
          </div>
        </div>
        <div className="backgrounclr ">
          Color
          <input
            type="color"
            name="color"
            value={bodyColor.color}
            onChange={(e) => {
              handleBodyColorChange(e.target.name, e.target.value);
            }}
          />
        </div>
      </PropertiesAccordion>
      <PropertiesAccordion title={"Body Content"} onRightClick={handlePopup}>
        <BodyContentDropDown
          selectedItemID={selectedItemID}
          editedProperties={editedProperties}
          handlePropertyChange={handlePropertyChange}
        />
      </PropertiesAccordion>
    </>
  );
}

export default BodyDropDowns;
