import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { MenuItem, Select } from "@mui/material";
import PropertiesAccordion from "../reusableComponents/PropertiesAccordion";
import debounce from "lodash/debounce";

const StyledAlignItems = styled("div")({
  width: "250px",
  flexShrink: 0,
  height: "calc(-54px + 100vh)",
  overflow: "auto",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  transition: "width 0.3s ease 0s",
});

const Margin = ({
  matchedStyles,
  updateDefaultStyle,
  marginTop,
  setMarginTop,
  setDefaultStyleState,
  alignSelfValue,
  marginBottom,
  setmarginBottom,
  marginLeft,
  marginRight,
  setMarginLeft,
  setMarginRight,
  defaultStyle,
  setDefaultStyle,
  styleType,
}) => {



 

  const defaultStyleObject = defaultStyle.find(
    (style) => style.styleType === styleType
  );

  useEffect(() => {
    if (defaultStyleObject) {
      const updatedStyle = defaultStyle.map((style) => {
        if (style.styleType === styleType) {
          return {
            ...style,
            style: {
              ...style.style,
              marginTop: marginTop,
              marginBottom: marginBottom,
              marginLeft: marginLeft,
              marginRight: marginRight,
            },
          };
        }
        return style;
      });

      setDefaultStyle(updatedStyle);
      updateDefaultStyle(updatedStyle);
      // setDefaultStyleState(updatedStyle);
    }
  }, [
    marginTop,
    styleType,
    alignSelfValue,
    marginBottom,
    marginLeft,
    marginRight,
  ]);

  const getNumericAndUnit = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }

    if (value === undefined || value === null) {
      return {
        numeric: "",
        unit: "px",
      };
    }

    const match = value.match(/^(-?[\d.]+)(\D*)$/);
    if (match) {
      const numeric = match[1];
      const unit = match[2] !== "" ? match[2] : "px";
      return {
        numeric: numeric,
        unit: unit,
      };
    }
    return {
      numeric: "",
      unit: "px",
    };
  };

  const debouncedSetMarginTop = debounce((value) => {
    setMarginTop(value);
  }, 10);

  const debouncedSetMarginBottom = debounce((value) => {
    setmarginBottom(value);
  }, 10);

  const debouncedSetMarginLeft = debounce((value) => {
    setMarginLeft(value);
  }, 10);

  const debouncedSetMarginRight = debounce((value) => {
    setMarginRight(value);
  }, 10);

  const handleNumericChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(marginTop).unit;
    debouncedSetMarginTop(`${numericValue}${unit}`);
  };

  const handleUnitTopChange = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(marginTop).numeric;
    debouncedSetMarginTop(`${numericValue}${unit}`);
  };

  const handleNumericChangeBottom = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(marginBottom).unit;
    debouncedSetMarginBottom(`${numericValue}${unit}`);
  };

  const handleNumericChangeLeft = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(marginLeft).unit;
    debouncedSetMarginLeft(`${numericValue}${unit}`);
  };

  const handleNumericChangeRight = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(marginRight).unit;
    debouncedSetMarginRight(`${numericValue}${unit}`);
  };

  const handleUnitBottomChange = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(marginBottom).numeric;
    debouncedSetMarginBottom(`${numericValue}${unit}`);
  };

  const handleUnitLeftChange = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(marginLeft).numeric;
    debouncedSetMarginLeft(`${numericValue}${unit}`);
  };

  const handleUnitRightChange = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(marginRight).numeric;
    debouncedSetMarginRight(`${numericValue}${unit}`);
  };

 



  return (
    <StyledAlignItems className="bor-remove-right-edit">
      <PropertiesAccordion title={"Margin"}>
        <>
          <div className="rht-flds-wrap">
            <div className="rht-fld-info">
              <div className="ipubrdr">
                <div className="rht-fld">
                  <input
                    type="number"
                    value={getNumericAndUnit(marginTop).numeric}
                    onChange={handleNumericChange}
                  />
                  <span className="unit-dropdown">
                    <Select
                      value={getNumericAndUnit(marginTop).unit}
                      onChange={handleUnitTopChange}
                      style={{
                        width: "40px",
                        height: "27px",
                        padding: "8px 11px 10px 12px",
                      }}
                      displayEmpty
                      IconComponent={() => null}
                      MenuProps={{ style: { top: "5px" } }}
                      className="custom-dropdown"
                    >
                      <MenuItem value="px" className="custom-menuitem">
                        px
                      </MenuItem>
                      <MenuItem value="em" className="custom-menuitem">
                        em
                      </MenuItem>
                      <MenuItem value="%" className="custom-menuitem">
                        %
                      </MenuItem>
                    </Select>
                  </span>
                </div>
              </div>
              <div className="rht-info">top</div>
            </div>

            <div className="rht-fld-info">
              <div className="ipubrdr">
                <div className="rht-fld">
                  <input
                    type="number"
                    value={getNumericAndUnit(marginRight).numeric}
                    onChange={handleNumericChangeRight}
                  />
                  <span className="unit-dropdown">
                    <Select
                      value={getNumericAndUnit(marginRight).unit}
                      onChange={handleUnitRightChange}
                      style={{
                        width: "40px",
                        height: "27px",
                        padding: "8px 11px 10px 12px",
                      }}
                      displayEmpty
                      IconComponent={() => null}
                      MenuProps={{ style: { top: "5px" } }}
                      className="custom-dropdown"
                    >
                      <MenuItem value="px" className="custom-menuitem">
                        px
                      </MenuItem>
                      <MenuItem value="em" className="custom-menuitem">
                        em
                      </MenuItem>
                      <MenuItem value="%" className="custom-menuitem">
                        %
                      </MenuItem>
                    </Select>
                  </span>
                </div>
              </div>
              <div className="rht-info">Right</div>
            </div>
          </div>
        </>
        <>
          <div className="rht-flds-wrap">
            <div className="rht-fld-info">
              <div className="ipubrdr">
                <div className="rht-fld">
                  <input
                    type="number"
                    value={getNumericAndUnit(marginBottom).numeric}
                    onChange={handleNumericChangeBottom}
                  />
                  <span className="unit-dropdown">
                    <Select
                      value={getNumericAndUnit(marginBottom).unit}
                      onChange={handleUnitBottomChange}
                      style={{
                        width: "40px",
                        height: "27px",
                        padding: "8px 11px 10px 12px",
                      }}
                      displayEmpty
                      IconComponent={() => null}
                      MenuProps={{ style: { top: "5px" } }}
                      className="custom-dropdown"
                    >
                      <MenuItem value="px" className="custom-menuitem">
                        px
                      </MenuItem>
                      <MenuItem value="em" className="custom-menuitem">
                        em
                      </MenuItem>
                      <MenuItem value="%" className="custom-menuitem">
                        %
                      </MenuItem>
                    </Select>
                  </span>
                </div>
              </div>
              <div className="rht-info">Bottom</div>
            </div>
            <div className="rht-fld-info">
              <div className="ipubrdr">
                <div className="rht-fld">
                  <input
                    type="number"
                    value={getNumericAndUnit(marginLeft).numeric}
                    onChange={handleNumericChangeLeft}
                  />
                  <span className="unit-dropdown">
                    <Select
                      value={getNumericAndUnit(marginLeft).unit}
                      onChange={handleUnitLeftChange}
                      style={{
                        width: "40px",
                        height: "27px",
                        padding: "8px 11px 10px 12px",
                      }}
                      displayEmpty
                      IconComponent={() => null}
                      MenuProps={{ style: { top: "5px" } }}
                      className="custom-dropdown"
                    >
                      <MenuItem value="px" className="custom-menuitem">
                        px
                      </MenuItem>
                      <MenuItem value="em" className="custom-menuitem">
                        em
                      </MenuItem>
                      <MenuItem value="%" className="custom-menuitem">
                        %
                      </MenuItem>
                    </Select>
                  </span>
                </div>
              </div>
              <div className="rht-info">Left</div>
            </div>
          </div>
        </>
      </PropertiesAccordion>
    </StyledAlignItems>
  );
};

export default Margin;
