import React from "react";
import CircleComponent from "../../Components/landingPage/CircleComponent";

export default function CallCircleComponent({
  section,
  hideArray,
  fetched,
  admin,
  handleDialogToggle,
  isMobile,
}) {
  // Filter items based on the condition
  const filteredItems =
    section.defView === 8 && !hideArray.includes(8) && section.items;

  return (
    <div
      style={
        !isMobile
          ? { justifyContent: "center", display: "flex", flexWrap: "wrap" }
          : null
      }
    >
      {filteredItems &&
        filteredItems.map((item, itemIndex) => (
          <CircleComponent
            key={itemIndex}
            src={item.mediaURL}
            alt={item.header}
            size={isMobile ? 350 : 250}
            position={isMobile ? "right" : "bottom"}
            title={item.header}
            text={item.description}
            textSize={20}
            itemID={item.feedID}
            agilineLink={item.link}
            fetched={fetched}
            admin={admin}
            handleDialogToggle={handleDialogToggle}
          />
        ))}
    </div>
  );
}
