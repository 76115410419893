import React from "react";
import {
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function Yearly({
  scheduleData,
  handleNumberChange,
  handleTimeChange,
  handleChange,
}) {
  return (
    <FormControl fullWidth size="small">
      <InputLabel>Option</InputLabel>
      <Select
        size="small"
        label="Option"
        name="yearlyOption"
        value={scheduleData.yearlyOption}
        onChange={handleChange}
        sx={{ marginBottom: "0.5rem" }}
      >
        <MenuItem value="byDayOfYear">By day of the year</MenuItem>
        <MenuItem value="setDaysOfYear">Set days of the year</MenuItem>
      </Select>
      {scheduleData.yearlyOption === "byDayOfYear" ? (
        <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Every</InputLabel>
              <Select
                size="small"
                label="Every"
                name="yearlyMonth"
                value={scheduleData.yearlyMonth}
                onChange={handleChange}
              >
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Day"
              type="number"
              name="yearlyDay"
              value={scheduleData.yearlyDay}
              onChange={(e) => handleNumberChange(e, 1, 31)}
            />
          </Grid>
        </Grid>
      ) : scheduleData.yearlyOption === "setDaysOfYear" ? (
        <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>The</InputLabel>
              <Select
                size="small"
                label="The"
                name="yearlySequence"
                value={scheduleData.yearlySequence}
                onChange={handleChange}
              >
                {["First", "Second", "Third", "Fourth"].map((week) => (
                  <MenuItem key={week} value={week}>
                    {week}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Day</InputLabel>
              <Select
                size="small"
                label="Day"
                name="yearlyDayOfTheWeek"
                value={scheduleData.yearlyDayOfTheWeek}
                onChange={handleChange}
              >
                {[
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ].map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel>Of</InputLabel>
              <Select
                size="small"
                label="Of"
                name="yearlyMonth"
                value={scheduleData.yearlyMonth}
                onChange={handleChange}
              >
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              marginLeft: "0.5rem",
            }}
          >
            Start at:
          </div>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
            <TimePicker
              label="Time"
              value={scheduleData.yearlyTime}
              onChange={(event) => handleTimeChange(event, "yearlyTime")}
              renderInput={(params) => <TextField {...params} fullWidth />}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default Yearly;
