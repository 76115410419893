import React from "react";
import BoxTemplate from "../BoxTemplate";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const dataSetMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "Field1" },
  { value: 1, label: "Field2" },
  { value: 2, label: "Field3" },
];

function SendEmailBox({ formData, handleChange, dataSetOptions }) {
  return (
    <BoxTemplate
      icon={<MailOutlineIcon style={{ marginRight: "0.5rem" }} />}
      title={"Email"}
      button={"Preview"}
      buttonOnClick={() => console.log("ButtonClicked")}
      //   subTitle={"Profiles enter the flow when this happens."}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            label="Name"
            size="small"
            fullWidth
            value={formData.name}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Module"
            size="small"
            variant="outlined"
            fullWidth
            name="module"
            value={formData.module}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel>Data Set</InputLabel>
            <Select
              label="Data Set"
              fullWidth
              size="small"
              name="dataSet"
              value={formData.dataSet}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem value="Select" disabled>
                {formData.module === "" ? "Please enter a module" : "Select"}
              </MenuItem>
              {dataSetOptions
                .filter((option) => option.value !== -1)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="linkTo"
            label="Link To"
            size="small"
            value={formData.linkTo}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Define To</InputLabel>
            <Select
              label="Define To"
              fullWidth
              size="small"
              name="defineTo"
              value={formData.defineTo}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem value="Select" disabled>
                {formData.module === "" ? "Please enter a module" : "Select"}
              </MenuItem>
              {dataSetMenuItems
                .filter((option) => option.value !== -1)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </BoxTemplate>
  );
}

export default SendEmailBox;
