import { toast } from "react-toastify";

let APIProvider = window.APIProvider;

export async function ReadArray(arId) {
  if (!window.APIProvider || !window.APIProvider.baseURL) {
    console.error("APIProvider is undefined or does not have baseURL property");
    toast.error("APIProvider is undefined or does not have baseURL property");
    return null;
  }
   let readArrayCallCount = 0;
  try {
    if (!APIProvider || !APIProvider.baseURL) {
      readArrayCallCount++;
      console.log(`ReadArray has been called ${readArrayCallCount} times`);
      console.error("APIProvider is not defined or baseURL is missing");
      toast.error("APIProvider is not defined or baseURL is missing");
      return null;
    }
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.ReadArray + "?arID=" + arId

      // `https://aicloud.agiline.com:7352/ReadArray/?arID=${arId}`
    );

    if (response.ok) {
      const resultData = await response.json();

      if (resultData.source !== null && resultData.source !== undefined) {
        const jsonData = [
          {
            arid: resultData.arID,
            arrayName: resultData.arrayName,
            description: resultData.description,
            dataSourceArID: resultData.dataSourceArID,
            Source: JSON.parse(resultData.source.replace(/~/g, '"')),
            IsTemplate: resultData.isTemplate,
            
          },
        ];

        return jsonData;
      } else {
        console.error("Error: source is null or undefined");
        toast.error("Error: source is null or undefined");

        throw new Error("Source is null or undefined");
      }
    } else {
      console.error("Error fetching data:", response.statusText);
      toast.error("Error fetching data:");
      return null;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    toast.error("Error fetching data:");

    if (error.message === "Source is null or undefined") {
      window.location.href = `/NotFound?arID=${arId}`;
    }
    return null;
  }
}

export async function WriteArray(
  treeData,
  arId,
  arrayName,
  description,
  category,
  htmlFileContent,
  dataSourceArID
) {
  console.log("writeArray treeData", treeData);

  if (!window.APIProvider || !window.APIProvider.baseURL) {
    console.error("APIProvider is undefined or does not have baseURL property");
    toast.error("APIProvider is undefined or does not have baseURL property");
    return null;
  }

  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify({
        ArID: arId,
        ArrayName: arrayName,
        Description: description,
        source: JSON.stringify(treeData[0].Source, null, 2),
        category: category,
        IsTemplate: "0",
        htmlOutput: htmlFileContent,
        dataSourceArID: dataSourceArID,
      }),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.WriteArray,
      request
    );
    console.log("respose on edit", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error ");
  }
}

export async function WriteArray1(
  treeData,
  arId,
  arrayName,
  description,
  category,
  htmlFileContent,
  dataSourceArID
) {
  console.log("writeArray treeData", treeData);

  if (!window.APIProvider || !window.APIProvider.baseURL) {
    console.error("APIProvider is undefined or does not have baseURL property");
    toast.error("APIProvider is undefined or does not have baseURL property");
    return null;
  }

  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify({
        ArID: arId,
        ArrayName: arrayName,
        Description: description,
        source: JSON.stringify(treeData[0].Source, null, 2),
        category: category,
        IsTemplate: "1",
        htmlOutput: htmlFileContent,
        dataSourceArID: dataSourceArID,
      }),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.WriteArray,
      // `https://aicloud.agiline.com:7352/WriteArray`,
      request
    );
    console.log("respose on edit", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
    toast.error("Error ");
  }
}
