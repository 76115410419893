import React from "react";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import BoxTemplate from "../BoxTemplate";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

const dataSetFormatMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "Format1" },
  { value: 1, label: "Format2" },
  { value: 2, label: "Format3" },
];
function EmailInfoBox({
  formData,
  handleChange,
}) {
  return (
    <BoxTemplate
      icon={<ContactMailOutlinedIcon style={{ marginRight: "0.5rem" }} />}
      title={"Email Info"}
      button={"Preview"}
      buttonOnClick={() => console.log("ButtonClicked")}
      subTitle={"Mail merge information."}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="subject"
            label="Subject"
            size="small"
            value={formData.subject}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="body"
            label="Body"
            size="small"
            value={formData.body}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Report</InputLabel>
            <Select
              label="Report"
              fullWidth
              size="small"
              name="report"
              value={formData.report}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem value="Select" disabled>
                {formData.module === "" ? "Please enter a module" : "Select"}
              </MenuItem>
              {dataSetFormatMenuItems
                .filter((option) => option.value !== -1)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="attachment"
            label="Attachment"
            size="small"
            value={formData.attachment}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </BoxTemplate>
  );
}

export default EmailInfoBox;
