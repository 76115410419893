import React from "react";
import FerryPicture from "../../assets/Trigger.png";
import CardComponent from "../../Components/landingPage/CardComponent";
export default function CallCardComponent({
  section,
  hideArray,
  admin,
  handleDialogToggle,
  IframeProvider,
  isMobile,
}) {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {section.defView === 9 &&
      !hideArray.includes(9) &&
      section.items &&
      isMobile ? (
        <CardComponent
          image={FerryPicture}
          size={350}
          position={"top"}
          title="Map"
          textSize={20}
          article={IframeProvider.baseURL + process.env.REACT_APP_IFRAME_MAP}
        />
      ) : (
        section.defView === 9 &&
        !hideArray.includes(9) &&
        section.items &&
        !isMobile && (
          <CardComponent
            image={FerryPicture}
            size={700}
            position={"left"}
            title="Map"
            textSize={20}
            article={IframeProvider.baseURL + process.env.REACT_APP_IFRAME_MAP}
            admin={false}
          />
        )
      )}
      {section.defView === 9 &&
        !hideArray.includes(9) &&
        section.items &&
        section.items.map((item, itemIndex) =>
          isMobile ? (
            <CardComponent
              key={itemIndex}
              image={item.mediaURL}
              title={item.header}
              text={item.description}
              textSize={20}
              position={"top"}
              size={350}
              article={item.link}
            />
          ) : (
            <CardComponent
              key={itemIndex}
              image={item.mediaURL}
              title={item.header}
              text={item.description}
              textSize={20}
              position={"left"}
              size={700}
              itemID={item.feedID}
              article={item.link}
              admin={admin}
              handleDialogToggle={handleDialogToggle}
            />
          )
        )}
    </div>
  );
}
