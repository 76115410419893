import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  MiniMap,
  MarkerType,
  Controls,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  Background,
  BackgroundVariant,
  ControlButton,
} from "reactflow";
import "reactflow/dist/style.css";
import DynamicNode from "../../nodes/DynamicNode.js";
import DesktopSidebar from "../../Components/diagramsComponents/sideBar/DesktopSideBar.js";
import "../../index.css";
// import { initialNodes, initialEdges } from "../../nodesAndEdges.js";
import { useDrop, useDragOver } from "../../functions/DragnDrop.js";
import { useSave, useRestore } from "../../functions/SaveAndRestore.js";
import { useLayoutedElements } from "../../functions/ElksAlignment.js";
import { useDeleteNode } from "../../functions/NodeFunctions.js";
import {
  useNodeDragStop,
  useUpdateNodeAndChildren,
  useNodeClick,
} from "../../functions/NodeFunctions.js";
import {
  useFlipArrow,
  useDeleteEdge,
  useEdgeClick,
} from "../../functions/EdgeFunctions.js";
import NavBar from "../../Components/navbarComponents/NavBar.js";
import { Theme } from "../../Components/IncludeFile/Theme.js";
import {
  GetToolProperties,
  GetDiagCardsInfo,
} from "../../apiCalls/DiagramsAPI.js";
import FloatingEdge from "../../functions/FloatingEdge.js";
import FloatingConnectionLine from "../../functions/ConnectionLine.js";
import DrawerComponent from "../../Components/diagramsComponents/DrawerComponent.js";
import CustomMarkers from "../../functions/MarkerFunctions.js";
import { ToastContainer } from "react-toastify";
import NodeDrawerComponent from "../../Components/diagramsComponents/nodeDrawerComponents/NodeDrawerComponent.js";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import { GetDiagTools } from "../../apiCalls/DiagramsAPI.js";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import themeProvider from "../../ThemeProvider.js";

// const flowKey = "flowJson";

// const fitViewOptions = { padding: 4 };

const nodeTypes = {
  dynamicNode: DynamicNode,
};

const edgeTypes = {
  floating: FloatingEdge,
};
const Diagrams = () => {
  //Get props
  const location = useLocation();
  let { diagTempID, diagramItem } = location.state || {};
  const [diagram, setDiagram] = useState({});
  const [fetched, setFetched] = useState(false);
  const [properties, setProperties] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [data, setData] = useState(null);
  const { setViewport } = useReactFlow();

  async function fetchTools(diagTempID) {
    const response = await GetDiagTools(diagTempID);
  
    return response.filter((item) => item.diagTempID === diagTempID.toString());
  }

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchTools(diagTempID);
        
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (!fetched && data) {
      getData();
    }
  }, [fetched, data]);
  async function getData() {
    try {
      const templates = await GetDiagCardsInfo();
      const foundObject = templates.find(
        (obj) => obj.diagID === diagramItem.diagID
      );
      setDiagram(foundObject);
      setNodes(foundObject.nodes !== "" ? JSON.parse(foundObject.nodes) : []);
      setEdges(foundObject.edges !== "" ? JSON.parse(foundObject.edges) : []);
      const fetchedData = [];
      for (let i = 0; i < data.length; i++) {
        const diagToolID = data[i].diagToolID; // Access the diagToolID property
        const props = await GetToolProperties(diagToolID);
        fetchedData.push(props);
        // Process the response for each tool ID as needed
      }
      setProperties(fetchedData); // Update the state after fetching all data
      setFetched(true); // Set fetched to true after fetching
      return fetchedData;
    } catch (error) {
      throw error; // Rethrow the error to be caught in useEffect
    }
  }
  // setViewport(
  //   diagram && diagram.viewPort && diagram.viewPort !== ""
  //     ? JSON.parse(diagram.viewPort)
  //     : { x: 0, y: 0, zoom: 1 }
  // );
  //for drawers
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [clickedEdge, setClickedEdge] = useState();
  const [isNodeDrawerOpen, setNodeDrawerOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  //shrink the whole sidebar
  const [showmenudsk, setShowdskmenu] = useState(false);
  //React flow variables
  // const [nodes, setNodes, onNodesChange] = useNodesState([]);
  // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const reactFlowWrapper = useRef(null);
  const [rfInstance, setRfInstance] = useState(null);
  const { getLayoutedElements } = useLayoutedElements();
  const { getNode } = useReactFlow();
  //Drag and Drop
  const onDrop = useDrop(reactFlowWrapper, rfInstance, setNodes);
  const onDragOver = useDragOver();
  //Save and Restore
  // const onSave = useSave(flowKey, rfInstance);
  const onSave = useSave(diagram, rfInstance, setDiagram);
  // const onRestore = useRestore(flowKey, setNodes, setEdges, setViewport);
  const onRestore = useRestore(diagram, setNodes, setEdges, setViewport);
  //node functions
  //handle node change (for controlling delete)
  const handleNodesChange = (changes) => {
    const nextChanges = changes.reduce((acc, change) => {
      // if this change is supposed to remove a node we want to validate it first
      if (change.type === "remove") {
        const node = getNode(change.id);

        // if the node can be removed, keep the change, otherwise we skip the change and keep the node
        if (node?.data[0]?.isDeletable !== "false") {
          return [...acc, change];
        }

        // change is skipped, node is kept
        return acc;
      }

      // all other change types are just put into the next changes arr
      return [...acc, change];
    }, []);

    // apply the changes we kept
    onNodesChange(nextChanges);
  };
  //recursive node movement
  const updateNodeAndChildren = useUpdateNodeAndChildren(nodes, edges);
  const handleNodeDragStop = useNodeDragStop(
    setNodes,
    nodes,
    edges,
    updateNodeAndChildren
  );
  //delete node
  const onDeleteNode = useDeleteNode(
    setEdges,
    nodes,
    edges,
    getIncomers,
    getOutgoers,
    getConnectedEdges,
    MarkerType
  );
  //edge functions
  //flip edge
  const handleFlipArrow = useFlipArrow(setEdges);
  //delete edge
  const handleDeleteEdge = useDeleteEdge(setEdges);
  //on edge click
  // const handleEdgeClick = useEdgeClick(handleFlipArrow);
  const handleEdgeClick = useEdgeClick(setClickedEdge, setDrawerOpen);
  const handleNodeClick = useNodeClick(setSelectedNode, setNodeDrawerOpen);

  //The default edge
  const defaultEdgeOptions = {
    // type: "smoothstep",
    type: "floating",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 30,
      height: 30,
    },
  };
  //creating a new edge
  // const onConnect = useCallback(
  //   (params) => setEdges((eds) => addEdge(params, eds)),
  //   []
  // );
  // const onConnect = useCallback(
  //   ({ source, target }) => {
  //     return setEdges((eds) =>
  //       nodes
  //         .filter((node) => node.id === source || node.selected)
  //         .reduce((eds, node) => addEdge({ source: node.id, target }, eds), eds)
  //     );
  //   },
  //   [nodes]
  // );

  const onConnect = useCallback(
    (params) => {
      const { source, target } = params;
      const sourceNode = nodes.find((node) => node.id === source);
      // Generate the edge ID using the specified format
      const edgeId = `e${source}-${target}${params.sourceHandle}${params.targetHandle}`;
      let edgeType = "floating";

      if (sourceNode?.data[0]?.nodeType === "0") {
        edgeType = "smoothstep";
      }
      return setEdges((eds) =>
        addEdge({ ...params, id: edgeId, type: edgeType }, eds)
      );
    },
    [nodes, setEdges]
  );

  //Form
  const navigate = useNavigate();

  const handleOpenClick = () => {
    // Navigate to the "/diagrams" route
    navigate("/Select");
  };

  //Function that control closing the edge Drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  //Function that control closing the node Drawer
  const handleNodeDrawerClose = () => {
    setNodeDrawerOpen(false);
  };

  const leftButton = {
    icon: Theme.Open,
    text: "Open", // color: "primary", // "primary" or "secondary"
    handleClick: () => {
      // Handle click for the left button
      handleOpenClick();
    },
  };

  const handleSearch = () => {
    console.log("search");
  };
  const rightButtons = [
    {
      icon: Theme.Save,
      text: "Save",
      color: "secondary",
      handleClick: () => {
        onSave();
      },
      // handleClick: (diagram, rfInstance) => {
      //   onSave(diagram, rfInstance);
      // },
    },
    {
      icon: Theme.Restore,
      text: "Restore",
      color: "secondary",
      handleClick: () => {
        onRestore();
      },
    },
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];

  const [backgroundColor, setBackgroundColor] = useState(
    themeProvider.palette.bg.lightWhite
  );

  const changeBackgroundColor = () => {
    setBackgroundColor(
      backgroundColor === themeProvider.palette.bg.lightWhite
        ? "#d3d3d3"
        : themeProvider.palette.bg.lightWhite
    );
  };
  return (
    <>
      <CustomMarkers />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavBar
        isSearchBar={true}
        searchQuery={""}
        handleSearch={handleSearch}
        leftButton={leftButton}
        rightButtons={rightButtons}
      />
      <div className={`dndflow`}>
        {data && properties.length > 0 && (
          <DesktopSidebar
            showmenudsk={showmenudsk}
            setShowdskmenu={setShowdskmenu}
            nodes={data}
            properties={properties}
          />
        )}
        <div
          className="reactflow-wrapper"
          ref={reactFlowWrapper}
          style={{
            display: "flex",
            width: "100%",
          }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={handleNodesChange}
            onNodesDelete={onDeleteNode}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setRfInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            // onNodeDragStop={handleNodeDragStop}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            // onNodeClick={handleNodeClick}
            onNodeContextMenu={handleNodeClick}
            connectionLineComponent={FloatingConnectionLine}
            onEdgeClick={handleEdgeClick}
            defaultEdgeOptions={defaultEdgeOptions}
            connectionMode="loose"
            edgeHitTestThreshold={100}
            style={{ background: backgroundColor }}
            // fitView
            // fitViewOptions={fitViewOptions}
          >
            {backgroundColor === themeProvider.palette.bg.lightWhite && (
              <Background />
            )}
            <Controls>
              <ControlButton
                onClick={() =>
                  getLayoutedElements({
                    "elk.algorithm": "layered",
                    "elk.direction": "RIGHT",
                    // "elk.direction": "LEFT",
                  })
                }
              >
                <AlignHorizontalLeftIcon />
              </ControlButton>
              <ControlButton
                onClick={() =>
                  getLayoutedElements({
                    "elk.algorithm": "layered",
                    "elk.direction": "DOWN",
                  })
                }
              >
                <AlignVerticalTopIcon />
              </ControlButton>
              <ControlButton
                onClick={() =>
                  getLayoutedElements({
                    "elk.algorithm": "org.eclipse.elk.force",
                  })
                }
              >
                <AlignVerticalCenterIcon />
              </ControlButton>
              <ControlButton onClick={changeBackgroundColor}>
                <FormatColorFillIcon />
              </ControlButton>
            </Controls>
            <MiniMap />
            {/* <TopButtons getLayoutedElements={getLayoutedElements} /> */}
          </ReactFlow>
          {isDrawerOpen && (
            <DrawerComponent
              isOpen={isDrawerOpen}
              onClose={handleDrawerClose}
              edges={edges}
              setEdges={setEdges}
              clickedEdge={clickedEdge}
            />
          )}
          {isNodeDrawerOpen && (
            <NodeDrawerComponent
              isOpen={isNodeDrawerOpen}
              onClose={handleNodeDrawerClose}
              nodes={nodes}
              setNodes={setNodes}
              selectedNode={selectedNode}
            />
          )}
        </div>
      </div>
    </>
  );
};

// export default Diagrams;
export default () => (
  <ReactFlowProvider>
    <Diagrams />
  </ReactFlowProvider>
);
