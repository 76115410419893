import React from "react";
import themeProvider from "../../ThemeProvider";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import SectionTitle from "../../Components/landingPage/SectionTitle";
import CallCircleComponent from "./CallCircleComponent";
import CallCardComponent from "./CallCardComponent";
import CallMiniCardComponent from "./CallMiniCardComponent";
import CallArticleComponent from "./CallArticleComponent";
import ProviderComponent from "../../Components/landingPage/ProviderComponent";
import BannerComponent from "../../Components/landingPage/BannerComponent";
import IFrameComponent from "../../Components/landingPage/IFrameComponent";
import LandingFooter from "../../Components/landingPage/LandingFooter";

let IframeProvider = window.IframeProvider;

export default function DesktopLandingPage({
  landingSectionsData,
  fetched,
  admin,
  handleDialogToggle,
}) {
  //Search URL params for IFrame from the url
  let [searchParams, setSearchParams] = useSearchParams();
  let IFRAME_DEFAULT_STYLE = { height: "25rem", width: "100%" };
  let guid = searchParams.get("guid");
  let width = searchParams.get("width");
  let height = searchParams.get("height");
  let caption = searchParams.get("caption");
  let hideAll = searchParams.get("hideAll");
  let hide = searchParams.get("hide");
  let show = searchParams.get("show");

  let showArray = show
    ? show.split(",").map((item) => parseInt(item.trim()))
    : [];
  let hideArray = hide
    ? hide.split(",").map((item) => parseInt(item.trim()))
    : [];

  // Create an array of numbers from 0 to 30
  let numbersArray = Array.from({ length: 31 }, (_, index) => index);

  // Filter out numbers that are already in 'showArray'
  numbersArray =
    showArray.length > 0
      ? numbersArray.filter((number) => !showArray.includes(number))
      : [];

  // Add the remaining numbers to 'hideArray'
  hideArray = hideArray.concat(numbersArray);

  // If you want to ensure that 'hideArray' only contains unique values, you can remove duplicates
  hideArray = Array.from(new Set(hideArray));
  const IFrameURLStyle = () => {
    var w, h;
    w = "100%";
    if (height !== null) {
      h = height;
    } else {
      h = IFRAME_DEFAULT_STYLE.height;
    }

    return { height: h, width: w };
  };

  const IframefromURL = () => {
    return (
      <>
        {caption !== null ? (
          <Typography
            className="text-center"
            style={{
              color: themeProvider.palette.primary.main,
              fontWeight: "bold",
              fontSize: "2rem",
            }}
          >
            {caption}
          </Typography>
        ) : (
          <></>
        )}
        <iframe
          style={IFrameURLStyle()}
          src={IframeProvider.baseURL + guid}
          title="form"
        ></iframe>
      </>
    );
  };

  var landingSections = landingSectionsData[0].sections;
  const pageName = landingSectionsData[0].page.name;

  return (
    <div style={{ backgroundColor: themeProvider.palette.landingBg.light }}>
      {fetched &&
        landingSections !== undefined &&
        hideAll === null &&
        landingSections.map((section, index) => (
          <div
            key={index}
            style={{
              backgroundColor:
                section.defView === 8
                  ? themeProvider.palette.landingBg.dark
                  : themeProvider.palette.landingBg.light,
            }}
          >
            <SectionTitle
              admin={admin}
              section={section}
              hideArray={hideArray}
              pageName={pageName}
              handleDialogToggle={handleDialogToggle}
            />
            <CallCircleComponent
              section={section}
              hideArray={hideArray}
              fetched={fetched}
              admin={admin}
              handleDialogToggle={handleDialogToggle}
            />
            <CallCardComponent
              section={section}
              hideArray={hideArray}
              admin={admin}
              handleDialogToggle={handleDialogToggle}
              IframeProvider={IframeProvider}
            />
            <CallMiniCardComponent
              section={section}
              hideArray={hideArray}
              fetched={fetched}
              admin={admin}
              handleDialogToggle={handleDialogToggle}
            />
            <CallArticleComponent
              section={section}
              hideArray={hideArray}
              fetched={fetched}
              admin={admin}
              handleDialogToggle={handleDialogToggle}
            />
            {section.defView === 11 &&
              !hideArray.includes(11) &&
              section.items && (
                <ProviderComponent
                  items={section.items}
                  admin={admin}
                  handleDialogToggle={handleDialogToggle}
                />
              )}
            {section.defView === 13 &&
            !hideArray.includes(13) &&
            guid === null ? (
              <BannerComponent
                section={section}
                size={50}
                textColor={themeProvider.palette.landingBg.bannerText}
                textSize={30}
                admin={admin}
                handleDialogToggle={handleDialogToggle}
              />
            ) : (
              section.defView === 13 &&
              !hideArray.includes(13) &&
              IframefromURL()
            )}
            {section.defView === 14 &&
              !hideArray.includes(14) &&
              section.items &&
              section.items.map((item, itemIndex) => (
                <IFrameComponent
                  key={itemIndex + 1000}
                  item={item}
                  admin={admin}
                />
              ))}
          </div>
        ))}
      {fetched && <LandingFooter />}
    </div>
  );
}
