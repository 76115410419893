import React, { useEffect, useState } from "react";
import {
  ThemeProvider,
  Grid,
  MenuItem,
  TextField,
  Select,
  Autocomplete,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import themeProvider from "../../../../ThemeProvider";
import { v4 as uuidv4 } from "uuid";

const FieldDropDownSet1 = [
  { value: "email", label: "Email", type: "varcher(50)" },
  { value: "city", label: "City", type: "varcher(10)" },
  { value: "country", label: "Country", type: "varcher(MAX)" },
];
const FieldDropDownSet2 = [
  { value: "isLoggedIn", label: "is logged in", type: "boolean" },
  { value: "loggedInDate", label: "logged in date", type: "date" },
  { value: "loggedInTime", label: "logged in Time", type: "timestamp" },
  {
    value: "loggedInDateAndTime",
    label: "logged in date and time",
    type: "datetime",
  },
];
const FieldDropDownSet3 = [
  { value: "integer", label: "Integer", type: "int" },
  { value: "float", label: "Float", type: "float" },
  { value: "money", label: "Money", type: "money" },
];

function Condition0({
  id,
  condition,
  originalDataSet,
  handleConditionChange,
  caller,
}) {
  console.log("condition ", condition);

  const handleDropdownChange = (fieldName, value) => {
    handleConditionChange(id, fieldName, value);
  };
  const [dropDowns, setDropDowns] = useState({
    // condition1DropDown: [
    //   { value: "email", label: "Email" },
    //   { value: "city", label: "City" },
    //   { value: "country", label: "Country" },
    // ],
    condition2DropDown: [
      { value: "=", label: "Equal to" },
      { value: ">", label: "Greater than" },
      { value: "<", label: "Less than" },
      { value: ">=", label: "Greater or equal" },
      { value: "<=", label: "Less or equal" },
      { value: "<>", label: "Not equal" },
      { value: "like", label: "Contains" },
      { value: "not like", label: "Does not contain" },
      { value: "like '%", label: "Starts with" },
      { value: "like '%'", label: "Ends with" },
      { value: "is null", label: "Is null" },
      { value: "is not null", label: "Is not null" },
      { value: "between", label: "Between" },
    ],
    condition2DropDownBoolean: [
      { value: "isTrue", label: "isTrue" },
      { value: "isFalse", label: "isFalse" },
    ],
    condition2DropDownDate: [
      { value: "is in the last", label: "is in the last" },
      { value: "is at least", label: "is at least" },
      { value: "is between", label: "is between" },
      { value: "is before", label: "is before" },
    ],
    condition2DropDownList: [
      { value: "contains", label: "contains" },
      { value: "doesn't contain", label: "doesn't contain" },
      { value: "is empty", label: "is empty" },
      { value: "has at least", label: "has at least" },
      { value: "has at most", label: "has at most" },
    ],
    typeDropDown: [
      { value: "text", label: "text" },
      { value: "number", label: "number" },
      { value: "date", label: "date" },
      { value: "boolean", label: "boolean" },
      { value: "list", label: "list" },
    ],
  });

  const [fieldDropDown, setFieldDropDown] = useState([]);
  const [selectedCondition1, setSelectedCondition1] = useState(
    condition.condition1
  );
  const fetchFieldDropDown = async (dataSet) => {
    console.log("dataSet ", dataSet);
    if (dataSet === 0) {
      return FieldDropDownSet1;
    } else if (dataSet === 1) {
      return FieldDropDownSet2;
    } else if (dataSet === 2) {
      return FieldDropDownSet3;
    } else {
      return [];
    }
  };

  useEffect(() => {
    const getFieldDropDown = async () => {
      let result;
      if (caller === 1) {
        result = await fetchFieldDropDown(condition.dataSet);
      } else {
        result = await fetchFieldDropDown(originalDataSet);
      }
      console.log("result ", result);
      setFieldDropDown(result);
      setSelectedCondition1("");
    };

    getFieldDropDown();
  }, [condition.dataSet, originalDataSet]);

  console.log("fieldDropDown", fieldDropDown);
  console.log("selectedCondition1", selectedCondition1);
  return (
    <ThemeProvider theme={themeProvider}>
      <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
        <Grid item xs={6}>
          <Autocomplete
            size="small"
            options={fieldDropDown || []}
            value={
              fieldDropDown.find(
                (option) => option.value === selectedCondition1
              ) || null
            }
            onChange={(event, newValue) => {
              const value = newValue ? newValue.value : "";
              setSelectedCondition1(value);
              handleDropdownChange("condition1", value);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Field" fullWidth />
            )}
            getOptionLabel={(option) => option.label}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            key={condition.type}
            size="small"
            options={
              condition.type === "boolean"
                ? dropDowns.condition2DropDownBoolean
                : condition.type === "date"
                  ? dropDowns.condition2DropDownDate
                  : condition.type === "list" // New condition for type "list"
                    ? dropDowns.condition2DropDownList // New options array for type "list"
                    : dropDowns.condition2DropDown
            }
            value={
              condition.type === "boolean"
                ? dropDowns.condition2DropDownBoolean.find(
                    (option) => option.value === condition.condition2Boolean
                  )
                : condition.type === "date"
                  ? dropDowns.condition2DropDownDate.find(
                      (option) => option.value === condition.condition2Date
                    )
                  : condition.type === "list" // New condition for type "list"
                    ? dropDowns.condition2DropDownList.find(
                        (option) => option.value === condition.condition2List
                      )
                    : dropDowns.condition2DropDown.find(
                        (option) => option.value === condition.condition2
                      )
            }
            onChange={(event, newValue) =>
              handleDropdownChange(
                condition.type === "boolean"
                  ? "condition2Boolean"
                  : condition.type === "date"
                    ? "condition2Date"
                    : condition.type === "list" // New condition for type "list"
                      ? "condition2List"
                      : "condition2",
                newValue ? newValue.value : ""
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  condition.type === "boolean"
                    ? "Boolean"
                    : condition.type === "date"
                      ? "Date"
                      : condition.type === "list" // New label for type "list"
                        ? "List"
                        : "Operand"
                }
                fullWidth
              />
            )}
            getOptionLabel={(option) => option.label}
          />
        </Grid>
      </Grid>
      {condition.type !== "boolean" &&
        condition.condition2 !== "is null" &&
        condition.condition2 !== "is not null" && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={
                condition.type === "date" || condition.condition2 === "between"
                  ? 5.5
                  : 12
              }
            >
              <TextField
                fullWidth
                type={
                  condition.type === "number" || condition.type === "date"
                    ? "number"
                    : "text"
                }
                size="small"
                name="value1"
                value={condition.value1}
                onChange={(event) =>
                  handleConditionChange(id, "value1", event.target.value)
                }
                label="Value"
              />
            </Grid>
            {condition.condition2 === "between" && (
              <>
                <Grid item xs={1} sm={1}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    AND
                  </div>
                </Grid>
                <Grid item xs={11} sm={5.5}>
                  <TextField
                    fullWidth
                    type={
                      condition.type === "number" || condition.type === "date"
                        ? "number"
                        : "text"
                    }
                    size="small"
                    name="value2"
                    value={condition.value2}
                    onChange={(event) =>
                      handleConditionChange(id, "value2", event.target.value)
                    }
                    label="Value2"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={condition.type === "date" ? 6 : 12}>
              {condition.type === "date" && (
                <FormControl fullWidth size="small">
                  <InputLabel style={{ textAlign: "center" }}>
                    Frequency
                  </InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    name="value2"
                    value={condition.value2}
                    onChange={(event) =>
                      handleConditionChange(id, "value2", event.target.value)
                    }
                    label={"Frequency"}
                  >
                    {["days", "weeks", "hours"].map((frequency) => (
                      <MenuItem key={frequency} value={frequency}>
                        {frequency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        )}
      {/* <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
        <Grid item xs={6}>
          <Autocomplete
            size="small"
            options={dropDowns.typeDropDown}
            value={dropDowns.typeDropDown.find(
              (option) => option.value === condition.type
            )}
            onChange={(event, newValue) =>
              handleDropdownChange("type", newValue ? newValue.value : "")
            }
            renderInput={(params) => (
              <TextField {...params} label="Type" fullWidth />
            )}
            getOptionLabel={(option) => option.label}
          />
        </Grid>
        <Grid
          item
          xs={6}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            // onClick={() => handleRemove(id)}
          >
            Advanced
          </Button>
        </Grid>
      </Grid> */}
      {/* <Advanced module={ }/> */}
    </ThemeProvider>
  );
}

export default Condition0;
