import React, { useState, useEffect, useRef, useCallback } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DialogComponent from "./DialogComponent";
import { ReadArray, WriteArray, WriteArray1 } from "../Api/ApiCalls";
import TreeItem from "./TreeItem";
import "./RecursiveTreeView.css";
import Controls from "../IncludeFile/Controls";
import { Theme } from "../IncludeFile/Theme";
import ReactDOMServer from "react-dom/server";
import CustomHtmlTag from "./CustomHtmlTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams, useLocation } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import GeneralSection from "./GeneralSection";
import FormTopSection from "./FormTopSection";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { CircularProgress } from "@mui/material";

const RecursiveTreeView = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedItemID, setSelectedItemID] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [renderedControls, setRenderedControls] = useState([]);
  const [selectedControl, setSelectedControl] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuStyles, setContextMenuStyles] = useState("");
  const [customStyles, setCustomStyles] = useState({});
  const [editedName, setEditedName] = useState(" ");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [editedData, setEditedData] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [numberOfRadioInputs, setNumberOfRadioInputs] = useState(1);
  const [numberOfCheckbox, setNumberOFCheckbox] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [inputValueRadio, setInputValueRadio] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [showmenu, setShowmenu] = useState(false);
  const [showtab, setShowtab] = useState(false);
  const [showtabdsk, setShowdsktab] = useState(false);
  const [showmenudsk, setShowdskmenu] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [checkboxPosition, setCheckboxPosition] = useState(1);
  const [isSwitched, setIsSwitched] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedOptionControl, setSelectedOptionControl] = useState("");
  const [tabSelect, settabSelect] = React.useState("Text");
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [selectedOptioncheck, setSelectedOptioncheck] = useState("false");
  const [selectedPositioncheck, setSelectedPositioncheck] = useState("right");
  const [selectedOptionmedia, setSelectedOptionmedia] = useState(1);
  const [selectedOptionSwitchcheck, setSelectedOptionSwitchcheck] =
    useState("false");
  const [fieldNames, setFieldNames] = useState([]);
  const [fieldNamestable, setFieldNamestable] = useState([]);
  const [displayClicked, setDisplayClicked] = useState(false);
  const [IconsClicked, setIconClicked] = useState(false);
  const [ContainerIcon, setContainerIcon] = useState(false);
  const [InputClicked, setInputClicked] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [headerCount, setHeaderCount] = useState(4);
  const [cellCount, setCellCount] = useState(4);
  const [tableHeader, setTableHeader] = useState("TableHeader");
  const [sourceData, setSourceData] = useState("");
  const [defaultStyle, setDefaultStyle] = useState("");
  const [inputType, setInputType] = useState("Date");
  const [inputText, setInputText] = useState("");
  const [imageText, setImageText] = useState("");

  const Navigate = useNavigate();
  const [tabValue, setTabValue] = useState("1");

  const inputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [tableProp, settableProp] = useState("");

  const [typeofcontrol, setTypeOFControl] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const [currentTable, setCurrentTable] = useState("");
  const rightsidetabs = [
    { label: "General", value: "1" },
    { label: "Style", value: "2" },
  ];

  const selectedTab = rightsidetabs.find((tab) => tab.value === tabValue);
  const rightsideStyletab = selectedTab ? selectedTab.value : null;



  useEffect(() => {
    if (selectedItem) {
      setTypeOFControl(selectedItem.type);
    }
  }, [selectedItem]);

  useEffect(() => {
    setSelectedControlType(typeofcontrol);
  }, [typeofcontrol]);

  const [selectedControlType, setSelectedControlType] = useState(typeofcontrol);

  const [readArrayStyles, setReadArrayStyles] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
        APIProvider.baseURL + APIProvider.recordPath.ReadArray + "?arID=" + 10
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const fetchedData = JSON.parse(data.source);
      setReadArrayStyles(fetchedData);

      const styledDataWithIds = fetchedData.map((style, index) => ({
        id: index + 1,
        ...style,
      }));

      setDefaultStyle(styledDataWithIds);
      setIsDataFetched(true);
    } catch (error) {
      console.error("Error fetching source data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    console.log("Fetching data...");
    fetchData();
  }, [fetchData]);



  const handleControlTypeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedControlType(selectedValue);

    const selectedItem = Controls.flatMap((category) => category.items).find(
      (item) => item.controltype === selectedValue
    );

    setDefaultStyle(readArrayStyles);

    const defaultStyles = Array.isArray(defaultStyle)
      ? defaultStyle.find((style) => style.styleType === selectedItem.styleType)
      : null;

    const defaultArray = defaultStyles.style;

    const mergedStyles = {
      ...defaultArray,
    };

    setCustomStyles(mergedStyles);
 
    setEditedName(selectedValue);

    setSelectedItem((prevSelectedItem) => ({
      ...prevSelectedItem,
      controltype: selectedValue,
      type: selectedValue,
      styleType: selectedValue,
 
      htmlTag: selectedItem.htmlTag,
      styles: JSON.stringify({ ...mergedStyles }, null, 2),
      name: selectedValue,
      controlName: selectedValue,
      controlChange: selectedValue,
    }));

    const updatedTreeDataControlType = updateTreeDataControlType(
      treeData,
      selectedItemID,
      selectedValue,
      selectedItem.htmlTag,
      JSON.stringify({ ...mergedStyles }, null, 2),
      selectedControlType,
      selectedItem
    );
    setTreeData(updatedTreeDataControlType);
  };

  const updateTreeDataControlType = (
    treeData,
    selectedItemID,
    selectedValue,
    htmlTag,
    styles,
    name,
    selectedControlType
  ) => {
    return treeData.map((item) => {
      const updatedSourceControlType = item.Source.map((source) => ({
        ...source,
        control: updateControlsControlType(
          source.control,
          selectedItemID,
          selectedValue,
          htmlTag,
          styles,
          name,
          selectedControlType
        ),
      }));
      return { ...item, Source: updatedSourceControlType };
    });
  };

  const updateControlsControlType = (
    controls,
    selectedItemID,
    selectedValue,
    htmlTag,
    styles,
    name,
    selectedControlType,
    selectedItem
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        let dataValue = selectedValue;
        if (selectedValue === "Checkbox") {
          dataValue = "data";
        }
        return {
          ...c,
          controltype: selectedValue,
          type: selectedValue,
          styleType: selectedValue,
          
          htmlTag: htmlTag,
          styles: styles,
          name: selectedValue,
          controlName: selectedValue,
          controlChange: selectedValue,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsControlType(
            c.control,
            selectedItemID,
            selectedValue,
            htmlTag,
            styles,
            name,
            selectedControlType,
            selectedItem
          ),
        };
      }
      return c;
    });
  };

  const handleSwitchChange = (
    newValue,
    switchId,
    selectedOptionSwitchcheck
  ) => {
    const updateTreeDataSwitch1 = updateTreeDataSwitch(
      treeData,
      switchId,
      newValue,
      selectedOptionSwitchcheck
    );
    setTreeData(updateTreeDataSwitch1);
    setSelectedOptionSwitchcheck(newValue ? "true" : "false");
  };

  const updateTreeDataSwitch = (
    treeData,
    switchId,
    newValue,
    selectedOptionSwitchcheck
  ) => {
    return treeData.map((item) => {
      const updatedSourceSwitch = item.Source.map((source) => ({
        ...source,
        control: updateControls1Switch(
          source.control,
          switchId,
          newValue,
          selectedOptionSwitchcheck
        ),
      }));
      return { ...item, Source: updatedSourceSwitch };
    });
  };

  const updateControls1Switch = (
    controls,
    switchId,
    newValue,
    selectedOptionSwitchcheck
  ) => {
    return controls.map((c) => {
      if (c.id === switchId) {
        return {
          ...c,
          switchcheck: newValue,
          switchoption: selectedOptionSwitchcheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControls1Switch(
            c.control,
            switchId,
            newValue,
            selectedOptionSwitchcheck
          ),
        };
      }
      return c;
    });
  };

  const handleSelectChange = (e, optionId) => {
    const selectedValue = e.target.value;
    const updateTreeDataSelect = updateTreeData1Select(
      treeData,
      optionId,
      selectedValue
    );

    setTreeData(updateTreeDataSelect);
  };

  const updateTreeData1Select = (
    treeData,
    optionId,
    selectedValue,
    selectedOptionControl
  ) => {
    return treeData.map((item) => {
      const updatedSourceSelect = item.Source.map((source) => ({
        ...source,
        control: updateControls1Select(
          source.control,
          optionId,
          selectedValue,
          selectedOptionControl
        ),
      }));
      return { ...item, Source: updatedSourceSelect };
    });
  };

  const updateControls1Select = (
    controls,
    optionId,
    selectedValue,
    selectedOptionControl
  ) => {
    return controls.map((c) => {
      if (c.id === optionId) {
        const dataArr = c.data.split(",");

        const newDataArr = [
          selectedValue,
          ...dataArr.filter((item) => item !== selectedValue),
        ];

        return {
          ...c,
          data: newDataArr.join(","),
        };
      }
      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControls1Select(
            c.control,
            optionId,
            selectedValue,
            selectedOptionControl
          ),
        };
      }
      return c;
    });
  };

  const handleCheckboxPositonChange = (newValue, checkboxId) => {
    const updatedTreeData = updateTreeDataPosition(
      treeData,
      checkboxId,
      newValue,
      newValue ? "right" : "left"
    );
    setTreeData(updatedTreeData);
    setSelectedPositioncheck(newValue ? "right" : "left");
  };

  const updateTreeDataPosition = (
    treeData,
    checkboxId,
    newValue,
    selectedPositioncheck
  ) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsPosition(
          source.control,
          checkboxId,
          newValue,
          selectedPositioncheck
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsPosition = (
    controls,
    checkboxId,
    newValue,
    selectedPositioncheck
  ) => {
    return controls.map((c) => {
      if (c.id === checkboxId) {
        return {
          ...c,
          media: newValue,
          checkposition: selectedPositioncheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsPosition(
            c.control,
            checkboxId,
            newValue,
            selectedPositioncheck
          ),
        };
      }
      return c;
    });
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = async (event, imageID) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = document.createElement("img");

    img.onload = () => {
      const maxWidth = 200;
      const maxHeight = 200;

      let width = img.width;
      let height = img.height;

      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;

        if (width > height) {
          width = maxWidth;
          height = width / aspectRatio;
        } else {
          height = maxHeight;
          width = height * aspectRatio;
        }
      }
      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();

          reader.onload = () => {
            const imageUrl = reader.result;

            const updateTreeData = updateTreeDataImage(
              treeData,
              imageID,
              imageUrl
            );
            setTreeData(updateTreeData);
          };

          reader.readAsDataURL(blob);
        },
        "image/jpeg",
        0.1
      );
    };

    img.src = URL.createObjectURL(file);
  };

  const updateTreeDataImage = (treeData, imageID, imageUrl) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsImage(source.control, imageID, imageUrl),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsImage = (controls, imageID, imageUrl) => {
    return controls.map((c) => {
      if (c.id === imageID) {
        return {
          ...c,
          media: imageUrl,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsImage(c.control, imageID, imageUrl),
        };
      }
      return c;
    });
  };

  const fixedIframeUrl = "https://aicloud.agiline.com";

  const [url, setURl] = useState(fixedIframeUrl);

  const handleIFrameClick = () => {
    const iframeUrl = inputRef.current.value;
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (urlRegex.test(iframeUrl)) {
      const updateTreeData = updateTreeDataIFrame(
        treeData,
        selectedItem.id,
        iframeUrl
      );
      setTreeData(updateTreeData);
    } else {
      toast.error("Please enter a valid URL");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleIFrameClick();
    }
  };

  const handleIFrameChange = async (event, iframeID) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const iframeUrl = reader.result;

      const updateTreeData = updateTreeDataIFrame(
        treeData,
        iframeID,
        iframeUrl
      );
      setTreeData(updateTreeData);
    };

    reader.readAsDataURL(file);
  };

  const updateTreeDataIFrame = (treeData, iframeID, iframeUrl) => {
    const updatedTreeData = treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsIFrame(source.control, iframeID, iframeUrl),
      }));
      return { ...item, Source: updatedSource };
    });
    return updatedTreeData;
  };

  const updateControlsIFrame = (controls, iframeID, iframeUrl) => {
    return controls.map((c) => {
      if (c.id === iframeID) {
        return {
          ...c,
          data: iframeUrl,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsIFrame(c.control, iframeID, iframeUrl),
        };
      }
      return c;
    });
  };

  const [selectedValue, setSelectedValue] = useState("");


  const [RadioPosition, setRadioPosition] = useState(1);

  const handleRadioPositionChange = (position, uniqueId) => {
    const newPosition = parseInt(position);
    const updateTreeData = updateTreeDataRadioPosition(
      treeData,
      uniqueId,
      newPosition
    );
    setTreeData(updateTreeData);
  };

  const updateTreeDataRadioPosition = (treeData, uniqueId, newPosition) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsRadioPosition(
          source.control,
          uniqueId,
          newPosition
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsRadioPosition = (controls, uniqueId, newPosition) => {
    return controls.map((c) => {
      if (c.id === uniqueId) {
        return {
          ...c,
          media: newPosition,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsPosition(c.control, uniqueId, newPosition),
        };
      }
      return c;
    });
  };

  const updateControlCheck = (controls, selectedItem, uniqueKey) => {
    return controls.map((c) => {
      if (c.uniqueKey && c.uniqueKey.includes(selectedItem)) {
        const updatedCheck = {
          ...c.check,
          [selectedItem]: !c.check[selectedItem],
        };

        return {
          ...c,
          check: updatedCheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlCheck(c.control, selectedItem, uniqueKey),
        };
      }
      return c;
    });
  };

  const handleRadioChangeData = (e, selectedItem, uniqueKey) => {
    setSelectedValue(selectedItem);

    setTreeData((prevTreeData) => {
      const updatedTreeData = prevTreeData.map((item) => ({
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlCheckData(
            source.control,
            selectedItem,
            uniqueKey
          ),
        })),
      }));

      return updatedTreeData;
    });
  };

  const updateControlCheckData = (controls, selectedItem, uniqueKey) => {
    return controls.map((c) => {
      if (c.uniqueKey && c.uniqueKey.includes(selectedItem)) {
        const updatedCheckData = {
          ...c.data,
          [selectedItem]: !c.data[selectedItem],
        };

        return {
          ...c,
          data: updatedCheckData,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlCheckData(c.control, selectedItem, uniqueKey),
        };
      }
      return c;
    });
  };



  const handleCheckboxChange = (newValue, checkboxId, selectedOptioncheck) => {
    const updatedTreeData = updateTreeData1(
      treeData,
      checkboxId,
      newValue,
      selectedOptioncheck
    );
    setTreeData(updatedTreeData);
    setSelectedOptioncheck(newValue ? "true" : "false");
  };

  const updateTreeData1 = (
    treeData,
    checkboxId,
    newValue,
    selectedOptioncheck
  ) => {
    return treeData.map((item) => {
      console.log("treeData",treeData)
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControls1(
          source.control,
          checkboxId,
          newValue,
          selectedOptioncheck
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControls1 = (
    controls,
    checkboxId,
    newValue,
    selectedOptioncheck
  ) => {
    return controls.map((c) => {
      console.log("controls",controls)
      if (c.id === checkboxId) {
        return {
          ...c,
          check: newValue,
          option: selectedOptioncheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControls1(
            c.control,
            checkboxId,
            newValue,
            selectedOptioncheck
          ),
        };
      }
      return c;
    });
  };


  const handleRadioChange = (newValue, radioId, selectedOptioncheck) => {
    const updatedTreeData = updateTreeDataRadio1(
      treeData,
      radioId,
      newValue,
      selectedOptioncheck
    );
    setSelectedOptioncheck(newValue ? "true" : "false");
    setTreeData(updatedTreeData);
 
  };
  
  const updateTreeDataRadio1 = (treeData, radioId, newValue, selectedOptioncheck) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsRadio1(
          source.control,
          radioId,
          newValue,
          selectedOptioncheck
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };
  
  const updateControlsRadio1 = (controls, radioId, newValue, selectedOptioncheck) => {
    let parentid = null;
  
    controls.forEach((c) => {
      if (c.id === radioId) {
        parentid = c.parentid;
      }
    });
  
    return controls.map((c) => {
      if (c.id === radioId) {
        return {
          ...c,
          check: newValue,
          option: selectedOptioncheck,
        };
      }
      if (c.parentid === parentid && c.controltype === 'Radio') {
        return {
          ...c,
          check: false,
          option: selectedOptioncheck,
        };
      }
  
      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsRadio1(
            c.control,
            radioId,
            newValue,
            selectedOptioncheck
          ),
        };
      }
  
      return c;
    });
  };
  const handleChange = (event) => {
    const newTab = event.target.value;
    settabSelect(newTab);
    const updatedTreeData = updateTreeDatanewTab(
      treeData,
      selectedItemID,
      newTab
    );

    setTreeData(updatedTreeData);
  };

  const updateTreeDatanewTab = (treeData, selectedItemID, newTab) => {
    return treeData.map((item) => {
      const updatedSourcenewTab = item.Source.map((source) => ({
        ...source,
        control: updateControlsnewTab(source.control, selectedItemID, newTab),
      }));

      return { ...item, Source: updatedSourcenewTab };
    });
  };

  const updateControlsnewTab = (controls, selectedItemID, newTab) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        return {
          ...c,
          tab: newTab,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsnewTab(c.control, selectedItemID, newTab),
        };
      }
      return c;
    });
  };

  const [Radios, setRadios] = useState([]);

  const handleInputChangeRadio = (e) => {
    let value = parseFloat(e.target.value);
    value = Math.min(Math.max(value, 1), 100);
    setInputValueRadio(value);
  };

  const handleAddControlRadio = () => {
    const numberToAdd = parseFloat(inputValueRadio);

    const newRadio = Array.from({ length: numberToAdd }, (_, index) => ({
      key: Radios.length + index + 1,
      value: Radios.length + index + 1,
    }));

    setRadios((prevRadios) => [...prevRadios, ...newRadio]);
    setInputValueRadio("");
    handleAddControl("Radio", {}, newRadio);
  };

  const handleInputChange = (e) => {
    let value = parseFloat(e.target.value);
    value = Math.min(Math.max(value, 1), 100);
    setInputValue(value);
  };

  const handleAddControlCheckbox = (
    checkValue,
    selectedCheckboxId,
    checkboxPosition
  ) => {
    let numberToAdd = parseFloat(inputValue);

    const newCheckboxes = Array.from({ length: numberToAdd }, (_, index) => ({
      key: checkboxes.length + index + 1,
      value: checkboxes.length + index + 1,
    }));

    setCheckboxes((prevCheckboxes) => [...prevCheckboxes, ...newCheckboxes]);
    setInputValue("");
    handleAddControl(
      "Checkbox",
      { check: checkValue },
      newCheckboxes,
      selectedCheckboxId
    );
  };

  const handleEditChange = (event) => {
    let newValue = event.target.value;
    
    if (/^"(.*)"$/g.test(newValue)) {
      newValue = newValue.replace(/^"(.*)"$/, "'$1'");
    }

    

    setEditedData(newValue);
    setSelectedOption(newValue);
    const updatedTreeData = updateTreeDatanewValue(
      treeData,
      selectedItemID,
      newValue
    );
    setTreeData(updatedTreeData);
  };

  const updateTreeDatanewValue = (treeData, selectedItemID, newValue) => {
    return treeData.map((item) => {
      const updatedSourcenewValue = item.Source.map((source) => ({
        ...source,
        control: updateControlsnewValue(
          source.control,
          selectedItemID,
          newValue
        ),
      }));
      return { ...item, Source: updatedSourcenewValue };
    });
  };

  const updateControlsnewValue = (
    controls,
    selectedItemID,
    newValue,
    selectedItemType
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        if (selectedItem.type === "Date") {
          return {
            ...c,
            inputText: newValue,
          };
        } else if (
          selectedItem.type === "Image" ||
          selectedItem.type === "Switch" ||
          selectedItem.type === "Iframe"
        ) {
          return {
            ...c,
            imageText: newValue,
          };
        } else if (selectedItem.type === "Checkbox") {
          return {
            ...c,
            data: newValue,
          };
        } else {
          return {
            ...c,
            data: newValue,
          };
        }
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsnewValue(
            c.control,
            selectedItemID,
            newValue,
            selectedItemType
          ),
        };
      }
      return c;
    });
  };

  const handleEditChangeforTable = (event) => {
    let newValue = event.target.value;

    if (/^"(.*)"$/g.test(newValue)) {
      newValue = newValue.replace(/^"(.*)"$/, "'$1'");
    }

    setEditedData(newValue);
    setSelectedOption(newValue);
    const updatedTreeDataforTable = updateTreeDatanewValueforTable(
      treeData,
      selectedItemID,
      newValue
    );
    setTreeData(updatedTreeDataforTable);
  };

  const updateTreeDatanewValueforTable = (
    treeData,
    selectedItemID,
    newValue
  ) => {
    return treeData.map((item) => {
      const updatedSourcenewValueforTable = item.Source.map((source) => ({
        ...source,
        control: updateControlsnewValueforTable(
          source.control,
          selectedItemID,
          newValue
        ),
      }));
      return { ...item, Source: updatedSourcenewValueforTable };
    });
  };

  function checkOptionMatch(control, option, newValue) {
    if (control.tableHeader === option) {
      control.data = newValue;
    }

    if (control.control && Array.isArray(control.control)) {
      control.control.forEach((childControl) =>
        checkOptionMatch(childControl, option, newValue)
      );
    }
  }

  const updateControlsnewValueforTable = (
    controls,
    selectedItemID,
    newValue,
    selectedItemType
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        let updatedValue = newValue;
        if (c.htmlTag === "td") {
          updatedValue = `{${newValue}}`;
          setEditedData(updatedValue);
          setSelectedOption(updatedValue);
          treeData.forEach((root) => {
            root.Source.forEach((source) => {
              source.control.forEach((control) => {
                checkOptionMatch(control, c.option, newValue);
              });
            });
          });
        }
        if (selectedItemType === "Date") {
          return {
            ...c,
            inputText: newValue,
          };
        } else if (
          selectedItemType === "Image" ||
          selectedItemType === "Switch" ||
          selectedItemType === "Iframe"
        ) {
          return {
            ...c,
            imageText: newValue,
          };
        } else if (selectedItemType === "Checkbox") {
          return {
            ...c,
            data: newValue,
          };
        } else {
          return {
            ...c,
            data: updatedValue,
          };
        }
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsnewValueforTable(
            c.control,
            selectedItemID,
            newValue,
            selectedItemType
          ),
        };
      }
      return c;
    });
  };

const handlePopupClick = () => {
    setOpen(true);
    setPopupVisible(true);
  };

  const handleAgree = () => {
    let isValid = true;
    let errorMessage = null;
    let regex = null;

    switch (selectedItem.type) {
      case "TextBox":
        switch (tabSelect) {
          case "Phone Number":
            regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            errorMessage = "Invalid phone number";
            break;
          case "Email":
            regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            errorMessage = "Invalid email address";
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    if (regex) {
      isValid = regex.test(editedData);
    }

    if (isValid) {
      const updatedTreeData = updateTreeData(
        treeData,
        selectedItemID,
        editedData
      );

      setTreeData(updatedTreeData);
      handleClose();
    } else {
      toast.error(errorMessage);
    }
  };

  const updateTreeData = (
    treeData,
    selectedItemID,
    editedData,
    selectedOption
  ) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControls(
          source.control,
          selectedItemID,
          editedData,
          selectedOption
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControls = (
    controls,
    selectedItemID,
    editedData,
    selectedOption
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        return {
          ...c,
          data: editedData || selectedOption,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControls(
            c.control,
            selectedItemID,
            editedData,
            selectedOption
          ),
        };
      }
      return c;
    });
  };

  const handleEditChangeName1 = (e) => {
    let newValue = e.target.value;

    if (/^"(.*)"$/g.test(newValue)) {
      newValue = newValue.replace(/^"(.*)"$/, "'$1'");
    }

    setEditedName(newValue);

    if (selectedItem) {
      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        name: newValue,
        tableHeader: newValue,
      }));
    }
  };

  const collectControlNames = (controls, names) => {
    controls.forEach((control) => {
      names.push(control.name);
      if (control.control && control.control.length > 0) {
        collectControlNames(control.control, names);
      }
    });
  };

  const controlNames = [];

  if (treeData.length > 0) {
    treeData.forEach((item) => {
      if (item.Source && item.Source.length > 0) {
        item.Source.forEach((source) => {
          if (source.control && source.control.length > 0) {
            collectControlNames(source.control, controlNames);
          }
        });
      }
    });
  }

  const handleRenameConfirm = () => {
    if (!selectedItem) {
      return;
    }

    const updatedTreeName = updateTreeName(
      treeData,
      selectedItemID,
      editedName
    );
    setTreeData(updatedTreeName);
    setIsEditing(!isEditing);

    setSelectedItem((prevSelectedItem) => ({
      ...prevSelectedItem,
      name: editedName,
    }));
  };

  const updateTreeName = (treeData, selectedItemID, editedName) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((sourceItem) => {
          return updateControlsName(sourceItem, selectedItemID, editedName);
        }),
      };
    });
  };

  const updateControlsName = (controls, selectedItemID, editedName) => {
    if (controls.id === selectedItemID) {
      return {
        ...controls,
        name: editedName,
      };
    }

    if (controls.control) {
      return {
        ...controls,
        control: controls.control.map((controlItem) => {
          return updateControlsName(controlItem, selectedItemID, editedName);
        }),
      };
    }

    return controls;
  };

  const handleClose = () => {
    setOpen(false);
    setShowPopup(false);
  };


  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    setContainerIcon(true);
    setIconClicked(true);
    setInputClicked(true);
    setDisplayClicked(true);
    if (window.innerWidth > 991) {
      setShowdskmenu(false);
      setShowmenu(false);
    }
    if (window.innerWidth < 992) {
      setShowmenu(true);
      setShowdskmenu(false);
    }
  };

  const filterTreeData = (data, query) => {
    if (!query) {
      return data;
    }

    return data
      .map((item) => {
        const filteredChildren = item.control
          ? filterTreeData(item.control, query)
          : [];

        if (
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          filteredChildren.length > 0
        ) {
          return {
            ...item,
            control: filteredChildren,
          };
        }

        return null;
      })
      .filter(Boolean);
  };

  const onDragEnd = (result) => {
    if (!result.destination || !result.source) {
      return;
    }

    const updatedTreeData = [...treeData];

    const sourceId = result.source.droppableId;
    const destinationId = result.destination.droppableId;

    const sourceParent = findNodeById(updatedTreeData[0].Source[0], sourceId);
    const destinationParent = findNodeById(
      updatedTreeData[0].Source[0],
      destinationId
    );

    if (sourceParent && destinationParent) {
      const [draggedItem] = sourceParent.control.splice(result.source.index, 1);

      const newId =
        destinationParent.id + "." + (result.destination.index + 1).toString();

      draggedItem.id = newId;
      draggedItem.parentid = destinationParent.id;

      if (draggedItem.styleid === null) {
        draggedItem.styleid = calculateNextStyleId(destinationParent.control);
      }

      destinationParent.control.splice(
        result.destination.index,
        0,
        draggedItem
      );

      updateIDsInTree(updatedTreeData);

      setTreeData(updatedTreeData);
    }
  };

  const updateIDsInTree = (treeData) => {
    treeData.forEach((root, index) => {
      root.Source[0].id = index.toString();
      updateChildIDs(root.Source[0].control, index.toString());
    });
  };

  const updateChildIDs = (children, parentID) => {
    children.forEach((child, index) => {
      if (child && child.id) {
        child.id = `${parentID}.${index}`;
        child.parentid = parentID;
        if (child.control && child.control.length > 0) {
          updateChildIDs(child.control, child.id);
        }
      }
    });
  };

  const findNodeById = (node, id) => {
    if (node.id === id) {
      return node;
    }
    if (node.control && node.control.length > 0) {
      for (const childNode of node.control) {
        const foundNode = findNodeById(childNode, id);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };

  const handleAddItem = () => {
    setTreeData([...treeData]);
  };

  const handleControls = () => {
    setIsBoxVisible(!isBoxVisible);
    setOpenDialog(true);
    setShowdskmenu(false);
  };

  const handleMenuButtonClick = () => {
    setShowmenu(!showmenu);
    setShowdskmenu(false);
  };

  const onClickforFocus = (e) => {
    e.preventDefault();
    const nodeListfocused = document.querySelectorAll(".focusingClass")[0];
    const element = document.getElementById(e.target.id);

    if (element && element.hasAttribute("styleid")) {
      if (nodeListfocused) {
        nodeListfocused.classList.remove("focusingClass");
      }

      element.classList.add("focusingClass");
      // document.querySelectorAll(".focusingClass")[0].focus();
      handleSelectItem(e.target.id);
      setSelectedNode(e.target);
      const navElement = document.getElementById("nav_" + e.target.id);
      if (navElement) {
        navElement.click();
      }

      setCurrentTable(
        document.getElementById(e.target.id).getAttribute("styleid")
      );
    }
  };

  const handleTabshwButtonClick = () => {
    setShowtab(!showtab);
    setShowdsktab(false);
    
  };

  const handleMenushwDskButtonClick = () => {
    setShowdskmenu(!showmenudsk);
    setShowmenu(false);
  };

  const handleTabshwDskButtonClick = () => {
    setShowdsktab(!showtabdsk);
    setShowtab(false);
  };

  const calculateNextStyleId = () => {
    const deletedItemId = localStorage.getItem("deletedItemId");

    const deletedItemNumber = deletedItemId
      ? parseInt(deletedItemId.replace(/[a-zA-Z]/g, ""))
      : -1;

    let maxStyleId = 0;

    const findMaxStyleId = (node) => {
      const styleIdRegex = /([a-zA-Z]+)(\d*)/;
      if (typeof node.styleid === "string") {
        const matches = node.styleid.match(styleIdRegex);
        if (matches && matches[1] === "agrfb") {
          const currentId = parseInt(matches[2]);
          if (!isNaN(currentId) && currentId > maxStyleId) {
            maxStyleId = currentId;
          }
        }
      }

      if (node.control && node.control.length > 0) {
        for (const childNode of node.control) {
          findMaxStyleId(childNode);
        }
      }
    };

    treeData[0].Source[0].control.forEach((rootNode) => {
      findMaxStyleId(rootNode);
    });

    const nextStyleId =
      deletedItemNumber > maxStyleId ? deletedItemNumber + 1 : maxStyleId + 1;

    return `agrfb${nextStyleId}`;
  };

 
  const findMaxNumber = (node, controlType) => {
    let maxNumber = 0;
    if (node.control) {
      for (const child of node.control) {
        if (child.type === controlType) {
          const number = parseInt(child.name.replace(controlType, ""));
          if (!isNaN(number) && number > maxNumber) {
            maxNumber = number;
          }
        }
        const childMaxNumber = findMaxNumber(child, controlType);
        if (childMaxNumber > maxNumber) {
          maxNumber = childMaxNumber;
        }
      }
    }
    return maxNumber;
  };

  const findMaxHeaderAndCellNumbers = (treeData) => {
    let maxHeaderNumber = 0;
    let maxCellNumber = 0;

    const traverse = (node) => {
      if (node.control) {
        for (const child of node.control) {
          if (child.htmlTag === "th") {
            const number = parseInt(child.tableHeader.replace("Header", ""));
            if (!isNaN(number) && number > maxHeaderNumber) {
              maxHeaderNumber = number;
            }
          } else if (child.controltype === "Row") {
            for (const cell of child.control) {
              if (cell.htmlTag === "td") {
                const number = parseInt(cell.tableHeader.replace("label", ""));
                if (!isNaN(number) && number > maxCellNumber) {
                  maxCellNumber = number;
                }
              }
            }
          }
          traverse(child);
        }
      }
    };

    traverse(treeData[0].Source[0]);
    return { maxHeaderNumber, maxCellNumber };
  };

  const addNewColumn = () => {
    const defaultlabel = defaultStyle.find(
      (style) => style.styleType === "TableLabel"
    );
  
    const defaultHeader = defaultStyle.find(
      (style) => style.styleType === "TableHeader"
    );

    const { maxHeaderNumber, maxCellNumber } =
      findMaxHeaderAndCellNumbers(treeData);

    const findTableNode = (node) => {
      if (node.type === "Table") {
        return node;
      } else if (node.control && Array.isArray(node.control)) {
        for (let i = 0; i < node.control.length; i++) {
          const result = findTableNode(node.control[i]);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    const tableNode = findTableNode(selectedNode);

    if (!tableNode) {
      console.error("Table node not found in treeData.");
      return;
    }

    const theadNode = tableNode.control.find(
      (node) => node.controltype === "Header"
    );

    if (!theadNode) {
      console.error("Table header node not found.");
      return;
    }

    const newHeaderIndex = maxHeaderNumber + 1;
    const newlabelindex = maxCellNumber + 1;

    const styleid = calculateNextStyleId();

    const controlToAddfortablelabel = Controls.flatMap(
      (category) => category.items
    ).find((item) => item.styleType === "Label");

  
    const newHeader = {
      id: `${tableNode.id}.thead.${newHeaderIndex}`,
      name: `Header${newHeaderIndex}`,
      controlName: `Header${newHeaderIndex}`,
      tableHeader: `Header${newHeaderIndex}`,
      data: `Header${newHeaderIndex}`,
      type: "Table",
      parentid: `${tableNode.id}.thead`,
      htmlTag: "th",
      controltype: "Header",
      styles: JSON.stringify({ ...defaultHeader.style}, null, 2),
      styleid: `${styleid}.thead.${newHeaderIndex}`,
      styleType: "TableHeader",
      mediaURl: "table",
      check: false,
      switchcheck: false,
      title: "Display",
      value: "",
      media: "",
      option: false,
      switchoption: false,
      position: 1,
      checkposition: "left",
      tab: "Text",
      inputType: "Date",
      inputText: "",
      imageText: "",
      sourceData: "",
      tableProp: "tableProp",
      controlChange: `Header${newHeaderIndex}`,
    };

    theadNode.control.push(newHeader);

    tableNode.control
      .filter((node) => node.controltype === "Row")
      .forEach((row) => {
        const newCellID = `${row.id}.cell${newHeaderIndex}`;
        const newCell = {
          id: newCellID,
          name: `label${newlabelindex}`,
          controlName: `label${newlabelindex}`,
          tableHeader: `label${newlabelindex}`,
          data: `label${newlabelindex}`,
          type: "Table",
          parentid: `${row.id}`,
          htmlTag: "td",
          controltype: controlToAddfortablelabel.controltype,
          styles: JSON.stringify({ ...defaultlabel.style}, null, 2),
          styleid: `${styleid}.row_cell${newlabelindex}`,
          styleType: "TableLabel",
          mediaURl: controlToAddfortablelabel.mediaURl,
          check: false,
          switchcheck: false,
          title: "Display",
          value: "",
          media: "",
          option: `Header${newlabelindex}`,
          switchoption: false,
          position: 1,
          checkposition: "left",
          tab: "Text",
          inputType: "Date",
          inputText: "",
          imageText: "",
          sourceData: `Header`,
          tableProp: "tableProp",
          controlChange: `label${newlabelindex}`,
        };

        row.control.push(newCell);
      });

    setTreeData([...treeData]);
  };

  const handleAddControl = (
    controlType,
    updatedStyles,
    newCheckboxes,
    generateNewKeys
  ) => {

    const defaultlabel = defaultStyle.find(
      (style) => style.styleType === "TableLabel"
    );
  
    const defaultHeader = defaultStyle.find(
      (style) => style.styleType === "TableHeader"
    );


    if (
      !treeData[0]?.Source[0]?.control ||
      !Array.isArray(treeData[0].Source[0].control)
    ) {
      console.error("Invalid treeData structure");
      toast.error("Invalid treeData structure");
      return;
    }

    if (selectedNode.styleType === "RadioContainer") {
      return;
    }

    const maxNumber = findMaxNumber(treeData[0].Source[0], controlType);
    const { maxHeaderNumber, maxCellNumber } =
      findMaxHeaderAndCellNumbers(treeData);
    const newName = `${controlType}${maxNumber + 1}`;
    const controlToAdd = Controls.flatMap((category) => category.items).find(
      (item) => item.controltype === controlType
    );

    const controlToAddfortablelabel = Controls.flatMap(
      (category) => category.items
    ).find((item) => item.styleType === "Label");

    if (controlToAdd) {
      setRenderedControls((prevControls) => [...prevControls, controlToAdd]);
    } else {
      console.log(`Control type ${controlType} not found in Controls data.`);
    }

    if (!controlToAdd) {
      console.log(`Control type ${controlType} not found in Controls data.`);
      return;
    }

    if (!selectedNode) {
      console.error("Control type is not selected.");
      toast.error("Control type is not selected");
      return;
    }

    if (selectedNode.styleType === "RadioContainer") {
      return;
    }

    const footerContainerIndex = treeData[0].Source[0].control.findIndex(
      (item) => item.type === "FooterContainer"
    );

    const headerContainerIndex = treeData[0].Source[0].control.findIndex(
      (item) => item.type === "HeaderContainer"
    );

    const getNextIDS = (treeData, controlType) => {
      let maxID = 0;

      treeData[0].Source[0].control.forEach((item) => {
        if (item.id && typeof item.id === "string") {
          const parts = item.id.split(".");
          const lastPart = parseInt(parts[parts.length - 1]);
          if (!isNaN(lastPart) && lastPart > maxID) {
            maxID = lastPart;
          }
        }
      });

      if (controlType === "HeaderContainer") {
        return 0;
      }
      return maxID + 1;
    };

    const customHtmlTag = controlToAdd.htmlTag;
    const styleid = calculateNextStyleId();

    setDefaultStyle(readArrayStyles);

    const defaultStyles = Array.isArray(defaultStyle)
      ? defaultStyle.find((style) => style.styleType === controlToAdd.styleType)
      : null;

    if (
      !defaultStyles ||
      !defaultStyles.style ||
      defaultStyles.style.length === 0
    ) {
      console.log(
        `Default styles for control type ${controlType} not found or empty.`
      );
      return;
    }

    const defaultRadioConatiner = defaultStyle.find(
      (style) => style.styleType === "RadioContainer"
    );

    const defaultCheckbox = defaultStyle.find(
      (style) => style.styleType === "Checkbox"
    );

    const defaultRadio = defaultStyle.find(
      (style) => style.styleType === "Radio"
    );

    const defaultArray = defaultStyles.style;

    const mergedStyles = {
      ...defaultArray,
      ...updatedStyles,
    };

    if (!selectedNode) {
      console.error("Control type is not selected.");
      toast.error("Please select a control type.");
      return;
    }

    const getValueForControlType = (type) => {
      switch (type) {
        case "Radio":
          return numberOfRadioInputs;
        default:
          return "";
      }
    };

    const getInputTextForControlType = (type) => {
      switch (type) {
        case "Date":
          return "";
        default:
          return "";
      }
    };

    if (controlType === "HeaderContainer") {
      if (headerContainerIndex !== -1) {
        console.log("HeaderContainer already exists.");
        toast.info("HeaderContainer already exists.");
        return;
      }

      const newHeaderContainer = {
        id: "0.0H",
        name: newName,
        controlName: newName,
        tableHeader: "",
        data: controlToAdd.description,
        type: controlType,
        parentid: "0",
        styleid: styleid,
        htmlTag: customHtmlTag,
        styles: JSON.stringify({ ...mergedStyles }, null, 2),
        controltype: controlType,
        styleType: controlToAdd.styleType,
        mediaURl: controlToAdd.mediaURl,
        check: isChecked,
        switchcheck: isSwitched,
        title: Controls.find((category) =>
          category.items.some((item) => item.controltype === controlType)
        )?.title,
        value: getValueForControlType(controlType, controlToAdd.description),
        media: "",
        uniqueKey: generateNewKeys,
        option: selectedOptioncheck,
        position: selectedOptionmedia,
        switchoption: selectedOptionSwitchcheck,
        checkposition: selectedPositioncheck,
        tab: tabSelect,
        inputType: inputType,
        inputText: getInputTextForControlType(
          controlType,
          controlToAdd.description
        ),
        imageText: imageText,
        sourceData: "sourceData",
        controlChange: controlType,
        tableProp: "null",
        url: url,
        control: [],
      };

      console.log("newHeaderContainer", newHeaderContainer);

      if (
        treeData[0].Source[0].control.length > 0 &&
        treeData[0].Source[0].control[0].type !== "HeaderContainer"
      ) {
        treeData[0].Source[0].control.unshift(newHeaderContainer);
      } else {
        treeData[0].Source[0].control.push(newHeaderContainer);
      }

      setOpenDialog(false);
      setTreeData([...treeData]);
      return;
    }

    const getDataForControlType = (type, description) => {
      switch (type) {
        case "List":
          return description.join(",");
        case "Dropdown":
          return description.join(",");
        case "Date":
          return new Date().toISOString().slice(0, 10);
        case "Iframe":
          return fixedIframeUrl;
        default:
          return description;
      }
    };

    const getMediaForControlType = (type) => {
      switch (type) {
        case "Image":
          return selectedImage;
        case "Radio":
          return RadioPosition;
        default:
          return "";
      }
    };

    if (controlType === "FooterContainer") {
      if (footerContainerIndex !== -1) {
        console.log("FooterContainer already exists.");
        toast.info("FooterContainer already exists.");
        return;
      }
      const newID = getNextIDS(treeData);
      const newNode = {
        id: `0.${newID}`,
        name: newName,
        controlName: newName,
        tableHeader: "",
        data: controlToAdd.description,
        type: controlType,
        htmlTag: customHtmlTag,
        controltype: controlType,
        styles: JSON.stringify({ ...mergedStyles }, null, 2),
        parentid: "0",
        styleid: styleid,
        styleType: controlToAdd.styleType,
        mediaURl: controlToAdd.mediaURl,
        check: isChecked,
        switchcheck: isSwitched,
        title: Controls.find((category) =>
          category.items.some((item) => item.controltype === controlType)
        )?.title,
        value: getValueForControlType(controlType, controlToAdd.description),
        media: getMediaForControlType(controlType, controlToAdd.description),
        uniqueKey: generateNewKeys,
        option: selectedOptioncheck,
        switchoption: selectedOptionSwitchcheck,
        position: selectedOptionmedia,
        checkposition: selectedPositioncheck,
        tab: tabSelect,
        inputType: inputType,
        inputText: getInputTextForControlType(
          controlType,
          controlToAdd.description
        ),
        imageText: imageText,
        sourceData: "sourceData",
        controlChange: controlType,
        tableProp: "null",
        url: url,
        control: [],
      };

      treeData[0].Source[0].control.push(newNode);
      setOpenDialog(false);
      setTreeData([...treeData]);
      return;
    } else {
      const parentID = selectedNode.id;
      let maxChildIndex = 0;

      if (selectedNode.control && Array.isArray(selectedNode.control)) {
        selectedNode.control.forEach((child) => {
          if (child.id && typeof child.id === "string") {
            const childIndex = parseInt(child.id.split(".").pop(), 10);
            if (!isNaN(childIndex) && childIndex > maxChildIndex) {
              maxChildIndex = childIndex;
            }
          }
        });
      }

      const childID = `${parentID}.${maxChildIndex + 1}`;

      const newNode = {
        id: childID,
        name: newName,
        controlName: newName,
        tableHeader: "",
        data: getDataForControlType(controlType, controlToAdd.description),
        type: controlType,
        parentid: parentID,
        htmlTag: customHtmlTag,
        controltype: controlType,
        styles: JSON.stringify({ ...mergedStyles }, null, 2),
        styleid: styleid,
        styleType: controlToAdd.styleType,
        mediaURl: controlToAdd.mediaURl,
        check: isChecked,
        switchcheck: isSwitched,
        title: Controls.find((category) =>
          category.items.some((item) => item.controltype === controlType)
        )?.title,
        value: getValueForControlType(controlType, controlToAdd.description),
        media: getMediaForControlType(controlType, controlToAdd.description),
        uniqueKey: generateNewKeys,
        option: selectedOptioncheck,
        switchoption: selectedOptionSwitchcheck,
        position: selectedOptionmedia,
        checkposition: selectedPositioncheck,
        tab: tabSelect,
        inputType: inputType,
        inputText: getInputTextForControlType(
          controlType,
          controlToAdd.description
        ),
        imageText: imageText,
        sourceData: "sourceData",
        controlChange: controlType,
        tableProp: "null",
        url: url,
        control: [],
      };

      const getParentNode = (root, node) => {
        if (!node || node.parentid === "0") {
          return root;
        }

        const findNode = (currentNode) => {
          if (currentNode.id === node.parentid) {
            return currentNode;
          }

          if (currentNode.control) {
            for (const childNode of currentNode.control) {
              const foundNode = findNode(childNode);
              if (foundNode) {
                return foundNode;
              }
            }
          }
          return null;
        };

        return findNode(root);
      };

      if (newCheckboxes) {
        newCheckboxes.forEach((checkbox, index) => {
          const styleid = calculateNextStyleId();
          const parentNode = getParentNode(treeData[0].Source[0], selectedNode);
          const childID = `${parentNode.id}.${parentNode.control.length + 1}`;

          const maxNumber = findMaxNumber(treeData[0].Source[0], controlType);
          const newName = `${controlType}${maxNumber + 1}`;

          if (!parentNode || !parentNode.id) {
            console.log("parentNode or parentNode.id is null or undefined.");
            return;
          }

          const newNode = {
            id: childID,
            name: newName,
            controlName: newName,
            tableHeader: "",
            data: controlToAdd.description,
            type: controlType,
            parentid: parentNode.id,
            styleid: styleid,
            htmlTag: customHtmlTag,
            controltype: controlType,
            check: isChecked,
            switchcheck: isSwitched,
            styles: JSON.stringify({ ...mergedStyles }, null, 2),
            styleType: controlToAdd.styleType,
            mediaURl: controlToAdd.mediaURl,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: inputType,
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: "sourceData",
            controlChange: controlType,
            tableProp: "null",
            url: url,
            control: [],
          };

          parentNode.control.push(newNode);
        });

        if (footerContainerIndex !== -1) {
          const [removed] = treeData[0].Source[0].control.splice(
            footerContainerIndex,
            1
          );
          treeData[0].Source[0].control.push(removed);
        }

        setTreeData([...treeData]);
        setOpenDialog(false);
        return;
      }

      if (controlType === "Radio") {
        const parentID = selectedNode.id;

        if (selectedNode.controltype === "RadioContainer") {
          toast.error("Cannot add a Radio inside another Radio.");
          return;
        }

        if (selectedNode.controltype === "Radio") {
          toast.error("Cannot add a Radio inside another Radio.");
          return;
        }

        const childIDLayout =
          parentID + "." + (selectedNode.control.length + 1);
        const childIDContainer = childIDLayout + ".1";

        const newNameLayout = `${controlType}${maxNumber + 1}`;
        const newNameContainer = `${controlType}${maxNumber + 2}`;

        if (selectedNode.controltype === "RadioContainer") {
          toast.error("Cannot add a Radio inside another Radio.");
          return;
        }

        if (selectedNode.controltype === "Button") {
          toast.error("Cannot add a Radio inside Button.");
          return;
        }

        const newContainerLayout = {
          id: childIDLayout,
          name: "RadioContainer",
          controlName: newNameLayout,
          tableHeader: "",
          data: "",
          type: "Container",
          parentid: parentID,
          htmlTag: "div",
          controltype: "Container",
          styles: JSON.stringify({ ...defaultRadioConatiner.style }, null, 2),
          styleid: `agrf${maxNumber + 1}`,
          styleType: "RadioContainer",
          mediaURl: controlToAdd.mediaURl,
          title: "Containers",
          tab: tabSelect,
          inputType: inputType,
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: "sourceData",
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [
            {
              id: childIDContainer,
              name: newNameContainer,
              controlName: newNameContainer,
              tableHeader: "",
              data: controlToAdd.description,
              type: "Radio",
              parentid: childIDLayout,
              htmlTag: customHtmlTag,
              controltype: controlType,
              styles: JSON.stringify({ ...defaultRadio.style }, null, 2),
              styleid: calculateNextStyleId(),
              styleType: controlToAdd.styleType,
              mediaURl: controlToAdd.mediaURl,
              check: isChecked,
              switchcheck: isSwitched,
              title: "Input",
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: selectedOptioncheck,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: inputType,
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              sourceData: "sourceData",
              controlChange: controlType,
              tableProp: "null",
              url: url,
              control: [],
            },
          ],
        };

        selectedNode.control.push(newContainerLayout);
        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (controlType === "Checkbox") {
        const parentID = selectedNode.id;
        const childIDLayout =
          parentID + "." + (selectedNode.control.length + 1);
        const childIDContainer = childIDLayout + ".1";

        const newNameLayout = `${controlType}${maxNumber + 1}`;
        const newNameContainer = `${controlType}${maxNumber + 2}`;

        if (selectedNode.controltype === "CheckboxContainer") {
          toast.error("Cannot add a Radio inside another Radio.");
          return;
        }

        if (selectedNode.controltype === "Checkbox") {
          toast.error("Cannot add a Checkbox inside another Checkbox.");
          return;
        }

        if (selectedNode.controltype === "Button") {
          toast.error("Cannot add a Checkbox inside Button.");
          return;
        }

        const newContainerLayout = {
          id: childIDLayout,
          name: "CheckboxContainer",
          controlName: newNameLayout,
          tableHeader: "",
          data: "",
          type: "Container",
          parentid: parentID,
          htmlTag: "div",
          controltype: "Container",
          styles: JSON.stringify({ ...defaultRadioConatiner.style }, null, 2),
          styleid: `agr${maxNumber + 1}`,
          styleType: "RadioContainer",
          mediaURl: controlToAdd.mediaURl,
          title: "Containers",
          tab: tabSelect,
          inputType: inputType,
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: "sourceData",
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [
            {
              id: childIDContainer,
              name: newNameContainer,
              controlName: newNameContainer,
              tableHeader: "",
              data: controlToAdd.description,
              type: "Checkbox",
              parentid: childIDLayout,
              htmlTag: customHtmlTag,
              controltype: controlType,
              styles: JSON.stringify({ ...defaultCheckbox.style }, null, 2),
              styleid: calculateNextStyleId(),
              styleType: controlToAdd.styleType,
              mediaURl: controlToAdd.mediaURl,
              check: isChecked,
              switchcheck: isSwitched,
              title: Controls.find((category) =>
                category.items.some((item) => item.controltype === controlType)
              )?.title,
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: selectedOptioncheck,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: inputType,
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              sourceData: "sourceData",
              controlChange: controlType,
              tableProp: "null",
              url: url,
              control: [],
            },
          ],
        };

        selectedNode.control.push(newContainerLayout);
        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      let globalCellCounter = 1;

      if (controlType === "Table") {
        const parentID = selectedNode.id;
        const childTableIDLayout =
          parentID + "." + (selectedNode.control.length + 1);
        const tableID = getNextIDS(treeData, controlType);
        const styleidTable = calculateNextStyleId();

        if (selectedNode.controltype === "Table" && controlType === "Table") {
          toast.error("Cannot add a table inside another control.");
          return;
        }

        if (
          selectedNode.title === "Display" ||
          selectedNode.title === "Input"
        ) {
          toast.error("Cannot add a table inside another control.");
          return;
        }

        const rowCount = 1;

        const tableNode = {
          id: `${childTableIDLayout}`,
          name: `Table${tableID}`,
          controlName: `Table${tableID}`,
          tableHeader: "tableHead",
          data: controlToAdd.description,
          type: "Table",
          parentid: parentID,
          htmlTag: "table",
          controltype: controlType,
          styleid: styleidTable,
          styles: JSON.stringify({ ...mergedStyles }, null, 2),
          styleType: controlToAdd.styleType,
          mediaURl: controlToAdd.mediaURl,
          check: isChecked,
          switchcheck: isSwitched,
          title: Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title,
          value: getValueForControlType(controlType, controlToAdd.description),
          media: getMediaForControlType(controlType, controlToAdd.description),
          uniqueKey: generateNewKeys,
          option: selectedOptioncheck,
          switchoption: selectedOptionSwitchcheck,
          position: selectedOptionmedia,
          checkposition: selectedPositioncheck,
          tab: tabSelect,
          inputType: "Header",
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: sourceData,
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [],
        };
        selectedNode.control.push(tableNode);

        const theadNode = {
          id: `${childTableIDLayout}.${tableID}`,
          name: `Table${tableID}_head`,
          controlName: `Table${tableID}_head`,
          tableHeader: "",
          data: "",
          type: "Table",
          parentid: `${parentID}.${tableID}`,
          htmlTag: "thead",
          controltype: "Header",
          styleid: `${styleidTable}_head`,
          styles: JSON.stringify({ ...mergedStyles }, null, 2),
          styleType: controlToAdd.styleType,
          mediaURl: controlToAdd.mediaURl,
          check: isChecked,
          switchcheck: isSwitched,
          title: Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title,
          value: getValueForControlType(controlType, controlToAdd.description),
          media: getMediaForControlType(controlType, controlToAdd.description),
          uniqueKey: generateNewKeys,
          option: selectedOptioncheck,
          switchoption: selectedOptionSwitchcheck,
          position: selectedOptionmedia,
          checkposition: selectedPositioncheck,
          tab: tabSelect,
          inputType: "Header",
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: sourceData,
          controlChange: `Table${tableID}_head`,
          tableProp: "null",
          url: url,
          control: [],
        };

        tableNode.control.push(theadNode);

        for (let i = 1; i <= headerCount; i++) {
          const styleidHeader = `${styleidTable}_header_${i}`;
          theadNode.control.push({
            id: `${childTableIDLayout}.${tableID}.${i}`,
            name: `Header${maxHeaderNumber + i}`,
            controlName: `Header${maxHeaderNumber + i}`,
            tableHeader: `Header${maxHeaderNumber + i}`,
            data: `Header${maxHeaderNumber + i}`,
            type: "Table",
            parentid: `${parentID}.${tableID}.thead`,
            htmlTag: "th",
            controltype: "Header",
            styleid: styleidHeader,
            styleType: "Table",
            styles: JSON.stringify({ ...defaultHeader.style}, null, 2),
            mediaURl: controlToAdd.mediaURl,
            check: isChecked,
            switchcheck: isSwitched,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: inputType,
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            controlChange: `Header${i}`,
            sourceData: sourceData,
            tableProp: "tableProp",
            url: url,
          });
        }

        for (let i = 1; i <= rowCount; i++) {
          const styleidRow = `${styleidTable}_row_${i}`;
          const row = {
            id: `${childTableIDLayout}.row${i}`,

            name: `Row${i}`,
            controlName: `Row${i}`,
            data: "",
            tableHeader: "",
            type: "Table",
            parentid: `${parentID}.${tableID}`,
            htmlTag: "tr",
            controltype: "Row",
            styleid: styleidRow,
            styles: JSON.stringify({ ...mergedStyles }, null, 2),
            styleType: controlToAdd.styleType,
            mediaURl: controlToAdd.mediaURl,
            check: isChecked,
            switchcheck: isSwitched,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: "Header",
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: sourceData,
            controlChange: "Row",
            tableProp: "null",
            url: url,
            control: [],
          };

          for (let j = 1; j <= cellCount; j++) {
            const styleidCell = `${styleidTable}_row${i}_cell${j}`;
            row.control.push({
              id: `${childTableIDLayout}.${tableID}.row${i}_cell${j}`,
              name: `label${maxCellNumber + j}`,
              controlName: `label${maxCellNumber + j}`,
              tableHeader: `label${maxCellNumber + j}`,
              data: `label${maxCellNumber + j}`,
              type: "Table",
              parentid: `${childTableIDLayout}.row${i}`,
              htmlTag: "td",
              controltype: controlToAddfortablelabel.controltype,
              styleid: styleidCell,
              styleType: "Table",
              styles: JSON.stringify({ ...defaultlabel.style}, null, 2),
              mediaURl: controlToAddfortablelabel.mediaURl,
              check: isChecked,
              switchcheck: isSwitched,
              title: Controls.find((category) =>
                category.items.some((item) => item.controltype === controlType)
              )?.title,
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: `Header${maxCellNumber + j}`,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: inputType,
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              controlChange: `Header${maxCellNumber + j}`,
              sourceData: sourceData,
              tableProp: "tableProp",
              url: url,
            });

            globalCellCounter++;
          }

          tableNode.control.push(row);
        }

        if (footerContainerIndex !== -1) {
          const [removed] = treeData[0].Source[0].control.splice(
            footerContainerIndex,
            1
          );
          treeData[0].Source[0].control.push(removed);
        }
        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      if (selectedNode && selectedNode.control) {
        if (
          selectedNode.title === "Display" ||
          selectedNode.title === "Input" ||
          selectedNode.title === "Icon"
        ) {
          const newID = getNextIDS(treeData);

          const newNode = {
            id: `0.${newID}`,
            name: newName,
            controlName: newName,
            tableHeader: "",
            data: getDataForControlType(controlType, controlToAdd.description),
            type: controlType,
            parentid: "0",
            styleid: styleid,
            htmlTag: customHtmlTag,
            controltype: controlType,
            styles: JSON.stringify({ ...mergedStyles }, null, 2),
            styleType: controlToAdd.styleType,
            mediaURl: controlToAdd.mediaURl,
            check: isChecked,
            switchcheck: isSwitched,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: inputType,
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: sourceData,
            controlChange: controlType,
            tableProp: "null",
            url: url,
            control: [],
          };

          treeData[0].Source[0].control.splice(
            footerContainerIndex,
            0,
            newNode
          );
          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }
        selectedNode.control.push(newNode);
        setOpenDialog(false);
        setTreeData([...treeData]);
      } else {
        const { childID } = getNextIDS(treeData);

        if (
          Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title === "Display" ||
          Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title === "Input" ||
          Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title === "Icon"
        ) {
          console.log(`Cannot add ${controlType} without a parent`);
          return;
        }

        const newNode = {
          id: childID,
          name: newName,
          controlName: newName,
          tableHeader: "",
          data: controlToAdd.description,
          type: controlType,
          parentid: "0",
          styleid: styleid,
          htmlTag: customHtmlTag,
          controltype: controlType,
          styles: JSON.stringify({ ...mergedStyles }, null, 2),
          styleType: controlToAdd.styleType,
          mediaURl: controlToAdd.mediaURl,
          check: isChecked,
          switchcheck: isSwitched,
          title: Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title,
          value: getValueForControlType(controlType, controlToAdd.description),
          media: getMediaForControlType(controlType, controlToAdd.description),
          uniqueKey: generateNewKeys,
          option: selectedOptioncheck,
          switchoption: selectedOptionSwitchcheck,
          position: selectedOptionmedia,
          checkposition: selectedPositioncheck,
          tab: tabSelect,
          inputType: inputType,
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: sourceData,
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [],
        };
        treeData[0].Source[0].control.splice(footerContainerIndex, 0, newNode);

        setOpenDialog(false);
        setTreeData([...treeData]);
      }
    }

    if (
      footerContainerIndex !== -1 &&
      footerContainerIndex !== treeData[0].Source[0].control.length - 1
    ) {
      const [removed] = treeData[0].Source[0].control.splice(
        footerContainerIndex,
        1
      );
      treeData[0].Source[0].control.push(removed);
      setTreeData([...treeData]);
    }
  };



  const handleSelectItem = (
    id,
    name,
    type,
    data,
    styles,
    styleid,
    htmlTag,
    text,
    styleType,
    check,
    media,
    option,
    position,
    switchcheck,
    switchoption,
    checkposition,
    value,
    tab,
    inputType,
    tableHeader,
    sourceData,
    controltype,
    controlName,
    controlChange,
    tableProp,
    inputText,
    imageText,
    newPosition
  ) => {
    const element = document.getElementById(id);
    const nodeListfocused = document.querySelectorAll(".focusingClass")[0];
    if (element) {
      if (nodeListfocused) {
        nodeListfocused.classList.remove("focusingClass");
      }
      element.classList.add("focusingClass");
      // document.querySelectorAll(".focusingClass")[0].focus();
      setSelectedItemID(id);

      setSelectedControl((prevSelectedItem) => ({
        ...prevSelectedItem,
        type,
      }));
      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        id,
        name: name || editedName,
        type,
        data: data || "",
        styleid,
        styles,
        htmlTag: htmlTag,
        text: text,
        styleType: styleType,
        check: check || isChecked,
        media: media || checkboxPosition || RadioPosition,
        position: position || selectedOptionmedia,
        switchcheck: switchcheck || isSwitched,
        switchoption: switchoption || selectedOptionSwitchcheck,
        checkposition: checkposition,
        tab: tabSelect || tab,
        inputType: inputType || inputType,
        tableHeader: tableHeader || tableHeader,
        sourceData: sourceData || sourceData,
        controltype: controltype,
        controlName: controlName,
        controlChange,
        tableProp: tableProp,
        inputText: inputText,
        imageText: imageText,
      }));
      setSelectedOptioncheck(check ? "true" : "false");
      setSelectedOptionSwitchcheck(switchcheck ? "false" : "true");
      setSelectedPositioncheck(checkposition || (media ? "left" : "right"));
      setEditedName(name || editedName);
      setEditedData(data || "");
      setSelectedOption(imageText || inputText || data);

      settabSelect(tab || "");
      // setInputType(inputType ? "Date" : "Date");
      setTableHeader(tableHeader ? "TableHeader" : "");
      setSourceData(sourceData ? "" : "");
      setSelectedControlType(controlChange || selectedControlType);
      setIsOptionSelected(!isOptionSelected);
      settableProp(tableProp ? "tableProp" : "null");
    } else {
      console.error("Element with ID", id, "not found in the DOM");
    }
  };

  
  useEffect(() => {
    if (treeData.length > 0 && !selectedNode) {
      const firstItem = treeData[0].Source[0];
      setSelectedNode(firstItem);
      setSelectedItem(firstItem);
      setSelectedControl(firstItem)
      setEditedName(firstItem.name);
      setSelectedItemID(firstItem.id);
    }
  }, [treeData, selectedNode]);


  const updateCustomStyle = (styleid, style) => {
    setCustomStyles((prevCustomStyles) => ({
      ...prevCustomStyles,
      [styleid]: style,
    }));
    setTreeData((prevTreeData) => {
      const updatedTreeData = [...prevTreeData];
      const rootNode = updatedTreeData[0].Source[0];
      updateStyleInTree(rootNode, styleid, style);
      return updatedTreeData;
    });
  };

  const updateStyleInTree = (node, styleid, style) => {
    if (node.styleid === styleid) {
      node.styles = JSON.stringify({ ...style }, null, 2);
    }
    if (node.control && node.control.length > 0) {
      node.control.forEach((child) => updateStyleInTree(child, styleid, style));
    }
  };

  
  const handleRightClick = (event, control) => {
    event.preventDefault();
    event.stopPropagation();
  
    const element = document.getElementById(control.id);
    const focusedNode = document.querySelector(".focusingClass");
  
    if (element) {
      if (focusedNode) {
        focusedNode.classList.remove("focusingClass");
      }
      element.classList.add("focusingClass");
    }
  
    const styles = control.styles;
    const x = event.clientX;
    const y = event.clientY;
  
    setPopupPosition({ x, y });
    setContextMenuVisible(true);
    setSelectedItemID(control.id);
    setContextMenuStyles(styles);
    setSelectedControl(control);
    setSelectedNode(control);
  
    setSelectedItem(control)
  
    setSelectedOptioncheck(control.check ? "true" : "false");
    setSelectedOptionSwitchcheck(control.switchcheck ? "true" : "false");
    setSelectedPositioncheck(control.checkposition || (control.media ? "left" : "right"));
    setEditedName(control.name || editedName);
    setEditedData(control.data || "");
    setSelectedOption(control.imageText || control.inputText || control.data);
  
    settabSelect(control.tab || "");
    setTableHeader(control.tableHeader ? "TableHeader" : "");
    setSourceData(control.sourceData ? "" : "");
    setSelectedControlType(control.controlChange || control.selectedControlType);
    setIsOptionSelected(!isOptionSelected);
    settableProp(control.tableProp ? "tableProp" : "null");
  
  
    setShowPopup(true);
  }


  const renderControl = (control) => {
    if (!control || typeof control.id === "undefined") {
      return null;
    }

    const isItemSelected = control.id === selectedItemID;

    const onUpdateStyleFromTextarea = (updatedStyles) => {
      setContextMenuStyles(updatedStyles);

      try {
        const parsedStyles = JSON.parse(updatedStyles);
        updateCustomStyle(selectedControl.styleid, parsedStyles);
      } catch (error) {
        const keyValuePairs = updatedStyles.split(/\s*,\s*/);
        const nonJsonStyles = {};
        keyValuePairs.forEach((pair) => {
          const [key, ...valueParts] = pair.split(/\s*:\s*/);
          const value = valueParts.join(":");
          nonJsonStyles[key] = value;
        });
        updateCustomStyle(selectedControl.styleid, nonJsonStyles);
      }
    };

    const shouldRenderMediaUrl = control.styleType === "Icon";

    const controlTypeToRender = shouldRenderMediaUrl ? (
      <>
        {control.mediaURl && (
          <FontAwesomeIcon
            icon={{
              prefix: control.mediaURl.startsWith("fas")
                ? "fas"
                : control.mediaURl.startsWith("fab")
                  ? "fab"
                  : "fa",
              iconName: control.mediaURl,
            }}
          />
        )}
      </>
    ) : control.htmlTag === "input" ? (
      control.styleType
    ) : (
      control.data
    );

    const createdElement = (
      <CustomHtmlTag
        keyss={control.id}
        styleType={control.styleType}
        text={control.text}
        htmlTag={control.htmlTag}
        styleid={control.styleid}
        controltype={controlTypeToRender}
        isSelected={isItemSelected}
        styles={control.styles}
        onContextMenu={(event) => {
          handleRightClick(event, control);
        }}
        controlName={control.type}
        mediaURl={shouldRenderMediaUrl ? controlTypeToRender : ""}
        customStyles={customStyles}
        updateCustomStyle={updateCustomStyle}
        data={control.data}
        inputType={control.inputType}
        editedData={editedData}
        handleSelectItem={handleSelectItem}
        control={control}
        selectedNode={selectedNode}
        controlToAdd={control.controlToAdd}
        handleAddControl={handleAddControl}
        numberOfRadioInputs={numberOfRadioInputs}
        numberOfCheckbox={numberOfCheckbox}
        treeData={treeData}
        setTreeData={setTreeData}
        handleCheckboxChange={handleCheckboxChange}
        value={control.value}
        check={control.check}
        media={control.media}
        selectedImage={selectedImage}
        checkboxPosition={checkboxPosition}
        uniqueKey={control.uniqueKey}
        selectedValue={selectedValue}
       
        handleRadioChangeData={handleRadioChangeData}
        switchcheck={control.switchcheck}
        handleSelectChange={handleSelectChange}
        selectedOptionControl={selectedOptionControl}
        onClickforFocus={onClickforFocus}
        name={control.name}
        rightsideStyletab={rightsideStyletab}
        setOpenDialog={setOpenDialog}
        inputRef={inputRef}
        
        position={control.position}
        selectedPositioncheck={selectedPositioncheck}
        checkposition={control.checkposition}
        handleDateChange={handleDateChange}
        setControlsVisible={setControlsVisible}
        controlsVisible={controlsVisible}
        selectedInputType={inputType}
        inputText={control.inputText}
        imageText={control.imageText}
        url={control.url}
        selectedItem={selectedItem}
        setCustomStyles={setCustomStyles}
        defaultStyle={defaultStyle}
        setDefaultStyle={setDefaultStyle}
        selectedItemID={selectedItemID}
      >
        {control.control &&
          control.control.length > 0 &&
          control.control.map((childControl) => (
            <React.Fragment key={childControl.id}>
              {renderControl(childControl)}
            </React.Fragment>
          ))}

        {selectedControl && selectedControl.id === control.id && (
          <React.Fragment onClick={handleRightClick}>
            <CustomPopUpForm
              onClose={handleClose}
              title={`Edit Styles: ${selectedControl.name}`}
              open={showPopup}
            >
              <div>
                <textarea
                  value={contextMenuStyles}
                  onChange={(e) => onUpdateStyleFromTextarea(e.target.value)}
                  style={{ width: "100%", minHeight: "300px" }}
                />
              </div>
            </CustomPopUpForm>
          </React.Fragment>
        )}
      </CustomHtmlTag>
    );
    return createdElement;
  };

  const accumulateHtmlContent = () => {
    let accumulatedContent = "";

    treeData.forEach((item) => {
      const controlsHtml = item.Source[0].control
        .map((control) => {
          const createdElement = renderControl(control);
          let htmlString = ReactDOMServer.renderToStaticMarkup(createdElement);
          return htmlString;
        })
        .join("");
      accumulatedContent += `${controlsHtml}`;
    });
    setHtmlContent(accumulatedContent);
  };

  useEffect(() => {
    accumulateHtmlContent();
  }, [treeData]);

  const generateHTMLFile = (htmlContent) => {
    const tagMatches = htmlContent.matchAll(/<(?!(\/))(\S+?)(?=\s|\/|>)/g);
    const tags = [...tagMatches].map((match) => match[0].slice(1));

    const regex = /style="([^"]*)"/g;
    const styleMatches = [...htmlContent.matchAll(regex)];
    const styles = styleMatches.map((match) => `{${match[1]}}`);
    const styleidMatches = htmlContent.match(/styleid="([^"]*)"/g);
    const styleids = styleidMatches
      ? styleidMatches.map((styleidMatch) => {
          return styleidMatch.slice(9, -1);
        })
      : [];

    const nameMatches = htmlContent.match(/name="([^"]*)"/g);
    const names = nameMatches
      ? nameMatches.map((nameMatch) => {
          return nameMatch.slice(6, -1);
        })
      : [];

    htmlContent = htmlContent.replace(/ style="([^"]*)"/g, "");

    const tagStylePairs = tags.map((tag, index) => ({
      tag,
      style: styles[index] || "{}",
      styleid: styleids[index] || "",
      name: names[index] || "",
    }));

    let formattedTagWithStyle = "";
    tagStylePairs.forEach(({ tag, style, styleid, name }) => {
      let tagWithStyle = `${styleid ? `[styleid="${styleid}"]` : ""}`;
      if (style !== "{}" || styleid || name) {
        tagWithStyle += `${style === "{}" ? "" : style}`;
        tagWithStyle += `${style === "{}" ? "" : style}`;
        formattedTagWithStyle += `${tagWithStyle}\r\n`;
      }
    });

    let switchCSSGenerated = false;
    let sliderCSSGenerated = false;
    let RadioCSSGenerated = false;
    let CheckCSSGenerated = false;

    const generateStyle = `
<style>
/*-- GEN <generic> --*/

  ${formattedTagWithStyle
    .split("\r\n")
    .filter((line) => line.trim() !== "")
    .map((line) => {
      const [selector, properties] = line.split("{");
      const formattedProperties = properties ? properties.split(";") : [];
      const formattedPropertiesString = formattedProperties
        .filter((property) => property.trim() !== "")
        .map((property) => `${property.trim()}`)
        .map((property) => `${property.trim()}`)
        .join(";\r\n");
      return `${selector.trim()}{\r\n${formattedPropertiesString}\r\n`;
    })
    .join("\r\n")}
 
      ${
        !switchCSSGenerated && names.includes("Switch")
          ? `  
   
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }
   
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
   
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
   
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
   
      input:checked + .slider {
        background-color: #2196F3;
      }
   
      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
   
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
   
      .slider.round {
        border-radius: 34px;
      }
   
      .slider.round:before {
        border-radius: 50%;
      }
      `
          : ""
      }
  ${
    !sliderCSSGenerated && names.includes("Slider")
      ? `
  .sliderwidth {
  width:400px;
  }`
      : ""
  }

       ${
         !RadioCSSGenerated && names.includes("Radio")
           ? `  
   
      .check{
  margin-right: 5px;
}
.checkleft{
  margin-left: 5px;
}
      `
           : ""
       }

           ${
             !CheckCSSGenerated && names.includes("Checkbox")
               ? `  
   
      .check{
  margin-left: 5px;
}
.checkleft{
  margin-right: 5px;
}
      `
               : ""
           }
  </style>`;

    if (names.includes("Switch")) {
      switchCSSGenerated = true;
    }

    if (names.includes("Slider")) {
      sliderCSSGenerated = true;
    }

    if (names.includes("Radio")) {
      RadioCSSGenerated = true;
    }

    if (names.includes("Checkbox")) {
      CheckCSSGenerated = true;
    }

    let restOfHtmlContent = htmlContent;

    let headerContent = "";
    let footerContent = "";

    if (htmlContent.includes("<header")) {
      const headerStartIndex = htmlContent.indexOf("<header");
      const headerEndIndex =
        htmlContent.indexOf("</header>") + "</header>".length;
      headerContent = htmlContent.substring(headerStartIndex, headerEndIndex);
      restOfHtmlContent =
        htmlContent.substring(0, headerStartIndex) +
        htmlContent.substring(headerEndIndex);
    }

    if (restOfHtmlContent.includes("<footer")) {
      const footerStartIndex = restOfHtmlContent.indexOf("<footer");
      const footerEndIndex =
        restOfHtmlContent.indexOf("</footer>") + "</footer>".length;
      footerContent = restOfHtmlContent.substring(
        footerStartIndex,
        footerEndIndex
      );
      restOfHtmlContent =
        restOfHtmlContent.substring(0, footerStartIndex) +
        restOfHtmlContent.substring(footerEndIndex);
    }

    const bodyHeaderComment = (index) => {
      return `
<!-- Body header ${index} -------->
<!------------------------------------------------------>
<!-- GEN <generic>`;
    };
    const theadComment = () => {
      return `\r\n<!------------------------------------------------------>
<!-- Inner Query loop 1 --->
<!------------------------------------------------------>
<!-- GEN <generic>`;
    };

    const closingDiv = `</div></div>`;

    const openingDiv = `<div class="form-group ctable col-sm-12">
    <div class="table-responsive">`;

    const extractTagsAfterHeader = (html) => {
      const headerEndIndex = html.indexOf("</header>");
      if (headerEndIndex !== -1) {
        return html.substring(headerEndIndex + "</header>".length);
      } else {
        return html;
      }
    };

    const tagsAfterHeader = extractTagsAfterHeader(htmlContent);
    let combinedOutput = `\t${bodyHeaderComment(1)}${tagsAfterHeader}`;

    let currentIndex = 0;
    let bodyHeaderIndex = 1;
    while (currentIndex !== -1) {
      const closingTableIndex = tagsAfterHeader.indexOf(
        "</table>",
        currentIndex
      );
      if (closingTableIndex !== -1) {
        const startTableIndex = tagsAfterHeader.lastIndexOf(
          "<table",
          closingTableIndex
        );
        if (startTableIndex !== -1) {
          const tableContent = tagsAfterHeader.substring(
            startTableIndex,
            closingTableIndex + "</table>".length
          );
          combinedOutput = combinedOutput.replace(
            tableContent,
            `${openingDiv}${tableContent}${closingDiv}${bodyHeaderComment(++bodyHeaderIndex)}`
          );
          currentIndex = closingTableIndex + "</table>".length;
        }
      } else {
        if (bodyHeaderIndex < 3) {
          combinedOutput += `\t${bodyHeaderComment(++bodyHeaderIndex)}`;
        } else {
          break;
        }
      }
    }

    let closingTheadIndex = combinedOutput.indexOf("</thead>");

    while (closingTheadIndex !== -1) {
      combinedOutput =
        combinedOutput.slice(0, closingTheadIndex + "</thead>".length) +
        `\n${theadComment(currentIndex)}\n` +
        combinedOutput.slice(closingTheadIndex + "</thead>".length);

      closingTheadIndex = combinedOutput.indexOf(
        "</thead>",
        closingTheadIndex + 1
      );
      currentIndex++;
    }

    const trComment = () => {
      return `\r\n<!------------------------------------------------------>
<!-- Inner Query loop 2 --->
<!------------------------------------------------------>
<!-- GEN <generic>`;
    };

    const innerLoopComment = `\r\n<!------------------------------------------------------>
<!-- Inner Query loop 3 --->
<!------------------------------------------------------>
<!-- GEN <generic>`;

    const footerComment = (index) => {
      return `<!------------------------------------------------------>
<!-- Body Footer ${index} -------->
<!------------------------------------------------------>
<!-- GEN <generic>`;
    };

    let footerindex = 1;

    let closingTRIndex = combinedOutput.indexOf("</tr>");

    while (closingTRIndex !== -1) {
      combinedOutput =
        combinedOutput.slice(0, closingTRIndex + "</tr>".length) +
        `\n${trComment(currentIndex)}\n${footerComment(footerindex++)}\n` +
        combinedOutput.slice(closingTRIndex + "</tr>".length);

      closingTRIndex = combinedOutput.indexOf("</tr>", closingTRIndex + 1);
      currentIndex++;
    }

    let theadStartIndex = combinedOutput.indexOf(theadComment());
    let theadEndIndex = combinedOutput.indexOf(trComment());

    while (theadStartIndex !== -1 && theadEndIndex !== -1) {
      let contentBetweenComments = combinedOutput.substring(
        theadStartIndex + theadComment().length,
        theadEndIndex
      );

      let trCommentIndex = combinedOutput.indexOf(trComment(), theadEndIndex);
      if (trCommentIndex !== -1) {
        combinedOutput =
          combinedOutput.slice(0, trCommentIndex + trComment().length) +
          "\n" +
          contentBetweenComments +
          innerLoopComment +
          contentBetweenComments +
          combinedOutput.slice(trCommentIndex + trComment().length);
      }

      theadStartIndex = combinedOutput.indexOf(
        theadComment(),
        trCommentIndex + trComment().length
      );
      theadEndIndex = combinedOutput.indexOf(
        trComment(),
        trCommentIndex + trComment().length
      );
    }

    const sections = combinedOutput.split("<!-- Body header");

    for (let i = 1; i < sections.length; i++) {
      const bodyHeaderIndex = sections[i].indexOf("<!-- GEN <generic>");
      const closingIndex = sections[i].indexOf("-->");

      if (closingIndex !== -1) {
        if (bodyHeaderIndex === -1) {
          const bodyHeader = sections[i].substring(0, closingIndex + 3);
          const afterHeader = sections[i].substring(closingIndex + 3);
          sections[i] = `${bodyHeader}\n<!-- GEN <generic> -->\n${afterHeader}`;
        }

        const afterHeader = sections[i].substring(closingIndex + 3);
        if (!afterHeader.includes("<!-- Inner Query loop 1 --->")) {
          sections[i] +=
            `\r\n${theadComment()}${trComment()}${innerLoopComment}${footerComment(footerindex++)}`;
        }
      }
    }

    combinedOutput = sections.join(
      "<!------------------------------------------------------>\n<!-- Body header"
    );

    const formatAttributes = (attributes) => {
      return attributes.replace(/(\w+="[^"]+")/g, "\r\n$1").trim();
    };

    const footerFormattedContent = footerContent.replace(
      /<footer([^>]*)>/,
      (match, attributes) => {
        const formattedAttributes = formatAttributes(attributes);
        return `<footer\r\n      ${formattedAttributes}>`;
      }
    );

    const headerFormattedContent = headerContent.replace(
      /<header([^>]*)>/,
      (match, attributes) => {
        const formattedAttributes = formatAttributes(attributes);
        return `<header\r\n      ${formattedAttributes}>`;
      }
    );

    const formatTag = (tag, content) => {
      return content.replace(
        new RegExp(`<${tag}([^>]*)>`),
        (match, attributes) => {
          const formattedAttributes = formatAttributes(attributes);
          return `<${tag}\r\n      ${formattedAttributes}>`;
        }
      );
    };

    const formattedTags = {};

    const tagsRegex = /<(\w+)([^>]*)>/g;
    let match;
    while ((match = tagsRegex.exec(restOfHtmlContent)) !== null) {
      const tagName = match[1];
      if (tagName.toLowerCase() === "svg" || tagName.toLowerCase() === "path") {
        continue;
      }
      if (!formattedTags[tagName]) {
        formattedTags[tagName] = [];
      }
      formattedTags[tagName].push(match[0]);
    }

    for (const tagName in formattedTags) {
      const tagInstances = formattedTags[tagName];
      for (const tagInstance of tagInstances) {
        restOfHtmlContent = restOfHtmlContent.replace(
          tagInstance,
          formatTag(tagName, tagInstance)
        );
      }
    }

    const modifyCombinedOutput = () => {
      const trRegex = /(<thead[^>]*>)/g;
      const modifiedWithtr = combinedOutput.replace(trRegex, "$1<tr>");

      const closeTrRegex = /(<\/thead>)/g;
      const modifiedOutput = modifiedWithtr.replace(closeTrRegex, "</tr>$1");

      return modifiedOutput;
    };

    const modifiedCombinedOutput = modifyCombinedOutput(combinedOutput);

    const filteredLines = modifiedCombinedOutput
      .split(/(<\/?[^>]+>|<!--[^>]*?-->)(?![^<>]*<\/generic>)/g)
      .filter(
        (line) =>
          !line.includes("<header") &&
          !line.includes("<footer") &&
          !line.includes("</footer")
      )
      .filter((line) => line.trim() !== "")
      .map((line) => {
        if (line.includes("<!--")) {
          line += "\n";
        }
        if (line.includes("<generic>")) {
          line = line.replace("<generic>", "<generic> -->");
        }
        return line.trim() === "" ? "" : line.trim();
      })
      .join("\n")
      .replace(/<generic> -->/g, "<generic> -->\n");

    const head = `
<head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>${extractedArrayName}</title>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.0/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css" />
    <script src="https://cdn.jsdelivr.net/npm/flatpickr"></script>

</head>`;
    const body = `\r\n<body>\n<!------------------------------------------------------>\n<!-- Style Definition --->\n<!------------------------------------------------------>${generateStyle}
<!------------------------------------------------------>\n<!-- Page Header -------->\n<!------------------------------------------------------>\n<!-- GEN <generic> -->
<div style="display: flex; flex-direction: column;">
${headerFormattedContent}
${filteredLines}
<!------------------------------------------------------>
<!-- Page Footer -------->
<!------------------------------------------------------>
<!-- GEN <generic> -->
${footerFormattedContent}
  </div>
<!------------------------------------------------------>
<!-- Bottom Page -------->
<!------------------------------------------------------>
</body>
`;

    const htmlFileContent = `<!DOCTYPE html>\r\n<html lang="en">${head}${body}</html>`;
    return htmlFileContent;
  };

  const saveHtmlToFileSystem = async (content, fileName) => {
    try {
      const htmlFileContent = generateHTMLFile(content);
      if (window.showSaveFilePicker) {
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: fileName,
          types: [
            {
              description: "HTML Files",
              accept: {
                "text/html": [".html"],
              },
            },
          ],
        });

        const writable = await fileHandle.createWritable({
          keepExistingData: false,
        });

        await writable.write(htmlFileContent);
        await writable.close();

        console.log("HTML content saved to file system successfully");
        toast.success("HTML content saved to file system successfully");
      } else {
        const currentDate = new Date().toISOString().replace(/:/g, "-");
        const updatedFileName = `${extractedArrayName}_${currentDate}.html`;

        const blob = new Blob([htmlFileContent], { type: "text/html" });
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(blob);
        anchor.download = updatedFileName;
        anchor.click();
        console.log("HTML content saved to file system successfully");
        toast.success("HTML content saved to file system successfully");
      }
    } catch (error) {
      console.error("Error saving HTML content to file system:", error);
      toast.error("Error saving HTML content to file system:", error);
    }
  };

  const handleSaveToFile = async () => {
    await saveHtmlToFileSystem(htmlContent, `${extractedArrayName}.html`);
  };

  const { arId } = useParams();
  const location = useLocation();
  const arrayName = location.state ? location.state.arrayName : null;
  const description = location.state ? location.state.description : null;
  const category = location.state ? location.state.category : null;
  const dataSourceArID = location.state ? location.state.dataSourceArID : null;
  const isTemplate = "1";

  async function onSaveClick(showToast) {
    try {
      const htmlFileContent = generateHTMLFile(htmlContent);
      let isValid = true;

      function validateControls(controls) {
        controls.forEach((controlItem) => {
          if (controlItem.tab === "Phone Number") {
            const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            if (!phoneNumberRegex.test(controlItem.data)) {
              isValid = false;
              console.error("Invalid phone number:", controlItem.data);
              toast.error("Invalid phone number");
            }
          } else if (controlItem.tab === "Email") {
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if (!emailRegex.test(controlItem.data)) {
              isValid = false;
              console.error("Invalid email address:", controlItem.data);
              toast.error("Invalid email address");
            }
          }

          if (controlItem.control && controlItem.control.length > 0) {
            validateControls(controlItem.control);
          }
        });
      }

      function traverseAndValidate(data) {
        data.forEach((item) => {
          item.Source.forEach((sourceItem) => {
            validateControls(sourceItem.control);
          });
        });
      }

      traverseAndValidate(treeData);

      if (isValid) {
        const response = await WriteArray(
          treeData,
          arId,
          arrayName,
          description,
          category,
          htmlFileContent,
          dataSourceArID
        );

        if (response.ok) {
          console.log("Data saved successfully");
        
          if (showToast) toast.success("Changes saved successfully");
         
        } else {
          console.error("Failed to save data to the backend");

          if (showToast) toast.error("Failed to save data to the backend");
        }
      }
    } catch (error) {
      console.error("Error while saving data:", error);

      if (showToast) toast.error("Error while saving data:");
    }
  }

  async function onSaveClick1(showToast) {
    try {
      const htmlFileContent = generateHTMLFile(htmlContent);
      let isValid = true;

      treeData.forEach((item) => {
        item.Source.forEach((sourceItem) => {
          sourceItem.control.forEach((controlItem) => {
            if (controlItem.tab === "Phone Number") {
              const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
              if (!phoneNumberRegex.test(controlItem.data)) {
                isValid = false;
                console.error("Invalid phone number:", controlItem.data);
                toast.error("Invalid phone number");
              }
            } else if (controlItem.tab === "Email") {
              const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
              if (!emailRegex.test(controlItem.data)) {
                isValid = false;
                console.error("Invalid email address:", controlItem.data);
                toast.error("Invalid email address");
              }
            }
          });
        });
      });

      if (isValid) {
        const response = await WriteArray1(
          treeData,
          arId,
          arrayName,
          description,
          category,
          htmlFileContent,
          dataSourceArID,
          isTemplate
        );

        if (response.ok) {
          console.log("Data saved successfully");
          localStorage.clear();
          if (showToast) toast.success("Changes saved successfully");
          localStorage.clear();
        } else {
          console.error("Failed to save data to the backend");

          if (showToast) toast.error("Failed to save data to the backend");
        }
      }
    } catch (error) {
      console.error("Error while saving data:", error);

      if (showToast) toast.error("Error while saving data:");
    }
  }

  const [fieldData, setFieldData] = useState([]);

  const [extractedArrayName, setextractedArrayName] = useState("");
  const [tempNum, setTempNum] = useState();
  const [templateSource, setTemplateSource] = useState("");

  let APIProvider = window.APIProvider;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    ReadArray(arId)
      .then((jsonData) => {
        if (jsonData) {
          setTreeData(jsonData);
          console.log("jsonData", jsonData);
          const arrayName = jsonData[0].arrayName;

          const templateNum = jsonData[0].IsTemplate;
          setTempNum(templateNum);

          setextractedArrayName(arrayName);

          const sources = jsonData[0].Source;

          setTemplateSource(sources);

          fetch(
            APIProvider.baseURL +
              APIProvider.recordPath.ReadArray +
              "?arID=" +
              jsonData[0].dataSourceArID
          )
            .then((sourceDataResponse) => {
              if (sourceDataResponse.ok) {
                return sourceDataResponse.json();
              } else {
                throw new Error("Failed to fetch source data");
              }
            })
            .then((sourceData) => {
              const fetchedSourceData = JSON.parse(sourceData.source);
              setFieldData(fetchedSourceData);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error fetching source data:", error);
              toast.error("Error fetching source data");
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data:");
        setLoading(false);
      });
  }, [arId]);

  useEffect(() => {
    const arrayFieldNames = fieldData
      ? fieldData
          .filter((item) => item.elementtype === "array")
          .flatMap((item) => item.elements.map((element) => element.fieldname))
      : [];
    setFieldNamestable(arrayFieldNames);
  }, [fieldData]);

  async function fetchDataFromUrl(arId) {
    try {
      const response = await fetch(
        APIProvider.baseURL + APIProvider.recordPath.ReadArray + "?arID=" + arId
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const formattedData = [
        {
          arid: data.arID,
          arrayName: data.arrayName,
          description: data.description,
          dataSourceArID: data.dataSourceArID,
          Source: JSON.parse(data.source),
          IsTemplate: data.isTemplate,
        },
      ];
      return formattedData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  }

  const handleGoBack = async () => {
    try {
      const fetchedData = await fetchDataFromUrl(arId);

      if (fetchedData) {
        if (JSON.stringify(treeData) !== JSON.stringify(fetchedData)) {
          setOpenConfirmation(true);
          localStorage.clear();
        } else {
          Navigate("/Open");
          localStorage.clear();
        }
      } else {
        console.log("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleConfirmGoBack = () => {
    setOpenConfirmation(false);
    Navigate("/Open");
  };

  const handleConfirmGoBackWithSave = () => {
    handleConfirmGoBack();
  };
  const isRootItem = (item, treeData) => {
    return treeData[0].Source[0].id === item.id;
  };

  useEffect(() => {
    setInputValue(inputValue);
  }, []);

  const handleEnterForNumber = (event) => {
    if (event.key === "Enter") {
      handleAgree(event);
    }
  };
  const handleDateChange = (event, timeID) => {
    const newDate = event.target.value;

    const updateTreeData = updateTreeDataDate(treeData, timeID, newDate);
    setTreeData(updateTreeData);

    if (selectedItem && selectedItem.id === timeID) {
      setSelectedItem({
        ...selectedItem,
        data: newDate,
      });
    }
  };

  const updateTreeDataDate = (treeData, timeID, newDate) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsDate(source.control, timeID, newDate),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsDate = (controls, timeID, newDate) => {
    return controls.map((c) => {
      if (c.id === timeID) {
        return {
          ...c,
          data: newDate,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsDate(c.control, timeID, newDate),
        };
      }
      return c;
    });
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="containertopsection">
          <FormTopSection
            searchQuery={searchQuery}
            handleSearch={handleSearch}
            handleControls={handleControls}
            handleMenuButtonClick={handleMenuButtonClick}
            handleTabshwButtonClick={handleTabshwButtonClick}
            onSaveClick={onSaveClick}
            handleSaveToFile={handleSaveToFile}
            handleGoBack={handleGoBack}
            openConfirmation={openConfirmation}
            handleCloseConfirmation={handleCloseConfirmation}
            handleConfirmGoBackWithSave={handleConfirmGoBackWithSave}
            treeData={treeData}
            renderControl={renderControl}
            arId={arId}
            setControlsVisible={setControlsVisible}
            controlsVisible={controlsVisible}
            onSaveClick1={onSaveClick1}
            templateSource={templateSource}
            tempNum={tempNum}
            dataSourceArID={dataSourceArID}
            category={category}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            setSelectedItemID={setSelectedItemID}
            setSelectedItem={setSelectedItem}
            htmlFileContent={generateHTMLFile(htmlContent)}
            selectedItem={selectedItem}
            setEditedName={setEditedName}
            setSelectedControl={setSelectedControl}
          />

          {loading ? (
            <CircularProgress
              style={{
                position: "relative",
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          ) : (
            <div className="container-top">
              {controlsVisible && (
                <div
                  id="sidebar"
                  className={
                    "sidebar" +
                    (showmenu ? " show" : "") +
                    (showmenudsk ? " showdsk" : "")
                  }
                >
                  {controlsVisible && (
                    <div className="sidebardata">
                      <DialogComponent
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        handleAddControl={handleAddControl}
                        searchQuery={searchQuery}
                        filterTreeData={filterTreeData}
                        setContainerIcon={setContainerIcon}
                        ContainerIcon={ContainerIcon}
                        setInputClicked={setInputClicked}
                        InputClicked={InputClicked}
                        setDisplayClicked={setDisplayClicked}
                        displayClicked={displayClicked}
                        setIconClicked={setIconClicked}
                        IconsClicked={IconsClicked}
                      />
                    </div>
                  )}
                  {controlsVisible && (
                    <div className="treeitem">
                      {treeData.length > 0 && (
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="tree" type="CONTROL">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {treeData[0].Source &&
                                treeData[0].Source.length > 0 &&
                                treeData[0].Source &&
                                filterTreeData(treeData[0].Source, searchQuery)
                                  .length > 0 ? (
                                  filterTreeData(
                                    treeData[0].Source,
                                    searchQuery
                                  ).map((item, index) => {
                                    const uniqueKey = `${item.type}-${index}`;
                                    return (
                                      <TreeItem
                                        key={uniqueKey}
                                        item={item}
                                        addItem={handleAddItem}
                                        selectedNode={selectedNode}
                                        setSelectedNode={setSelectedNode}
                                        index={index}
                                        treeData={treeData}
                                        setTreeData={setTreeData}
                                        handleSelectItem={handleSelectItem}
                                        selectedItem={selectedItem}
                                        onDragEnd={onDragEnd}
                                        setEditedName={setEditedName}
                                        setSelectedItem={setSelectedItem}
                                        setSelectedControl ={setSelectedControl}
                                        setSelectedItemID ={setSelectedItemID}
                                        setSelectedOptioncheck={setSelectedOptioncheck}
                                        setSelectedOptionSwitchcheck={setSelectedOptionSwitchcheck}
                                         setSelectedPositioncheck={setSelectedPositioncheck}
                                         editedName={editedName}
                                         setEditedData={setEditedData}
                                        setSelectedOption={setSelectedOption}
                                         settabSelect={settabSelect}
                                      setTableHeader={setTableHeader}
                                         setSourceData={setSourceData}
                                          setSelectedControlType={setSelectedControlType}
                                       setIsOptionSelected={setIsOptionSelected}
                                       isOptionSelected={isOptionSelected}
                                        settableProp={settableProp}
                                      />
                                    );
                                  })
                                ) : (
                                  <p
                                    style={{
                                      textAlignLast: "center",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    No Results
                                  </p>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                    </div>
                  )}
                </div>
              )}

              {controlsVisible && (
                <div
                  className={
                    "tgl-menu-panel" + (showmenudsk ? " reverticn" : "")
                  }
                  onClick={handleMenushwDskButtonClick}
                >
                  <Tooltip
                    id="tooltipstyleLft"
                    arrow
                    title={
                      <span className="tooltipstyleLft">
                        Show/Hide left panel
                      </span>
                    }
                    placement="right-end"
                  >
                    {Theme.ChevronRight}
                  </Tooltip>
                </div>
              )}

              <div className="middle-section" id="0">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {treeData.map((item) => {
                    if (!controlsVisible && dataSourceArID > 0) {
                      const filteredFields = fieldData
                        ? fieldData.filter(
                            (field) =>
                              field.fieldtype === "text" ||
                              field.fieldtype === "datetime" ||
                              field.fieldtype === "date" ||
                              field.fieldtype === "time" ||
                              field.fieldtype === "Image" ||
                              field.fieldtype === "Iframe" ||
                              field.fieldtype === "boolean" ||
                              field.fieldtype === "number"
                          )
                        : [];

                      const filterTable = fieldData.filter(
                        (field) =>
                          field.elementtype === "array" &&
                          field.type === "table"
                      );

                      const processControl = (control) => {
                        const hasCurlyBrackets = (str) => /{.*?}/.test(str);

                        const getCleanedData = (data) => {
                          return data.replace(/{|}/g, "").toLowerCase();
                        };
                 

                        const replaceDataIfNeeded = (data, matchingField) => {
                          return hasCurlyBrackets(data) && matchingField
                            ? matchingField.fieldvalue
                            : data;
                        };

                        if (
                          control.type === "TextBox" ||
                          control.type === "TextArea" ||
                          control.type === "Header" ||
                          control.type === "SubHeader" ||
                          control.type === "Checkbox" ||
                          control.type === "Radio" ||
                          control.htmlTag === "td"
                        ) {
                          const cleanedData = getCleanedData(control.data);
                          let matchingField;

                          if (control.type !== "Table") {
                            matchingField = filteredFields.find(
                              (field) =>
                                field.fieldname.toLowerCase() === cleanedData
                            );
                          } else {
                            matchingField = filterTable[0]?.elements.find(
                              (field) =>
                                field.fieldname.toLowerCase() === cleanedData
                            );
                          }

                          return {
                            ...control,
                            data: replaceDataIfNeeded(
                              control.data,
                              matchingField
                            ),
                          };
                        } else if (control.type === "Date") {
                          const cleanedData = getCleanedData(control.inputText);
                          let matchingField;

                          if (control.type !== "Table") {
                            matchingField = filteredFields.find(
                              (field) =>
                                field.fieldname.toLowerCase() === cleanedData
                            );
                          } else {
                            matchingField = filterTable[0]?.elements.find(
                              (field) =>
                                field.fieldname.toLowerCase() === cleanedData
                            );
                          }

                          return {
                            ...control,
                            inputText: replaceDataIfNeeded(
                              control.inputText,
                              matchingField
                            ),
                          };
                        } else if (
                          control.type === "Image" ||
                          control.type === "Iframe" ||
                          control.type === "Switch"
                        ) {
                          const cleanedData = getCleanedData(control.imageText);
                          let matchingField;

                          if (control.type !== "Table") {
                            matchingField = filteredFields.find(
                              (field) =>
                                field.fieldname.toLowerCase() === cleanedData
                            );
                          } else {
                            matchingField = filterTable[0]?.elements.find(
                              (field) =>
                                field.fieldname.toLowerCase() === cleanedData
                            );
                          }

                          return {
                            ...control,
                            imageText: replaceDataIfNeeded(
                              control.imageText,
                              matchingField
                            ),
                          };
                        } else {
                          return control;
                        }
                      };

                      const processControls = (controls) => {
                        return controls.map((control) => {
                          if (control.control && control.control.length > 0) {
                            const processedNestedControls = processControls(
                              control.control
                            );

                            return {
                              ...control,
                              control: processedNestedControls.map(
                                (nestedControl) => {
                                  if (
                                    nestedControl.type === "Date" &&
                                    nestedControl.inputText
                                  ) {
                                    const dateParts =
                                      nestedControl.inputText.split("/");
                                    if (dateParts.length === 3) {
                                      const month = dateParts[0].padStart(
                                        2,
                                        "0"
                                      );
                                      const day = dateParts[1].padStart(2, "0");
                                      const year = dateParts[2];
                                      const formattedDate = `${year}-${month}-${day}`;
                                      nestedControl.data = formattedDate;
                                      nestedControl.inputType = "date";
                                      nestedControl.inputText = "";
                                    } else {
                                      console.error(
                                        "Invalid date format:",
                                        nestedControl.inputText
                                      );
                                    }
                                  } else if (
                                    nestedControl.type === "Image" &&
                                    nestedControl.imageText
                                  ) {
                                    nestedControl.media =
                                      nestedControl.imageText;
                                    nestedControl.imageText = "";
                                  } else if (
                                    nestedControl.type === "Iframe" &&
                                    nestedControl.imageText
                                  ) {
                                    nestedControl.data =
                                      nestedControl.imageText;
                                    nestedControl.imageText = "";
                                  } else if (
                                    nestedControl.type === "Checkbox" &&
                                    (nestedControl.data === "true" ||
                                      nestedControl.data === "false" ||
                                      nestedControl.data === 0 ||
                                      nestedControl.data === 1)
                                  ) {
                                    nestedControl.check =
                                      nestedControl.data === "true" ||
                                      nestedControl.data === 1;
                                  } else if (
                                    nestedControl.type === "Radio" &&
                                    (nestedControl.data === "true" ||
                                      nestedControl.data === "false" ||
                                      nestedControl.data === 0 ||
                                      nestedControl.data === 1)
                                  ) {
                                    nestedControl.check =
                                      nestedControl.data === "true" ||
                                      nestedControl.data === 1;
                                  } else if (
                                    nestedControl.type === "Switch" &&
                                    nestedControl.imageText
                                  ) {
                                    nestedControl.switchcheck =
                                      nestedControl.imageText === "true" ||
                                      nestedControl.data === 1;

                                    nestedControl.imageText = "";
                                  } else if (
                                    nestedControl.type === "Radio" &&
                                    nestedControl.imageText
                                  ) {
                                    nestedControl.check =
                                      nestedControl.imageText === "true" ||
                                      nestedControl.data === 1;

                                    nestedControl.imageText = "";
                                  }
                                  return nestedControl;
                                }
                              ),
                            };
                          } else {
                            return processControl(control);
                          }
                        });
                      };

                      const processedControls = item.Source.map((source) => {
                        return {
                          ...source,
                          control: processControls(source.control),
                        };
                      });
                      return processedControls.map((source) =>
                        source.control.map((control) => {
                          if (control.type === "Date" && control.inputText) {
                            const dateParts = control.inputText.split("/");
                            if (dateParts.length === 3) {
                              const month = dateParts[0].padStart(2, "0");
                              const day = dateParts[1].padStart(2, "0");
                              const year = dateParts[2];
                              const formattedDate = `${year}-${month}-${day}`;
                              control.data = formattedDate;
                              control.inputType = "date";
                              control.inputText = "";
                            } else {
                              console.error(
                                "Invalid date format:",
                                control.inputText
                              );
                            }
                          } else if (
                            control.type === "Image" &&
                            control.imageText
                          ) {
                            control.media = control.imageText;
                            control.imageText = "";
                          } else if (
                            control.type === "Iframe" &&
                            control.imageText
                          ) {
                            control.data = control.imageText;
                            control.imageText = "";
                          } else if (
                            control.type === "Switch" &&
                            control.imageText
                          ) {
                            control.switchcheck =
                              control.imageText === "true" ||
                              control.imageText === 1;
                            control.imageText = "";
                          } else if (
                            control.type === "Checkbox" &&
                            (control.data === "true" ||
                              control.data === "false" ||
                              control.data === 0 ||
                              control.data === 1)
                          ) {
                            control.check =
                              control.data === "true" ||
                              control.data === "true" ||
                              control.data === "false" ||
                              control.data === 0 ||
                              control.data === 1;
                          } else if (control.type === "Radio" && control.data) {
                            control.check =
                              control.data === "true" || control.data === 1;
                          }
                          return renderControl(control);
                        })
                      );
                    } else {
                      return item.Source[0].control.map((control) =>
                        renderControl(control)
                      );
                    }
                  })}
                </div>
              </div>

              {controlsVisible && (
                <div
                  className={
                    "tgl-right-panel " + (showtabdsk ? "reverticn" : "")
                  }
                  onClick={handleTabshwDskButtonClick}
                >
                  <Tooltip
                    id="tooltipstyleRht"
                    arrow
                    title={
                      <span className="tooltipstyleRht">
                        Show/Hide right panel
                      </span>
                    }
                    placement="left-end"
                  >
                    {Theme.ChevronRight}
                  </Tooltip>
                </div>
              )}
              {controlsVisible && (
                <div
                  id="stylesection"
                  className={
                    "style-section" +
                    (showtab ? " show" : "") +
                    (showtabdsk ? " showdsk" : "")
                  }
                >
                  {controlsVisible && (
                    <GeneralSection
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      rightsidetabs={rightsidetabs}
                      selectedItem={selectedItem}
                      isRootItem={isRootItem}
                      treeData={treeData}
                      editedData={editedData}
                      handleEditChange={handleEditChange}
                      handleEditChangeforTable={handleEditChangeforTable}
                      tabSelect={tabSelect}
                      handleEnterForNumber={handleEnterForNumber}
                      handleAgree={handleAgree}
                      handlePopupClick={handlePopupClick}
                      isPopupVisible={isPopupVisible}
                      handleClose={handleClose}
                      selectedOption={selectedOption}
                      dataSourceArID={dataSourceArID}
                      fieldNames={fieldNames}
                      fieldNamestable={fieldNamestable}
                      handleChange={handleChange}
                      inputValueRadio={inputValueRadio}
                      handleInputChangeRadio={handleInputChangeRadio}
                      handleAddControlRadio={handleAddControlRadio}
                      handleRadioPositionChange={handleRadioPositionChange}
                      inputValue={inputValue}
                      handleInputChange={handleInputChange}
                      handleAddControlCheckbox={handleAddControlCheckbox}
                      selectedOptioncheck={selectedOptioncheck}
                      handleCheckboxChange={handleCheckboxChange}
                      handleRadioChange={handleRadioChange}
                      selectedPositioncheck={selectedPositioncheck}
                      handleCheckboxPositonChange={handleCheckboxPositonChange}
                      selectedOptionSwitchcheck={selectedOptionSwitchcheck}
                      isSwitched={isSwitched}
                      handleSwitchChange={handleSwitchChange}
                      inputRef={inputRef}
                      handleImageChange={handleImageChange}
                      handleImageClick={handleImageClick}
                      handleIFrameChange={handleIFrameChange}
                      handleIFrameClick={handleIFrameClick}
                      handleKeyPress={handleKeyPress}
                      handleDateChange={handleDateChange}
                      isOpen={isOpen}
                      handleEditChangeName1={handleEditChangeName1}
                      handleRenameConfirm={handleRenameConfirm}
                      editedName={editedName}
                      isEditing={isEditing}
                      setControlsVisible={setControlsVisible}
                      controlsVisible={controlsVisible}
                      inputType={inputType}
                      addNewColumn={addNewColumn}
                      data={editedData}
                      setTreeData={setTreeData}
                      selectedItemID={selectedItemID}
                      selectedControlType={selectedControlType}
                      setSelectedControlType={setSelectedControlType}
                      handleControlTypeChange={handleControlTypeChange}
                      tableProp={tableProp}
                      setEditedData={setEditedData}
                      setEditedName={setEditedName}
                      fieldData={fieldData}
                      setFieldNames={setFieldNames}
                      inputText={inputText}
                      url={url}
                      selectedControl={selectedControl}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </DndProvider>
    </>
  );
};
export default RecursiveTreeView;