import { styled } from "@mui/material/styles";
import { TableCell } from "@mui/material";

const shouldForwardProp = (prop) =>
  prop !== "bootstrapZones" &&
  prop !== "showPagination" &&
  prop !== "itemsPerPage" &&
  prop !== "currentPage" &&
  prop !== "totalPages" &&
  prop !== "mergeFields" &&
  prop !== "mergeCounts";

export const StyledTableCellBody = styled(TableCell)({
  border: "1px solid black",
  padding: "10px",
});
export const StyledTableCellHeader = styled(StyledTableCellBody)({
  fontWeight: "bold",
  cursor: "pointer",
});
const cardStyle = (bootstrapZones) => ({
  display: "grid",
  gridTemplateColumns: bootstrapZones.map((width) => `${width}fr`).join(" "),
  gridColumnGap: "10px",
  gridRowGap: "10px",
  borderBottom: "1px solid gray",
  marginBottom: "10px",
  paddingBottom: "10px",
});

export const RenderingCardDiv = styled("div", { shouldForwardProp })(
  ({
    bootstrapZones,
    showPagination,
    index,
    itemsPerPage,
    currentPage,
    totalPages,
    contents,
  }) => ({
    ...cardStyle(bootstrapZones),
    overflow: "auto",
    borderBottom:
      (showPagination &&
        (index === itemsPerPage - 1 ||
          (currentPage === totalPages &&
            index + 1 === contents.length % itemsPerPage))) ||
      (!showPagination && index === contents.length - 1)
        ? "none"
        : "1px solid gray",
    marginBottom:
      (showPagination &&
        (index === itemsPerPage - 1 ||
          (currentPage === totalPages &&
            index + 1 === contents.length % itemsPerPage))) ||
      (!showPagination && index === contents.length - 1)
        ? "0"
        : "10px",
    paddingBottom:
      (showPagination &&
        (index === itemsPerPage - 1 ||
          (currentPage === totalPages &&
            index + 1 === contents.length % itemsPerPage))) ||
      (!showPagination && index === contents.length - 1)
        ? "0"
        : "10px",
  })
);
export const MergeCellsCardDiv = styled("div", { shouldForwardProp })(
  ({ keyy, i, bootstrapZones, mergeFields, mergeCounts }) => ({
    gridColumn:
      mergeFields.includes(keyy) &&
      (i % bootstrapZones.length) + mergeCounts[mergeFields.indexOf(keyy)] <=
        bootstrapZones.length
        ? `span ${mergeCounts[mergeFields.indexOf(keyy)]}`
        : mergeFields.includes(keyy) &&
          (i % bootstrapZones.length) + mergeCounts[mergeFields.indexOf(keyy)] >
            bootstrapZones.length
        ? `span ${bootstrapZones.length - (i % bootstrapZones.length)}`
        : "auto",
    gridRow:
      mergeFields.includes(keyy) &&
      mergeCounts[mergeFields.indexOf(keyy)] %
        (bootstrapZones.length - (i % bootstrapZones.length)) ===
        0
        ? `span ${Math.floor(
            mergeCounts[mergeFields.indexOf(keyy)] /
              (bootstrapZones.length - (i % bootstrapZones.length))
          )}`
        : mergeFields.includes(keyy) &&
          mergeCounts[mergeFields.indexOf(keyy)] %
            (bootstrapZones.length - (i % bootstrapZones.length)) !==
            0
        ? `span ${
            Math.floor(
              mergeCounts[mergeFields.indexOf(keyy)] /
                (bootstrapZones.length - (i % bootstrapZones.length))
            ) + 1
          }`
        : "auto",
  })
);

export const StyledInput = styled("input")(
  ({ fieldErrors, row, keyy, types, isColor, isCard }) => ({
    border: fieldErrors.some(
      (error) =>
        error.externalIdForSorting === row.externalIdForSorting &&
        error.key === keyy
    )
      ? "1px solid red"
      : "1px solid lightgray",
    borderRadius: "0.5rem",
    padding: isColor ? null : "0.7rem",
    textDecoration: types[keyy] === "url" ? "underline" : "none",
    color: types[keyy] === "url" ? "#007bff" : "inherit",
    cursor: types[keyy] === "url" ? "pointer" : "auto",
    width: isCard && !isColor ? "100%" : null,
  })
);

// export const DateCarouselCard = styled(Card, { shouldForwardProp })(({ theme, isMobile }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// export const ArrowButton = styled(IconButton, { shouldForwardProp })(({ theme, isMobile }) => ({
//   marginLeft: isMobile ? 0 : theme.spacing(2),
//   marginRight: isMobile ? 0 : theme.spacing(2),
//   backgroundColor: isMobile ? "transparent" : theme.palette.primary.main,
//   "&:hover": {
//     backgroundColor: isMobile ? "transparent" : theme.palette.primary.dark,
//   },
//   "&.Mui-disabled": {
//     backgroundColor: isMobile ? "transparent" : theme.palette.gray.light,
//     pointerEvents: "none",
//     cursor: "default",
//   },
// }));

// export const DateItemDiv = styled("div")(({ theme }) => ({
//   margin: "1rem",
//   cursor: "pointer",
//   fontSize: "1rem",
//   position: "relative",
// }));

// export const ShadowDiv = styled("div")(({ selectedDate }) => ({
//   position: "absolute",
//   top: -6,
//   left: selectedDate ? -10 : -6,
//   width: "120%",
//   height: "100%",
//   backgroundColor: "rgba(200, 200, 200, 0.5)",
//   borderRadius: "0.5rem",
//   padding: "0.35rem",
//   zIndex: 1,
// }));

// export const DayDiv = styled("div")(({ theme, isMobile }) => ({
//   fontSize: isMobile ? "1.2rem" : "1.5rem",
//   fontWeight: "bold",
//   color: theme.palette.primary.dark,
//   display: "flex",
//   justifyContent: "center",
// }));

// export const MonthDiv = styled("div")(({ theme, isMobile }) => ({
//   fontSize: isMobile ? "0.7rem" : "1rem",
//   display: "flex",
//   justifyContent: "center",
// }));

// export const datesStyle = {
//   display: "flex",
// };
