import React from "react";
import { Select, MenuItem } from "@mui/material";

function NodeBorderDropDown({
  selectedItemID,
  editedProperties,
  handlePropertyChange,
}) {
  const selectedItem = editedProperties.find(
    (item) => item[0].diagToolID === selectedItemID
  );

  const borderSizeObj = selectedItem.find(
    (prop) => prop.propertyName === "Node_Border_size"
  );
  const borderColorObj = selectedItem.find(
    (prop) => prop.propertyName === "Node_Border_color"
  );
  const borderRadiusObj = selectedItem.find(
    (prop) => prop.propertyName === "Node_Border_radius"
  );

  const handleBorderColorClear = () => {
    const borderColorObj = selectedItem.find(
      (prop) => prop.propertyName === "Node_Border_color"
    );

    console.log("borderColorObj ", borderColorObj);
    if (borderColorObj) {
      borderColorObj.diagPropValue = "";
      handlePropertyChange("Node_Border_color", "transparent");
    }
  };

  const handleChange = (propertyName, value) => {
    handlePropertyChange(propertyName, value);
  };

  return (
    <div>
      <div className="colorforborder">
        <div className="inptcheck">
          <div className="ipubrdr">
            <input
              type="number"
              name="borderSize"
              value={borderSizeObj?.diagPropValue || ""}
              onChange={(e) => handleChange("Node_Border_size", e.target.value)}
            />
            <span className="unit-dropdown">
              <Select
                value="px"
                onChange={(e) => {}}
                style={{
                  width: "40px",
                  height: "27px",
                  padding: "10px 11px 10px 0px",
                }}
                displayEmpty
                IconComponent={() => null}
                MenuProps={{ style: { display: "none" } }}
              >
                <MenuItem value="px" disabled className="custom-menuitem">
                  px
                </MenuItem>
              </Select>
            </span>
          </div>
          <span className="thickness">Border Size</span>
        </div>

        <div className="colrfrborder">
          <input
            type="color"
            name="borderColor"
            value={borderColorObj?.diagPropValue || "#000000"}
            onChange={(e) => handleChange("Node_Border_color", e.target.value)}
          />
        </div>
        <div className="iconset clearColorWrap">
          <div className="clearColor" onClick={handleBorderColorClear}>
            <span className="crsLinelft"></span>
            <span className="crsLinerht"></span>
            <span className="tooltiptext">Transparent</span>
          </div>
        </div>
      </div>
      <div className="colorforborder">
        <div className="inptcheck">
          <div className="ipubrdr">
            <input
              type="number"
              name="borderRadius"
              value={borderRadiusObj?.diagPropValue || ""}
              onChange={(e) =>
                handleChange("Node_Border_radius", e.target.value)
              }
            />
            <span className="unit-dropdown">
              <Select
                value="px"
                onChange={(e) => {}}
                style={{
                  width: "40px",
                  height: "27px",
                  padding: "10px 11px 10px 0px",
                }}
                displayEmpty
                IconComponent={() => null}
                MenuProps={{ style: { display: "none" } }}
              >
                <MenuItem value="px" disabled className="custom-menuitem">
                  px
                </MenuItem>
              </Select>
            </span>
          </div>
          <span className="thickness">Border Radius</span>
        </div>
      </div>
    </div>
  );
}

export default NodeBorderDropDown;
