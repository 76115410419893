// deleteNodeFunctions.js

import { useCallback } from "react";

//JUST IF NEEDED IN THE FUTURE
export const useDeleteNodeWithoutRerouting = (setNodes) => {
  return useCallback(
    (nodeId) => {
      console.log("nodeId ", nodeId);
      setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
    },
    [setNodes]
  );
};

export const useDeleteNode = (
  setEdges,
  nodes,
  edges,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  MarkerType
) => {
  return useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
              animated: true,
              style: {
                strokeWidth: 4,
                stroke: "red",
              },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 10,
                height: 10,
                color: "red",
              },
            }))
          );

          const updatedEdges = createdEdges.map((edge) => ({
            ...edge,
            timeoutId: setTimeout(() => {
              setEdges((prevEdges) =>
                prevEdges.map((prevEdge) =>
                  prevEdge.id === edge.id
                    ? {
                        ...prevEdge,
                        animated: false,
                        style: {
                          strokeWidth: 1,
                        },
                        markerEnd: {
                          type: MarkerType.ArrowClosed,
                          width: 30,
                          height: 30,
                        },
                      }
                    : prevEdge
                )
              );
            }, 3000),
          }));

          return [...remainingEdges, ...updatedEdges];
        }, edges)
      );
    },
    [
      setEdges,
      nodes,
      edges,
      getIncomers,
      getOutgoers,
      getConnectedEdges,
      MarkerType,
    ]
  );
};
export const useUpdateNodeAndChildren = (nodes, edges) => {
  const updateNodeAndChildren = useCallback(
    (parentNode, node) => {
      // Calculate the new position based on the dragged node's position
      const offsetY = parentNode.position.y - node.position.y + 100;

      // Update the position of the current node
      node.position.y = node.position.y + offsetY;

      // Recursively update children nodes
      edges
        .filter((edge) => edge.source === node.id)
        .forEach((edge) => {
          const connectedNode = nodes.find((n) => n.id === edge.target);
          if (connectedNode) {
            updateNodeAndChildren(node, connectedNode);
          }
        });
    },
    [nodes, edges]
  );

  return updateNodeAndChildren;
};

export const useNodeDragStop = (
  setNodes,
  nodes,
  edges,
  updateNodeAndChildren
) => {
  return useCallback(
    (event, draggedNode) => {
      var connectedNodes = edges
        .filter((edge) => edge.source === draggedNode.id)
        .map((edge) => edge.target);

      // Update the dragged node and its connected nodes
      nodes.forEach((node) => {
        if (node.id === draggedNode.id || connectedNodes.includes(node.id)) {
          updateNodeAndChildren(draggedNode, node);
        }
      });

      setNodes([...nodes]); // Trigger a state update to re-render
    },
    [setNodes, nodes, edges, updateNodeAndChildren]
  );
};

export const useNodeClick = (setSelectedNode, setNodeDrawerOpen) => {
  return useCallback(
    (event, clickedNode) => {
      setSelectedNode(clickedNode);
      setNodeDrawerOpen(true);
    },
    [setSelectedNode]
  );
};
