import React from "react";
import themeProvider from "../../ThemeProvider";
import { Card, Button, CardContent, CardMedia } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Action } from "./ActionList";
const CardComponent = ({
  image,
  title,
  text,
  textSize,
  position,
  size,
  itemID,
  article,
  admin,
  handleDialogToggle,
}) => {
  const cardHeightLeft = size * 0.4;

  const style = {
    position: "relative",
    width: position === "left" ? `${size}px` : `${size}px`,
    height: position === "left" ? `${cardHeightLeft}px` : `${size}px`,
    backgroundColor: "white",
    display: "flex",
    justifyContent: position === "left" ? "flex-start" : "flex-start",
    alignItems: "flex-start",
    flexDirection: position === "left" ? "row" : "column",
    margin: "1rem 0",
  };

  const imgStyle = {
    width: position === "left" ? `${size / 2}px` : `${size}px`,
    height: position === "left" ? `${cardHeightLeft}px` : `${size / 2}px`,
    objectFit: "cover",
    objectPosition: "center",
    marginRight: position === "left" ? "10px" : "0",
    marginBottom: position === "left" ? "0" : "10px",
  };

  const titleStyle = {
    fontSize: `${textSize}px`,
    fontWeight: "bold",
    color: themeProvider.palette.primary.main,
  };
  const textStyle = {
    fontSize: `${textSize * 0.75}px`,
    maxHeight: "4rem",
    overflow: "hidden",
  };


  // const isAdmin = () => {
  //   let result =
  //     auth.user !== null &&
  //     auth.user !== undefined &&
  //     auth.user.isAdmin === true;
  //   return result;
  // };

  const handleGoToArticleClick = () => {
    // Open the link in a new tab or window
    window.open(article.toString(), "_blank");
  };

  const isAdmin = () => {
    return admin;
  };
  return (
    <Card style={style}>
      <CardMedia
        component="img"
        style={imgStyle}
        image={image}
        alt="landing page image"
      />
      <CardContent>
        <div style={{ padding: "10px" }}>
          <p style={titleStyle}>{title}</p>
          <div
            style={textStyle}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          <Button
            style={textStyle}
            sx={{
              fontSize: "0.8rem",
              padding: 0,
              fontWeight: "bold",
              color: "grey",
            }}
            onClick={handleGoToArticleClick}
          >
            {"View More"}
          </Button>
          {isAdmin() && (
            <Button
              style={{ position: "absolute", top: 10, right: 10 }}
              onClick={(event) =>
                handleDialogToggle(event, itemID, title, Action.EDIT_ITEM)
              }
            >
              <EditIcon
                style={{
                  backgroundColor: "#f2f2f2",
                  borderColor: "#e8e8e8",
                  borderWidth: "2px",
                  borderRadius: "25px",
                  height: "2rem",
                  width: "2rem",
                  padding: "0.5rem",
                  marginLeft: "5%",
                  color: "#212325",
                }}
              />
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
