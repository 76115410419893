import React from "react";
import ArticleCard from "../../Components/landingPage/ArticleCard";
import MobileArticleCard from "../../Components/landingPage/MobileArticleCard";

export default function CallArticleComponent({
  section,
  hideArray,
  admin,
  handleDialogToggle,
  isMobile,
}) {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {section.defView === 10 &&
        !hideArray.includes(10) &&
        section.items &&
        section.items.map((item, itemIndex) =>
          isMobile ? (
            <MobileArticleCard
              key={itemIndex}
              feedID={item.feedID}
              image={item.mediaURL}
              title={item.header}
              description={item.description}
              size={200}
              textSize={23}
            />
          ) : (
            <ArticleCard
              key={itemIndex}
              feedID={item.feedID}
              image={item.mediaURL}
              title={item.header}
              description={item.description}
              size={300}
              textSize={23}
              admin={admin}
              handleDialogToggle={handleDialogToggle}
            />
          )
        )}
    </div>
  );
}
