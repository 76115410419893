import { Card, CardContent, Box, Button, CardActions } from "@mui/material";
import React from "react";
import { Navigate, useHistory, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
export default function MenuCardComponent({
  data,
  size,
  textSize,
  handleDetails,
  handleEditDiagram,
  caller,
  isAddCard,
}) {
  const Navigate = useNavigate();

  // const handleSelect = () => {
  //   if (data.DiagID === 0) {
  //     console.log(data.ArID);
  //     Navigate(`/RecursiveTreeView/${data.ArID}`);
  //   } else {
  //     console.log("Non-selectable item clicked");
  //   }
  // };

  return (
    <>
      <Card
        sx={{
          width: `${size}px`,
          height: "auto",
          marginBottom: "1rem",
          marginRight: "1rem",
          display: "flex",
          flexDirection: "column",
          transition: "box-shadow 0.3s",
          "&:hover": {
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <CardContent sx={{ height: isAddCard ? "100%" : "auto" }}>
          {isAddCard === 1 && (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleEditDiagram}
            >
              <AddIcon
                fontSize="large"
                color="primary"
                style={{ fontSize: 100 }}
              />
            </div>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div>
              {data?.mediaURL && (
                <>
                  <img
                    src={data.mediaURL}
                    alt={data.name}
                    style={{
                      width: "2rem",
                      height: "2rem",
                      marginRight: "0.5rem",
                    }}
                  />
                </>
              )}
            </div>
            <p
              style={{
                color: "#1976d2",
                fontWeight: "bold",
                fontSize: textSize,
              }}
            >
              {data?.diagID === 0 ? data?.name : data?.title}
            </p>
          </Box>
          <Box>
            <div
              style={{ fontSize: textSize * 0.75, textAlign: "center" }}
              dangerouslySetInnerHTML={{
                __html: data?.explanation,
              }}
            />
          </Box>
        </CardContent>
        {caller === 1 ? (
          <CardActions
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {data?.diagID === 0 ? (
              <Button
                onClick={() => handleDetails(data)}
                sx={{
                  fontSize: "1rem",
                  width: "50%",
                  height: "85%",
                  borderRadius: "30px",
                }}
                variant="outlined"
              >
                Select
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => handleDetails(data)}
                  sx={{
                    fontSize: "1rem",
                    width: "50%",
                    height: "85%",
                    borderRadius: "30px",
                  }}
                  variant="outlined"
                >
                  Overview
                </Button>
                <Button
                  onClick={() => handleEditDiagram(data)}
                  sx={{
                    fontSize: "1rem",
                    width: "50%",
                    height: "85%",
                    borderRadius: "30px",
                  }}
                  variant="outlined"
                >
                  Edit
                </Button>
              </>
            )}
          </CardActions>
        ) : (
          isAddCard !== 1 && (
            <CardActions
              sx={{
                marginTop: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  fontSize: "1rem",
                  width: "50%",
                  height: "85%",
                  borderRadius: "30px",
                }}
                variant="outlined"
                onClick={() => handleDetails(data)}
              >
                Select
              </Button>
            </CardActions>
          )
        )}
      </Card>
    </>
  );
}
