import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { MenuItem, Select } from "@mui/material";
import PropertiesAccordion from "../reusableComponents/PropertiesAccordion";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import debounce from "lodash/debounce"; // Import lodash debounce

const StyledAlignItems = styled("div")({
  width: "250px",
  flexShrink: 0,
  height: "calc(-54px + 100vh)",
  overflow: "auto",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  transition: "width 0.3s ease 0s",
});

const Border = ({
  matchedStyles,
  updateDefaultStyle,
  borderWidth,
  setborderWidth,
  setDefaultStyleState,
  borderRadius,
  setborderRadius,
  borderColorValue,
  setborderColorValue,
  defaultStyle,
  setDefaultStyle,
  styleType,
}) => {

 



  const defaultStyleObject = defaultStyle.find(
    (style) => style.styleType === styleType
  );

  useEffect(() => {
    if (defaultStyleObject) {
      const updatedStyle = defaultStyle.map((style) => {
        if (style.styleType === styleType) {
          return {
            ...style,
            style: {
              ...style.style,
              borderWidth: borderWidth,
              borderRadius: borderRadius,
              borderColor: borderColorValue,
            },
          };
        }
        return style;
      });

      setDefaultStyle(updatedStyle);
      updateDefaultStyle(updatedStyle);
      setDefaultStyleState(updatedStyle);
    }
  }, [borderWidth, styleType, borderRadius, borderColorValue]);

  const getNumericAndUnit = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }

    if (value === undefined || value === null) {
      return {
        numeric: "",
        unit: "px",
      };
    }

    const match = value.match(/^(-?[\d.]+)(\D*)$/);
    if (match) {
      const numeric = match[1];
      const unit = match[2] !== "" ? match[2] : "px";
      return {
        numeric: numeric,
        unit: unit,
      };
    }
    return {
      numeric: "",
      unit: "px",
    };
  };

  const debouncedSetBorderWidth = debounce((value) => {
    setborderWidth(value);
  }, 10);

  const debouncedSetBorderRadius = debounce((value) => {
    setborderRadius(value);
  }, 10);

  const handleBorderNumericChange = (e) => {
    const numericValue = Math.max(0, e.target.value);
    const unit = getNumericAndUnit(borderWidth).unit;
    debouncedSetBorderWidth(`${numericValue}${unit}`);
  };

  const handleBorderUnitChange = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(borderWidth).numeric;
    debouncedSetBorderWidth(`${numericValue}${unit}`);
  };

  const handleBorderNumericChangeR = (e) => {
    const numericValue = Math.max(0, e.target.value);
    const unit = getNumericAndUnit(borderRadius).unit;
    debouncedSetBorderRadius(`${numericValue}${unit}`);
  };

  const handleBorderUnitChangeR = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(borderRadius).numeric;
    debouncedSetBorderRadius(`${numericValue}${unit}`);
  };

  const handleBorderColorClick = (eventOrValue) => {
    const value = typeof eventOrValue === 'object' ? eventOrValue.target.value : eventOrValue;
    setborderColorValue(value);
  };

  return (
    <StyledAlignItems className="bor-remove-right-edit" style={{ borderColor: borderColorValue }}>
      <PropertiesAccordion title={"Border"}>
        <>
          <div className="colorforborder col-for-border-edit">
            <div className="inptcheck">
              <div className="ipubrdr">
                <input
                  type="number"
                  value={getNumericAndUnit(borderWidth).numeric}
                  onChange={handleBorderNumericChange}
                />
                <span className="unit-dropdown">
                  <Select
                    value={getNumericAndUnit(borderWidth).unit}
                    onChange={handleBorderUnitChange}
                    style={{
                      width: "40px",
                      padding: "9px 11px 10px 12px",
                      height: "27px",
                    }}
                    displayEmpty
                    IconComponent={() => null}
                    MenuProps={{ style: { top: "5px" } }}
                    className="custom-dropdown"
                  >
                    <MenuItem value="px" className="custom-menuitem">
                      px
                    </MenuItem>
                    <MenuItem value="em" className="custom-menuitem">
                      em
                    </MenuItem>
                    <MenuItem value="%" className="custom-menuitem">
                      %
                    </MenuItem>
                  </Select>
                </span>
              </div>
              <span className="thickness">Thickness</span>
            </div>
            <div className="backgroundclr bck-color-edit">
              <p className="backgroundclr-for-body"></p>
              <input
                type="color"
                value={borderColorValue}
                onChange={handleBorderColorClick}
                style={{
                  width: "26px",
                  height: "26px",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              />

              <div className="iconset clearColorWrap forbgClr">
                <div
                  className="clearColor"
                  onClick={(e) => {
                    e.preventDefault();
                    handleBorderColorClick("transparent");
                  }}
                >
                  <span className="crsLinelft"></span>
                  <span className="crsLinerht"></span>
                  <span className="tooltiptext">Transparent</span>
                </div>
              </div>
            </div>
          </div>
        </>
        <>
          <div className="colorforborder col-for-border-edit">
            <div className="inptcheck">
              <div className="ipubrdr">
                <input
                  type="number"
                  value={getNumericAndUnit(borderRadius).numeric}
                  onChange={handleBorderNumericChangeR}
                />
                <span className="unit-dropdown">
                  <Select
                    value={getNumericAndUnit(borderRadius).unit}
                    onChange={handleBorderUnitChangeR}
                    style={{
                      width: "40px",
                      padding: "9px 11px 10px 12px",
                      height: "27px",
                    }}
                    displayEmpty
                    IconComponent={() => null}
                    MenuProps={{ style: { top: "5px" } }}
                    className="custom-dropdown"
                  >
                    <MenuItem value="px" className="custom-menuitem">
                      px
                    </MenuItem>
                    <MenuItem value="em" className="custom-menuitem">
                      em
                    </MenuItem>
                    <MenuItem value="%" className="custom-menuitem">
                      %
                    </MenuItem>
                  </Select>
                </span>
              </div>
              <span className="thickness">Radius</span>
            </div>
          </div>
        </>
      </PropertiesAccordion>
    </StyledAlignItems>
  );
};

export default Border;