import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/styles";
import themeProvider from "../../../../ThemeProvider";
import SendEmailBox from "./SendEmailBox";
import EmailInfoBox from "./EmailInfoBox";
import EmailAction from "./EmailActionBox";

const dataSetMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "set1" },
  { value: 1, label: "set2" },
  { value: 2, label: "set3" },
];
function SendEmail() {
  const [formData, setFormData] = useState({
    name: "",
    module: "",
    dataSet: "",
    linkTo: "",
    defineTo: "",
    subject: "",
    body: "",
    report: "",
    attachment: null,
    onStart: "",
    loop: "",
    onExecutionAction: "",
    onEnd: "",
  });
  const [dataSetOptions, setDataSetOptions] = useState([]);
  useEffect(() => {
    const fetchDataSets = async () => {
      if (formData.module) {
        // const items = await getDataSet(formData.module);
        // setDataSetOptions(items);
        setDataSetOptions(dataSetMenuItems);
      }
    };

    fetchDataSets();
  }, [formData.module]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <SendEmailBox
        formData={formData}
        handleChange={handleChange}
        dataSetOptions={dataSetOptions}
      />
      <EmailInfoBox
        formData={formData}
        handleChange={handleChange}
      />
      <EmailAction formData={formData} handleChange={handleChange} />
    </ThemeProvider>
  );
}

export default SendEmail;
