import React, { useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ThemeProvider, Tooltip } from "@mui/material";
import { themeProvider } from "../../ThemeProvider";
import { Theme } from "../IncludeFile/Theme";
import { useState } from "react";
function NavBar({
  searchQuery,
  handleSearch,
  isSearchBar,
  leftButton,
  rightButtons,
  iconButtons,
  title,
  toggleMenu,
  isMenuOpenleft
}) {
  const renderLeftButton = () =>
    leftButton ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          color={leftButton.color || "primary"}
          style={{ height: "33px" }}
          onClick={leftButton.handleClick}
        >
          {leftButton.icon}
          <h6 className="addcontrollbutton">{leftButton.text}</h6>
        </Button>
        {renderIconButtons()}
      </div>
    ) : (
      <div></div>
    );

  const renderIconButtons = () =>
    iconButtons
      ? iconButtons.map((iconButton, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={iconButton.handleClick}
            style={{
              marginLeft: "5px",
              height: "33px",
            }}
          >
            <Tooltip arrow title={iconButton.tooltip}>
              {iconButton.icon}
            </Tooltip>
          </Button>
        ))
      : null;

//   const renderRightButtons = () =>
//     rightButtons &&
//     rightButtons.map((button, index) => (
     
//       <Button
//         key={index}
//         color={button.color || "secondary"}
//         variant="contained"
//         className="addandsavebutton"
//         onClick={button.handleClick}
//       >
//         {index === 0 &&
//          <Tooltip
//       id="tooltipstyletop"
//       arrow
//       title={
//         <span className="tooltipstyletop">
//          JSON Form
//         </span>
//       }
//       >
//         {button.icon}
//         </Tooltip>
// }
//         {index !== 0 && button.icon}
//         <Tooltip
//       id="tooltipstyletop"
//       arrow
//       title={
//         <span className="tooltipstyletop">
//          Back
//         </span>
//       }
//       >
//         <h6 className="addcontrollbutton">{button.text}</h6>
//         </Tooltip>
//       </Button>
      
//     ));
const renderRightButtons = () => (
  rightButtons &&
  rightButtons.map((button, index) => (
    <Button
      key={index}
      color={button.color || "secondary"}
      variant="contained"
      className="addandsavebutton"
      onClick={button.handleClick}
    >
      
      <Tooltip
        id="tooltipstyletopnav"
        arrow
        title={
          <span className="tooltipstyletopnav">
            {button.text}
          </span>
        }
      >
        <div style={{display:"flex", alignItems:"center"}}>
        {button.icon}
        <h6 className="addcontrollbutton">{button.text}</h6>
        </div>
      </Tooltip>
     
    </Button>
  ))
);
const inputRef = useRef(null);

  return (
    <ThemeProvider theme={themeProvider}>
      <div className="containerbtnsecction" style={{ paddingRight: "2px" }}>
        {isSearchBar && (
          <div className="menu-srch-form-wrap-frist">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="inputfield-wrap">
                <input
                  className="inputfield"
                  id="outlined-multiline-flexible"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => handleSearch(e)}
                  ref={inputRef}
                />
                <div className="inputfield-srch-icon">
                <span style={{cursor:"pointer"}} onClick={() => {inputRef.current.focus()}}>{Theme.Search}</span>
                </div>
              </div>
            </Box>
          </div>
        )}

        {leftButton && (
          <div
            className="bordersec"
            style={{ marginLeft: !isSearchBar ? "0.5rem" : 0 }}
          />
        )}
        
{isMenuOpenleft && (
 <div className="slect-wrap-slide">
 <Tooltip
     id="tooltipstyletoppanel"
     arrow
     title={
       <span className="tooltipstyletoppanel">
         Show/Hide left panel
       </span>
     }
     >
   <span onClick={toggleMenu} className="left-panel-icon">
     {Theme.MenuOpenIcon}
   </span>
   </Tooltip>
 </div>
)}
       

        <div
          className="addsavebtn"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
        >
          {renderLeftButton()}
          {title && (
            <Typography className="custom-typogrpy">{title}</Typography>
          )}
          <div className="save-export-btn" style={{ padding: "10px" }}>
            {renderRightButtons()}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default NavBar;
