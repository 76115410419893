import React from "react";
export const isSpecialType = (value) => {
  // Check if it's a link
  if (/^https?:\/\//.test(value)) {
    // console.log("Link ", value);
    return "link";
  }

  // Check if it's an email
  if (/^\S+@\S+\.\S+$/.test(value)) {
    // console.log("Email ", value);
    return "email";
  }

  // Check if it's a number
  if (
    /\b(?:\+?\d{1,3}[-. ]?)?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}\b/.test(value)
  ) {
    // console.log("Phone ", value);
    return "phone";
  }

  // // Check if it's a currency ($100, 123.45, €50, 100.0$, $100.0, 100$, 100.00$)
  // if (/^\p{Sc}?[-+]?\d+(\.\d+)?\$?$/.test(value)) {
  //   console.log("currency ", value);
  //   return "currency";
  // }
  // Check if it's in the format $100 100$ 100.77
  if (/^(?:\$\d+|\d+\$|\$?\d+\.\d{2}\$?)$/.test(value)) {
    // console.log("currency ", value);
    return "currency";
  }

  // Check if it's a time
  if (/^\d{2}:\d{2}:\d{2}$/.test(value)) {
    // console.log("time ", value);
    return "time";
  }
  if (/^\d{2}:\d{2}$/.test(value)) {
    // console.log("time ", value);
    return "time";
  }

  // Check if it's a date (YYYY-MM-DD format)
  if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
    return "date";
  }

  // Now, check if it's a datetime by looking for the time component
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value)) {
    return "dateTime";
  }
  // // Check if it's a date (YYYY-MM-DD format)
  // if (!isNaN(Date.parse(value)) && isNaN(value)) {
  //   // Now, check if it's a datetime by looking for time component
  //   if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value)) {
  //     console.log("DateTime ", value);
  //     return "dateTime";
  //   } else {
  //     console.log("date ", value);
  //     return "date";
  //   }
  // }

  // If it's not a special type, return null
  return null;
};
export const maskPhoneNumber = (phoneNumber) => {
  phoneNumber = phoneNumber.toString();
  if (/^\+?\d{10,15}$/.test(phoneNumber)) {
    const formattedPhoneNumber = `+1 (${phoneNumber.slice(
      0,
      3
    )}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    return formattedPhoneNumber;
  } else {
    return phoneNumber;
  }
};

export const formatDate = (value) => {
  const dateObj = new Date(value);
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear();
  return `${month}-${day}-${year}`;
};
export const stripCurrencyMask = (value) => {
  const strippedValue = value.replace(/[^\d.-]/g, "");
  const parsedValue = parseFloat(strippedValue);
  const formattedValue =
    parsedValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "$";
  return formattedValue;
};
