import { useCallback } from "react";
import { UpdateDiagHeader, GetDiagCardsInfo } from "../apiCalls/DiagramsAPI";
import { toast } from "react-toastify";
// export const useSave = (flowKey, rfInstance) => {
//   return useCallback(() => {
//     if (rfInstance) {
//       let flow = rfInstance.toObject();
//       console.log("flow ", flow);
//       // Temporarily saving to local storage
//       localStorage.setItem(flowKey, JSON.stringify(flow));
//     }
//   }, [rfInstance]);
// };

export const useSave = (diagram, rfInstance, setDiagram) => {
  return useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      // Prepare diagram data
      const diagramData = {
        DiagID: diagram.diagID,
        DiagTempID: diagram.diagTempID,
        Title: diagram.title,
        Description: diagram.explanation,
        Nodes: JSON.stringify(flow.nodes),
        Edges: JSON.stringify(flow.edges),
        ViewPort: JSON.stringify(flow.viewport),
      };
      setDiagram({
        diagTempID: diagram.diagTempID,
        diagID: diagram.diagID,
        name: diagram.name,
        title: diagram.title,
        explanation: diagram.explanation,
        notes: diagram.notes,
        showMinMap: diagram.showMinMap,
        layout: diagram.layout,
        nodes: JSON.stringify(flow.nodes),
        edges: JSON.stringify(flow.edges),
        viewPort: JSON.stringify(flow.viewport),
      });
      // Call UpdateDiagHeader with diagramData
      UpdateDiagHeader([diagramData])
        .then(() => {
          toast.success("Diagram header updated successfully.");
        })
        .catch((error) => {
          toast.error("Error updating diagram header:", error);
        });
    }
  }, [diagram, rfInstance]);
};

// export const useRestore = (flowKey, setNodes, setEdges, setViewport) => {
//   return useCallback(() => {
//     const restoreFlow = async () => {
//       // Temporarily restoring from local storage
//       const flow = JSON.parse(localStorage.getItem(flowKey));

//       if (flow) {
//         const { x = 0, y = 0, zoom = 1 } = flow.viewport;
//         setNodes(flow.nodes || []);
//         setEdges(flow.edges || []);
//         setViewport({ x, y, zoom });
//       }
//     };

//     restoreFlow();
//   }, [flowKey, setNodes, setEdges, setViewport]);
export const useRestore = (diagram, setNodes, setEdges, setViewport) => {
  return useCallback(() => {
    const restoreFlow = async () => {
      // Temporarily restoring from local storage
      const initialNodes =
        diagram.nodes !== "" ? JSON.parse(diagram.nodes) : [];
      const initialEdges =
        diagram.nodes !== "" ? JSON.parse(diagram.edges) : [];
      const initialViewPort =
        diagram.nodes !== ""
          ? JSON.parse(diagram.viewPort)
          : { x: 0, y: 0, zoom: 1 };
      setNodes(initialNodes);
      setEdges(initialEdges);
      setViewport(initialViewPort);
    };

    restoreFlow();
  }, [diagram, setNodes, setEdges, setViewport]);
};
