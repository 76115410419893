import React, { useState } from "react";
import { Select, MenuItem } from "@mui/material";

function PaddingDropDown({
  selectedItemID,
  editedProperties,
  handlePropertyChange,
}) {
  const [nodePadding, setNodePadding] = useState({
    top: "",
    topUnit: "",
    right: "",
    rightUnit: "",
    bottom: "",
    bottomUnit: "",
    left: "",
    leftUnit: "",
  });
  React.useEffect(() => {
    const propertyGroup = editedProperties.find(
      (group) => group[0]?.diagToolID === selectedItemID
    );
    if (propertyGroup) {
      const nodePaddingProperty = propertyGroup.find(
        (property) => property.propertyName === `Node_Padding`
      );
      const propertyParsed = nodePaddingProperty?.diagPropValue
        ? JSON.parse(nodePaddingProperty.diagPropValue)
        : null;
      setNodePadding({
        top: propertyParsed ? propertyParsed.top : "",
        topUnit: propertyParsed ? propertyParsed.topUnit : "",
        right: propertyParsed ? propertyParsed.right : "",
        rightUnit: propertyParsed ? propertyParsed.rightUnit : "",
        bottom: propertyParsed ? propertyParsed.bottom : "",
        bottomUnit: propertyParsed ? propertyParsed.bottomUnit : "",
        left: propertyParsed ? propertyParsed.left : "",
        leftUnit: propertyParsed ? propertyParsed.leftUnit : "",
      });
    }
  }, [selectedItemID, editedProperties]);

  const handlePaddingChange = (name, value) => {
    setNodePadding((prevNodePadding) => {
      const updatedNodePadding = {
        ...prevNodePadding,
        [name]: value,
      };
      handlePropertyChange(`Node_Padding`, JSON.stringify(updatedNodePadding));

      return updatedNodePadding;
    });
  };

  const renderInput = (name) => (
    <>
      <input
        type="number"
        name={name}
        value={nodePadding[name]}
        onChange={(e) => handlePaddingChange(name, e.target.value)}
      />
    </>
  );

  const renderSelect = (name) => (
    <Select
      name={`${name}Unit`}
      value={nodePadding[`${name}Unit`]}
      onChange={(e) => handlePaddingChange(`${name}Unit`, e.target.value)}
      style={{ width: "40px", height: "27px", padding: "10px 11px 10px 13px" }}
      displayEmpty
      IconComponent={() => null}
      MenuProps={{ style: { top: "5px" } }}
      className="custom-dropdown"
    >
      <MenuItem value="px" className="custom-menuitem">
        px
      </MenuItem>
      <MenuItem value="em" className="custom-menuitem">
        em
      </MenuItem>
      <MenuItem value="pc" className="custom-menuitem">
        %
      </MenuItem>
    </Select>
  );

  return (
    <div>
      <div className="rht-flds-wrap">
        <div className="rht-fld-info">
          <div className="ipubrdr">
            <div className="rht-fld">
              {renderInput("top")}
              <span className="unit-dropdown">{renderSelect("top")}</span>
            </div>
          </div>
          <div className="rht-info">top</div>
        </div>
        <div className="rht-fld-info">
          <div className="ipubrdr">
            <div className="rht-fld">
              {renderInput("right")}
              <span className="unit-dropdown">{renderSelect("right")}</span>
            </div>
          </div>
          <div className="rht-info">Right</div>
        </div>
      </div>

      <div className="rht-flds-wrap">
        <div className="rht-fld-info">
          <div className="ipubrdr">
            <div className="rht-fld">
              {renderInput("bottom")}
              <span className="unit-dropdown">{renderSelect("bottom")}</span>
            </div>
          </div>
          <div className="rht-info">Bottom</div>
        </div>
        <div className="rht-fld-info">
          <div className="ipubrdr">
            <div className="rht-fld">
              {renderInput("left")}
              <span className="unit-dropdown">{renderSelect("left")}</span>
            </div>
          </div>
          <div className="rht-info">Left</div>
        </div>
      </div>
    </div>
  );
}

export default PaddingDropDown;
