import { useState } from "react";

import {
  Card,
  CardMedia,
  CardContent,
  Box,
  IconButton,
  Button,
  ThemeProvider,
} from "@mui/material";
import themeProvider from "../../ThemeProvider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Action } from "./ActionList";

const ProviderComponent = ({ items, admin, handleDialogToggle }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Calculate the number of items based on the length of the items array
  const calculateItems = () => {
    if (screenWidth >= 1024) {
      return Math.min(3, items.length);
    } else if (screenWidth >= 750) {
      return Math.min(2, items.length);
    } else {
      return Math.min(1, items.length);
    }
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: Math.min(3, items.length),
      slidesToSlide: Math.min(3, items.length),
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: Math.min(2, items.length),
      slidesToSlide: Math.min(2, items.length),
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: Math.min(1, items.length),
      slidesToSlide: Math.min(1, items.length),
    },
  };

  //URL for admin to change the marketing info
  const EDIT_URL =
    "https://aiworksdev.agiline.com//global/index?globalurlid=FFDAAC4F-9871-4849-8E96-E49B4E434090&param1=";

  // Calculate the number of items displayed
  const numItemsDisplayed = calculateItems(); // Use the same function as before

  // Determine if arrows should be hidden
  const hideArrows = numItemsDisplayed >= items.length;

  const isAdmin = () => {
    return admin;
  };

  const style = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "300px",
    margin: "1rem 0",
    padding: "1.5rem",
  };

  const imgStyle = {
    width: "40%",
    height: "300px",
    objectFit: "cover",
    objectPosition: "center",
    marginRight: "10px",
  };

  const descriptionStyle = {
    fontSize: "10px",
    marginTop: "10px",
    maxHeight: "5rem",
    maxWidth: "270px", // set the maximum width of the container
    wordWrap: "break-word", // allow the text to wrap to a new line when it reaches the maximum width
    overflow: "hidden",
  };

  const arrowButtonStyle = {
    position: "relative",
    display: "flex-end",
    left: "70%",
  };
  const titleStyle = {
    maxWidth: "90%",
    wordWrap: "break-word", // allow the text to wrap to a new line when it reaches the maximum width
    overflow: "hidden",
    fontSize: "15px",
    fontWeight: "bold",
    color: themeProvider.palette.primary.main,
  };
  const textStyle = {
    fontSize: `10px`,
    maxHeight: "4.5rem",
    maxWidth: "90%",
    overflow: "hidden",
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <div style={{ margin: "0 1rem" }}>
        <Carousel
          // customRightArrow={<CustomRightArrow />}
          swipeable={screenWidth < 750 ? true : false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={screenWidth < 750 ? true : false}
          autoPlaySpeed={10000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile"]}
          deviceType={screenWidth < 750 ? "mobile" : "desktop"}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          arrows={!hideArrows}
        >
          {items.map((item, i) => (
            <Card key={i} style={style}>
              <CardMedia
                component="img"
                style={imgStyle}
                image={item.mediaURL}
                alt="landing page image"
              />
              <CardContent>
                <p style={titleStyle}>{item.header}</p>
                <div
                  style={textStyle}
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                />
                <Box display="flex" alignItems="center">
                  <IconButton
                    sx={arrowButtonStyle}
                    style={{ color: themeProvider.palette.primary.main }}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
                {isAdmin() && (
                  <Button
                    onClick={(event) => {
                      handleDialogToggle(
                        event,
                        item.feedID,
                        item.header,
                        Action.EDIT_ITEM
                      );
                    }}
                    style={{
                      position: "absolute",
                      top: "15px",
                      right: "0",
                      zIndex: 1,
                    }}
                  >
                    <EditIcon
                      style={{
                        backgroundColor: "f2f2f2",
                        borderColor: "#e8e8e8",
                        borderWidth: "2px",
                        borderRadius: "25px",
                        height: "1.3rem",
                        width: "1.3rem",
                        padding: "0.5rem",
                        marginLeft: "5%",
                        color: "#212325",
                      }}
                    />
                  </Button>
                )}
              </CardContent>
            </Card>
            // <Card key={i} style={cardStyle}>
            //   <CardMedia style={mediaStyle} image={item.mediaURL} />
            //   <CardContent>
            //     <Typography
            //       style={{
            //         marginTop: "2rem",
            //         color: theme.palette.primary.main,
            //         maxWidth: "270px",
            //         wordWrap: "break-word",
            //         fontSize: "15px",
            //       }}
            //     >
            //       {item.header}
            //     </Typography>
            //     <Typography
            //       sx={descriptionStyle}
            //       variant="body1"
            //       component="div"
            //       dangerouslySetInnerHTML={{ __html: item.description }}
            //     ></Typography>
            //     <Box display="flex" alignItems="center">
            //       <IconButton
            //         sx={arrowButtonStyle}
            //         style={{ color: theme.palette.primary.main }}
            //         href={item.link}
            //         target="_blank"
            //         rel="noopener noreferrer"
            //       >
            //         <ArrowForwardIcon />
            //       </IconButton>
            //     </Box>
            //   </CardContent>
            //   {isAdmin() && (
            //     <Button
            //       onClick={(event) => {
            //         handleDialogToggle(
            //           event,
            //           item.feedID,
            //           item.header,
            //           Action.EDIT_ITEM
            //         );
            //       }}
            //       style={{
            //         position: "absolute",
            //         top: "10px",
            //         right: "0",
            //         zIndex: 1,
            //       }}
            //     >
            //       <EditIcon
            //         style={{
            //           backgroundColor: "f2f2f2",
            //           borderColor: "#e8e8e8",
            //           borderWidth: "2px",
            //           borderRadius: "25px",
            //           height: "1.3rem",
            //           width: "1.3rem",
            //           padding: "0.5rem",
            //           marginLeft: "5%",
            //           color: theme.palette.black.light,
            //         }}
            //       />
            //     </Button>
            //   )}
            // </Card>
          ))}
        </Carousel>
      </div>
    </ThemeProvider>
  );
};

export default ProviderComponent;
