import React, { useState } from "react";
import PropertiesAccordion from "../../../reusableComponents/PropertiesAccordion";
import FontDropDown from "./FontDropDown";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import NodeBorderDropDown from "./NodeBorderDropDown";
import PaddingDropDown from "./PaddingDropDown";
import EditCss from "../../../reusableComponents/EditCss";
import EditIcon from "@mui/icons-material/Edit";

// const nodeTypeLabels = {
//   0: "Default",
//   1: "Start",
//   2: "End",
// };
function NodeDropDowns({
  editedProperties,
  selectedItemID,
  handlePropertyChange,
}) {
  const [openPopup, setOpenPopup] = useState(false);
  const [nodeColor, setNodeColor] = useState({
    backgroundColor: "",
    color: "",
  });
  React.useEffect(() => {
    const propertyGroup = editedProperties.find(
      (group) => group[0]?.diagToolID === selectedItemID
    );

    if (propertyGroup) {
      const nodeBackground = propertyGroup.find(
        (property) => property.propertyName === "Node_Background"
      );
      const color = propertyGroup.find(
        (property) => property.propertyName === "Node_Color"
      );
      if (nodeBackground) {
        setNodeColor((prevNodeColor) => ({
          ...prevNodeColor,
          backgroundColor: nodeBackground
            ? nodeBackground.diagPropValue.toString()
            : "",
        }));
      } else {
        setNodeColor((prevNodeColor) => ({
          ...prevNodeColor,
          backgroundColor: "",
        }));
      }
      if (color) {
        setNodeColor((prevNodeColor) => ({
          ...prevNodeColor,
          color: color ? color.diagPropValue.toString() : "",
        }));
      } else {
        setNodeColor((prevNodeColor) => ({
          ...prevNodeColor,
          color: "",
        }));
      }
    }
  }, [selectedItemID, editedProperties]);
  const handleNodeColorChange = (name, value) => {
    setNodeColor({
      ...nodeColor,
      [name]: value,
    });
    if (name === "backgroundColor") {
      handlePropertyChange("Node_Background", value);
    } else {
      handlePropertyChange("Node_Color", value);
    }
  };
  const handleBgColorClear = () => {
    setNodeColor({
      ...nodeColor,
      backgroundColor: "",
    });
    handlePropertyChange("Node_Background", "transparent");
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "0.5rem 0",
            cursor: "pointer", // Adding cursor style to indicate it's clickable
          }}
          onClick={() => setOpenPopup(true)}
        >
          Node Default Css
        </div>
        <IconButton onClick={() => setOpenPopup(true)}>
          <EditIcon />
        </IconButton>
      </div>
      {openPopup && (
        <EditCss
          value={
            editedProperties
              .flatMap((propertyGroup) => propertyGroup)
              .find(
                (property) =>
                  property.diagToolID === selectedItemID &&
                  property.propertyName === "Node_Default_CSS"
              )?.diagPropValue
          }
          onChange={(newValue) =>
            handlePropertyChange("Node_Default_CSS", newValue.target.value)
          }
          handlePopup={() => setOpenPopup(false)}
        />
      )}
      <PropertiesAccordion
        title={"Node Color"}
        onRightClick={() => setOpenPopup(true)}
      >
        <div className="backgrounclr ">
          Background Color
          <div>
            <input
              type="color"
              name="backgroundColor"
              value={nodeColor.backgroundColor}
              onChange={(e) => {
                handleNodeColorChange(e.target.name, e.target.value);
              }}
            />
            <div className="iconset clearColorWrap">
              <div className="clearColor" onClick={handleBgColorClear}>
                <span className="crsLinelft"></span>
                <span className="crsLinerht"></span>
                <span className="tooltiptext">Transparent</span>
              </div>
            </div>
          </div>
        </div>
        <div className="backgrounclr ">
          Color
          <input
            type="color"
            name="color"
            value={nodeColor.color}
            onChange={(e) => {
              handleNodeColorChange(e.target.name, e.target.value);
            }}
          />
        </div>
      </PropertiesAccordion>
      <PropertiesAccordion
        title={"Node Border"}
        onRightClick={() => setOpenPopup(true)}
      >
        <NodeBorderDropDown
          selectedItemID={selectedItemID}
          editedProperties={editedProperties}
          handlePropertyChange={handlePropertyChange}
        />
      </PropertiesAccordion>
      <PropertiesAccordion
        title={"Node Padding"}
        onRightClick={() => setOpenPopup(true)}
      >
        <PaddingDropDown
          selectedItemID={selectedItemID}
          editedProperties={editedProperties}
          handlePropertyChange={handlePropertyChange}
        />
      </PropertiesAccordion>
      <PropertiesAccordion
        title={"Node Font"}
        onRightClick={() => setOpenPopup(true)}
      >
        <FontDropDown
          selectedItemID={selectedItemID}
          editedProperties={editedProperties}
          handlePropertyChange={handlePropertyChange}
          propSection="Node"
        />
      </PropertiesAccordion>
    </>
  );
  // return (
  //   <>
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "space-between",
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: "flex",
  //           margin: "0.5rem 0",
  //           cursor: "pointer", // Adding cursor style to indicate it's clickable
  //         }}
  //         onClick={handlePopup}
  //       >
  //         Node Default Css
  //       </div>
  //       <IconButton onClick={handlePopup}>
  //         <EditIcon />
  //       </IconButton>
  //     </div>

  //     <PropertiesAccordion title={"Node Style"} onRightClick={handlePopup}>
  //       <Grid container spacing={2}>
  //         <Grid item xs={12} sm={6}>
  //           <TextField
  //             size="small"
  //             fullWidth
  //             type="number" // Set input type to number
  //             label="Node_Height"
  //             value={
  //               editedProperties.find(
  //                 (item) => item.diagToolID === selectedItemID
  //               )?.["Node_Height"]
  //             }
  //             onChange={(event) => handlePropertyChange(event, "Node_Height")}
  //           />
  //         </Grid>
  //         <Grid item xs={12} sm={6}>
  //           <TextField
  //             size="small"
  //             fullWidth
  //             type="number" // Set input type to number
  //             label="Node_Width"
  //             value={
  //               editedProperties.find(
  //                 (item) => item.diagToolID === selectedItemID
  //               )?.["Node_Width"]
  //             }
  //             onChange={(event) => handlePropertyChange(event, "Node_Width")}
  //           />
  //         </Grid>
  //       </Grid>
  //       <Grid container spacing={2} marginTop="0.1rem">
  //         <Grid item xs={12} sm={6}>
  //           <FormControl fullWidth>
  //             <InputLabel>Node Type</InputLabel>
  //             <Select
  //               size="small"
  //               id="NodeType"
  //               name="NodeType"
  //               label="NodeType"
  //               value={
  //                 editedProperties.find(
  //                   (item) => item.diagToolID === selectedItemID
  //                 )?.["NodeType"]
  //               }
  //               onChange={(event) => handlePropertyChange(event, "NodeType")}
  //               displayEmpty
  //               MenuProps={{ style: { maxHeight: "300px" } }}
  //               inputProps={{ "aria-label": "Time Unit" }}
  //             >
  //               {Object.entries(nodeTypeLabels).map(([value, label]) => (
  //                 <MenuItem key={value} value={value}>
  //                   {label}
  //                 </MenuItem>
  //               ))}
  //             </Select>
  //           </FormControl>
  //         </Grid>
  //         <Grid item xs={12} sm={6}>
  //           <FormControl fullWidth>
  //             <InputLabel>Handles</InputLabel>
  //             <Select
  //               size="small"
  //               id="Handles"
  //               name="Handles"
  //               label="Handles"
  //               value={
  //                 editedProperties.find(
  //                   (item) => item.diagToolID === selectedItemID
  //                 )?.["Handles"]
  //               }
  //               onChange={(event) => handlePropertyChange(event, "Handles")}
  //               fullwidth
  //               displayEmpty
  //               MenuProps={{ style: { maxHeight: "300px" } }}
  //               inputProps={{ "aria-label": "Time Unit" }}
  //             >
  //               {[-1, 0, 1, 2, 4, 8, 16].map((option) => (
  //                 <MenuItem key={option} value={option}>
  //                   {option}
  //                 </MenuItem>
  //               ))}
  //             </Select>
  //           </FormControl>
  //         </Grid>
  //       </Grid>
  //     </PropertiesAccordion>
  //     <PropertiesAccordion title={"Node Font"} onRightClick={handlePopup}>
  //       <FontDropDown
  //         fontStyles={nodeFontStyles}
  //         setFontStyles={setNodeFontStyles}
  //       />
  //     </PropertiesAccordion>
  //     <PropertiesAccordion title={"Node Color"} onRightClick={handlePopup}>
  //       <div className="backgrounclr ">
  //         Background Color
  //         <input
  //           type="color"
  //           name="backgroundColor"
  //           value={nodeColor.backgroundColor}
  //           onChange={(e) => {
  //             handleNodeColorChange(e.target.name, e.target.value);
  //           }}
  //         />
  //       </div>
  //       <div className="backgrounclr ">
  //         Color
  //         <input
  //           type="color"
  //           name="color"
  //           value={nodeColor.color}
  //           onChange={(e) => {
  //             handleNodeColorChange(e.target.name, e.target.value);
  //           }}
  //         />
  //       </div>
  //     </PropertiesAccordion>
  //     <PropertiesAccordion title={"Node Border"} onRightClick={handlePopup}>
  //       <NodeBorderDropDown
  //         nodeBorder={nodeBorder}
  //         setNodeBorder={setNodeBorder}
  //       />
  //     </PropertiesAccordion>
  //     <PropertiesAccordion title={"Node Padding"} onRightClick={handlePopup}>
  //       <PaddingDropDown
  //         nodePadding={nodePadding}
  //         setNodePadding={setNodePadding}
  //       />
  //     </PropertiesAccordion>
  //     {openPopup && (
  //       <EditCss
  //         value={nodeDefaultCss}
  //         onChange={handleTextareaChange}
  //         handlePopup={handlePopup}
  //       />
  //     )}
  //   </>
  // );
}

export default NodeDropDowns;
