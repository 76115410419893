import React, { useState, useEffect } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Box,
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../assets/icofontcharts/icofont.min.css";
import { Image, Theme } from "../Components/IncludeFile/Theme";
import { useAppState } from "./AppStateProvider ";
import { ToastContainer, toast } from "react-toastify";

const CardContentData = React.memo(
  ({
    size,
    textSize,
    arrayName,
    description,
    dataSourceArID,
    arID,
    handleDelete,
    category,
    isTemplate,
    setOpenCreateInfo,
    openCreateInfo,
    setOpenViewCreateInfo,
    openViewCreateInfo,
    openDiscoverPage,
    setOpenDiscoverPage,
    isDeleteConfirmation,
    DeleteConfirmation,
  }) => {
  
    const Navigate = useNavigate();
    const [editedArrayName, setEditedArrayName] = useState(arrayName);
    const [editedDescription, setEditedDescription] = useState(description);
    const [editedcategory, seteditedcategory] = useState(category);
    const [editeddataSourceArID, setediteddataSourceArID] =
      useState(dataSourceArID);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
      useState(false);

    const openDeleteConfirmation = () => {
      setIsDeleteConfirmationOpen(true);
    };

  

    const closeDeleteConfirmation = () => {
      setIsDeleteConfirmationOpen(false);
    };

    const onDeleteConfirmation = () => {
      closeDeleteConfirmation();
      onDelete();
      toast.success("Record is deleted successfully");
    };

    const handleSelect = () => {
      const stateObject = { arrayName, description, category, dataSourceArID };
      Navigate(`/Form/${arID}`, { state: stateObject });
    };

    let APIProvider = window.APIProvider;

    let GET_HEADER = {
      method: "GET",
      headers: {},
      mode: "cors",
    };

    async function ReadArray(arId) {
      if (!window.APIProvider || !window.APIProvider.baseURL) {
        console.error(
          "APIProvider is undefined or does not have baseURL property"
        );
        toast.error(
          "APIProvider is undefined or does not have baseURL property"
        );
        return null;
      }

      try {
        if (!APIProvider || !APIProvider.baseURL) {
          console.error("APIProvider is not defined or baseURL is missing");
          toast.error("APIProvider is not defined or baseURL is missing");
          return null;
        }
        const response = await fetch(
          APIProvider.baseURL +
            APIProvider.recordPath.ReadArray +
            "?arID=" +
            arId

          // `https://aicloud.agiline.com:7352/ReadArray/?arID= ${arId}`
        );

        if (response.ok) {
          const resultData = await response.json();
          if (resultData.source !== null && resultData.source !== undefined) {
            const jsonData = [
              {
                arid: resultData.arID,
                arrayName: resultData.arrayName,
                description: resultData.description,
                Source: JSON.parse(resultData.source.replace(/~/g, '"')),
                dataSourceArID: resultData.dataSourceArID,
              },
            ];

            return jsonData;
          } else {
            console.error("Error: source is null or undefined");
            toast.error("Error: source is null or undefined");
            throw new Error("Source is null or undefined");
          }
        } else {
          console.error("Error fetching data:", response.statusText);
          toast.error("Error fetching data:");
          return null;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data:", error);

        if (error.message === "Source is null or undefined") {
          window.location.href = `/NotFound?arID=${arId}`;
        }
        return null;
      }
    }

    const navigate = useNavigate();

    const { appState, setArrayData } = useAppState();


    const closeEditDialog = () => {
      setIsEditDialogOpen(false);
    };

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const openDeleteDialog = () => {
      setIsDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
      setIsDeleteDialogOpen(false);
    };

    const onDelete = async () => {
      closeDeleteDialog();
      try {
        const response = await fetch(
          APIProvider.baseURL +
            APIProvider.recordPath.DeleteArrayByarID +
            `?arID=${arID}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          handleDelete(arID);
        } else {
          console.error("Failed to delete item.");
          toast.error("Failed to delete item.");
        }
      } catch (error) {
        console.error("Error deleting item:", error);
        toast.error("Error deleting item:", error);
      }
    };

    useEffect(() => {
      setEditedArrayName(arrayName);
      setEditedDescription(description);
      seteditedcategory(category);
      setediteddataSourceArID(dataSourceArID);
    }, [arrayName, description, category, dataSourceArID]);

    const handleCreate = async () => {
      try {
        const fetchDataResponse = await fetch(
          APIProvider.baseURL +
            APIProvider.recordPath.ReadArrayByName +
            "?arrayName=" +
            arrayName
          // `https://aicloud.agiline.com:7352/ReadArrayByName/?arrayName=${arrayName}`,
        );

        if (fetchDataResponse.ok) {
          const resultData = await fetchDataResponse.json();
          const arID = resultData.arID;
          const jsonData = await ReadArray(arID);

          if (jsonData) {
            const sourceData = jsonData[0].Source;

            const response = await fetch(
              APIProvider.baseURL + APIProvider.recordPath.WriteArray,
              // `https://aicloud.agiline.com:7352/WriteArray`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  arrayName: editedArrayName,
                  description: editedDescription,
                  category: category,
                  source: JSON.stringify(sourceData),
                  IsTemplate: "0",
                  htmlOutput: "new",
                  dataSourceArID: editeddataSourceArID,
                }),
              }
            );

            if (response.ok) {
              const fetchDataResponse = await fetch(
                APIProvider.baseURL +
                  APIProvider.recordPath.ReadArrayByName +
                  "?arrayName=" +
                  editedArrayName
                // `https://aicloud.agiline.com:7352/ReadArrayByName/?arrayName= ${editedArrayName}`,
              );

              if (fetchDataResponse.ok) {
                const resultData = await fetchDataResponse.json();
                const arID = resultData.arID;
                const newArrayName = resultData.arrayName;
                const newDescription = resultData.description;
                const newCategory = resultData.category;
                const newdataSourceArID = resultData.dataSourceArID;

                const newData = await ReadArray(arID);
                if (newData) {
                  console.log(
                    "Fetched data based on arrayName:",
                    newData[0].Source
                  );

                  const stateObject = {
                    arrayName: newArrayName,
                    description: newDescription,
                    category: newCategory,
                    source: JSON.stringify(sourceData),
                    IsTemplate: "0",
                    dataSourceArID: newdataSourceArID,
                  };

                  setArrayData(
                    newArrayName,
                    newDescription,
                    newCategory,
                    newdataSourceArID
                  );

                  navigate(`/Form/${arID}`, {
                    state: stateObject,
                  });
                }
              } else {
                console.error(
                  "Error fetching data based on arrayName:",
                  fetchDataResponse.statusText
                );
                toast.error("Error fetching data based on arrayName:");
              }
            } else {
              console.error("Error creating array:", response.statusText);
              toast.error("Error creating array:");
            }
          }
        } else {
          console.error(
            "Error fetching data based on arrayName:",
            fetchDataResponse.statusText
          );
          toast.error("Error fetching data based on arrayName:");
        }
      } catch (error) {
        console.error("Error:", error.message);
        toast.error("Error:");
      }
    };

    const stateObject = {
      arrayName: arrayName,
      description: description,
      category: category,
      dataSourceArID: dataSourceArID,
    };

    const handleNavigate = () => {
      setOpenViewCreateInfo(!openViewCreateInfo);
      setOpenDiscoverPage(!openDiscoverPage);
      navigate("/Open", { state: stateObject });
    };

    const handleOverView = () => {
      setOpenCreateInfo(!openCreateInfo);
      navigate("/Open", { state: stateObject, arID: arID });
    };

    if (isTemplate === 2) {
      return null;
    }

    const openEdit = () => {
      navigate(`/Form/${arID}`, { state: stateObject });
    };

    return (
      <>
        <div className="card-form card-tostfy" style={{ display: "flex" }}>
          <ToastContainer position="bottom-right" autoClose={3000} />
          <>
            {isTemplate !== 2 && isTemplate !== 3 &&(
              <Card
                sx={{
                  width: `${size}px`,
                  height: "auto",
                  marginBottom: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  transition: "box-shadow 0.3s",
                  "&:hover": {
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <CardContent>
                  <Box>
                    <div className="tmplt-icon-tst">
                      {category === "Contact Form" && (
                        <img
                          src={Image.ContactForm}
                          alt="Contact Form Icon"
                          className="form-icon"
                        />
                      )}
                      {category === "Survey Form" && (
                        <img
                          src={Image.SurveyForm}
                          alt="Survey Form Icon"
                          className="form-icon"
                        />
                      )}
                      {category === "Blank Form" && (
                        <img
                          src={Image.UserCreateForm}
                          alt="Blank Form Icon"
                          className="form-icon"
                        />
                      )}

                      {category === "Grant Writer Form" && (
                        <img
                          src={Image.grantWriter}
                          alt="Blank Form Icon"
                          className="form-icon"
                        />
                      )}
                      {category === "Json Form" && (
                        <img
                          src={Image.json}
                          alt="Blank Form Icon"
                          className="form-icon"
                        />
                      )}

                      <div className="edit-title">
                        <div className="title-edit-txt-cntr">{arrayName}</div>

                        {isTemplate === 1 ? (
                          <div className="iconset-delet">
                          <div className="Edit-del-icn dashbord-titl">
                            <span className="icon-for-edit"
                              onClick={openEdit}
                            >
                             {Theme.Edit}
                            </span>
                            <div className="tooltiptext toltip-top">Edit</div>
                          </div>
                        </div>
                        ) : (
                          <div className="iconset-delet">
                            <div className="Edit-del-icn">
                              <span
                                className="icon"
                                onClick={openDeleteConfirmation}
                              >
                                <HighlightOffIcon />
                              </span>

                              <div className="tooltiptext">Delete</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "12.75px",
                        textAlign: "center",
                        paddingTop: 3,
                      }}
                    >
                      {description}
                    </div>

                    <div
                      style={{
                        fontSize: textSize * 0.75,
                        textAlign: "center",
                        paddingTop: 3,
                      }}
                    >
                      {category}
                    </div>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    marginTop: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {isTemplate === 1 ? (
                    <>
                      <Button
                        sx={{
                          fontSize: "1rem",
                          width: "50%",
                          height: "85%",
                          borderRadius: "30px",
                        }}
                        variant="outlined"
                        onClick={handleNavigate}
                      >
                        Select
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        sx={{
                          fontSize: "1rem",
                          width: "50%",
                          height: "85%",
                          borderRadius: "30px",
                        }}
                        variant="outlined"
                        onClick={handleOverView}
                      >
                        Overview
                      </Button>
                      <Button
                        sx={{
                          fontSize: "1rem",
                          width: "50%",
                          height: "85%",
                          borderRadius: "30px",
                        }}
                        variant="outlined"
                        onClick={handleSelect}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </CardActions>
              </Card>
            )}

            <Dialog open={isEditDialogOpen} onClose={closeEditDialog}>
              <DialogTitle>Create New</DialogTitle>
              <DialogContent className="dial-cont">
                <p>
                  <TextField
                    className="edit-popup"
                    label="Form Name"
                    onChange={(e) => setEditedArrayName(e.target.value)}
                  />
                </p>
                <p>
                  <TextField
                    className="edit-popup"
                    label="Description"
                    onChange={(e) => setEditedDescription(e.target.value)}
                  />
                </p>
                <p>
                  <TextField
                    className="edit-popup"
                    label="Category"
                    value={editedcategory}
                    aria-readonly
                  />
                </p>
                <p>
                  <TextField
                    className="edit-popup"
                    label="Description"
                    onChange={(e) => setediteddataSourceArID(e.target.value)}
                  />
                </p>
              </DialogContent>
              <DialogActions>
                <Button className="savebtn" onClick={handleCreate}>
                  Create New
                </Button>
                <Button className="cancelbtn" onClick={closeEditDialog}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <div>
              <Dialog
                open={isDeleteConfirmationOpen}
                onClose={closeDeleteConfirmation}
                maxWidth="md"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <div className="dashbord-delete-popup">
                  <div className="dashbord-delete-text">
                    <DialogTitle>
                      <div>
                        <HighlightOffIcon
                          style={{
                            fontSize: "3em",
                            color: "red",
                          }}
                        />
                        <IconButton
                          edge="end"
                          color="inherit"
                          onClick={closeDeleteConfirmation}
                          aria-label="close"
                          sx={{
                            position: "absolute",
                            right: "35px",
                            top: 20,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </DialogTitle>
                    <DialogContent style={{ paddingBottom: "0px" }}>
                      <div className="card-dlt-icon-txt">
                        <h1>Confirmation</h1>
                        <p>
                          Do you really want to delete this record?
                          <br />
                          This process cannot be undone.
                        </p>
                      </div>
                    </DialogContent>
                    <DialogActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "20px",
                      }}
                    >
                      <Button
                        onClick={closeDeleteConfirmation}
                        sx={{
                          backgroundColor: "#008e05",
                          border: "1px solid #008e05",
                          color: "#fff",
                          margin: "0 5px",
                          padding: "3px 15px",
                          transition: "background-color 0.3s, color 0.3s",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#008e05",
                            color: "#ffffff",
                            boxShadow:
                              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onDeleteConfirmation}
                        sx={{
                          backgroundColor: "#ff0000",
                          margin: "0 5px",
                          color: "#ffffff",
                          padding: "3px 15px",
                          border: "1px solid #ff0000",
                          transition: "background-color 0.3s, border 0.3s",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#ff0000",
                            boxShadow:
                              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </div>
                </div>
              </Dialog>
            </div>
          </>
        </div>
      </>
    );
  }
);

export default CardContentData;