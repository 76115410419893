import React, { useEffect, useState } from "react";
import { usePagination, DOTS } from "./UsePagination";
// import "./paginationStyle.css";
import {
  PaginationContainer,
  PaginationItem,
  LeftArrow,
  RightArrow,
  DisabledLeftArrow,
  DisabledRightArrow,
} from "./PaginationStyle";

const PaginationComponent = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    selectedColor,
    hoverColor,
    IconColor,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0) {
    return null;
  }
  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const onNext = (event) => {
    onPageChange(event, currentPage + 1);
  };

  const onPrevious = (event) => {
    onPageChange(event, currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div>
      <PaginationContainer>
        <PaginationItem
          style={{ pointerEvents: currentPage === 1 ? "none" : "all" }}
          // className={`pagination-item ${currentPage === 1 ? "disabled" : "bg"}`}
          onClick={(event) => onPrevious(event)}
        >
          {currentPage === 1 ? <DisabledLeftArrow /> : <LeftArrow />}
        </PaginationItem>
        {paginationRange.map((pageNumber, pageIndex) => {
          if (pageNumber === DOTS) {
            return (
              <li key={pageIndex} className="pagination-item dots">
                &#8230;
              </li>
            );
          }

          return (
            <PaginationItem
              key={pageIndex}
              iconColor={
                pageNumber !== currentPage ? "rgba(0, 0, 0, 0.87)" : IconColor
              }
              selectedColor={
                pageNumber !== currentPage ? "rgba(0, 0, 0, 0)" : selectedColor
              }
              hoverColor={hoverColor}
              onClick={(event) => onPageChange(event, pageNumber)}
            >
              {pageNumber}
            </PaginationItem>
          );
        })}
        <PaginationItem
          // className={`pagination-item ${
          //   currentPage === lastPage ? "disabled" : "bg"
          // }`}
          style={{ pointerEvents: currentPage === lastPage ? "none" : "all" }}
          onClick={(event) => onNext(event)}
        >
          {currentPage === lastPage ? <DisabledRightArrow /> : <RightArrow />}
        </PaginationItem>
      </PaginationContainer>
    </div>
  );
};
export default PaginationComponent;
