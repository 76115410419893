import { styled } from "@mui/material/styles";
import themeProvider from "../../ThemeProvider";

export const FormField = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  height: "",
}));
export const FormLabel = {
  marginRight: themeProvider.spacing(2),
};
