import React, { useState } from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import themeProvider from "../../ThemeProvider";
import { styled } from "@mui/material/styles";

//ZoomIn Div
const ImageContainer = styled("div")({
  overflow: "hidden",
});

const MobileArticleCard = ({
  feedID,
  image,
  title,
  description,
  size,
  textSize,
}) => {
  //Zoom In Variables
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <Card
        id={feedID}
        sx={{
          margin: "20px",
          paddingTop: "2rem",
          height: "auto",
          maxHeight: "600px",
          minWidth: "92%",
          maxWidth: "92%",
          borderRadius: "16px",
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        <ImageContainer
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <CardMedia
            component="img"
            image={image}
            alt="landing page image"
            // sx={{  objectFit: "cover", }}
            style={{
              height: "auto",
              maxHeight: `${size}px`,
              width: "auto",
              maxWidth: `85%`,
              margin: "0 auto", // Center the image horizontally
              paddingLeft: "10px",
              transform: isHovered ? "scale(1.2)" : "scale(1)",
              transition: "transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
            }}
          />
        </ImageContainer>
        <CardContent>
          <Typography
            style={{
              color: themeProvider.palette.primary.main,
              fontWeight: "bold",
              fontSize: `${textSize}px`,
            }}
            className="text-center"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              "& img": {
                maxWidth: "100%",
              },
              wordWrap: "break-word",
              fontSize: `${textSize * 0.75}px`,
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          ></Typography>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default MobileArticleCard;
