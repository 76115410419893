import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropertiesAccordion from "../reusableComponents/PropertiesAccordion";
import debounce from "lodash/debounce";

const StyledAlignItems = styled("div")({
  width: "250px",
  flexShrink: 0,
  height: "calc(-54px + 100vh)",
  overflow: "auto",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  transition: "width 0.3s ease 0s",
});

const Padding = ({
  matchedStyles,
  updateDefaultStyle,
  setDefaultStyleState,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  setPaddingTop,
  setPaddingBottom,
  setPaddingRight,
  setPaddingLeft,
  defaultStyle,
  setDefaultStyle,
  styleType,
}) => {
  

  const defaultStyleObject = defaultStyle.find(
    (style) => style.styleType === styleType
  );

  useEffect(() => {
    if (defaultStyleObject) {
      const updatedStyle = defaultStyle.map((style) => {
        if (style.styleType === styleType) {
          return {
            ...style,
            style: {
              ...style.style,
              paddingTop: paddingTop,
              paddingBottom: paddingBottom,
              paddingLeft: paddingLeft,
              paddingRight: paddingRight,
            },
          };
        }
        return style;
      });

      setDefaultStyle(updatedStyle);
      updateDefaultStyle(updatedStyle);
      // setDefaultStyleState(updatedStyle);
    }
  }, [paddingTop, paddingBottom, paddingLeft, paddingRight, styleType]);

  const getNumericAndUnit = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }

    if (value === undefined || value === null) {
      return {
        numeric: "",
        unit: "px",
      };
    }

    const match = value.match(/^(-?[\d.]+)(\D*)$/);
    if (match) {
      const numeric = match[1];
      const unit = match[2] !== "" ? match[2] : "px";
      return {
        numeric: numeric,
        unit: unit,
      };
    }
    return {
      numeric: "",
      unit: "px",
    };
  };

  const debouncedsetPaddingTop = debounce((value) => {
    setPaddingTop(value);
  }, 10);

  const debouncedsetPaddingBottom= debounce((value) => {
    setPaddingBottom(value);
  }, 10);

  const debouncedsetPaddingLeft = debounce((value) => {
    setPaddingLeft(value);
  }, 10);

  const debouncedsetPaddingRight = debounce((value) => {
    setPaddingRight(value);
  }, 10);

  const handleNumericTopChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(paddingTop).unit;
    // setPaddingTop(`${numericValue}${unit}`);
    debouncedsetPaddingTop(`${numericValue}${unit}`);
  };

  const handleNumericBottomChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(paddingTop).unit;
    debouncedsetPaddingBottom(`${numericValue}${unit}`);
  };

  const handleNumericLeftChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(paddingTop).unit;
    debouncedsetPaddingLeft(`${numericValue}${unit}`);
  };

  const handleNumericRightChange = (e) => {
    const numericValue = e.target.value;
    const unit = getNumericAndUnit(paddingTop).unit;
    debouncedsetPaddingRight(`${numericValue}${unit}`);
  };

  const handleUnitTopChange = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(paddingTop).numeric;
    debouncedsetPaddingTop(`${numericValue}${unit}`);
  };

  const handleUnitBottomChange = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(paddingTop).numeric;
    debouncedsetPaddingBottom(`${numericValue}${unit}`);
  };

  const handleUnitLeftChange = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(paddingTop).numeric;
    debouncedsetPaddingLeft(`${numericValue}${unit}`);
  };

  const handleUnitRightChange = (e) => {
    const unit = e.target.value;
    const numericValue = getNumericAndUnit(paddingTop).numeric;
    debouncedsetPaddingRight(`${numericValue}${unit}`);
  };
 

  return (
    <StyledAlignItems className="bor-remove-right-edit">
      <div>
    
        
        <PropertiesAccordion title={"Padding"}>
          <>
            <div className="rht-flds-wrap">
              <div className="rht-fld-info">
                <div className="ipubrdr">
                  <div className="rht-fld">
                    <input
                      type="number"
                      value={getNumericAndUnit(paddingTop).numeric}
                      onChange={handleNumericTopChange}
                    />
                    <span className="unit-dropdown">
                      <Select
                        value={getNumericAndUnit(paddingTop).unit}
                        onChange={handleUnitTopChange}
                        style={{
                          width: "40px",
                          height: "27px",
                          padding: "8px 11px 10px 12px",
                        }}
                        displayEmpty
                        IconComponent={() => null}
                        MenuProps={{ style: { top: "5px" } }}
                        className="custom-dropdown"
                      >
                        <MenuItem value="px" className="custom-menuitem">
                          px
                        </MenuItem>
                        <MenuItem value="em" className="custom-menuitem">
                          em
                        </MenuItem>
                        <MenuItem value="%" className="custom-menuitem">
                          %
                        </MenuItem>
                      </Select>
                    </span>
                  </div>
                </div>
                <div className="rht-info">top</div>
              </div>
              <div className="rht-fld-info">
                <div className="ipubrdr">
                  <div className="rht-fld">
                    <input
                      type="number"
                      value={getNumericAndUnit(paddingRight).numeric}
                      onChange={handleNumericRightChange}
                    />
                    <span className="unit-dropdown">
                      <Select
                        value={getNumericAndUnit(paddingRight).unit}
                        onChange={handleUnitRightChange}
                        style={{
                          width: "40px",
                          height: "27px",
                          padding: "8px 11px 10px 12px",
                        }}
                        displayEmpty
                        IconComponent={() => null}
                        MenuProps={{ style: { top: "5px" } }}
                        className="custom-dropdown"
                      >
                        <MenuItem value="px" className="custom-menuitem">
                          px
                        </MenuItem>
                        <MenuItem value="em" className="custom-menuitem">
                          em
                        </MenuItem>
                        <MenuItem value="%" className="custom-menuitem">
                          %
                        </MenuItem>
                      </Select>
                    </span>
                  </div>
                </div>
                <div className="rht-info">Right</div>
              </div>
            </div>
          </>
          <>
            <div className="rht-flds-wrap">
              <div className="rht-fld-info">
                <div className="ipubrdr">
                  <div className="rht-fld">
                    <input
                      type="number"
                      value={getNumericAndUnit(paddingBottom).numeric}
                      onChange={handleNumericBottomChange}
                    />
                    <span className="unit-dropdown">
                      <Select
                        value={getNumericAndUnit(paddingBottom).unit}
                        onChange={handleUnitBottomChange}
                        style={{
                          width: "40px",
                          height: "27px",
                          padding: "8px 11px 10px 12px",
                        }}
                        displayEmpty
                        IconComponent={() => null}
                        MenuProps={{ style: { top: "5px" } }}
                        className="custom-dropdown"
                      >
                        <MenuItem value="px" className="custom-menuitem">
                          px
                        </MenuItem>
                        <MenuItem value="em" className="custom-menuitem">
                          em
                        </MenuItem>
                        <MenuItem value="%" className="custom-menuitem">
                          %
                        </MenuItem>
                      </Select>
                    </span>
                  </div>
                </div>
                <div className="rht-info">Bottom</div>
              </div>
              <div className="rht-fld-info">
                <div className="ipubrdr">
                  <div className="rht-fld">
                    <input
                      type="number"
                      value={getNumericAndUnit(paddingLeft).numeric}
                      onChange={handleNumericLeftChange}
                    />
                    <span className="unit-dropdown">
                      <Select
                        value={getNumericAndUnit(paddingLeft).unit}
                        onChange={handleUnitLeftChange}
                        style={{
                          width: "40px",
                          height: "27px",
                          padding: "8px 11px 10px 12px",
                        }}
                        displayEmpty
                        IconComponent={() => null}
                        MenuProps={{ style: { top: "5px" } }}
                        className="custom-dropdown"
                      >
                        <MenuItem value="px" className="custom-menuitem">
                          px
                        </MenuItem>
                        <MenuItem value="em" className="custom-menuitem">
                          em
                        </MenuItem>
                        <MenuItem value="%" className="custom-menuitem">
                          %
                        </MenuItem>
                      </Select>
                    </span>
                  </div>
                </div>
                <div className="rht-info">Left</div>
              </div>
            </div>
          </>
        </PropertiesAccordion>
      </div>
    </StyledAlignItems>
  );
};

export default Padding;
