import React, { useState, useEffect,  } from "react";
import { SplitScreen } from "./SplitScreen";
import CardContentData from "./CardContentData";
import { CircularProgress, Grid } from "@mui/material";
import SelectorData from "./SelectorData";
import { ToastContainer, toast } from "react-toastify";


function CreateCards({
  handleDetails,
  handleEditDiagram,
  searchQuery,
  caller,
  handleClick,
  handleCreate,
  isMenuOpenleft,
  setOpenCreateInfo,
  openCreateInfo,
  setOpenViewCreateInfo,
  openViewCreateInfo,
  openDiscoverPage,
  setOpenDiscoverPage,
}) {
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  let APIProvider = window.APIProvider;

  useEffect(() => {
    async function fetchData() {
      const scriptElement = document.createElement("script");
      scriptElement.src = `FormJson.js?v=${Date.now()}`;

      scriptElement.onload = () => {
        const fetchedData = window.MyVar || [];
        setData(fetchedData);

        const extractedData = fetchedData.map((item) => ({
          Name: item.Name,
          Explanation: item.Explanation,
          arID: item.ArID,
          DiagID: item.DiagID,
        }));

        setSelected(extractedData);
        setFetched(true);
        setLoading(false);
      };

      document.head.appendChild(scriptElement);
    }

    if (!fetched) {
      fetchData();
    }
  }, [fetched]);

  useEffect(() => {
    async function fetchApiData() {
      try {
        const response = await fetch(
          APIProvider.baseURL + APIProvider.recordPath.ReadArrayAll

          // `https://aicloud.agiline.com:7352/ReadArrayAll`
          );

        if (response.ok) {
          const apiData = await response.json();

          setLoading(false);
          setApiData(apiData);
          selected
            .flat()
            .filter(
              (item) => !apiData.some((apiItem) => apiItem.arID === item.arID)
            );
        } else {
          console.error("Failed to fetch data from the API");
          toast.error("Failed to fetch data from the API");
        }
      } catch (error) {
        console.error("Error fetching data from the API", error);
        toast.error("Error fetching data from the API");
      }
    }

    if (fetched) {
      fetchApiData();
    }
  }, [fetched]);

  const itemsToDisplay = selected
  .flat()
  .filter(section => 
    section.arrayName && section.arrayName.toLowerCase().includes(searchQuery.toLowerCase())
  )
  .sort((a, b) =>
    a.isTemplate !== b.isTemplate ? (a.isTemplate ? 1 : -1) : a.arID - b.arID
  );


  const handleDelete = (arIDToDelete) => {
    const updatedSelected = selected.filter(
      (item) => item.arID !== arIDToDelete
    );
    setSelected(updatedSelected);

    const updatedApiData = apiData.filter((item) => item.arID !== arIDToDelete);
    setApiData(updatedApiData);
  };

  const handleEditArray = async (
    arID,
    editedArrayName,
    editedDescription,
    editedCategory,
    editeddataSourceArID
  ) => {
    try {
      const response = await fetch(
        APIProvider.baseURL + APIProvider.recordPath.EditArray,
        // `https://aicloud.agiline.com:7352/EditArray`,

        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            arID: arID,
            arrayName: editedArrayName,
            description: editedDescription,
            category: editedCategory,
            IsTemplate: "0",
            dataSourceArID: editeddataSourceArID,
          }),
        }
      );

      if (response.ok) {
        const updatedData = data.map((item) =>
          item.arID === arID
            ? {
                ...item,
                arrayName: editedArrayName,
                description: editedDescription,
                category: editedCategory,
                dataSourceArID: editeddataSourceArID,
              }
            : item
        );
        setData(updatedData);

        const updatedSelected = selected.map((section) => {
          if (Array.isArray(section)) {
            return section.map((item) =>
              item.arID === arID
                ? {
                    ...item,
                    arrayName: editedArrayName,
                    description: editedDescription,
                    category: editedCategory,
                    dataSourceArID: editeddataSourceArID,
                  }
                : item
            );
          } else if (section && typeof section === "object") {
            return {
              ...section,
              arrayName:
                section.arID === arID ? editedArrayName : section.arrayName,
              description:
                section.arID === arID ? editedDescription : section.description,
              category:
                section.arID === arID ? editedCategory : section.category,
              dataSourceArID:
                section.arID === arID
                  ? editeddataSourceArID
                  : section.dataSourceArID,
            };
          } else {
            return section;
          }
        });

        setSelected(updatedSelected);
      } else {
        console.error("Failed to edit item.");
        toast.error("Failed to edit item.");
      }
    } catch (error) {
      console.error("Error editing item:", error);
      toast.error("Error editing item:");
    }
  };


  return (
    <>
      <SplitScreen
        leftWeight={1}
        rightWeight={3}
        isMenuOpenleft={isMenuOpenleft}
      >
        <SelectorData
          apiData={fetched ? apiData : []}
          selected={fetched ? selected : []}
          setSelected={setSelected}
          handleDelete={handleDelete}
        />
        
        {loading ? (
          <CircularProgress size={48} color="success"
          style={{
            position: 'relative',
            minHeight: '100vh',
            display: 'flex', 
            alignItems: 'center',
            justifyContent:'center',
            marginLeft:'auto',
            marginRight:'auto'
           }}
          />
         ) : (
          <Grid sx={{ flexGrow: 1 }} container padding={1}>
            {itemsToDisplay.map((item, index) => (
          <CardContentData
          key={index}
                data={item}
                size={325}
                textSize={17}
                isMobile={window.innerWidth < 750 ? true : false}
                handleDetails={handleDetails}
                handleEditDiagram={handleEditDiagram}
                // caller={caller}
                arrayName={item.arrayName}
                description={item.description}
                category={item.category}
                dataSourceArID={item.dataSourceArID}
                arID={item.arID}
                handleDelete={handleDelete}
                handleEditArray={handleEditArray}
                isTemplate={item.isTemplate}
                handleClick={handleClick}
                handleCreate={handleCreate}
                setSelected={setSelected}
                setOpenCreateInfo={setOpenCreateInfo}
                openCreateInfo={openCreateInfo}
                setOpenViewCreateInfo={setOpenViewCreateInfo}
                openViewCreateInfo={openViewCreateInfo}
                openDiscoverPage={openDiscoverPage}
                setOpenDiscoverPage={setOpenDiscoverPage}
                //   isDeleteConfirmation={isDeleteConfirmation}
                // DeleteConfirmation={DeleteConfirmation}
              />
            ))}
          </Grid>
        )}
        
      </SplitScreen>

      <ToastContainer position="bottom-right" autoClose={3000} />
    </>
  );
}

export default CreateCards;