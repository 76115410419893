import React from "react";
import { Grid, TextField } from "@mui/material";
function Minutes({ scheduleData, handleNumberChange }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          size="small"
          label="Every"
          type="number"
          name="minutes"
          value={scheduleData.minutes}
          onChange={(e) => handleNumberChange(e, 1, 60)}
        />
      </Grid>
      <Grid item xs={6}>
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          {scheduleData.minutes > 1 ? "Minutes" : "Minute"}
        </div>
      </Grid>
    </Grid>
  );
}

export default Minutes;
