// dragAndDropFunctions.js

import { useCallback } from "react";
import DynamicNode from "../nodes/DynamicNode";
import { v4 as uuidv4 } from "uuid";

export const useDragOver = () => {
  return useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);
};

export const getPropertyValue = (propertyName, properties) => {
  const property = properties?.find(
    (prop) => prop.propertyName === propertyName
  );

  // If property exists and the propertyName is "Node_Default_CSS", convert the CSS string to object
  if (
    (propertyName === "Node_Default_CSS" && property) ||
    (propertyName === "Body_Default_CSS" && property)
  ) {
    return convertCSSStringToObject(property.diagPropValue);
  }
  return property ? property.diagPropValue : null;
};

const convertCSSStringToObject = (cssString) => {
  const cssProperties = cssString.split(";").filter(Boolean); // Split the string by semicolons and filter out empty strings
  const cssObject = {};
  cssProperties.forEach((property) => {
    const [key, value] = property.split(":").map((str) => str.trim()); // Split each property by colon and trim whitespaces
    const formattedKey = key.includes("-") ? convertToCamelCase(key) : key;
    cssObject[formattedKey] = value; // Convert the key to camelCase and set it in the object
  });
  return cssObject;
};
// Function to convert camelCase to dash-separated strings
const convertToCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
};

const getShortenedUuid = () => {
  return uuidv4().split("-")[0]; // Take only the first part of the UUID
};

const getNodeId = () => getShortenedUuid();
// export const useDrop = (reactFlowWrapper, rfInstance, setNodes, data) => {
//   return useCallback(
//     (event) => {
//       event.preventDefault();
//       let type = event.dataTransfer.getData("application/reactflow");
//       // check if the dropped element is valid
//       if (typeof type === "undefined" || !type) {
//         return;
//       }

//       const position = rfInstance.screenToFlowPosition({
//         x: event.clientX,
//         y: event.clientY,
//       });
//       const newNode = {
//         id: getNodeId(),
//         type: "dynamicNode",
//         position,
//         data: { label: 'NodeResizer' },
//         // style: { background: '#fff', border: '1px solid black', borderRadius: 15, fontSize: 12 },
//       };

//       setNodes((nds) => nds.concat(newNode));
//     },
//     [rfInstance, setNodes, getNodeId]
//   );
// };
export const useDrop = (reactFlowWrapper, rfInstance, setNodes) => {
  return useCallback(
    (event) => {
      event.preventDefault();

      // const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      let type = event.dataTransfer.getData("application/reactflow");
      let customData = event.dataTransfer.getData("customData");
      let tempData = event.dataTransfer.getData("data");
      let properties = null;
   
      if (customData !== null && customData !== "undefined") {
        properties = JSON.parse(customData);
      }
      let data = null;
      if (tempData !== null && tempData !== "undefined") {
        data = JSON.parse(tempData);
      }
      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = rfInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      // const position = rfInstance.screenToFlowPosition({
      //   x: event.clientX - reactFlowBounds.left,
      //   y: event.clientY - reactFlowBounds.top,
      // });

      const newNodeData = [
        data.find((obj) => obj.name === type),
        properties,
        getNodeId(),
      ];
      if (newNodeData[0]?.name === "DefaultNode") {
        newNodeData[0].node_Height = "160";
        newNodeData[0].node_Width = "320";
        newNodeData[0].handles = "4";
        newNodeData[0].isDraggable = "false";
        newNodeData[0].isDeletable = "false";
        newNodeData[0].isDisabled = "true";
      }
      if (newNodeData[0]?.name === "Trigger Node") {
        newNodeData[0].nodeType = "0";
        newNodeData[0].handles = "2";
      }
      const nodeHeight = newNodeData[0]?.node_Height
        ? newNodeData[0].node_Height + "px"
        : "145px";
      const nodeWidth = newNodeData[0]?.node_Width
        ? newNodeData[0].node_Width + "px"
        : "200px";
      const isDraggable = newNodeData[0]?.isDraggable
        ? newNodeData[0]?.isDraggable
        : "true";
      type = type.split(" ")[0].toLowerCase();
      let nodePadding = getPropertyValue("Node_Padding", properties);
      nodePadding = nodePadding
        ? JSON.parse(nodePadding)
        : {
            top: "0",
            topUnit: "px",
            right: "0",
            rightUnit: "px",
            bottom: "0",
            bottomUnit: "px",
            left: "0",
            leftUnit: "px",
          };
      let fontWeight = getPropertyValue("Node_Font_weight", properties);
      fontWeight = fontWeight
        ? JSON.parse(fontWeight)
        : {
            bold: "0",
            italic: "0",
            underline: "0",
          };
      const newNode = {
        id: newNodeData[2],
        type: "dynamicNode",
        position,
        data: newNodeData,
        style: {
          width: nodeWidth,
          height: nodeHeight,
        },
        draggable: isDraggable?.toLowerCase() === "true",
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [rfInstance, setNodes, getNodeId]
  );
};
