import React, { useState } from "react";
import BoxTemplate from "../BoxTemplate";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import Conditions from "../conditions/Conditions";

const checkResourceMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];
const resourceListOfFieldsMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "Field1" },
  { value: 1, label: "Field2" },
];
const onStartMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "Action1" },
  { value: 1, label: "Action2" },
];

const triggerTypes = [
  { value: "", label: "Select Trigger Type" },
  { value: "dataUpdate", label: "On Data Update" },
  { value: "userAction", label: "On User Action" },
  { value: "scheduledTime", label: "Scheduled Time" },
];

function WhenBox({
  formData,
  dataSetOptions,
  whenFormData,
  handleDateChange,
  handleTimeChange,
  handleWhenBoxChange,
}) {
  return (
    <BoxTemplate
      icon={<AccessTimeIcon style={{ marginRight: "0.5rem" }} />}
      title={"When"}
      subTitle={"When to start execution."}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel>Trigger Type</InputLabel>
            <Select
              label="Trigger Type"
              size="small"
              name="triggerType"
              value={whenFormData.triggerType}
              onChange={handleWhenBoxChange}
              variant="outlined"
            >
              {triggerTypes.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                  disabled={option.value === ""}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {whenFormData.triggerType === "dataUpdate" && (
          <Grid item xs={12}>
            <Conditions
              originalDataSet={formData.dataSet}
              originalModule={formData.module}
              dataSetOptions={dataSetOptions}
            />
          </Grid>
        )}

        {whenFormData.triggerType === "userAction" && (
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel>On Start</InputLabel>
              <Select
                label="On Start"
                size="small"
                name="onStart"
                value={whenFormData.onStart}
                onChange={handleWhenBoxChange}
                variant="outlined"
              >
                <MenuItem value="Select" disabled>
                  Select
                </MenuItem>
                {onStartMenuItems
                  .filter((option) => option.value !== -1)
                  .map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {whenFormData.triggerType === "scheduledTime" && (
          <>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"en"}
            >
              <Grid item xs={6}>
                <DatePicker
                  label="Date"
                  value={whenFormData.date}
                  onChange={handleDateChange}
                  minDate={dayjs()}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth variant="outlined" />
                  )}
                  slotProps={{ textField: { size: "small" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="Time"
                  value={whenFormData.time}
                  onChange={handleTimeChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12}>
              <TextField
                required
                label="Frequency"
                size="small"
                variant="outlined"
                fullWidth
                type="number"
                name="frequency"
                value={whenFormData.frequency}
                onChange={handleWhenBoxChange}
              />
            </Grid>
          </>
        )}
      </Grid>
    </BoxTemplate>
  );
}

export default WhenBox;
