import React, { useState } from "react";
import { Select, MenuItem } from "@mui/material";

function DividerDropDown({
  selectedItemID,
  editedProperties,
  handlePropertyChange,
  propSection,
}) {
  const [divider, setDivider] = useState({
    dividerSize: "",
    dividerUnit: "",
  });
  const [dividerColor, setDividerColor] = useState("");
  React.useEffect(() => {
    const propertyGroup = editedProperties.find(
      (group) => group[0]?.diagToolID === selectedItemID
    );

    if (propertyGroup) {
      const dividerSize = propertyGroup.find(
        (property) => property.propertyName === `${propSection}_Divider_size`
      );
      const dividerColor = propertyGroup.find(
        (property) => property.propertyName === `${propSection}_Divider_color`
      );
      setDivider((prevDivider) => ({
        ...prevDivider,
        dividerSize: dividerSize
          ? dividerSize.diagPropValue
              .toString()
              .substring(0, dividerSize.diagPropValue.toString().length - 2)
          : "",
        dividerUnit: dividerSize
          ? dividerSize.diagPropValue
              .toString()
              .substring(
                dividerSize.diagPropValue.toString().length - 2,
                dividerSize.diagPropValue.toString().length
              )
          : "px",
      }));
      setDividerColor(
        dividerColor ? dividerColor.diagPropValue.toString() : ""
      );
    }
  }, [selectedItemID, editedProperties, propSection]);

  const handleDividerChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDivider((prevDividerStyles) => {
      const updatedDividerStyles = {
        ...prevDividerStyles,
        [name]: type === "checkbox" ? checked : value,
      };
      handlePropertyChange(
        `${propSection}_Divider_size`,
        updatedDividerStyles.dividerSize + updatedDividerStyles.dividerUnit
      );
      return updatedDividerStyles;
    });
  };

  const handleDividerColorChange = (value) => {
    setDividerColor(value);
    handlePropertyChange(`${propSection}_Divider_color`, value);
  };
  return (
    <>
      <div className="backgrounclr ">
        Color
        <input
          type="color"
          name="dividerColor"
          value={dividerColor}
          onChange={(e) => handleDividerColorChange(e.target.value)}
        />
      </div>
      <div className="stle-font">
        <div className="rht-fld-selectwrap for-fild-styl">
          <div className="rht-info fontlft-size">Divider Size</div>
          <div className="rht-fnt">
            <div className="ipubrdr fontszefr-inp">
              <div className="rht-fld">
                <input
                  name="dividerSize"
                  type="number"
                  value={divider.dividerSize}
                  onChange={(e) => {
                    handleDividerChange(e);
                  }}
                />
                <span className="unit-dropdown unit-font-drop">
                  <Select
                    name="dividerUnit"
                    value={divider.dividerUnit}
                    onChange={(e) => {
                      handleDividerChange(e);
                    }}
                    style={{
                      width: "40px",
                      height: "25px",
                      backgroundColor: "#CFD8DD",
                      fontSize: "15px",
                    }}
                    displayEmpty
                    IconComponent={() => null}
                    MenuProps={{
                      style: { top: "5px" },
                    }}
                  >
                    <MenuItem value="px" className="custom-menuitem">
                      px
                    </MenuItem>
                    <MenuItem value="em" className="custom-menuitem">
                      em
                    </MenuItem>
                    <MenuItem value="%" className="custom-menuitem">
                      %
                    </MenuItem>
                  </Select>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DividerDropDown;
