import React, { useState } from "react";
import { Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TabsComponent from "../TabsComponent";
import { getPropertyValue } from "../../../functions/DragnDrop";
import Conditions from "./conditions/Conditions";
import Trigger from "./triggerNodeComponents/Trigger";
import Wait from "./waitNodeComponents/Wait";
import ConditionalSplit from "./conditionalNodeComponents/ConditionalSplit";
import SendEmail from "./sendEmailComponents/SendEmail";
import ScheduleSelector from "../Crono.js/CronExpression";
const NodeDrawerComponent = ({
  isOpen,
  onClose,
  nodes,
  setNodes,
  selectedNode,
}) => {
  //tabs
  const [tabValue, setTabValue] = useState("1");
  const tabs = [
    { label: "Style", value: "1" },
    // { label: "Condition", value: "2" },
  ];
  // Parse Icon related properties from properties
  const iconType = getPropertyValue("Caption_IconType", selectedNode.data[1]);
  const iconLink = getPropertyValue("Caption_IconLink", selectedNode.data[1]);
  const iconPosition = getPropertyValue(
    "Caption_IconPosition",
    selectedNode.data[1]
  );
  const iconSize = getPropertyValue("Caption_IconSize", selectedNode.data[1]);
  const iconSizeMapping = {
    small: "24px", // Adjust this value according to your needs
    medium: "36px", // Adjust this value according to your needs
    large: "48px", // Adjust this value according to your needs
  };
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <div style={{ width: "500px", padding: "16px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "15px" }}
          >
            {iconType === "Url" && iconLink ? (
              <img
                src={iconLink}
                alt="Icon"
                style={{
                  width: iconSizeMapping[iconSize],
                  marginRight: "0.5rem",
                }}
              />
            ) : (
              iconType === "Bootstrap" &&
              iconLink && (
                <i
                  class={`fa fa-${iconLink}`}
                  aria-hidden="true"
                  style={{ marginRight: "0.5rem" }}
                ></i>
              )
            )}
            {selectedNode.data[0].caption}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <TabsComponent
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabs={tabs}
        >
          <div key="1">
            {selectedNode.data[0].diagToolID === "24" && <Trigger />}
            {selectedNode.data[0].diagToolID === "26" && <ConditionalSplit />}
            {selectedNode.data[0].diagToolID === "27" && <Wait />}
            {selectedNode.data[0].diagToolID === "28" && <SendEmail />}
            {selectedNode.data[0].diagToolID === "23" && <ScheduleSelector />}
          </div>
          {/* <div key="2">
            <Conditions />
          </div> */}
        </TabsComponent>
      </div>
    </Drawer>
  );
};

export default NodeDrawerComponent;
