import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import WhenBox from "./WhenBox";
import themeProvider from "../../../../ThemeProvider";
import dayjs from "dayjs";
import TriggerBox from "./TriggerBox";

const dataSetMenuItems = [
  { value: -1, label: "" },
  { value: 0, label: "set1" },
  { value: 1, label: "set2" },
  { value: 2, label: "set3" },
];

function Trigger() {
  const [formData, setFormData] = useState({
    name: "",
    module: "",
    dataSet: "",
  });
  const [dataSetOptions, setDataSetOptions] = useState([]);

  useEffect(() => {
    const fetchDataSets = async () => {
      if (formData.module) {
        // const items = await getDataSet(formData.module);
        // setDataSetOptions(items);
        setDataSetOptions(dataSetMenuItems);
      }
    };

    fetchDataSets();
  }, [formData.module]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // WhenBox vars
  const [whenFormData, setWhenFormData] = useState({
    triggerType: "",
    date: dayjs(),
    time: dayjs(),
    frequency: "",
    onStart: "",
    checkResource: "",
    resourceListOfFields: "",
  });

  const handleDateChange = (date) => {
    if (date.isBefore(dayjs(), "day")) {
      alert("Date is before today.");
    } else {
      setWhenFormData({
        ...whenFormData,
        date: date,
      });
    }
  };

  const handleTimeChange = (time) => {
    const today = dayjs();
    if (whenFormData.date.isSame(today, "day") && time.isBefore(today)) {
      console.log("IF");
      alert("Time is before now.");
    } else {
      console.log("ELSE");
      setWhenFormData({
        ...whenFormData,
        time: time,
      });
    }
  };

  const handleWhenBoxChange = (event) => {
    const { name, value, type, checked } = event.target;
    setWhenFormData({
      ...whenFormData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <TriggerBox
        formData={formData}
        handleChange={handleChange}
        dataSetOptions={dataSetOptions}
      />
      <WhenBox
        formData={formData}
        dataSetOptions={dataSetOptions}
        whenFormData={whenFormData}
        handleDateChange={handleDateChange}
        handleTimeChange={handleTimeChange}
        handleWhenBoxChange={handleWhenBoxChange}
      />
    </ThemeProvider>
  );
}

export default Trigger;
