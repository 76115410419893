import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import "../../assets/icofontcharts/icofont.min.css";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const LIST_ITEMS = ["Form Builder", "Flow", "Edit Tools", "Edit Templates" , "Edit Controls" ,

  ];
const LIST_ICONS = [
  <FontAwesomeIcon icon="fa-solid fa-cube" />,
  <BorderColorIcon />,
  <i className="icofont-chart-flow-2"></i>,
  <HandymanOutlinedIcon />,
  <BorderColorIcon />,
  <ModeEditIcon/>
];
const LIST_PATHS = ["/Open", "/Select", "/ToolSelector", "/EditTemplates" ,"/Edit" , "/BillOfLading"];
function LeftNavBar({ open, drawerWidth }) {
  let navigate = useNavigate();
  
  function handleItemClick(text, index) {
    navigate(`${LIST_PATHS[index]}`);
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          zIndex: open ? 1 : 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            marginTop: "55px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <List>
          {LIST_ITEMS.map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              onClick={() => handleItemClick(text, index)}
            >
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    "&.MuiListItemIcon-root": {
                      minWidth: "2.5rem",
                      maxWidth: "2.5rem",
                    },
                  }}
                >
                  {LIST_ICONS[index]}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}

export default LeftNavBar;
