import React, { useEffect, useState, useRef, useMemo, memo } from "react";
import {
  Handle,
  Position,
  useUpdateNodeInternals,
  NodeResizer,
} from "reactflow";
import { getPropertyValue } from "../functions/DragnDrop";
import { drag } from "d3-drag";
import { select } from "d3-selection";
import styles from "../style.module.css";

const controlStyle = {
  background: "transparent",
  border: "none",
};

const DynamicCustomNode = ({ data, selected }) => {
  // Destructure the data array
  let [nodeData, properties, id] = data;
  // Function to get property value from properties array
  const nodeHeight = nodeData?.node_Height
    ? nodeData.node_Height + "px"
    : "125px";
  const nodeWidth = nodeData?.node_Width ? nodeData.node_Width + "px" : "200px";
  const handleCount = nodeData?.handles ? parseInt(nodeData.handles) : "16";

  let captionFontWeight = getPropertyValue("Caption_Font_weight", properties);
  captionFontWeight = captionFontWeight
    ? JSON.parse(captionFontWeight)
    : {
        bold: "0",
        italic: "0",
        underline: "0",
      };
  // Parse Icon related properties from properties
  const iconType = getPropertyValue("Caption_IconType", properties);
  const iconLink = getPropertyValue("Caption_IconLink", properties);
  const iconPosition = getPropertyValue("Caption_IconPosition", properties);
  const iconSize = getPropertyValue("Caption_IconSize", properties);
  const iconSizeMapping = {
    small: "24px", // Adjust this value according to your needs
    medium: "36px", // Adjust this value according to your needs
    large: "48px", // Adjust this value according to your needs
  };

  // const body =
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
  const body = getPropertyValue("Body_Text", properties);
  let bodyType = getPropertyValue("Body_type", properties);
  if (bodyType === null) {
    bodyType = "0";
  }
  const getFlexAlignItems = (alignment) => {
    switch (alignment?.toLowerCase()) {
      case "top":
        return "stretch";
      case "vcenter":
        return "stretch";
      case "bottom":
        return "stretch";
      case "vstretch":
        return "stretch";
      default:
        return "initial"; // or any default value you prefer
    }
  };
  const getFlexJustifyContent = (alignment) => {
    switch (alignment?.toLowerCase()) {
      case "left":
        return "flex-start";
      case "hcenter":
        return "center";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "initial"; // or any default value you prefer
    }
  };
  const alignItemsValue = getFlexAlignItems(
    getPropertyValue("Body_Alignment_Vertical", properties)
  );
  const bodyHorizontalAlignment = getFlexJustifyContent(
    getPropertyValue("Body_Alignment_Horizontal", properties)
  );
  let bodyFontWeight = getPropertyValue("Body_Font_weight", properties);
  bodyFontWeight = bodyFontWeight
    ? JSON.parse(bodyFontWeight)
    : {
        bold: "0",
        italic: "0",
        underline: "0",
      };
  let textOverflow = getPropertyValue("Body_TextOverflow", properties);
  if (textOverflow === null) {
    textOverflow = "1";
  }
  const url = getPropertyValue("Body_URL", properties);
  const imageFit = getPropertyValue("Body_Image_Fit", properties);
  let linkAlignment = getFlexJustifyContent(
    getPropertyValue("Link_Alignment_Horizontal", properties)
  );
  let linkFontWeight = getPropertyValue("Link_Font_weight", properties);
  linkFontWeight = linkFontWeight
    ? JSON.parse(linkFontWeight)
    : {
        bold: "0",
        italic: "0",
        underline: "0",
      };

  const calculateHandlePosition = (index) => {
    const perSide = index % 4; // Assuming equal distribution on each side

    switch (perSide) {
      case 0: // Top side
        return Position.Top;
      case 1: // Bottom side
        return Position.Bottom;
      case 2: // Right side
        return Position.Right;
      case 3: // Left side
        return Position.Left;
      default:
        return Position.Right; // Default to right
    }
  };
  // Define handle positions based on handle count
  // const handles = useMemo(
  //   () =>
  //     Array.from({ length: handleCount }, (_, i) => {
  //       let floor = Math.floor(i / 4);
  //       const handleId = `handle-${i}`;
  //       return (
  //         <Handle
  //           key={handleId}
  //           id={handleId}
  //           type="source"
  //           position={calculateHandlePosition(i)}
  //           style={
  //             i % 4 === 0
  //               ? { marginLeft: 20 * floor }
  //               : i % 4 === 1
  //                 ? { marginLeft: 20 * floor }
  //                 : i % 4 === 2
  //                   ? { marginTop: 20 * floor }
  //                   : { marginTop: 20 * floor }
  //           }
  //           isConnectable={
  //             nodeData?.isDisabled
  //               ? nodeData.isDisabled.toLowerCase() === "false"
  //               : true
  //           }
  //         />
  //       );
  //     }),
  //   [handleCount]
  // );
  const handles = useMemo(
    () =>
      Array.from({ length: handleCount }, (_, i) => {
        let floor = Math.floor(i / 4);
        const handleId = `handle-${i}`;
        return (
          <Handle
            key={handleId}
            id={handleId}
            type="source"
            position={calculateHandlePosition(i)}
            className={nodeData?.nodeType === "0" ? "" : `${styles.handle}`}
            style={
              i % 4 === 0 || i % 4 === 1
                ? { marginLeft: 20 * floor }
                : { marginTop: 20 * floor }
            }
            isConnectable={
              nodeData?.isDisabled
                ? nodeData.isDisabled.toLowerCase() === "false"
                : true
            }
          />
        );
      }),
    [handleCount]
  );

  const rotateControlRef = useRef(null);
  const updateNodeInternals = useUpdateNodeInternals();
  const [rotation, setRotation] = useState(0);
  useEffect(() => {
    if (!rotateControlRef.current) {
      return;
    }

    const selection = select(rotateControlRef.current);
    const dragHandler = drag().on("drag", (evt) => {
      const dx = evt.x - 100;
      const dy = evt.y - 100;
      const rad = Math.atan2(dx, dy);
      const deg = rad * (180 / Math.PI);
      setRotation(180 - deg);
      updateNodeInternals(id);
    });

    selection.call(dragHandler);
  }, [id, updateNodeInternals]);
  const borderSize = getPropertyValue("Node_Border_size", properties) || "1";
  let nodePadding = getPropertyValue("Node_Padding", properties);
  nodePadding = nodePadding
    ? JSON.parse(nodePadding)
    : {
        top: "0",
        topUnit: "px",
        right: "0",
        rightUnit: "px",
        bottom: "0",
        bottomUnit: "px",
        left: "0",
        leftUnit: "px",
      };
  let fontWeight = getPropertyValue("Node_Font_weight", properties);
  fontWeight = fontWeight
    ? JSON.parse(fontWeight)
    : {
        bold: "0",
        italic: "0",
        underline: "0",
      };

  let captionDividerSize = getPropertyValue("Caption_Divider_size", properties);
  let captionDividerColor = getPropertyValue(
    "Caption_Divider_color",
    properties
  );
  let linkDividerSize = getPropertyValue("Link_Divider_size", properties);
  let linkDividerColor = getPropertyValue("Link_Divider_color", properties);
  // Remove the resizeObserver error
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);
  return (
    <>
      {/* NODE */}
      <div
        style={{
          width: "100%",
          height: "100%",
          border: `${borderSize}px solid ${getPropertyValue("Node_Border_color", properties)}`,
          borderRadius: `${getPropertyValue("Node_Border_radius", properties)}px`,
          background: `${getPropertyValue("Node_Background", properties)}`,
          color: `${getPropertyValue("Node_Color", properties)}`,
          padding: `${nodePadding.top}${nodePadding.topUnit} ${nodePadding.right}${nodePadding.rightUnit} ${nodePadding.bottom}${nodePadding.bottomUnit} ${nodePadding.left}${nodePadding.leftUnit}`,
          fontSize: `${getPropertyValue("Node_Font_Size", properties)}`,
          fontFamily: `${getPropertyValue("Node_Font_Name", properties)}`,
          fontWeight: `${fontWeight.bold === "1" ? "bold" : ""}`,
          fontStyle: `${fontWeight.italic === "1" ? "italic" : ""}`,
          textDecoration: `${fontWeight.underline === "1" ? "underline" : ""}`,
          transform: `rotate(${rotation}deg)`,
          opacity: nodeData?.isDisabled?.toLowerCase() === "true" ? 0.5 : 1,
        }}
      >
        <NodeResizer isVisible={selected} minWidth={100} minHeight={30} />
        <div
          ref={rotateControlRef}
          style={{
            display: selected ? "block" : "none",
          }}
          className={`nodrag ${styles.rotateHandle}`}
        />
        {handles}
        {/* CAPTION */}
        <div
          style={{
            display: "flex",
            background: `${getPropertyValue("Caption_Background", properties)}`,
            color: `${getPropertyValue("Caption_Color", properties)}`,
            justifyContent:
              getPropertyValue(
                "Caption_Alignment_Horizontal",
                properties
              )?.toLowerCase() === "left"
                ? "flex-start"
                : getPropertyValue(
                      "Caption_Alignment_Horizontal",
                      properties
                    )?.toLowerCase() === "right"
                  ? "flex-end"
                  : "center",
            fontSize: `${getPropertyValue("Caption_Font_Size", properties)}`,
            fontFamily: `${getPropertyValue("Caption_Font_Name", properties)}`,
            fontWeight: `${captionFontWeight.bold === "1" ? "bold" : ""}`,
            fontStyle: `${captionFontWeight.italic === "1" ? "italic" : ""}`,
            textDecoration: `${captionFontWeight.underline === "1" ? "underline" : ""}`,
            ...getPropertyValue("Node_Default_CSS", properties),
          }}
        >
          {((iconPosition?.toLowerCase() === "left" && iconType === "Url") ||
            (iconPosition?.toLowerCase() === "center" &&
              iconType === "Url" &&
              nodeData?.caption)) &&
            iconLink && (
              <img
                src={iconLink}
                alt="Icon"
                style={{
                  width: iconSizeMapping[iconSize],
                  marginRight: "0.5rem",
                }}
              />
            )}
          {((iconPosition?.toLowerCase() === "left" &&
            iconType === "Bootstrap") ||
            (iconPosition?.toLowerCase() === "center" &&
              iconType === "Bootstrap" &&
              nodeData.caption)) && (
            <i
              class={`fa fa-${iconLink}`}
              aria-hidden="true"
              style={{ marginRight: "0.5rem" }}
            ></i>
          )}
          {!nodeData?.caption &&
            iconPosition?.toLowerCase() === "left" &&
            iconType === "Url" &&
            iconLink && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
                  src={iconLink}
                  alt="Icon"
                  style={{
                    width: iconSizeMapping[iconSize],
                  }}
                />
              </div>
            )}
          {nodeData?.caption}
          {iconPosition?.toLowerCase() === "right" && iconType === "Url" && (
            <img
              src={iconLink}
              alt="Icon"
              style={{ width: iconSizeMapping[iconSize] }}
            />
          )}
        </div>
        {/* CAPTION DIVIDER */}
        {captionDividerSize &&
          captionDividerSize !== "0px" &&
          captionDividerColor && (
            <div
              style={{
                // borderBottom: `${captionDividerSize} solid ${captionDividerColor}`, // Styling for the divider
                marginBottom: "0.5rem 0",
              }}
            ></div>
          )}
        {/* BODY */}
        <div
          style={{
            display: "flex",
            height:
              textOverflow === "0" || textOverflow === "1" ? "60%" : "auto",
            width:
              textOverflow === "0" || textOverflow === "1" ? "100%" : "auto",
            background: `${getPropertyValue("Body_Background", properties)}`,
            color: `${getPropertyValue("Body_Color", properties)}`,
            alignItems: alignItemsValue,
            justifyContent: bodyHorizontalAlignment,
            fontSize: `${getPropertyValue("Body_Font_Size", properties)}`,
            fontFamily: `${getPropertyValue("Body_Font_Name", properties)}`,
            fontWeight: `${bodyFontWeight.bold === "1" ? "bold" : ""}`,
            fontStyle: `${bodyFontWeight.italic === "1" ? "italic" : ""}`,
            textDecoration: `${bodyFontWeight.underline === "1" ? "underline" : ""}`,
            overflow:
              textOverflow === "0" || textOverflow === "1" ? "hidden" : "",
            whiteSpace: textOverflow === "1" ? "nowrap" : "wrap",
            textOverflow: textOverflow === "1" ? "ellipsis" : "",
            ...getPropertyValue("Body_Default_CSS", properties),
          }}
        >
          {bodyType === "0" ? (
            body
          ) : bodyType === "1" ? (
            <div dangerouslySetInnerHTML={{ __html: body }} />
          ) : (
            <img
              src={url}
              alt="Body"
              style={{
                width: "100%",
                height: "100%",
                objectFit:
                  imageFit === "0"
                    ? "none"
                    : imageFit === "1"
                      ? "fill"
                      : "contain",
              }}
            />
          )}
        </div>
        {/* LINK DIVIDER */}
        {linkDividerSize && linkDividerSize !== "0px" && linkDividerColor && (
          <div
            style={{
              borderBottom: `${linkDividerSize} solid ${linkDividerColor}`,
              marginBottom: "0.5rem 0",
            }}
          ></div>
        )}
        {/* LINK */}
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            background: `${getPropertyValue("Link_Background", properties)}`,
            color: `${getPropertyValue("Link_Color", properties)}`,
            justifyContent: linkAlignment,
          }}
        >
          <a
            // href="https://hbq.agiline.com/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: `${getPropertyValue("Link_Color", properties)}`,
              fontSize: `${getPropertyValue("Link_Font_Size", properties)}`,
              fontFamily: `${getPropertyValue("Link_Font_Name", properties)}`,
              fontWeight: `${linkFontWeight.bold === "1" ? "bold" : ""}`,
              fontStyle: `${linkFontWeight.italic === "1" ? "italic" : ""}`,
              textDecoration: `${linkFontWeight.underline === "1" ? "underline" : ""}`,
            }}
          >
            {/* www.hbq.agiline.com */}
          </a>
        </div>
      </div>
    </>
  );
};

export default memo(DynamicCustomNode);
