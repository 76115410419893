import React from "react";
import {
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function Hourly({
  scheduleData,
  handleNumberChange,
  handleTimeChange,
  handleChange,
}) {
  return (
    <FormControl fullWidth size="small">
      <InputLabel>Option</InputLabel>
      <Select
        size="small"
        label="Option"
        name="hourlyOption"
        value={scheduleData.hourlyOption}
        onChange={handleChange}
        sx={{ marginBottom: "0.5rem" }}
      >
        <MenuItem value="byHour">By the hour</MenuItem>
        <MenuItem value="setTime">By set time of day</MenuItem>
      </Select>
      {scheduleData.hourlyOption === "byHour" ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Every"
              type="number"
              name="hours"
              value={scheduleData.hours}
              onChange={(e) => handleNumberChange(e, 1, 24)}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
            >
              {scheduleData.hours > 1 ? "Hours" : "Hour"}
            </div>
          </Grid>
        </Grid>
      ) : scheduleData.hourlyOption === "setTime" ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                marginLeft: "0.5rem",
              }}
            >
              Every Day at:
            </div>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"en"}
            >
              <TimePicker
                label="Time"
                value={scheduleData.hourlyTime}
                onChange={(event) => handleTimeChange(event, "hourlyTime")}
                renderInput={(params) => <TextField {...params} fullWidth />}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      ) : null}
    </FormControl>
  );
}

export default Hourly;
