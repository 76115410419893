import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import themeProvider from "../../ThemeProvider";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import NavBar from "../../Components/navbarComponents/NavBar";
import {
  UpdateDiagTools,
  MakeToolInactive,
  UpdateTemplateDetails,
  UnselectToolInTemplate,
  UpdateToolProperties,
} from "../../apiCalls/DiagramsAPI";
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowModes,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { TiExport } from "react-icons/ti";
import AddIcon from "@mui/icons-material/Add";
import { CopyAllOutlined } from "@mui/icons-material";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { GetDiagTools, GetToolPropertiesAll } from "../../apiCalls/DiagramsAPI";
import { ToastContainer, toast } from "react-toastify";
import { Theme } from "../../Components/IncludeFile/Theme";
import RestoreIcon from '@mui/icons-material/Restore';
import "../../styles.css"

const NodeSelector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { DiagTempID } = location.state || {};
  const [tools, setTools] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [fetched, setFetched] = useState(false);
  //Get JSON from public Folder
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const scriptElement = document.createElement("script");
  //       scriptElement.src = `ToolsJSON.js?v=${Date.now()}`;

  //       scriptElement.onload = () => {
  //         const fetchedData = window.MyVar || [];
  //         console.log("fetchedData ", fetchedData);
  //         setTools(fetchedData);
  //         setFetched(true);
  //       };
  //       document.head.appendChild(scriptElement);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  //   if (!fetched) {
  //     fetchData();
  //   }
  // }, [fetched]);
  //Get JSON from API

  async function fetchProperties() {
    try {
      const fetchedData = await GetToolPropertiesAll();
      // setProperties(fetchedData);
      return fetchedData;
    } catch (error) {
      console.error("Fetch Error ", error);
      // toast.error("Error fetching data: " + error.message);
    }
  }
  async function saveProperties(properties) {
    try {
      const response = await UpdateToolProperties(properties);
      toast.success("Data Saved!");
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  }

  const getPropertyDiagPropIDAndSection = (properties, propertyName) => {
    const property = properties?.find(
      (item) =>
        item.propertyName?.toString().toLowerCase() ===
        propertyName?.toLowerCase()
    );
    return {
      diagPropID: property.diagPropID,
      propSection: property.propSection,
    };
  };

  async function addPropertiesToNewTool(tool) {
    const newToolProperty = [];
    try {
      const properties = await fetchProperties();
      properties.map((property, index) => {
        const { diagPropID, propSection } = getPropertyDiagPropIDAndSection(
          properties,
          property.propertyName
        );
        if (diagPropID && propSection) {
          const newProperty = {
            toolPropID: 0,
            diagToolID: tool.diagToolID,
            diagPropID: property.diagPropID,
            name: tool.name,
            propSection: propSection,
            propertyName: property.propertyName,
            diagPropValue: property.defaultValue,
          };
          newToolProperty.push(newProperty);
        }
      });
      saveProperties(newToolProperty);
    } catch (error) {
      toast.error("Error fetching default properties: " + error.message);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await GetDiagTools(
          DiagTempID ? DiagTempID.toString() : "0"
        );
        setTools(fetchedData);
        const selected = fetchedData.filter((item) => item.diagTempID !== "0");
        setSelectedNodes(selected.map((item) => item.diagToolID));
        setFetched(true);
      } catch (error) {
        console.error("Fetch Error ", error);
        toast.error("Error fetching data: " + error.message);
      }
    }
    if (!fetched) {
      fetchData();
    }
  }, [fetched]);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function saveData(newTool) {
    try {
      const response = await UpdateDiagTools(newTool);
      console.log("response ", response);
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  }

  const handleAdd = () => {
    const newDiagToolID = Math.max(...tools.map((tool) => tool.diagToolID)) + 1;
    const newTool = {
      diagToolID: newDiagToolID.toString(),
      name: "New Tool " + newDiagToolID,
      title: "New Tool",
      explain: "New Tool Explanation",
      notes: "New Tool Notes",
      caption: "New Tool",
      description: "New Tool Description",
      isInactive: "0",
      createdBy: "Admin",
      modifiedBy: "Admin",
      autoSize: "0",
      node_Height: "0",
      node_Width: "4",
      nodeType: "0",
      handles: "0",
      isResizable: "0",
      isDraggable: "0",
      isDisabled: "0",
      isRotatable: "0",
      isDeletable: "0",
      link1: "",
      link1Text: "",
      paramLink1Name1: "",
      paramLink1Value1: "",
      link2: "",
      link2Text: "",
      paramLink2Name1: "",
      paramLink2Value1: "",
    };
    setTools([...tools, newTool]);
    saveData(newTool);
    addPropertiesToNewTool(newTool);
    setSelectedNodes([...selectedNodes, newDiagToolID]);
  };

  const handleCopy = (rowID) => {
    const index = tools.findIndex((tool) => tool.diagToolID === rowID);

    if (index !== -1) {
      const copiedTool = { ...tools[index] };
      const newDiagToolID =
        Math.max(...tools.map((tool) => tool.diagToolID)) + 1;

      copiedTool.diagToolID = newDiagToolID.toString();
      copiedTool.name += " Copy " + newDiagToolID;
      copiedTool.title += " Copy";
      copiedTool.notes += " Copy";
      // copiedTool.explain += " Copy";
      copiedTool.caption += " Copy";
      setTools([...tools, copiedTool]);
      saveData(copiedTool);
      addPropertiesToNewTool(copiedTool);
      setSelectedNodes([...selectedNodes, newDiagToolID]);
    } else {
      console.error("Tool not found");
      toast.error("Tool Not Found");
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteID(id);
    setDeletePopup(true);
  };

  const deleteTool = async () => {
    const response = await MakeToolInactive(deleteID);
    return !response.isError;
  };
  const handleDelete = () => {
    setDeletePopup(false);
    deleteTool();
    // if (deleteTool()) {
    //   setTools(tools.filter((tool) => tool.diagToolID !== deleteID));
    //   setSelectedNodes(
    //     selectedNodes.filter((selectedNode) => selectedNode !== deleteID)
    //   );
    // }
  };

  const [rowModesModel, setRowModesModel] = useState({});

  const handleEdit = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSave = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancel = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const index = tools.findIndex((tool) => tool.diagToolID === newRow.id);

    if (index !== -1) {
      const updatedTool = { ...tools[index], ...newRow };
      const updatedTools = [...tools];
      updatedTools[index] = updatedTool;
      saveData(updatedTool);
      setTools(updatedTools);
      return updatedTool;
    } else {
      console.error("Tool not found");
      return null;
    }
  };

  const handleNavigation = (path, propValue) => {
    navigate(path, { state: { prop: propValue, tools: tools } });
  };

  const handleCreateTemplate = async () => {
    try {
      if (DiagTempID !== null && DiagTempID !== undefined) {
        const selectedTools = tools
          .filter((tool) => selectedNodes.includes(tool.diagToolID))
          .map(({ diagToolID, detDiagTempID, seq }) => ({
            diagToolID: parseInt(diagToolID),
            DiagTempID: parseInt(DiagTempID),
            detDiagTempID: parseInt(detDiagTempID),
            seq: parseInt(seq),
          }));
        const result = await UpdateTemplateDetails(selectedTools);
        console.log("result ", result);
        // Identify tools that need to be unselected
        const unselectedTools = tools.filter(
          (tool) =>
            tool.detDiagTempID !== "0" &&
            !selectedNodes.includes(tool.diagToolID)
        );
        console.log("unselectedTools ", unselectedTools);
        await Promise.all(
          unselectedTools.map(async (tool) => {
            await UnselectToolInTemplate(tool.detDiagTempID);
          })
        );
      }
      handleNavigation("/ToolProperties", selectedNodes);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];

  const columns = [
    { field: "name", headerName: "Name", editable: true, flex: 1 },
    {
      field: "title",
      headerName: "Title",
      editable: true,
      flex: 1,
      fontWeight: "bold",
    },
    // { field: "explain", headerName: "Explain", editable: true, flex: 1 },
    {
      field: "notes",
      headerName: "Notes",
      editable: true,
      sortable: true,
      flex: 1,
    },
    {
      field: "caption",
      headerName: "Caption",
      editable: true,
      sortable: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      sortable: true,
      flex: 1,
    },
    {
      field: "seq",
      headerName: "Sequence",
      width: 150,
      editable: true,
      sortable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSave(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancel(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEdit(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<CopyAllOutlined />}
            label="Copy"
            onClick={() => handleCopy(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteOutline />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const rows = tools.map((tool, index) => ({
    id: tool.diagToolID,
    name: tool.name,
    title: tool.title,
    // explain: tool.explain,
    notes: tool.notes,
    caption: tool.caption,
    description: tool.description,
    seq: tool.seq,
  }));

  //inactive row color
  const getRowClassName = (params) => {
    const tool = tools.find((item) => item.diagToolID === params.id);
    if (tool && tool.isInactive === 1) {
      return 'inactive-row';
    }
    return '';
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAdd}>
          Add Tool
        </Button>
      </GridToolbarContainer>
    );
  }
  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavBar
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        isSearchBar={true}
        rightButtons={rightButtons}
        title="Choose Tools"
        isMenuOpenleft={false}
      />
      <div style={{ height: "94vh", overflowX: "auto" }}>
        <Box
          sx={{ width: "100%", padding: isSmallScreen ? "1rem" : "1rem 2rem" }}
        >
          <DataGrid
            rows={filteredRows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            getRowClassName={getRowClassName}
            // getRowClassName={(params) => (params.row.isInactive === "1" ? 'rowStyles' : '')}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              columns: {
                columnVisibilityModel: {
                  seq:
                    DiagTempID === null || DiagTempID === undefined
                      ? false
                      : true,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            rowSelectionModel={selectedNodes}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectedNodes(newRowSelectionModel);
            }}
            disableRowSelectionOnClick
            sx={{
              "& .MuiDataGrid-root": {
                width: "100%",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                color: themeProvider.palette.primary.dark,
              },
            }}
            slots={{
              toolbar: EditToolbar,
            }}
          />
        </Box>
        <div
          style={{
            margin: "1rem",
            display: "flex",
            justifyContent: isSmallScreen ? "center" : "flex-end",
          }}
        >
          {/* {DiagTempID === null ||
            (DiagTempID === undefined && (
              <div
                style={{
                  margin: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  width: isSmallScreen ? "100%" : "20%",
                }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={handleCreateTemplate}
                  disabled={selectedNodes.length === 0}
                >
                  Create Template
                </Button>
              </div>
            ))} */}
          <div
            className="control-property-btn"
            // style={{
            //   margin: "1rem",
            //   display: "flex",
            //   justifyContent: "center",
            //   width: isSmallScreen ? "100%" : "20%",
            // }}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleCreateTemplate}
              disabled={selectedNodes.length === 0}
            >
              Edit Tools Properties
            </Button>
          </div>
        </div>
        {deletePopup && (
          <CustomPopUpForm
            open={deletePopup}
            onClose={() => setDeletePopup(false)}
            title="Are you sure you want to delete?"
          >
            <></>
            <>
              <Button fullWidth onClick={() => setDeletePopup(false)}>
                No
              </Button>
              <Button variant="contained" fullWidth onClick={handleDelete}>
                Yes
              </Button>
            </>
          </CustomPopUpForm>
        )}
      </div>
    </ThemeProvider>
  );
};

export default NodeSelector;
