import React, { useState } from "react";
import dayjs from "dayjs";
import themeProvider from "../../../../ThemeProvider";
import { ThemeProvider } from "@mui/styles";
import WaitBox from "./WaitBox";
function Wait() {
  const [formData, setFormData] = useState({
    date: dayjs(),
    time: dayjs(),
    frequency: "",
    unit: "days",
  });

  const handleDateChange = (date) => {
    if (date.isBefore(dayjs(), "day")) {
      alert("Date is before today.");
    }
    setFormData({
      ...formData,
      date: date,
    });
  };

  const handleTimeChange = (time) => {
    const today = dayjs();
    if (formData.date.isSame(today, "day") && time.isBefore(today)) {
      alert("Time is be before now.");
    }
    setFormData({
      ...formData,
      time: time,
    });
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "frequency" && Number(value) < 0) {
      return;
    }
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  return (
    <ThemeProvider theme={themeProvider}>
      <WaitBox
        formData={formData}
        handleDateChange={handleDateChange}
        handleTimeChange={handleTimeChange}
        handleChange={handleChange}
      />
    </ThemeProvider>
  );
}

export default Wait;
