import React, { useState, useEffect } from "react";
import { SplitScreen } from "./SplitScreen";
import MultiSelector from "./multiSelector/MultiSelector";
import MenuCardComponent from "./MenuCardComponent";
import { Grid,  ThemeProvider } from "@mui/material";
import { GetDiagCardsInfo,  } from "../apiCalls/DiagramsAPI";
import { ToastContainer, toast } from "react-toastify";
import themeProvider from "../ThemeProvider";

function CreateOrOpenDiag({
  handleDetails,
  handleEditDiagram,
  searchQuery,
  caller,
  isMenuOpenleft,
  selected,
  setSelected,
  data,
  fetched,
}) {
  // const [selected, setSelected] = useState([]);
  // const [data, setData] = useState([]);
  // const [fetched, setFetched] = useState(false);

  // useEffect(() => {
  //   if (!fetched) {
  //     fetchData();
  //   }
  // }, [fetched]);
  //cache
  // async function fetchData() {
  //   const scriptElement = document.createElement("script");
  //   // scriptElement.src = `FormJSON.js?v=${Date.now()}`;
  //   scriptElement.src = `TemplateJSON.js?v=${Date.now()}`;

  //   scriptElement.onload = () => {
  //     const fetchedData = window.MyVar || [];
  //     setData(fetchedData);
  //     setSelected(fetchedData);
  //     setFetched(true);
  //   };
  //   document.head.appendChild(scriptElement);
  // }
  //API
  // async function fetchData() {
  //   try {
  //     console.log("Create or open");
  //     const templates = await GetDiagCardsInfo();
  //     setData(templates);
  //     setSelected(templates);
  //     setFetched(true);
  //   } catch (error) {
  //     console.error("Fetch Error ", error);
  //     toast.error("Error getting templates: " + error.message);
  //   }
  // }

  const itemsToDisplay = selected.filter(
    (section) =>
      section.name.toLowerCase().includes(searchQuery) ||
      section.title.toLowerCase().includes(searchQuery)
  );

  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <SplitScreen
        leftWeight={1}
        rightWeight={2}
        isMenuOpenleft={isMenuOpenleft}
      >
        <MultiSelector
          data={fetched ? data : []}
          selected={fetched ? selected : []}
          setSelected={setSelected}
        />
        <Grid sx={{ flexGrow: 1 }} container padding={1}>
          {itemsToDisplay.map((item, index) => (
            <MenuCardComponent
              key={index}
              data={item}
              size={310}
              textSize={17}
              isMobile={window.innerWidth < 750 ? true : false}
              handleDetails={handleDetails}
              handleEditDiagram={handleEditDiagram}
              caller={caller}
            />
          ))}
        </Grid>
      </SplitScreen>
    </ThemeProvider>
  );
}

export default CreateOrOpenDiag;
