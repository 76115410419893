import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { TextField } from "@mui/material";
import CustomPopUpForm from "../../../layout/CustomPopUpForm";
function SaveAsConfirmation({ open, handleClose, handleSave }) {
  const [data, setData] = useState({
    toolName: "",
    toolTitle: "",
    toolExplain: "",
    toolNotes: "",
    toolCaption: "",
    toolDescription: "",
  });
  const [nameError, setNameError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const onSave = () => {
    if (data.toolName.trim() === "") {
      setNameError("Name cannot be empty");
      return;
    }
    setNameError("");
    handleSave(data);
  };
  return (
    <CustomPopUpForm open={open} onClose={handleClose} title="Save As">
      <>
        <TextField
          required
          label="Tool Name"
          size="small"
          variant="outlined"
          fullWidth
          name="toolName"
          value={data.toolName}
          onChange={(e) => handleChange(e)}
          error={nameError}
          helperText={nameError}
        />
        <TextField
          label="Tool Title"
          size="small"
          variant="outlined"
          fullWidth
          name="toolTitle"
          value={data.toolTitle}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          label="Tool Explain"
          size="small"
          variant="outlined"
          fullWidth
          name="toolExplain"
          value={data.toolExplain}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          label="Tool Notes"
          size="small"
          variant="outlined"
          fullWidth
          name="toolNotes"
          value={data.toolNotes}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          label="Tool Caption"
          size="small"
          variant="outlined"
          fullWidth
          name="toolCaption"
          value={data.toolCaption}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          label="Tool Description"
          size="small"
          variant="outlined"
          fullWidth
          name="toolDescription"
          value={data.toolDescription}
          onChange={(e) => handleChange(e)}
        />
      </>
      <>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </>
    </CustomPopUpForm>
  );
}

export default SaveAsConfirmation;
