import React, { useState, useEffect } from "react";
import MenuCardComponent from "../../PopUpForm/MenuCardComponent";
import themeProvider from "../../ThemeProvider";
import { ThemeProvider } from "@mui/styles";
import NavBar from "../../Components/navbarComponents/NavBar";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Card, CardContent } from "@mui/material";
import { Theme } from "../../Components/IncludeFile/Theme";
import {
  UpdateTemplateHeader,
  GetDiagCardsInfo,
} from "../../apiCalls/DiagramsAPI";
import Overview from "../../Components/diagramsComponents/Overview";
import { ToastContainer, toast } from "react-toastify";
function EditTemplatePage() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selected, setSelected] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [openOverview, setOpenOverview] = useState(false);

  const handleOverviewPopup = () => {
    setOpenOverview(!openOverview);
  };

  const handleSave = async (data) => {
    try {
      const response = await UpdateTemplateHeader([data]);
      console.log("response ", response);
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const templates = await GetDiagCardsInfo();
        setSelected(templates.filter((item) => item.diagID === 0));
        setFetched(true);
      } catch (error) {
        console.error("Fetch Error ", error);
        toast.error("Error getting templates: " + error.message);
      }
    }
    if (!fetched) {
      fetchData();
    }
  }, [fetched]);
  // useEffect(() => {
  //   async function fetchData() {
  //     const scriptElement = document.createElement("script");
  //     scriptElement.src = `TemplateJSON.js?v=${Date.now()}`;

  //     scriptElement.onload = () => {
  //       const fetchedData = window.MyVar || [];
  //       const filteredData = fetchedData.filter((item) => item.DiagID === 0);
  //       console.log("filteredData ", filteredData);
  //       setSelected(filteredData);
  //       setFetched(true);
  //     };
  //     document.head.appendChild(scriptElement);
  //   }
  //   if (!fetched) {
  //     fetchData();
  //   }
  // }, [fetched]);

  //popup bottom buttons
  const handleBack = () => {
    console.log("Back");
    setSelectedItem(null);
    handleOverviewPopup();
  };

  const handleNext = () => {
    //call backend
    console.log("Submit");
    navigate("/ToolSelector", {
      state: { DiagTempID: selectedItem?.diagTempID },
    });
  };

  const handleDetails = (item) => {
    if (item !== -1) setSelectedItem(item);
    else {
      setSelectedItem(null);
    }
    handleOverviewPopup();
  };

  //search
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const itemsToDisplay = selected.filter(
    (section) =>
      section.name.toLowerCase().includes(searchQuery) ||
      section.title.toLowerCase().includes(searchQuery)
  );

  //navbar button
  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate("/Landing");
      },
    },
  ];
  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <NavBar
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        isSearchBar={true}
        rightButtons={rightButtons}
        title="Choose Template"
        isMenuOpenleft={false}
      />
      <div style={{ height: "94vh", overflowX: "auto" }}>
        <Grid sx={{ flexGrow: 1 }} container padding={1}>
          {itemsToDisplay.map((item, index) => (
            <MenuCardComponent
              key={index}
              data={item}
              size={360}
              textSize={17}
              handleDetails={handleDetails}
              caller={0}
            />
          ))}
          <MenuCardComponent
            size={360}
            textSize={17}
            handleDetails={handleDetails}
            handleEditDiagram={handleNext}
            isAddCard={1}
          />
        </Grid>
      </div>
      {openOverview && (
        <Overview
          open={openOverview}
          handlePopup={handleOverviewPopup}
          item={selectedItem}
          handleBack={handleBack}
          handleNext={handleNext}
          handleSave={handleSave}
        />
      )}
    </ThemeProvider>
  );
}

export default EditTemplatePage;
