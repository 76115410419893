import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Button,
  Grid,
  IconButton,
  InputLabel,
  FormControl,
  Collapse,
} from "@mui/material";
import Condition0 from "./Condition0";
import { v4 as uuidv4 } from "uuid";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function ConditionFactory({
  id,
  removeCondition,
  condition,
  setConditions,
  handleConditionChange,
  originalDataSet,
  originalModule,
  dataSetOptions,
}) {
  const [open, setOpen] = useState(true);
  const [openChildren, setOpenChildren] = useState({});

  const toggleChildCollapse = (childId) => {
    setOpenChildren((prevState) => ({
      ...prevState,
      [childId]: !prevState[childId],
    }));
  };

  const handleDataSetChange = (id, value) => {
    handleConditionChange(id, "dataSet", value);
  };
  const handleRemove = (id) => {
    removeCondition(id);
  };

  const handleOR = (id) => {
    setOpen(true);
    setConditions((prevConditions) => {
      const addChildrenById = (conditions, targetId) => {
        for (const cond of conditions) {
          if (cond.id === targetId) {
            const newCondition = createNewCondition(targetId);
            if (cond.children) {
              cond.children.push(newCondition);
            } else {
              cond.children = [newCondition];
            }
            setOpenChildren((prevState) => ({
              ...prevState,
              [newCondition.id]: true,
            }));
            return;
          }
          if (cond.children) addChildrenById(cond.children, targetId);
        }
      };

      const createNewCondition = (targetId) => ({
        id: uuidv4(),
        mainCondition: "",
        condition1: "",
        condition2: "",
        condition2Boolean: "",
        condition2Date: "",
        condition2List: "",
        type: "text",
        value1: "",
        value2: "",
      });

      addChildrenById(prevConditions, id);
      return [...prevConditions];
    });
  };

  // const handleOR = async (id) => {
  //   setOpen(true);
  //   setConditions((prevConditions) => {
  //     // Helper function to recursively add children to a condition by ID
  //     const addChildrenById = (conditions, targetId) => {
  //       for (const condition of conditions) {
  //         if (condition.id === targetId) {
  //           // If the condition has children, add a new object to its children array
  //           if (condition.children) {
  //             condition.children.push({
  //               id: uuidv4(),
  //               mainCondition: "",
  //               condition1: "",
  //               condition2: "",
  //               condition2Boolean: "",
  //               condition2Date: "",
  //               condition2List: "",
  //               type: "text",
  //               value1: "",
  //               value2: "",
  //             });
  //           }
  //           // If the condition doesn't have children, add the children key to the object
  //           else {
  //             condition.children = [
  //               {
  //                 id: uuidv4(),
  //                 mainCondition: "",
  //                 condition1: "",
  //                 condition2: "",
  //                 condition2Boolean: "",
  //                 condition2Date: "",
  //                 condition2List: "",
  //                 type: "text",
  //                 value1: "",
  //                 value2: "",
  //               },
  //             ];
  //           }
  //           return;
  //         }
  //         // If condition has children, recursively search through them
  //         if (condition.children) {
  //           addChildrenById(condition.children, targetId);
  //         }
  //       }
  //     };

  //     // Call the helper function to add children to the condition with the specified ID
  //     addChildrenById(prevConditions, id);

  //     return [...prevConditions]; // Return the updated conditions array
  //   });
  // };

  return (
    <Grid
      style={{
        border: "1px solid #A9A9A9",
        padding: "0.5rem",
        borderRadius: "10px",
        marginBottom: "0.5rem",
      }}
    >
      <Grid container>
        <Grid item xs={condition.mainCondition ? 10 : 11}>
          <FormControl fullWidth size="small">
            <InputLabel>Condition</InputLabel>
            <Select
              fullWidth
              label="Condition"
              size="small"
              name="mainCondition"
              value={condition.mainCondition}
              onChange={(event) =>
                handleConditionChange(id, "mainCondition", event.target.value)
              }
              sx={{ marginBottom: "1rem" }}
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              {originalDataSet !== "" && (
                <MenuItem value="0">
                  Properties about {originalDataSet}
                </MenuItem>
              )}
              <MenuItem value="1">Choose a new data set</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {condition.mainCondition && (
          <Grid item xs={1}>
            <IconButton onClick={() => setOpen(!open)}>
              {open ? (
                <ExpandLessIcon color="primary" />
              ) : (
                <ExpandMoreIcon color="primary" />
              )}
            </IconButton>
          </Grid>
        )}
        <Grid item xs={1}>
          <IconButton variant="outlined" onClick={() => handleRemove(id)}>
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={open}>
        {condition.mainCondition === "0" ? (
          <Condition0
            id={id}
            condition={condition}
            originalDataSet={originalDataSet}
            handleConditionChange={handleConditionChange}
            caller={0}
          />
        ) : condition.mainCondition === "1" ? (
          <>
            <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel>Data Set</InputLabel>
                  <Select
                    label="Data Set"
                    fullWidth
                    size="small"
                    name="dataSet"
                    value={condition.dataSet}
                    onChange={(e) => handleDataSetChange(id, e.target.value)}
                  >
                    {dataSetOptions.length <= 0 ? (
                      <MenuItem disabled> Please pick a module</MenuItem>
                    ) : (
                      dataSetOptions
                        .filter((option) => option.value !== -1)
                        .map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Condition0
              id={id}
              condition={condition}
              originalDataSet={originalDataSet}
              handleConditionChange={handleConditionChange}
              caller={1}
            />
          </>
        ) : null}
        {condition.children &&
          condition.children.length > 0 &&
          condition.children.map((child, i) => (
            <Grid
              key={i}
              style={{
                margin: "0.5rem",
                padding: "0 1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    border: "1px solid lightGray",
                    borderRadius: "5px",
                    padding: "0.2rem 0.5rem",
                    marginRight: "0.5rem",
                    marginBottom: "0.5rem",
                    color: "gray",
                  }}
                >
                  or
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    borderBottom: "1px dashed black",
                    borderColor: "lightGray",
                  }}
                ></div>
                <IconButton onClick={() => toggleChildCollapse(child.id)}>
                  {openChildren[child.id] ? (
                    <ExpandLessIcon color="secondary" />
                  ) : (
                    <ExpandMoreIcon color="secondary" />
                  )}
                </IconButton>
                <IconButton
                  variant="outlined"
                  onClick={() => handleRemove(child.id)}
                >
                  <ClearIcon />
                </IconButton>
              </div>
              <Collapse in={openChildren[child.id]}>
                <FormControl fullWidth size="small">
                  <InputLabel>Condition</InputLabel>
                  <Select
                    fullWidth
                    label="Condition"
                    size="small"
                    name="mainCondition"
                    value={child.mainCondition}
                    onChange={(event) =>
                      handleConditionChange(
                        child.id,
                        "mainCondition",
                        event.target.value
                      )
                    }
                    sx={{ marginBottom: "1rem" }}
                  >
                    {/* Dropdown options */}
                    <MenuItem value="Select" disabled>
                      Select
                    </MenuItem>
                    {originalDataSet !== "" && (
                      <MenuItem value="0">
                        Properties about {originalDataSet}
                      </MenuItem>
                    )}
                    <MenuItem value="1">Choose a new data set</MenuItem>
                  </Select>
                </FormControl>
                {child.mainCondition === "0" ? (
                  <Condition0
                    id={child.id}
                    condition={child}
                    originalDataSet={originalDataSet}
                    handleConditionChange={handleConditionChange}
                    caller={0}
                    // fieldDropDowns={child.fieldDropDown}
                  />
                ) : child.mainCondition === "1" ? (
                  <>
                    <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
                      <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Data Set</InputLabel>
                          <Select
                            label="Data Set"
                            fullWidth
                            size="small"
                            name="dataSet"
                            value={child.dataSet}
                            onChange={(e) =>
                              handleDataSetChange(child.id, e.target.value)
                            }
                          >
                            {dataSetOptions.length <= 0 ? (
                              <MenuItem disabled>
                                {" "}
                                Please pick a module
                              </MenuItem>
                            ) : (
                              dataSetOptions
                                .filter((option) => option.value !== -1)
                                .map((option, index) => (
                                  <MenuItem key={index} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Condition0
                      id={child.id}
                      condition={child}
                      originalDataSet={originalDataSet}
                      handleConditionChange={handleConditionChange}
                      caller={1}
                      // fieldDropDowns={child.fieldDropDown}
                      // fieldDropDowns={fetchDataSets(condition.dataSet)}
                    />
                  </>
                ) : null}
              </Collapse>
            </Grid>
          ))}
      </Collapse>
      {/* action buttons */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            style={{
              fontSize: "0.75rem",
              maxWidth: "50px",
              maxHeight: "30px",
              minWidth: "50px",
              minHeight: "30px",
            }}
            onClick={() => handleOR(id)}
          >
            OR
          </Button>
        </Grid>
        {/* <Grid item xs={6}>
          <Button
            variant="outlined"
            style={{
              fontSize: "0.75rem",
              maxWidth: "80px",
              maxHeight: "30px",
              minWidth: "80px",
              minHeight: "30px",
            }}
            // onClick={() => handleRemove(id)}
          >
            Advanced
          </Button>
        </Grid> */}
      </div>
    </Grid>
  );
}

export default ConditionFactory;
