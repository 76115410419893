const DEFAULT_USERID = "-1";

let APIProvider = window.APIProvider;

let POST_HEADER = {
  method: "POST",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json, text/plain, */*",
    //   MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
  },
  mode: "cors",
};

let GET_HEADER = {
  method: "GET",
  headers: {
    //   MemberID: IsLogin() ? userInfo.memberID : DEFAULT_USERID,
  },
  mode: "cors",
};

function GetRequestHeader() {
  return GET_HEADER;
}

function PostRequestHeader() {
  return POST_HEADER;
}

// function GetAPI() {
//   return AquariusAPI;
// }

// function GetSessionUser() {
//   return userInfo;
// }

function GetDefaultUserID() {
  return DEFAULT_USERID;
}

//Start of API
async function GetLandingPageSections() {
  try {
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.getLandingSections,
      GET_HEADER
    );
    const landing = await response.json();
    console.log("landing ", landing);
    return landing;
  } catch (error) {
    console.error("Error when fetching landing page items", error);
  }
}

async function PushWebPages(pageName) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.PushWebPage +
        "?pageName=" +
        pageName,
      GET_HEADER
    );
    const pushWebPages = await response;
    console.log("pushWebPages ", pushWebPages);
    return "Page Loaded";
  } catch (error) {
    console.error("Error when fetching landing page items", error);
  }
}
export { GetLandingPageSections, PushWebPages };
