import React, { useState } from "react";
import ScheduleSelector from "../../Crono.js/CronExpression";
import { ThemeProvider } from "@mui/styles";
import themeProvider from "../../../../ThemeProvider";
import ConditionalSplitBox from "./ConditionalSplitBox";
function ConditionalSplit() {
  const [formData, setFormData] = useState({
    name: "",
    checkResource: "",
    resourceListOfFields: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <ConditionalSplitBox formData={formData} handleChange={handleChange} />
    </ThemeProvider>
  );
  // return <ScheduleSelector />;
}

export default ConditionalSplit;
