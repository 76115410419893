import React, { useEffect, useState } from "react";
import {
  Typography,
  InputLabel,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { Theme } from "../IncludeFile/Theme";
import { ToastContainer, toast } from "react-toastify";
import NavBar from "../navbarComponents/NavBar";

import CustomPopUpForm from "../../layout/CustomPopUpForm";

function Overview({
  open,
  handlePopup,
  item,
  handleBack,
  handleNext,
  handleSave,
  data,
}) {
  const [isEditable, setIsEditable] = useState(item ? false : true);
  const [isSaved, setIsSaved] = useState(false);
  const [formData, setFormData] = useState({
    DiagTempID:
      item.diagTempID !== null && item.diagTempID !== undefined
        ? item.diagTempID
        : 0,
    DiagID: item.diagID !== null && item.diagID !== undefined ? item.diagID : 0,
    Name: item.name ? item.name : "",
    Title: item.title ? item.title : "",
    Explanation: item.explanation ? item.explanation : "",
    Notes: item.notes ? item.notes : "",
    ShowMinMap:
      item.showMinMap !== null && item.showMinMap !== undefined
        ? item.showMinMap
        : 1,
    Layout: item.layout !== null && item.layout !== undefined ? item.layout : 1,
    nodes: item.nodes,
    edges: item.edges,
    viewPort: item.viewPort,
  });
  const options = data
    ?.filter((item) => item.diagID === 0)
    .map((item) => ({
      value: item.name, // Use name as the visible value
      diagTempID: item.diagTempID, // Store diagTempID for selection
    }));
  const handleEdit = () => {
    setIsEditable(true);
  };

  const handleSaveClick = () => {
    // Implement save functionality here
    setIsSaved(true);
    if (formData.Name !== "") {
      setIsEditable(false);
    }
    handleSave(formData);
  };

  const handleCancelClick = () => {
    // Implement cancel functionality here
    setIsEditable(false);
    setIsSaved(false);
    setFormData({
      DiagTempID:
        item.diagTempID !== null && item.diagTempID !== undefined
          ? item.diagTempID
          : 0,
      DiagID:
        item.diagID !== null && item.diagID !== undefined ? item.diagID : 0,
      Name: item.name ? item.name : "",
      Title: item.title ? item.title : "",
      Explanation: item.explanation ? item.explanation : "",
      Notes: item.notes ? item.notes : "",
      ShowMinMap:
        item.showMinMap !== null && item.showMinMap !== undefined
          ? item.showMinMap
          : 1,
      Layout:
        item.layout !== null && item.layout !== undefined ? item.layout : 1,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Check the type of input element
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    if (name === "Name") {
      const selectedOption = options?.find((item) => item.value === value);
      setFormData({
        ...formData,
        [name]: newValue,
        DiagTempID: selectedOption.diagTempID,
      });
    } else {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    }
  };

  const iconButtonsSaveAndRestore = [
    {
      icon: Theme.Restore,
      color: "primary",
      handleClick: () => {
        handleCancelClick();
      },
      tooltip: "Cancel",
    },
    {
      icon: Theme.Save,
      color: "primary",
      handleClick: () => {
        handleSaveClick();
      },
      tooltip: "Save",
    },
  ];
  const iconButtonsEdit = [
    {
      icon: Theme.Edit,
      color: "primary",
      handleClick: () => {
        handleEdit();
      },
      tooltip: "Click To Edit",
    },
  ];

  return (
    <CustomPopUpForm
      open={open}
      onClose={handlePopup}
      title="Overview"
      iconButtons={isEditable ? iconButtonsSaveAndRestore : iconButtonsEdit}
    >
      <>
        {!isEditable ? (
          <>
            <div className="card-info">
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Name*:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {formData.Name}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Title:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {formData.Title}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Explanation:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {formData.Explanation}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Notes:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {formData.Notes}
                  </Typography>
                </div>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.ShowMinMap === 1 ? true : false}
                    onChange={handleInputChange}
                    name="ShowMinMap"
                    sx={{ marginLeft: "10px" }}
                    disabled
                  />
                }
                label="ShowMinMap"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.Layout === 1 ? true : false}
                    onChange={handleInputChange}
                    name="Layout"
                    sx={{ marginLeft: "10px" }}
                    disabled
                  />
                }
                // label={formData.Layout === 1 ? "Enabled" : "Disabled"}
                label="Layout"
              />
            </div>
          </>
        ) : (
          <div className="card-info">
            <div className="card-info-inside">
              {data !== null && data !== undefined ? (
                <>
                  <Typography style={{ fontWeight: "bold" }}>Name :</Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      width: "99%",
                      marginRight: "auto",
                    }}
                  >
                    <Select
                      sx={{
                        width: "99%",
                        marginRight: "auto",
                      }}
                      value={formData.Name}
                      size="small"
                      name="Name"
                      onChange={handleInputChange}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  {" "}
                  <Typography style={{ fontWeight: "bold" }}>Name :</Typography>
                  <TextField
                    required
                    type="text"
                    className="edit-popup"
                    name="Name"
                    onChange={handleInputChange}
                    value={formData.Name}
                  />
                </>
              )}
              {isSaved && formData.Name === "" && (
                <Typography style={{ color: "red" }}>
                  Name is required
                </Typography>
              )}
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>Title:</Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Title"
                onChange={handleInputChange}
                value={formData.Title}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Explanation:
              </Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Explanation"
                onChange={handleInputChange}
                value={formData.Explanation}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>Notes:</Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Notes"
                onChange={handleInputChange}
                value={formData.Notes}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.ShowMinMap === 1 ? true : false}
                  onChange={handleInputChange}
                  name="ShowMinMap"
                  sx={{ marginLeft: "10px" }}
                />
              }
              label="ShowMinMap"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.Layout === 1 ? true : false}
                  onChange={handleInputChange}
                  name="Layout"
                  sx={{ marginLeft: "10px" }}
                />
              }
              label="Layout"
            />
          </div>
        )}
      </>
      <>
        <Button fullWidth onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" fullWidth onClick={handleNext}>
          Next
        </Button>
      </>
    </CustomPopUpForm>
  );
}

export default Overview;
