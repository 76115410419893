import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import "./MultiSelectorStyles.css";

export default function MultiSelector({ data, selected, setSelected }) {
  const isAllSelected = data.length > 0 && selected.length === data.length;

  const handleToggle = (value) => () => {
    setSelected((prevSelected) => {
      const isSelected = prevSelected.some((section) => section.name === value);

      if (isSelected) {
        // If the item is already in the selected array, remove all occurrences
        return prevSelected.filter((section) => section.name !== value);
      } else {
        // If the item is not in the selected array, add all occurrences at the correct position
        const selectedSections = data.filter(
          (section) => section.name === value
        );
        const insertIndex = data.findIndex((section) => section.name === value);

        return [
          ...prevSelected.slice(0, insertIndex),
          ...selectedSections,
          ...prevSelected.slice(insertIndex),
        ];
      }
    });
  };

  const handleSelectAll = () => {
    setSelected((prevSelected) => {
      if (prevSelected.length === data.length) {
        // If all items are currently selected, deselect all
        return [];
      } else {
        // If some or none are selected, select all
        return [...data];
      }
    });
  };

  return (
    <List>
      <ListItem button onClick={handleSelectAll}>
        <ListItemIcon>
          <Checkbox
            checked={isAllSelected}
            indeterminate={selected.length > 0 && selected.length < data.length}
          />
        </ListItemIcon>
        <ListItemText primary={isAllSelected ? "Unselect All" : "Select All"} />
      </ListItem>
      {data.map((section) => {
        if (section.diagID === 0) {
          return (
            <ListItem
              key={section.diagTempID}
              button
              onClick={handleToggle(section.name)}
            >
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(section) > -1} />
              </ListItemIcon>
              <ListItemText primary={section.name} />
            </ListItem>
          );
        }
        return null; // If DiagID is not 0, return null (or an empty fragment)
      })}
    </List>
  );
}
