import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./RecursiveTreeView.css";
import { Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Controls from "../IncludeFile/Controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DialogComponent = ({
  open,
  onClose,
  handleAddControl,
  searchQuery,
  setContainerIcon,
  ContainerIcon,
  InputClicked,
  setInputClicked,
  displayClicked,
  setDisplayClicked,
  IconsClicked,
  setIconClicked,
}) => {
  const toggleDisplay = () => setDisplayClicked(!displayClicked);
  const toggleIcons = () => setIconClicked(!IconsClicked);
  const toggleDisplayIcons = () => setContainerIcon(!ContainerIcon);
  const toggleInput = () => setInputClicked(!InputClicked);

  const hasResults = Controls.some((controlGroup) =>
    controlGroup.items.some((control) =>
      control.NameOFControl.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );


  return (
    <div className="drawer-component">
      <Drawer anchor="left" open={open} variant={"persistent"}>
        <Box
          sx={{ p: 1, minWidth: "auto", maxWidth: "250px", className: "com" }}
        >
          <div>
            <div className="centered-buttons">
              <div style={{ marginBottom: "50px" }}>
                <div>
                  <h2
                    style={{
                      color: "rgb(1, 74, 145)",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    Choose Control
                  </h2>
                </div>
                <div
                  onClick={onClose}
                  style={{ position: "absolute", right: "10px", top: "10px" }}
                >
                  <CloseIcon />
                </div>
                {hasResults ? (
                  <>
                    {Controls[0].items.some((control) =>
                      control.NameOFControl.toLowerCase().includes(
                        searchQuery.toLowerCase()
                      )
                    ) && (
                      <>
                        <List
                          component="div"
                          disablePadding
                          className={
                            ContainerIcon
                              ? "controlmenuLinktitle currentLink"
                              : "controlmenuLinktitle"
                          }
                        >
                          <ListItem onClick={toggleDisplayIcons}>
                            <ListItemText primary={Controls[0].title} />
                            {ContainerIcon ? (
                              <ExpandMoreIcon />
                            ) : (
                              <ChevronRightIcon />
                            )}
                          </ListItem>

                          {ContainerIcon && (
                            <div className="container-lft-panel">
                              {Controls[0].items
                                .filter((control) =>
                                  control.NameOFControl.toLowerCase().includes(
                                    searchQuery.toLowerCase()
                                  )
                                )
                                .filter(
                                  (control) =>
                                    control.NameOFControl !== "Radio Container"
                                )
                                .sort((a, b) =>
                                  a.NameOFControl.localeCompare(b.NameOFControl)
                                )
                                .map((control, index) => (
                                  <div
                                    key={index}
                                    className="headercontainer"
                                    onClick={() =>
                                      handleAddControl(control.controltype)
                                    }
                                  >
                                    <div className="ContStyl">
                                    
                                        <div className="ContainerIconstyl">
                                          <div className="icon-name-conatainer">
                                            <FontAwesomeIcon
                                              icon={{
                                                prefix: "fa",
                                                iconName: [control.mediaURl],
                                              }}
                                            />
                                          </div>
                                          <div className="top-elips-text">
                                            {control.NameOFControl}
                                          </div>
                                        </div>
                                     
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </List>
                      </>
                    )}

                    {Controls[1].items
                      .filter(
                        (control) =>
                          control.NameOFControl !== "TableLabel" &&
                          control.NameOFControl !== "Row"
                      )
                      .some((control) =>
                        control.NameOFControl.toLowerCase().includes(
                          searchQuery.toLowerCase()
                        )
                      ) && (
                      <>
                        <List
                          component="div"
                          disablePadding
                          className={
                            displayClicked
                              ? "controlmenuLinktitle currentLink"
                              : "controlmenuLinktitle"
                          }
                        >
                          <ListItem onClick={toggleDisplay}>
                            <ListItemText primary={Controls[1].title} />
                            {displayClicked ? (
                              <ExpandMoreIcon />
                            ) : (
                              <ChevronRightIcon />
                            )}
                          </ListItem>

                          {displayClicked && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "5px",
                              }}
                            >
                              {Controls[1].items
                                .filter(
                                  (control) =>
                                    control.NameOFControl !== "TableLabel" &&
                                    control.NameOFControl !== "Row"
                                )
                                .filter((control) =>
                                  control.NameOFControl.toLowerCase().includes(
                                    searchQuery.toLowerCase()
                                  )
                                )
                                .sort((a, b) =>
                                  a.NameOFControl.localeCompare(b.NameOFControl)
                                )
                                .map((control, index) => (
                                  <div
                                    key={index}
                                    className="headercontainer"
                                    onClick={() =>
                                      handleAddControl(control.controltype)
                                    }
                                  >
                                    <div className="ContStyl">
                                    
                                        <div className="ContainerIconstyl">
                                          <div className="container-display-icon">
                                            <FontAwesomeIcon
                                              icon={{
                                                prefix: "fa",
                                                iconName: [control.mediaURl],
                                              }}
                                            />
                                          </div>
                                          <div className="top-elips-text">
                                            {control.NameOFControl}
                                          </div>
                                        </div>
                                     
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </List>
                      </>
                    )}

                    {Controls[2].items.some((control) =>
                      control.NameOFControl.toLowerCase().includes(
                        searchQuery.toLowerCase()
                      )
                    ) && (
                      <>
                        <List
                          component="div"
                          disablePadding
                          className={
                            InputClicked
                              ? "controlmenuLinktitle currentLink"
                              : "controlmenuLinktitle"
                          }
                        >
                          <ListItem onClick={toggleInput}>
                            <ListItemText primary={Controls[2].title} />
                            {InputClicked ? (
                              <ExpandMoreIcon />
                            ) : (
                              <ChevronRightIcon />
                            )}
                          </ListItem>

                          {InputClicked && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "5px",
                              }}
                            >
                              {Controls[2].items
                                .filter((control) =>
                                  control.NameOFControl.toLowerCase().includes(
                                    searchQuery.toLowerCase()
                                  )
                                )
                                .sort((a, b) =>
                                  a.NameOFControl.localeCompare(b.NameOFControl)
                                )
                                .map((control, index) => (
                                  <div
                                    key={index}
                                    className="headercontainer"
                                    onClick={() =>
                                      handleAddControl(control.controltype)
                                    }
                                  >
                                    <div className="ContStyl">
                                    
                                        <div className="ContainerIconstyl">
                                          <div className="container-icon-input">
                                            <FontAwesomeIcon
                                              icon={{
                                                prefix: "fa",
                                                iconName: [control.mediaURl],
                                              }}
                                            />
                                          </div>
                                          <div className="top-elips-text">
                                            {control.NameOFControl}
                                          </div>
                                        </div>
                                     
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </List>
                      </>
                    )}

                    {Controls[3].items.some((control) =>
                      control.NameOFControl.toLowerCase().includes(
                        searchQuery.toLowerCase()
                      )
                    ) && (
                      <>
                        <List
                          component="div"
                          disablePadding
                          className={
                            IconsClicked
                              ? "controlmenuLinktitle currentLink"
                              : "controlmenuLinktitle"
                          }
                        >
                          <ListItem onClick={toggleIcons}>
                            <ListItemText primary={Controls[3].title} />
                            {IconsClicked ? (
                              <ExpandMoreIcon />
                            ) : (
                              <ChevronRightIcon />
                            )}
                          </ListItem>

                          {IconsClicked && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "5px",
                              }}
                            >
                              {Controls[3].items
                                .filter((control) =>
                                  control.NameOFControl.toLowerCase().includes(
                                    searchQuery.toLowerCase()
                                  )
                                )
                                .sort((a, b) =>
                                  a.NameOFControl.localeCompare(b.NameOFControl)
                                )
                                .map((control, index) => (
                                  <div
                                    key={index}
                                    className="iconContainer"
                                    onClick={() =>
                                      handleAddControl(control.controltype)
                                    }
                                  >
                                    <div className="ContStyl headercontainer container-icon">
                                      <Tooltip
                                        title={control.NameOFControl}
                                        arrow
                                      >
                                        <div className="ContainerIconstyl icon-style-left">
                                          <div style={{ fontSize: "30px" }}>
                                            <FontAwesomeIcon
                                              icon={{
                                                prefix:
                                                  control.mediaURl.startsWith(
                                                    "fa"
                                                  )
                                                    ? control.mediaURl.startsWith(
                                                        "fas"
                                                      )
                                                      ? "fas"
                                                      : "fab"
                                                    : "fa",
                                                iconName: [control.mediaURl],
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </List>
                      </>
                    )}
                  </>
                ) : (
                  <p
                    style={{
                      textAlignLast: "center",
                      fontStyle: "italic",
                    }}
                  >
                    No Results
                  </p>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default DialogComponent;
