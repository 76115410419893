import React from "react";
import Logo from "../../assets/bubblesLogo.png";
import email from "../../assets/email.svg";
import phone from "../../assets/phone.svg";
import { Typography } from "@mui/material";
import twitter from "../../assets/twitter.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import themeProvider from "../../ThemeProvider";

// let emailAddress = window.landingPageFooter.emailAddress;
// let phoneNumber = window.landingPageFooter.phoneNumber;

function LandingFooterMobile() {
  const [open, setOpen] = React.useState(false);
  function onClose() {
    setOpen(false);
  }
  function handleFeedBack() {
    setOpen(true);
  }
  return (
    <div
      style={{
        backgroundColor: themeProvider.palette.landingBg.dark,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
      >
        {/* <Link to="/"> */}
          <img
            src={Logo}
            style={{
              width: "10rem",
              height: "auto",
              marginTop: "10px",
              marginLeft: "10px",
            }}
            alt="Aquarius icon"
          />
        {/* </Link> */}
      </div>
      <hr
        style={{
          borderColor: "#FFFFFF",
          borderWidth: "0.px",
          marginBottom: "3rem",
        }}
      />

      {/* top links */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {/* <Link
          to="/PrivacyPolicy"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "0.8rem" }}
          >
            Privacy Policy
          </Typography>
        {/* </Link> */}
        <div
          style={{
            borderLeft: "2px solid white",
            height: "20px",
            marginLeft: "1rem",
          }}
        ></div>
        {/* <Link
          to="/TermOfUse"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "0.8rem", marginLeft: "1rem" }}
          >
            Terms Of Use
          </Typography>
        {/* </Link> */}
        <div
          style={{
            borderLeft: "2px solid white",
            height: "20px",
            marginLeft: "1rem",
          }}
        ></div>
        {/* <Link
          to="/CookiePolicy"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "0.8rem", marginLeft: "1rem" }}
          >
            Cookie Policy
          </Typography>
        {/* </Link> */}
      </div>

      {/* bottom links */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "3rem",
          flexWrap: "wrap",
        }}
      >
        {/* <Link
          to="/AdvertisingPolicy"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "0.8rem" }}
          >
            Advertising Policy
          </Typography>
        {/* </Link> */}
        <div
          style={{
            borderLeft: "2px solid white",
            height: "20px",
            marginLeft: "1rem",
          }}
        ></div>
        {/* <Link
          to="/About"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        > */}
          <Typography
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "0.8rem", marginLeft: "1rem" }}
          >
            About Us
          </Typography>
        {/* </Link> */}
        <div
          style={{
            borderLeft: "2px solid white",
            height: "20px",
            marginLeft: "1rem",
          }}
        ></div>
        {/* <Link style={{ textDecoration: "none", color: "inherit" }}> */}
          <Typography
            onClick={handleFeedBack}
            noWrap
            component="div"
            sx={{ color: "#FFFFFF", fontSize: "0.8rem", marginLeft: "1rem" }}
          >
            Contact Us
          </Typography>
        {/* </Link> */}
      </div>
      {/* Phone and email */}
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          // marginLeft: "2rem",
          marginTop: "3rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <img
            src={email}
            alt="email address"
            style={{ width: "27px", height: "27px", marginRight: "5px" }}
          />
          {/* <p style={{ color: "white", marginLeft: "5px", fontSize: "0.9rem" }}>
            {emailAddress}
          </p> */}
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <img
            src={phone}
            alt="phone number"
            style={{ width: "27px", height: "27px", marginRight: "5px" }}
          />
          {/* <p style={{ color: "white", marginLeft: "5px", fontSize: "0.9rem" }}>
            {phoneNumber}
          </p> */}
        </div>
      </div>
      {/* Social Media Icons */}
      <div
        style={{
          marginTop: "4rem",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img
          src={twitter}
          alt="phone number"
          style={{ width: "30px", height: "30px" }}
        />
        <img
          src={facebook}
          alt="phone number"
          style={{ width: "30px", height: "30px", marginLeft: "1rem" }}
        />
        <img
          src={instagram}
          alt="phone number"
          style={{ width: "30px", height: "30px", marginLeft: "1rem" }}
        />
      </div>

      <span
        style={{
          fontSize: "0.7rem",
          backgroundColor: themeProvider.palette.landingBg.copyright,
          bottom: "0",
          width: "100%",
          color: "white",
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          marginTop: "3rem",
        }}
      >
        Copyright © 2023 | {window.landingPageFooter.companyName}™ | All Rights
        Reserved | Powered by Agiline Software, LLC
      </span>
    </div>
  );
}
export default LandingFooterMobile;
