import React from "react";
import { useSearchParams } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import themeProvider from "../../ThemeProvider";
import PopUpComponent from "./PopUpComponent";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";

let IframeProvider = window.IframeProvider;

//https://aquarius.agiline.com/search?guid=E0E2B68A-33AB-4587-873B-79FE5B2B8BF7&width=1084px&height=1000px&caption=Ports Served

function IFrameComponent({ item }) {
  //Get Params from Link
  const urlParams = new URLSearchParams(item.link.split("?")[1]);

  const extractHeight = (value) => {
    // Regular expression to check if the value ends with a valid suffix
    const suffixRegex = /(px|rem|em|vh|vw|vmin|vmax)$/i;

    // Check if the value ends with a valid suffix
    const hasSuffix = suffixRegex.test(value);

    if (!hasSuffix) {
      // If it doesn't have a valid suffix, append "px" to the value
      return value + "px";
    }

    return value;
  };
  const variables = {
    guid: urlParams.get("guid"),
    width: urlParams.get("width"),
    height: extractHeight(urlParams.get("height")),
    caption: urlParams.get("caption"),
  };

  const [open, setOpen] = React.useState(false);
  const [sectionID, setSectionID] = React.useState(0);
  const [sectionTitle, setSectionTitle] = React.useState("");
  const handleDialogToggle = (event, sectionID, sectionTitle) => {
    event.stopPropagation();
    setSectionID(sectionID);
    setSectionTitle(sectionTitle);
    setOpen(!open);
  };

  return (
    <>
      {variables.caption !== null && (
        <div>
          <h2
            style={{
              color: themeProvider.palette.primary.main,
              fontSize: "28px",
              fontWeight: "bold",
              textAlign: "right",
              marginLeft: "20px",
              marginRight: "20px",
              paddingBottom: "20px",
            }}
          >
            {/* {admin && (
              <>
                <IconButton
                  onClick={(event) =>
                    handleDialogToggle(
                      event,
                      variables.caption,
                      variables.caption
                    )
                  }
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={(event) =>
                    handleDialogToggle(
                      event,
                      variables.caption,
                      variables.caption
                    )
                  }
                  color="primary"
                  sx={{ marginRight: "10px" }}
                >
                  <AddCircleIcon />
                </IconButton>
              </>
            )} */}
            {variables.caption}
          </h2>
        </div>
      )}
      <iframe
        style={{
          width: "100%",
          //   width: variables.width ? variables.width : "100%",
          height: variables.height ? variables.height : "25rem",
        }}
        src={IframeProvider.baseURL + variables.guid}
        title="form"
      ></iframe>
    </>
  );
}

export default IFrameComponent;
