import { styled } from "@mui/material/styles";

export const PaginationContainer = styled("ul")({
  display: "flex",
  listStyleType: "none",
  padding: 0,
  margin: 0,
  justifyContent: "center",
  flexWrap: "wrap",
});
export const PaginationItem = styled("li")(
  ({ iconColor, selectedColor, hoverColor }) => ({
    padding: "0 12px",
    height: "32px",
    textAlign: "center",
    margin: "auto 4px",
    color: iconColor,
    display: "flex",
    boxSizing: "border-box",
    alignItems: "center",
    letterSpacing: "0.01071em",
    borderRadius: "16px",
    lineHeight: "1.43",
    fontSize: "13px",
    minWth: "32px",
    cursor: "pointer",
    backgroundColor: selectedColor,
    "&:hover": {
      backgroundColor: hoverColor,
    },
  })
);

export const Arrow = styled("div")({
  position: "relative",
  content: "''",
  display: "inline-block",
  width: "0.4em",
  height: "0.4em",
  borderRight: "0.12em solid rgba(0, 0, 0, 0.87)",
  borderTop: "0.12em solid rgba(0, 0, 0, 0.87)",
});

export const LeftArrow = styled(Arrow)({
  transform: "rotate(-135deg) translateX(-1px) translateY(2px)",
});
export const RightArrow = styled(Arrow)({
  transform: "rotate(45deg)",
});
export const DisabledLeftArrow = styled(LeftArrow)({
  pointerEvents: "none",
  borderColor: "rgba(210, 210, 210, 0.87)",
});

export const DisabledRightArrow = styled(RightArrow)({
  pointerEvents: "none",
  borderColor: "rgba(210, 210, 210, 0.87)",
});
