import React from "react";
import {
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function Daily({
  scheduleData,
  handleNumberChange,
  handleTimeChange,
  handleChange,
}) {
  return (
    <FormControl fullWidth size="small">
      <InputLabel>Option</InputLabel>
      <Select
        size="small"
        label="Option"
        name="dailyOption"
        value={scheduleData.dailyOption}
        onChange={handleChange}
        sx={{ marginBottom: "0.5rem" }}
      >
        <MenuItem value="byDay">By Day</MenuItem>
        <MenuItem value="allWeekDays">Every Week Day</MenuItem>
      </Select>
      {scheduleData.dailyOption === "byDay" && (
        <Grid container spacing={2} sx={{ marginBottom: "0.5rem" }}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Every"
              type="number"
              name="days"
              value={scheduleData.days}
              onChange={(e) => handleNumberChange(e, 1, 100)}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
            >
              {scheduleData.days > 1 ? "Days" : "Day"}
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              marginLeft: "0.5rem",
            }}
          >
            Start at:
          </div>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
            <TimePicker
              label="Time"
              value={scheduleData.dailyTime}
              onChange={(event) => handleTimeChange(event, "dailyTime")}
              renderInput={(params) => <TextField {...params} fullWidth />}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default Daily;
