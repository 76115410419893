import { createTheme } from "@mui/material/styles";

export const themeProvider = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 830,
      lg: 1280,
      xl: 1920,
    },
  },

  palette: {
    mode: "light",
    primary: {
      main: "#1976D2",
      light: "#42A5F5",
      dark: "#1565C0",
    },
    secondary: {
      main: "#008E05",
      light: "#0EB314",
      dark: "#006F04",
    },
    background: {
      default: "#f1f1f1",
    },
    landingBg: {
      light: "#D9DFE7",
      dark: "#B3C5D8",
      copyright: "#7C9FC3",
      bannerText: "#F7F4F3",
      navBar: "#FFFFFF",
    },
    bg:{
      lightWhite:"#FAFAFA",
    },
    primaryContrastColor: {
      main: "#FFFFFF",
    },
  },
});

export default themeProvider;
