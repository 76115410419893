import React, { useState, useEffect } from "react";
import PropertiesAccordion from "../../../reusableComponents/PropertiesAccordion";
import IconBox from "../IconBox";
import FontDropDown from "./FontDropDown";
import DividerDropDown from "./DividerDropDown";

import {
  PiAlignRightSimpleBold,
  PiAlignLeftSimpleDuotone,
} from "react-icons/pi";
import { BsAlignCenter } from "react-icons/bs";
import { RxStretchHorizontally } from "react-icons/rx";

function LinkDropDowns({
  selectedItemID,
  editedProperties,
  handlePropertyChange,
}) {
  const [linkHorizontalAlignment, setLinkHorizontalAlignment] = useState({
    left: 0,
    horizontalCenter: 0,
    right: 0,
    horizontalStretch: 0,
  });
  const [linkColor, setLinkColor] = useState({
    backgroundColor: "",
    color: "",
  });
  useEffect(() => {
    const propertyGroup = editedProperties.find(
      (group) => group[0]?.diagToolID === selectedItemID
    );

    if (propertyGroup) {
      const linkAlignment = propertyGroup.find(
        (property) => property.propertyName === "Link_Alignment_Horizontal"
      );
      const linkBackground = propertyGroup.find(
        (property) => property.propertyName === "Link_Background"
      );
      const color = propertyGroup.find(
        (property) => property.propertyName === "Link_Color"
      );
      if (linkAlignment) {
        setLinkHorizontalAlignment({
          left:
            linkAlignment?.diagPropValue.toString().toLowerCase() === "left"
              ? 1
              : 0,
          horizontalCenter:
            linkAlignment?.diagPropValue.toString().toLowerCase() === "center"
              ? 1
              : 0,
          right:
            linkAlignment?.diagPropValue.toString().toLowerCase() === "right"
              ? 1
              : 0,
          horizontalStretch:
            linkAlignment?.diagPropValue.toString().toLowerCase() === "stretch"
              ? 1
              : 0,
        });
      } else {
        setLinkHorizontalAlignment({
          left: 0,
          horizontalCenter: 0,
          right: 0,
          horizontalStretch: 0,
        });
      }
      if (linkBackground) {
        setLinkColor((prevLinkColor) => ({
          ...prevLinkColor,
          backgroundColor: linkBackground
            ? linkBackground.diagPropValue.toString()
            : "",
        }));
      } else {
        setLinkColor((prevLinkColor) => ({
          ...prevLinkColor,
          backgroundColor: "",
        }));
      }
      if (color) {
        setLinkColor((prevLinkColor) => ({
          ...prevLinkColor,
          color: color ? color.diagPropValue.toString() : "",
        }));
      } else {
        setLinkColor((prevLinkColor) => ({
          ...prevLinkColor,
          color: "",
        }));
      }
    }
  }, [selectedItemID, editedProperties]);
  const handleLinkAlignmentChange = (name, value) => {
    // Update the appropriate state variable based on the name passed
    if (
      name === "Left" ||
      name === "Center" ||
      name === "Right" ||
      name === "Stretch"
    ) {
      setLinkHorizontalAlignment({
        left: name === "Left" ? value : 0,
        horizontalCenter: name === "Center" ? value : 0,
        right: name === "Right" ? value : 0,
        horizontalStretch: name === "Stretch" ? value : 0,
      });
      handlePropertyChange("Link_Alignment_Horizontal", name);
    }
  };
  const handleLinkColorChange = (name, value) => {
    setLinkColor({
      ...linkColor,
      [name]: value,
    });
    if (name === "backgroundColor") {
      handlePropertyChange("Link_Background", value);
    } else {
      handlePropertyChange("Link_Color", value);
    }
  };
  const handleBgColorClear = () => {
    setLinkColor({
      ...linkColor,
      backgroundColor: "",
    });
    handlePropertyChange("Link_Background", "transparent");
  };
  return (
    <>
      <PropertiesAccordion title={"Link Alignment"}>
        <IconBox
          handleChange={handleLinkAlignmentChange}
          buttonList={[
            {
              icon: <PiAlignLeftSimpleDuotone />,
              name: "Left",
              value: linkHorizontalAlignment.left,
            },
            {
              icon: <BsAlignCenter />,
              name: "Center",
              value: linkHorizontalAlignment.horizontalCenter,
            },
            {
              icon: <PiAlignRightSimpleBold />,
              name: "Right",
              value: linkHorizontalAlignment.right,
            },
            {
              icon: <RxStretchHorizontally />,
              name: "Stretch",
              value: linkHorizontalAlignment.horizontalStretch,
            },
          ]}
        />
      </PropertiesAccordion>
      <PropertiesAccordion title={"Link Font"}>
        <FontDropDown
          selectedItemID={selectedItemID}
          editedProperties={editedProperties}
          handlePropertyChange={handlePropertyChange}
          propSection="Link"
        />
      </PropertiesAccordion>
      <PropertiesAccordion title={"Link Divider"}>
        <DividerDropDown
          selectedItemID={selectedItemID}
          editedProperties={editedProperties}
          handlePropertyChange={handlePropertyChange}
          propSection="Link"
        />
      </PropertiesAccordion>
      <PropertiesAccordion title={"Link Color"}>
        <div className="backgrounclr ">
          Background Color
          <div>
            {" "}
            <input
              type="color"
              name="backgroundColor"
              value={linkColor.backgroundColor}
              onChange={(e) => {
                handleLinkColorChange(e.target.name, e.target.value);
              }}
            />
            <div className="iconset clearColorWrap">
              <div className="clearColor" onClick={handleBgColorClear}>
                <span className="crsLinelft"></span>
                <span className="crsLinerht"></span>
                <span className="tooltiptext">Transparent</span>
              </div>
            </div>
          </div>
        </div>
        <div className="backgrounclr ">
          Color
          <input
            type="color"
            name="color"
            value={linkColor.color}
            onChange={(e) => {
              handleLinkColorChange(e.target.name, e.target.value);
            }}
          />
        </div>
      </PropertiesAccordion>
    </>
  );
}

export default LinkDropDowns;
